import { useFind } from 'src/api/APIHooks';
import { defaultQueryConfig } from 'src/components/Backoffice/api/hooks/apiHookOptions';
import { isEmpty } from 'lodash';

export const useFindProviders = (
  providerIds,
) => useFind(
  'providers',
  { id: providerIds },
  {
    queryConfig: {
      enabled: !isEmpty(providerIds),
      placeholderData: undefined,
      ...defaultQueryConfig,
    },
  },
);

export const useFindProvidersByNameAndNetwork = (
  providerName,
  providerNetwork,
  state,
  licensed,
  params = { queryConfig: defaultQueryConfig },
) => useFind(
  'providers',
  {
    name: providerName,
    networks: providerNetwork,
    state,
    licensed,
  },
  {
    queryConfig: {
      enabled: !!providerNetwork,
      placeholderData: undefined,
    },
    ...params,
  },
);
