import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  BaseCard,
  BaseCardBody,
} from '@unite-us/ui';
import ReferralHistoryTable from 'src/components/ReferralHistory/components/ReferralHistoryTable';
import useCaseReferrals from 'src/components/Cases/hooks/useCaseReferrals';
import { usePopulate } from 'src/api/APIHooks';
import './ReferralDetailReferralHistory.scss';

const ReferralDetailReferralHistory = ({ serviceCase, groupId, isClosedByCoordinationCenter }) => {
  usePopulate('provider', 'provider', serviceCase, { queryConfig: { placeholderData: undefined } });
  const { isLoading: isLoadingReferrals, allReferrals: referrals } = useCaseReferrals(serviceCase);

  return isLoadingReferrals ? null : (
    <div className="case-referral-history">
      <h2>Referral History</h2>
      <div className="referral-history-table">
        <BaseCard className="referral-history">
          <BaseCardBody>
            <ReferralHistoryTable
              referrals={referrals}
              groupId={groupId}
              isClosedByCoordinationCenter={isClosedByCoordinationCenter}
            />
          </BaseCardBody>
        </BaseCard>
      </div>
    </div>
  );
};

ReferralDetailReferralHistory.propTypes = {
  serviceCase: PropTypes.object.isRequired,
  groupId: PropTypes.string.isRequired,
  isClosedByCoordinationCenter: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    serviceCase: _.wget(ownProps, 'referral.case'),
    groupId: _.wget(ownProps, 'groupId'),
  };
}

export default connect(mapStateToProps)(ReferralDetailReferralHistory);
