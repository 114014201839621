import React from 'react';
import PropTypes from 'prop-types';
import { SearchableSelectField } from 'components/Backoffice/form/SearchableSelectField';
import { useManagedProviderOptions } from '../../hooks';

export const ManagedProviderFilter = ({
  className,
  labelClassName,
  onChange,
  value,
}) => {
  const nlProviders = useManagedProviderOptions();

  return (
    <SearchableSelectField
      className={className}
      label="Provider"
      labelClassName={labelClassName}
      onChange={({ value: val }) => onChange(val)}
      options={nlProviders}
      placeholder="Choose Provider"
      value={value ?? ''}
      truncateOptions={false}
      clearable
    />
  );
};

ManagedProviderFilter.propTypes = {
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

ManagedProviderFilter.defaultProps = {
  className: undefined,
  labelClassName: undefined,
  value: undefined,
};
