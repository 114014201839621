import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { datadogRum } from '@datadog/browser-rum';
import { SEARCH_CLIENTS } from '@unite-us/app-search';
import { init, useAppCreateReferralContext } from '@unite-us/app-create-referral';
import { TrackerContext } from '@unite-us/client-utils';
import { ComponentLibraryLoader } from '@unite-us/app-components';
import { getAuthToken } from 'src/api/config';
import { SEARCH_API, CORE_API, GOOGLE_MAPS_API_KEY, SHARES_URL } from 'src/config/env/env.config';
import { get, find } from 'lodash';
import Notifier from 'common/helpers/Notifier';
import { updateGlobalState } from 'actions/Global/globalActions';
import {
  uup459SupersetPhase2 as uup459SupersetPhase2Selector,
  hint716SearchNetworkHubSupportPremiumSelector,
  hint921ManualSendback as hint921ManualSendbackSelector,
  hint1066PaymentsLiteModal as hint1066PaymentsLiteModalSelector,
  hint1246HideLgbtqPlusFilter as hint1246HideLgbtqPlusFilterSelector,
  hint1980ShowSharesMessageBox as hint1980ShowSharesMessageBoxSelector,
  includePathwaysServices as serviceTypesIncludePathwaysSelector,
  crtb1127AuthPaymentProgramsInReferrals as crtb1127AuthPaymentProgramsInReferralsSelector,
  cerb1367ResourceListDetailEnhancements as cerb1367ResourceListDetailEnhancementsSelector,
  cerbNy1115 as cerbNy1115Selector,
  cerb1519ScreeningRequiredFeeSchedules as cerb1519ScreeningRequiredFeeSchedulesSelector,
} from 'common/utils/FeatureFlags/flags';
import { browserHistory } from '../../common/utils/browserHistory';
import './ResourceLists.scss';

const getBasename = ({ pathname, route, basename = '' }) => {
  const path = pathname.split(route).at(0);
  return `${basename}${path}${route}`;
};

const AppRL = ComponentLibraryLoader({
  loadFn: () => import('@unite-us/app-resource-list'),
  component: 'AppResourceList',
  loadingApp: 'app-client',
  source: 'app-resource-list',
  datadogRum,
});

const ShareDrawerLoader = ComponentLibraryLoader({
  loadFn: () => import('@unite-us/shares-utils'),
  component: 'ShareDrawer',
  loadingApp: 'app-client',
  source: 'shares-utils',
  datadogRum,
});

function ResourceLists({
  firstName,
  initialAddresses,
  location,
  params,
  employeeId,
  providerId,
  screeningRequiredFeeSchedulesIds,
  // FLAGS
  serviceTypeOptions,
  uup459SupersetPhase2,
  hint921ManualSendback,
  hint1066PaymentsLiteModal,
  hint716SearchNetworkHubSupportPremium,
  hint1246HideLgbtqPlusFilter,
  hint1980ShowSharesMessageBox,
  serviceTypesIncludePathways,
  crtb1127AuthPaymentProgramsInReferrals,
  cerb1367ResourceListDetailEnhancements,
  cerbNy1115,
}) {
  const trackEvent = useContext(TrackerContext);

  const basename = getBasename({
    basename: location.basename,
    pathname: location.pathname,
    route: 'resource-lists',
  });

  const { dispatch } = useAppCreateReferralContext();

  const routeReferralFromResourceLists = (id, personId, programs) => {
    dispatch(
      init({
        selectedPrograms: programs,
        person: personId,
        resourceListId: id,
      }),
    );

    browserHistory.push({
      pathname: '/referrals/2/create/builder',
    });
  };

  const routeFindMoreResourceLists = (id, personId) => {
    browserHistory.push(`/referrals/create/add-resources?resource_list=${id}&person=${personId}`);
  };

  const routeFindMore = (personId) => {
    browserHistory.push(`/referrals/create/add-resources?person=${personId}`);
  };

  return (
    <AppRL
      serviceTypeOptions={serviceTypeOptions}
      source="app-client"
      basename={basename}
      appState={{
        personId: params.id,
        providerId,
        employeeId,
        firstName,
        hint921ManualSendback,
        hint1066PaymentsLiteModal,
        initialAddresses,
        idsOfFeeSchedulesThatRequireSpecializedScreenings: screeningRequiredFeeSchedulesIds,
      }}
      callbacks={{
        trackEvent,
        notify: {
          error: (message) => Notifier.dispatch('error', message),
          success: (message) => Notifier.dispatch('success', message),
          warn: (message) => Notifier.dispatch('warning', message),
        },
        routeReferralFromResourceLists,
        routeFindMoreResourceLists,
        routeFindMore,
      }}
      appSettings={{
        client: SEARCH_CLIENTS.APP_CLIENT,
        components: { ShareDrawer: ShareDrawerLoader },
        env: {
          getAuthToken,
          employeeId,
          providerId,
          SHARES_URL,
          googleApiKey: GOOGLE_MAPS_API_KEY,
        },
        endpoints: {
          search: {
            url: SEARCH_API,
          },
          core: {
            url: CORE_API,
          },
        },
        flags: {
          hint1246HideLgbtqPlusFilter,
          hint716SearchNetworkHubSupportPremium,
          hint1980ShowSharesMessageBox,
          serviceTypesIncludePathways,
          uup459SupersetPhase2,
          crtb1127AuthPaymentProgramsInReferrals,
          cerb1367ResourceListDetailEnhancements,
          cerbNy1115,
        },
        roles: [],
      }}
    />
  );
}

// Should match shape of address validator in Core
const addressShape = {
  address_type: PropTypes.string.isRequired,
  line_1: PropTypes.string,
  line_2: PropTypes.string,
  city: PropTypes.string.isRequired,
  county: PropTypes.string,
  state: PropTypes.string.isRequired,
  postal_code: PropTypes.string,
  country: PropTypes.string.isRequired,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  is_primary: PropTypes.bool,
};

ResourceLists.propTypes = {
  serviceTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      parent_id: PropTypes.string,
      parent_code: PropTypes.string,
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      taxonomy: PropTypes.string.isRequired,
      is_sensitive: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  employeeId: PropTypes.string.isRequired,
  providerId: PropTypes.string.isRequired,
  serviceTypesIncludePathways: PropTypes.bool.isRequired,
  firstName: PropTypes.string.isRequired,
  initialAddresses: PropTypes.shape({
    CLIENT: PropTypes.arrayOf(PropTypes.shape(addressShape)),
    USER: PropTypes.arrayOf(PropTypes.shape(addressShape)),
    GROUP: PropTypes.arrayOf(PropTypes.shape(addressShape)),
  }).isRequired,
  // FLAGS
  cerbNy1115: PropTypes.bool.isRequired,
  uup459SupersetPhase2: PropTypes.bool.isRequired,
  hint716SearchNetworkHubSupportPremium: PropTypes.bool.isRequired,
  hint921ManualSendback: PropTypes.bool.isRequired,
  hint1066PaymentsLiteModal: PropTypes.bool.isRequired,
  hint1246HideLgbtqPlusFilter: PropTypes.bool.isRequired,
  hint1980ShowSharesMessageBox: PropTypes.bool.isRequired,
  crtb1127AuthPaymentProgramsInReferrals: PropTypes.bool.isRequired,
  cerb1367ResourceListDetailEnhancements: PropTypes.bool.isRequired,
  screeningRequiredFeeSchedulesIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

function mapStateToProps(state, ownProps) {
  const {
    currentEmployee: {
      addresses: userAddresses,
      provider: { addresses: groupAddresses, id: providerId },
    },
  } = state.globalState;

  const selectServiceTypes = createSelector(
    (inState) => inState.session.globals.service_types,
    (serviceTypes) => {
      const ret = [];
      serviceTypes.forEach((parentType) => {
        parentType.children.forEach((child) => ret.push({
          id: child.id,
          parent_id: parentType.id,
          parent_code: parentType.code,
          name: child.name,
          code: child.code,
          taxonomy: child.taxonomy,
          is_sensitive: child.sensitive,
        }));

        ret.push({
          id: parentType.id,
          parent_id: null,
          parent_code: null,
          name: parentType.name,
          code: parentType.code,
          taxonomy: parentType.taxonomy,
          is_sensitive: parentType.sensitive,
        });
      });
      return ret;
    },
  );

  const contactId = get(ownProps, 'params.id');
  const contact = find(state.contacts.contacts, { id: contactId });
  return {
    serviceTypeOptions: selectServiceTypes(state),
    employeeId: state.globalState.currentEmployee.id,
    uup459SupersetPhase2: uup459SupersetPhase2Selector(state),
    hint716SearchNetworkHubSupportPremium: hint716SearchNetworkHubSupportPremiumSelector(state),
    hint921ManualSendback: hint921ManualSendbackSelector(state),
    hint1066PaymentsLiteModal: hint1066PaymentsLiteModalSelector(state),
    hint1246HideLgbtqPlusFilter: hint1246HideLgbtqPlusFilterSelector(state),
    hint1980ShowSharesMessageBox: hint1980ShowSharesMessageBoxSelector(state),
    serviceTypesIncludePathways: serviceTypesIncludePathwaysSelector(state),
    crtb1127AuthPaymentProgramsInReferrals: crtb1127AuthPaymentProgramsInReferralsSelector(state),
    cerb1367ResourceListDetailEnhancements: cerb1367ResourceListDetailEnhancementsSelector(state),
    screeningRequiredFeeSchedulesIds: cerb1519ScreeningRequiredFeeSchedulesSelector(state),
    cerbNy1115: cerbNy1115Selector(state),
    firstName: contact?.first_name,
    providerId,
    initialAddresses: {
      client: contact?.addresses,
      user: userAddresses,
      ours: groupAddresses,
    },
  };
}

export default connect(
  mapStateToProps,
  {
    updateGlobalState,
  },
  null,
  { forwardRef: true },
)(ResourceLists);
