import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { get } from 'lodash';
import { Icon } from '@unite-us/ui';
import { dollarAmount } from 'src/common/display/Money/DollarAmount';
import { useFindRecord } from '../../../api/APIHooks';
import { INVOICE_STATUS } from '../../../pages/invoices/constants';

const isTask = (key) => key.startsWith('Task.');
const isInvoice = (key) => key.includes('invoice');
const isServiceAuthorization = (key) => key.includes('service_authorization');
const isSCCEnded = (key) => key.includes('social_care_coverage');
let notificationAction = '';

const taskMessage = (key) => {
  const [, , name, client, date, status, type] = key.split('.');
  const isReminderContactType = type === 'reminder_contact';
  const message = {
    taskName: isReminderContactType ? 'User Created' : name,
    client: client ? `for ${client} ` : '',
    formattedDate: moment(date).format('MM/DD/YYYY'),
  };

  if (isReminderContactType) {
    switch (status) {
      case 'new':
        return `You have been assigned a ${message.taskName} task ${message.client}due ${message.formattedDate}`;
      case 'upcoming':
        return `You have an upcoming ${message.taskName} task ${message.client}due ${message.formattedDate}`;
      case 'due':
        return `Your ${message.taskName} task ${message.client}is due today`;
      case 'overdue':
        return `Your ${message.taskName} task ${message.client}was due ${message.formattedDate}`;
      case 'assigned':
        return `You have been assigned a ${message.taskName} task ${message.client}due ${message.formattedDate}`;
      default:
        return `Task ${message.taskName} is ${status}`;
    }
  } else {
    switch (status) {
      case 'new':
        return `You have a new ${message.taskName} task ${message.client}due ${message.formattedDate}`;
      case 'due':
        return `Task ${message.taskName} ${message.client}is due today`;
      case 'overdue':
        return `Task ${message.taskName} ${message.client}was due ${message.formattedDate}`;
      case 'assigned':
        return `You have been assigned a ${message.taskName} task ${message.client}due ${message.formattedDate}`;
      default:
        return `Task ${message.taskName} is ${status}`;
    }
  }
};

const invoiceMessage = (notification) => {
  const key = notification.key;
  const { data: invoiceData } = useFindRecord(
    'invoice',
    notification.subject.id,
    {
      queryConfig: {
        enabled: !!notification?.subject?.id,
        placeholderData: undefined,
      },
    },
  );
  const invoice = get(invoiceData, 'data.data', {});
  const DISPUTED_STATUS_OPTIONS = [
    {
      value: INVOICE_STATUS.rejected_by_payer,
      label: 'Rejected by Payer',
    },
    {
      value: INVOICE_STATUS.accepted_by_payer,
      label: 'Accepted',
    },
    {
      value: INVOICE_STATUS.paid,
      label: 'Paid',
    },
  ];
  const disputeInvoiceStatus = DISPUTED_STATUS_OPTIONS.find((status) => status.value === invoice.invoice_status)?.label;
  switch (key) {
    case 'invoice_received':
      return {
        invoice_received:
          `New invoice received from ${invoice.provider_name} for ${dollarAmount(invoice.total_amount_invoiced, true)}`,
      }[key];
    case 'invoice_under_dispute':
      return {
        invoice_under_dispute: `${disputeInvoiceStatus} invoice has been disputed by ${invoice.provider_name}`,
      }[key];
    case 'invoice_dispute_resolved':
      return {
        invoice_dispute_resolved: `Disputed invoice has been resolved by ${invoice.provider_name}`,
      }[key];
    default:
      return undefined;
  }
};

const serviceAuthorizationMessage = (notification) => {
  const key = notification.key.split('.')[0];
  const data = notification.message_data;

  const message = (action) => `Your authorization request for ${data.fsp_name}` +
    ` was ${action} by ${data.reviewer_name}`;

  switch (key) {
    case 'service_authorization_approved':
      return {
        service_authorization_approved: message('accepted'),
      }[key];
    case 'service_authorization_denied':
      return {
        service_authorization_denied: message('rejected'),
      }[key];
    default:
      return undefined;
  }
};

const sccEndedMessage = (notification) => {
  const messageData = notification.message_data;
  const clientName = messageData.client.name;
  const plan = messageData.plan;
  const feeScheduleProgram = messageData.fee_schedule_program;
  const disenrollDate = messageData.disenroll_date;
  // eslint-disable-next-line max-len
  return (<>The {plan} plan for <b>{clientName}&apos;s {feeScheduleProgram}</b> case has ended. Your organization will not be able to submit contracted service notes or invoices for services provided after <b>{disenrollDate}</b>. Please work with the client&apos;s care team to coordinate client/member outreach and close the affected case(s).</>);
};

const notificationMessage = (key, notification) => {
  notificationAction = '';
  if (isInvoice(key)) {
    notificationAction = 'View Invoice';
    return invoiceMessage(notification);
  }

  if (isServiceAuthorization(key)) {
    notificationAction = 'View Case';
    return serviceAuthorizationMessage(notification);
  }

  if (isSCCEnded(notification.key)) {
    notificationAction = 'View Case';
    return sccEndedMessage(notification);
  }

  if (key === 'export_completed') {
    notificationAction = 'Download';
    return 'Invoice export file is ready to download';
  }

  return taskMessage(key);
};

const notificationIcon = (key) => {
  if (isTask(key)) {
    return 'IconClipboardCheck';
  }

  if (isInvoice(key) || isServiceAuthorization(key)) {
    return 'IconDollarSign';
  }

  if (isSCCEnded(key)) {
    return 'IconFileText';
  }

  return {
    export_completed: 'IconDownloadV2',
  }[key];
};

const EmployeeNotification = ({ notification }) => (
  <div className="flex items-start justify-between pr-2">
    <div className="flex justify-start items-start">
      <div className="relative flex justify-center items-center mr-4">
        <div className="rounded-full h-10 w-10 bg-medium-fill-grey" />
        <Icon
          color="#182A44"
          className="absolute"
          icon={notificationIcon(notification.key)}
          size={18}
        />
      </div>
      <div className="flex flex-col text-sm whitespace-pre-wrap break-words">
        <p className="mb-px">{notificationMessage(notification.key, notification)}</p>
        <p className="text-action-blue mt-px">{notificationAction}</p>
        <b className="mt-1">{moment(new Date(notification.created_at)).fromNow()}</b>
      </div>
    </div>
    <div className="max-width">
      {
        !notification.viewed && (
          <Icon
            color="text-brand-blue"
            icon="IconCircle"
            size={26}
          />
        )
      }
    </div>
  </div>
);

EmployeeNotification.propTypes = {
  notification: PropTypes.object,
};

EmployeeNotification.defaultProps = {
  notification: {},
};

export default EmployeeNotification;
