import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hasPayerInvoicesRole as getHasPayerInvoicesRole } from 'common/utils/FeatureFlags/flags';
import { LogoCard } from 'src/common/TailwindComponents';
import { InvoiceWorkqueue } from './InvoiceWorkqueue';
import { NoFilteredInvoicesFound } from '../components';
import { ALL_CBO_ADMIN_STATUSES, ALL_NL_STATUSES, ALL_PAYER_STATUSES } from '../constants';

const NoInvoicesExist = () => (
  <>
    <h4 className="font-heavy-font text-base text-dark-grey">
      You don&apos;t have any invoices yet.
    </h4>
    <p className="max-w-xs text-dark-grey">
      Invoices are added to this list whenever a contracted service has been submitted for a client.
    </p>
  </>
);

const AllInvoices = ({ children, hasPayerInvoicesRole }) => {
  let bulkActions;
  if (hasPayerInvoicesRole) {
    bulkActions = ['LogoShield', 'IconCheckCircle', 'IconCrossCircleV2', 'IconDownloadV2', 'IconArchive'];
  } else {
    bulkActions = ['IconDownloadV2', 'IconArchive'];
  }

  return (
    <InvoiceWorkqueue
      bulkActions={bulkActions}
      cboStatuses={ALL_CBO_ADMIN_STATUSES}
      networkLeadStatuses={ALL_NL_STATUSES}
      pageName="All"
      path="all/"
      payerStatuses={ALL_PAYER_STATUSES}
      renderNoInvoicesFound={({ isFiltered, resetFilters }) => (
        <LogoCard>
          {isFiltered ? (
            <NoFilteredInvoicesFound resetFilters={resetFilters} />
          ) : (
            <NoInvoicesExist />
          )}
        </LogoCard>
      )}
      showArchived={null}
      showDisputed={null}
    >
      {children}
    </InvoiceWorkqueue>
  );
};

AllInvoices.propTypes = {
  children: PropTypes.node,
  hasPayerInvoicesRole: PropTypes.bool,
};

AllInvoices.defaultProps = {
  children: undefined,
  hasPayerInvoicesRole: false,
};

const mapStateToProps = (state) => ({
  hasPayerInvoicesRole: getHasPayerInvoicesRole(state),
});

export default connect(mapStateToProps)(AllInvoices);
