import { clearSession } from 'common/utils/utils';
import { remove as removeCookie } from 'es-cookie';
import { COOKIE_SESSION_KEY } from 'src/common/constants';

const submitLogoutForm = () => {
  const form = document.getElementById('logout-auth-form');

  removeCookie(COOKIE_SESSION_KEY);
  // log all other UU tabs out
  localStorage.setItem('autologout', JSON.stringify({
    warn: false,
    logout: true,
  }));
  clearSession();

  if (form) {
    form.submit();
  }
};

export default submitLogoutForm;
