import { get as getCookie, set as setCookie } from 'es-cookie';
import { get } from 'lodash';
import axios from 'axios';
import moment from 'moment';
import { setAuthTokenClients, getAuthTokenDetails } from 'src/api/config';
import { datadogLogs } from '@datadog/browser-logs';
import { setAxiosHeaders, secureProtocol } from 'common/utils/utils';
import { AUTH_URL, CLIENT_ID } from 'src/config/env/env.config';
import { SET_AUTH_SESSION } from 'actions';
import { COOKIE_SESSION_KEY } from 'src/common/constants';

const SECONDS_BETWEEN_RETRIES = 15;
const NUMBER_OF_RETRIES = 5;

const refreshTokenCall = async (AUTH_TOKEN_URL, reqParams, shouldLog, retry = 0) => {
  const startTime = Date.now();
  try {
    const authResponse = await axios.post(AUTH_TOKEN_URL, reqParams);
    return authResponse;
  } catch (authError) {
    const isConnectedToInternet = get(navigator, 'onLine', 'unknown');
    const duration = Date.now() - startTime;
    if (shouldLog) {
      datadogLogs.logger.info('LogoutLogs: Auth Refresh failed', {
        authError,
        retry,
        isConnectedToInternet,
        duration: `${duration}ms`,
      });
    }

    await new Promise((resolve) => setTimeout(resolve, SECONDS_BETWEEN_RETRIES * 1000));
    if (retry < NUMBER_OF_RETRIES) {
      return refreshTokenCall(AUTH_TOKEN_URL, reqParams, shouldLog, retry + 1);
    }
    if (shouldLog) {
      datadogLogs.logger.error(`LogoutLogs: Auth Refresh failed after ${NUMBER_OF_RETRIES} retries`, {
        authError,
      });
    }
    return null;
  }
};

const refresh = async (dispatch, getState, submitLogoutForm) => {
  const state = getState();
  const shouldLog = datadogLogs && get(state, 'flags.logoutLogs', false);
  const logData = {
    globalStateImpersonation: get(state, 'globalState.impersonation'),
    sessionImpersonationType: get(state, 'session.impersonationType'),
    flagAutoLogout: get(state, 'flags.autoLogout'),
    flagAutoLogoutConfig: get(state, 'flags.autoLogoutConfig'),
    flagRefreshTokenPoll: get(state, 'flags.refreshTokenPoll'),
  };
  try {
    if (shouldLog) {
      datadogLogs.logger.info('LogoutLogs: refresh()', { COOKIE_SESSION_KEY });
    }
    const tokenFromCookie = getCookie(COOKIE_SESSION_KEY);
    if (shouldLog) {
      datadogLogs.logger.info('LogoutLogs: tokenFromCookie', { tokenFromCookie });
    }
    if (!tokenFromCookie) {
      if (shouldLog) {
        datadogLogs.logger.error('LogoutLogs: No token cookie - do not refresh Auth token. Logging out.');
      }
      return submitLogoutForm();
    }
    const parsedTokenCookie = JSON.parse(tokenFromCookie);
    if (shouldLog) {
      datadogLogs.logger.info('LogoutLogs: parsedTokenCookie', {
        parsedTokenCookie,
        isParsedTokenCookieNull: Boolean(parsedTokenCookie),
        ...logData,
      });
    }
    if (!parsedTokenCookie) {
      if (shouldLog) {
        datadogLogs.logger.error('LogoutLogs: No parsed token cookie - do not attempt to refresh Auth token', {
          ...logData,
        });
      }
      return submitLogoutForm();
    }

    const now = moment().unix();
    const { created_at } = parsedTokenCookie;

    // If the token was created less than 10 seconds ago, don't refresh it
    // This occurs when multiple tabs are open and intervals create an Auth race condition
    if (now - created_at < 10) {
      if (shouldLog) {
        datadogLogs.logger.info('LogoutLogs: Token was created less than 10 seconds ago, not refreshing');
      }
      return null;
    }

    if (shouldLog && !parsedTokenCookie.refresh_token) {
      datadogLogs.logger.error('LogoutLogs: No refresh token in cookie', {
        parsedTokenCookie,
        ...logData,
      });
    }

    const reqParams = {
      client_id: CLIENT_ID,
      refresh_token: parsedTokenCookie.refresh_token,
      grant_type: 'refresh_token',
    };

    const AUTH_TOKEN_URL = `${AUTH_URL}/oauth2/token`;
    if (shouldLog) {
      datadogLogs.logger.info('LogoutLogs: before POST to /auth', {
        AUTH_TOKEN_URL,
        reqParams,
      });
    }

    const authResponse = await refreshTokenCall(AUTH_TOKEN_URL, reqParams, shouldLog);
    if (!authResponse) {
      if (shouldLog) {
        datadogLogs.logger.error(`LogoutLogs: Cannot refresh token after ${NUMBER_OF_RETRIES} retries. Logging out.`);
      }
      return submitLogoutForm();
    }
    if (shouldLog) {
      datadogLogs.logger.info('LogoutLogs: authResponse', { authResponse });
    }
    const { data } = authResponse;
    const expiresIn = data.expires_in;
    if (shouldLog && (!expiresIn || expiresIn < 890 || expiresIn > 900)) {
      datadogLogs.logger.error('LogoutLogs: Invalid expires_in value', { expiresIn });
    }
    const expirationDate = new Date();
    expirationDate.setSeconds(expirationDate.getSeconds() + expiresIn);

    if (!data.refresh_token && window.Rollbar) {
      if (shouldLog) {
        datadogLogs.logger.error('LogoutLogs: No refresh token in response', { data });
      }
      window.Rollbar.error(
        `Setting COOKIE_SESSION_KEY (2).
        Auth response: ${JSON.stringify(data, 0, 2)},
        Request params: ${JSON.stringify(reqParams, 0, 2)}`,
      );
    }

    setCookie(COOKIE_SESSION_KEY, JSON.stringify(data), { secure: secureProtocol, expires: expirationDate });
    setAxiosHeaders(data.access_token);
    setAuthTokenClients(data.access_token);
    const payload = {
      session: {
        access_token: data.access_token,
        token: data.refresh_token,
        expires_in: data.expires_in,
      },
    };

    return dispatch({
      type: SET_AUTH_SESSION,
      payload,
    });
  } catch (error) {
    const message = 'Error refreshing token';
    if (window.Rollbar) {
      window.Rollbar.error(message, error);
    } else {
      // eslint-disable-next-line no-console
      console.error(message, error);
    }

    const currentTokenDetails = getAuthTokenDetails(shouldLog);
    return datadogLogs.logger.error('RefreshSession: Error refreshing token', {
      error,
      currentTokenDetails,
    });
  }
};

export default function refreshSession(submitLogoutForm) {
  return (dispatch, getState) => refresh(dispatch, getState, submitLogoutForm);
}
