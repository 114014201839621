export const PLAN_TYPES = [
  { label: 'Medicare', value: 'medicare' },
  { label: 'Medicaid', value: 'medicaid' },
  { label: 'Commercial', value: 'commercial' },
  { label: 'Social', value: 'social' },
  { label: 'Tricare', value: 'tricare' },
];

export const ENROLLMENT_OPTIONS = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

export const NETWORK_ORG_RADIO_OPTIONS = [
  { label: 'Add this Network', value: 'add-network-selected' },
  { label: 'Add Organizations From This Network', value: 'add-orgs-selected' },
];
