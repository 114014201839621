import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { CheckBoxField } from '@unite-us/ui';
import {
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
  Button,
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from '@unite-us/app-components';
import { tableWidth } from 'common/helpers/Tables';
import Pagination from 'common/Pagination/Pagination';
import { Spinner } from 'common/spinners';
import scrollToElement from 'common/utils/Form/ScrollToElement';
import EmptyTable from 'common/tables/EmptyTable/EmptyTable';

import FeeScheduleScreeningProgramsAssociations from './FeeScheduleScreeningProgramsAssociations';
import { useProgramTable } from './useProgramsTable';

const FeeScheduleScreeningProgramsTable = ({ feeScheduleScreening }) => {
  const {
    programs,
    paging,
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    isLoading,
    selectedProgramsForRemove,
    onCheckAllProgramsForRemove,
    onCheckProgramForRemove,
    onRemovePrograms,
  } = useProgramTable(feeScheduleScreening);

  const addProgramModalRef = useRef(null);

  const openAddProgramModal = () => {
    addProgramModalRef.current.openDialog();
  };

  const scrollToElementEvent = () => {
    scrollToElement(document.getElementById('TopOfForms'));
  };

  if (isLoading) return <Spinner />;

  return (
    <>
      <div className="fee-schedule-base-card">
        <BaseCard className="p-6" noBorder>
          <BaseCardHeader title="Programs" noBorder className="px-6 font-bold">
            <Button
              id="edit-attachment-button"
              icon={{
                name: 'Plus', // TODO: update app-components to add PlusCircle icon https://uniteus.atlassian.net/browse/PAYS-7497
                position: 'left',
                color: 'current-blue',
              }}
              className="flex"
              label="Add Program"
              onClick={openAddProgramModal}
              disabled={
                moment.utc().isAfter(moment(feeScheduleScreening.ends_at).utc()) ||
                !feeScheduleScreening.providers.length
              }
            />
            <Button
              id="edit-attachment-button-secondary"
              icon={{
                name: 'MinusCircle',
                color: 'current-blue',
                position: 'left',
              }}
              className="flex"
              label="Remove Program"
              onClick={onRemovePrograms}
              disabled={
                moment.utc().isAfter(moment(feeScheduleScreening.ends_at).utc()) ||
                !feeScheduleScreening.programs.length ||
                !selectedProgramsForRemove.length
              }
            />
          </BaseCardHeader>
          <BaseCardBody className="px-6">
            <Table id="fee-schedule-screenings-programs-table">
              <TableHeader>
                <TableRow>
                  {programs.length > 0 && (
                    <TableHeaderColumn className="w-6 h-6 py-3 px-4" style={{ width: tableWidth('icon') }}>
                      <CheckBoxField
                        id="select-all-checkbox"
                        className="mb-0 h-6"
                        checked={selectedProgramsForRemove.length === programs.length && !isEmpty(programs)}
                        onChange={onCheckAllProgramsForRemove}
                      />
                    </TableHeaderColumn>
                  )}
                  <TableHeaderColumn style={{ width: tableWidth('large') }}>
                    PROGRAM NAME
                  </TableHeaderColumn>
                  <TableHeaderColumn style={{ width: tableWidth('small') }}>
                    PROGRAM ID
                  </TableHeaderColumn>
                  <TableHeaderColumn style={{ width: tableWidth('small') }}>
                    CBO
                  </TableHeaderColumn>
                </TableRow>
              </TableHeader>
              <TableBody>
                {programs.map((program, idx) => (
                  <TableRow
                    dataTestId={`fee-schedule-screening-program-row-${idx}`}
                    key={program.id}
                  >
                    <TableRowColumn className="w-6 h-6 flex">
                      <CheckBoxField
                        className="mb-0 h-6"
                        id={`select-checkbox-${program.id}`}
                        checked={selectedProgramsForRemove.includes(program.id)}
                        onChange={onCheckProgramForRemove(program.id)}
                      />
                    </TableRowColumn>
                    <TableRowColumn style={{ width: tableWidth('large') }}>
                      {program.name}
                    </TableRowColumn>
                    <TableRowColumn style={{ width: tableWidth('small') }}>
                      {program.id}
                    </TableRowColumn>
                    <TableRowColumn style={{ width: tableWidth('small') }}>
                      {program?.provider?.name}
                    </TableRowColumn>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {(paging.total_count === 0) && (
              <EmptyTable
                className="p-6 border border-t-0 border-solid border-light-border-blue"
                message={(
                  <>
                    <p>No programs linked to this Fee Schedule Screening</p>
                    <p>Click “Add Program” to add a program to this Fee Schedule Screening.</p>
                  </>
                )}
              />
            )}
            <div className="p-6">
              <Pagination
                pageNumber={pageNumber}
                pageSize={pageSize}
                totalItemCount={paging.total_count}
                totalPageCount={paging.total_pages}
                setPageSize={setPageSize}
                setPageNumber={setPageNumber}
                scrollPaginationElement={scrollToElementEvent}
              />
            </div>
          </BaseCardBody>
        </BaseCard>
      </div>
      <FeeScheduleScreeningProgramsAssociations feeScheduleScreening={feeScheduleScreening} ref={addProgramModalRef} />
    </>
  );
};

FeeScheduleScreeningProgramsTable.propTypes = {
  feeScheduleScreening: PropTypes.object.isRequired,
};

export default FeeScheduleScreeningProgramsTable;
