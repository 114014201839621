import React from 'react';
import PropTypes from 'prop-types';
import formatShortDate from 'src/common/utils/Dates/formatShortDate';
import { dollarAmount } from 'src/common/display/Money/DollarAmount';
import { CardDetail } from 'src/common/Card';
import moment from 'moment';

const DisplayAddress = ({ address }) => (
  <>
    <p>{address.line_1}</p>
    <p>{address.line_2 ? address.line_2 : ''}</p>
    <p>{`${address.city}, ${address.state} ${address.postal_code}`}</p>
  </>
);

export const InvoiceMetafieldsSection = ({
  providedServiceMetafields,
  feeScheduleProgramMetafields,
}) => {
  const displayMetafields = feeScheduleProgramMetafields?.reduce((metafieldsAccumulator, fsMetafield) => {
    if (fsMetafield.type === 'file' || fsMetafield.field === 'service_description') {
      return metafieldsAccumulator;
    }

    const psMetaFieldMatch =
      providedServiceMetafields.find((psMetaField) => psMetaField.field === fsMetafield.field);

    if (psMetaFieldMatch) {
      metafieldsAccumulator.push({
        ...fsMetafield,
        ...psMetaFieldMatch,
      });
    }

    return metafieldsAccumulator;
  }, []);

  return (
    <>
      {
        displayMetafields?.map((metafield) => {
          switch (metafield.type) {
            case 'address':
              return (
                <CardDetail
                  key={metafield.field}
                  label={metafield.label}
                >
                  <DisplayAddress address={metafield.value} />
                </CardDetail>
              );
            case 'multipledropdown':
              return (
                <CardDetail
                  key={metafield.field}
                  label={metafield.label}
                >
                  {metafield.value.join(', ')}
                </CardDetail>
              );
            case 'money':
              return (
                <CardDetail
                  key={metafield.field}
                  label={metafield.label}
                >
                  {dollarAmount(metafield.value, true)}
                </CardDetail>
              );
            case 'date':
              return (
                <CardDetail
                  key={metafield.field}
                  label={metafield.label}
                >
                  {formatShortDate(metafield.value)}
                </CardDetail>
              );
            case 'datetime':
              return (
                <CardDetail
                  key={metafield.field}
                  label={metafield.label}
                >
                  {moment(metafield.value).format('dddd, MMMM Do YYYY, h:mm a')}
                </CardDetail>
              );
            default:
              return <CardDetail key={metafield.field} label={metafield.label}>{metafield.value}</CardDetail>;
          }
        })
      }
    </>
  );
};

InvoiceMetafieldsSection.propTypes = {
  providedServiceMetafields: PropTypes.array.isRequired,
  feeScheduleProgramMetafields: PropTypes.array.isRequired,
};

DisplayAddress.propTypes = {
  address: PropTypes.shape({
    line_1: PropTypes.string,
    line_2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postal_code: PropTypes.string,
  }).isRequired,
};
