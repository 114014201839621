import React from 'react';
import {
  SelectField,
  BaseCard,
  BaseCardHeader,
  BaseCardBody,
  InputField,
} from '@unite-us/ui';
import { ageRangeConverters } from '@unite-us/client-utils';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import DEFAULT_ENUMS from 'src/reducers/constants/defaultEnums';
import DivReset from 'src/components/Organization/components/DivLineHeightReset';
import addLabelsToEnums from 'common/utils/addLabelsToEnums';

const {
  upperAgeLimit, validateMaxRange, validateMinRange, agePreview,
} = ageRangeConverters;

const addLabels = (options) => options.map((option) => ({ ...option, label: option.display_name }));

const ageUnitEnums = DEFAULT_ENUMS.groups.programs.eligibility.age_units;
const ageUnitOptions = addLabelsToEnums(ageUnitEnums);

const AccessibilityFormV2 = ({ values }) => {
  const {
    base: { languages },
    groups: {
      programs: {
        population, payment_options, accessibility_options, transportation_options,
      },
    },
  } = DEFAULT_ENUMS;
  const populationOptions = addLabels(population);
  const languageOptions = addLabels(languages);
  const accessibilityOptions = addLabelsToEnums(accessibility_options);
  const paymentOptions = addLabelsToEnums(payment_options);
  const transportationOptions = addLabelsToEnums(transportation_options);
  const {
    age_spec_min,
    age_spec_min_units,
    age_spec_max,
    age_spec_max_units,
  } = values;

  // eslint-disable-next-line no-shadow
  const ageRangeMinValidation = (value, { age_spec_min_units, age_spec_max, age_spec_max_units }) => (
    validateMinRange(value, age_spec_min_units, age_spec_max, age_spec_max_units)
  );

  // eslint-disable-next-line no-shadow
  const ageRangeMaxValidation = (value, { age_spec_min, age_spec_min_units, age_spec_max_units }) => (
    validateMaxRange(age_spec_min, age_spec_min_units, value, age_spec_max_units)
  );

  return (
    <BaseCard className="mt-6">
      <BaseCardHeader title="Program Accessibility">
        <div className="pt-2 -mb-2" />
      </BaseCardHeader>
      <BaseCardBody className="px-8 py-10">
        <DivReset className="my-2">
          <Field name="languages" data-test-element="languages">
            {(params) => (
              <SelectField
                label="Languages supported:"
                multiple
                placeholder="Select languages..."
                id="languages"
                options={languageOptions}
                required
                isRequired
                searchThreshold={0.1}
                {...params}
              />
            )}
          </Field>
        </DivReset>
        <DivReset className="my-2">
          <Field
            name="population_specializations"
            data-test-element="population_specializations"
          >
            {(params) => (
              <SelectField
                label="Populations specialized in:"
                multiple
                placeholder="Select populations..."
                id="population_specializations"
                options={populationOptions}
                {...params}
              />
            )}
          </Field>
        </DivReset>
        <DivReset className="my-2">
          <label htmlFor="age" className="ui-form-field__label">
            Age(s) specialized in:
          </label>
          <div className="flex">
            <div className="flex ml-2 gap-2 age-range-box">
              <Field
                name="age_spec_min"
                validate={ageRangeMinValidation}
              >
                {(params) => (
                  <InputField
                    label="Min"
                    id="min-age"
                    type="number"
                    min={0}
                    max={upperAgeLimit(age_spec_min_units)}
                    step="1"
                    {...params}
                  />
                )}
              </Field>
              <Field name="age_spec_min_units">
                {(params) => (
                  <SelectField
                    dataTestElement="age_spec_min_units_dropdown"
                    label="Unit"
                    id="age_spec_min_units"
                    options={ageUnitOptions}
                    required={!!age_spec_min}
                    {...params}
                  />
                )}
              </Field>
            </div>
            <div className="flex ml-2 gap-2 age-range-box">
              <Field
                name="age_spec_max"
                validate={ageRangeMaxValidation}
              >
                {(params) => (
                  <InputField
                    label="Max"
                    id="max-age"
                    type="number"
                    min={0}
                    max={upperAgeLimit(age_spec_max_units)}
                    step="1"
                    {...params}
                  />
                )}
              </Field>
              <Field name="age_spec_max_units">
                {(params) => (
                  <SelectField
                    dataTestElement="age_spec_max_units_dropdown"
                    label="Unit"
                    id="age_spec_max_units"
                    options={ageUnitOptions}
                    required={!!age_spec_max}
                    {...params}
                  />
                )}
              </Field>
            </div>

            <div className="ml-4">
              <div className="preview-label">
                PREVIEW
              </div>
              <div className="flex align-center">
                <span className="my-2">
                  {agePreview(age_spec_min, age_spec_min_units, age_spec_max, age_spec_max_units)}
                </span>
              </div>
            </div>

          </div>
        </DivReset>
        <DivReset className="my-2">
          <Field
            name="accessibility_options"
            data-test-element="accessibility_options"
          >
            {(params) => (
              <SelectField
                label="Accessibility options:"
                multiple
                placeholder="Select accessibility options..."
                id="accessibility_options"
                options={accessibilityOptions}
                {...params}
              />
            )}
          </Field>
        </DivReset>
        <DivReset className="my-2">
          <Field name="transportation_options" data-test-element="transportation_options">
            {(params) => (
              <SelectField
                label="Transportation options:"
                multiple
                placeholder="Select transportation options..."
                id="transportation_options"
                options={transportationOptions}
                {...params}
              />
            )}
          </Field>
        </DivReset>
        <DivReset className="my-2">
          <Field name="payment_options" data-test-element="payment_options">
            {(params) => (
              <SelectField
                label="Payment options:"
                multiple
                placeholder="Select payment options..."
                id="payment_options"
                options={paymentOptions}
                {...params}
              />
            )}
          </Field>
        </DivReset>
      </BaseCardBody>
    </BaseCard>
  );
};

AccessibilityFormV2.propTypes = {
  values: PropTypes.shape({
    age_spec_min: PropTypes.string,
    age_spec_min_units: PropTypes.string,
    age_spec_max: PropTypes.string,
    age_spec_max_units: PropTypes.string,
  }),
};

AccessibilityFormV2.defaultProps = {
  values: {},
};

export default AccessibilityFormV2;
