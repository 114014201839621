export const audioEye = () => {
    const b = function() {
        // eslint-disable-next-line no-underscore-dangle
        window.__AudioEyeSiteHash = '9d983cb358691c74ab8c5ae9410572bf';
        const a = document.createElement('script');
        a.src = 'https://wsmcdn.audioeye.com/aem.js';
        a.type = 'text/javascript';
        a.setAttribute('async', '');
        document.getElementsByTagName('body')[0].appendChild(a);
    };
    /* eslint-disable */
    'complete'!==document.readyState?window.addEventListener?window.addEventListener('load', b):window.attachEvent&&window.attachEvent('onload', b):b();
    /* eslint-enable */
};
