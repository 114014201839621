import React from 'react';
import PropTypes from 'prop-types';

const HouseholdValue = ({ value }) => (
  <div>
    {value.total ? <p>Household Size: {value.total}&nbsp;</p> : null}
    {
      value.adults || value.children ? (
        <p className="mt-1">
          {value.adults ? <span>Adults: {value.adults}&nbsp;</span> : null}
          {value.children ? <span>Children: {value.children}</span> : null}
        </p>
      ) :
        null
    }
  </div>
);

HouseholdValue.propTypes = {
  value: PropTypes.object.isRequired,
};

export default HouseholdValue;
