import { useCallback, useState } from 'react';
import { DEFAULT_PAGE_SIZE } from 'components/Backoffice/backofficeUtils';
import { useFindProgramsByName, useFindProvidersByName, useUpdateFeeScheduleScreening } from 'components/Backoffice/api/hooks/v1/feeScheduleScreeningHooks';

export const ASSOCIATION_STEPS = {
  PROVIDER_SELECTION: 1,
  PROGRAM_SELECTION: 2,
};

export const useFeeScheduleScreeningProgramsAssociations = (feeScheduleScreening) => {
  const [step, setStep] = useState(ASSOCIATION_STEPS.PROVIDER_SELECTION);
  const [selectedProviderId, setSelectedProviderId] = useState('');
  const [selectedProgramsIds, setSelectedProgramsIds] = useState([]);

  // NOTE: provider search state
  const [searchProviderName, setSearchProviderName] = useState('');
  const [searchProvidersPageSize, setSearchProvidersPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [searchProvidersPageNumber, setSearchProvidersPageNumber] = useState(1);

  // NOTE: program search state
  const [searchProgramName, setSearchProgramName] = useState('');
  const [searchProgramPageSize, setSearchProgramPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [searchProgramPageNumber, setSearchProgramPageNumber] = useState(1);

  const { data: searchedProvidersData, isLoading: isSearchProvidersLoading } = useFindProvidersByName(
    searchProviderName,
    feeScheduleScreening.id,
    {
      page: {
        size: searchProvidersPageSize,
        number: searchProvidersPageNumber,
      },
    },
  );

  const { data: searchedProgramsData, isLoading: isSearchProgramsLoading } = useFindProgramsByName(
    searchProgramName,
    selectedProviderId,
    {
      page: {
        size: searchProgramPageSize,
        number: searchProgramPageNumber,
      },
    },
  );

  const { updateRecord: updateFeeScheduleScreening } = useUpdateFeeScheduleScreening();

  const searchedProviders = (searchedProvidersData?.data?.data || []).map((provider) => ({
    id: provider.id,
    data: [provider.name, provider.id],
  }));

  const searchedPrograms = (searchedProgramsData?.data?.data || []).map((program) => ({
    id: program.id,
    data: [program.name, program.id],
  }));

  const searchedProgramsPaging = searchedProgramsData?.data?.paging || null;
  const searchedProvidersPaging = searchedProvidersData?.data?.paging || null;

  const currentSelectedProviderName = selectedProviderId ?
    searchedProviders.find((provider) => provider.id === selectedProviderId)?.data[0] : '';

  const resetPrograms = () => {
    setStep(ASSOCIATION_STEPS.PROVIDER_SELECTION); // NOTE: only reset programs if step is 1
    setSelectedProgramsIds([]);
    setSearchProgramName('');
    setSearchProgramPageSize(DEFAULT_PAGE_SIZE);
    setSearchProgramPageNumber(1);
  };

  const resetProviders = () => {
    setSelectedProviderId('');
    setSearchProviderName('');
    setSearchProvidersPageSize(DEFAULT_PAGE_SIZE);
    setSearchProvidersPageNumber(1);
  };

  const shouldShowCheckedProgram = useCallback(
    (programId) => feeScheduleScreening?.programs?.find((program) => program.id === programId),
    [feeScheduleScreening],
  );

  const onSelectProgram = (programIds) => {
    const filteredPrograms = programIds.filter((programId) => !shouldShowCheckedProgram(programId));
    setSelectedProgramsIds(filteredPrograms);
  };

  const onAddProgramConfirmation = async () => {
    if (step === ASSOCIATION_STEPS.PROVIDER_SELECTION) {
      if (!selectedProviderId) return;
      setStep(ASSOCIATION_STEPS.PROGRAM_SELECTION);
    } else if (step === ASSOCIATION_STEPS.PROGRAM_SELECTION && !selectedProgramsIds.length) {
      return;
    } else {
      const updatedPrograms = [...feeScheduleScreening.programs, ...selectedProgramsIds];
      await updateFeeScheduleScreening(
        feeScheduleScreening.id,
        { programs: updatedPrograms },
      );
      resetProviders();
      resetPrograms();
    }
  };

  return {
    currentSelectedProviderName,
    step,
    selectedProviderId,
    selectedProgramsIds,
    searchedProviders,
    searchedProvidersPaging,
    searchedPrograms,
    searchedProgramsPaging,
    searchProviderName,
    searchProgramName,
    searchProvidersPageSize,
    searchProvidersPageNumber,
    searchProgramPageSize,
    searchProgramPageNumber,
    isSearchProvidersLoading,
    isSearchProgramsLoading,
    setSearchProviderName,
    setSearchProvidersPageSize,
    setSearchProvidersPageNumber,
    setSearchProgramName,
    setSearchProgramPageSize,
    setSearchProgramPageNumber,
    setSelectedProviderId,
    onSelectProgram,
    onAddProgramConfirmation,
    resetPrograms,
    resetProviders,
    shouldShowCheckedProgram,
  };
};
