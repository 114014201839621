/* eslint-disable */
import { get } from 'lodash';

export const EL_ASSESS_TEMPLATES = {
  STAGING: {
    V2: 'e648cd04-52df-4e54-b45a-b4468ba58886',
    V3: '3ef86ff4-5698-44f8-bd16-c2b7b8c19ffd',
  },
  NAAA: {
    V2: '52f9c652-b81d-4239-98fc-ea8c438fa05d',
    V3: 'ca2d1ad0-b1fd-4d30-a724-50805e3bd2f2',
  },
  TRAINING: {
    V2: '4261a46d-3ff6-450e-a8a2-9f8d84f2bd9b',
    V3: '4483499e-ca19-483c-8809-74f5f1c662f5',
  },
  PRODUCTION: {
    V2: '5a7aa70e-4dc7-45de-841d-34bfee5bbfc6',
    V3: '11560335-bbd7-487d-8a52-99ac93ad6f69',
  },
};

const DEBUG_MODE = false;

const SURVEYJS_TYPES = {
  TEXT: 'text',
  RADIO: 'radiogroup',
  SELECT: 'dropdown',
  SELECT_MULTIPLE: 'dropdown-multiselect',
  HTML: 'html',
};

export const ELEMENT_DICTIONARY = {
  'qConfirmAccurateInfo': 'Do you confirm that the information you provide during this assessment is accurate and complete to the best of your knowledge?',
  'qVerifyIdentity': 'Verify the member\'s identity using acceptable methods (e.g., government-issued ID, knowledge-based verification).',
  'qVerifyMedicaidEnrollment': 'Use ePACES to verify Medicaid enrollment status for both Fee-For-Service and Managed Care Members',
  'qFoodAssistance': 'Would you like assistance with your food needs?',
  'qHousingUtilitiesAssistance': 'Would you like assistance with your housing and utilities needs?',
  'qEmploymentAssistance': 'Would you like assistance with your employment needs?',
  'qEducationAssistance': 'Would you like assistance with your education needs?',
  'qTransportationAssistance': 'Would you like assistance with your transportation needs?',
  'qFoodServiceDuplicationCheck': 'NYS Food Service Duplication Check - Medically Tailored Meals',
  'qEnhancedPopulations': 'Enhanced Populations',
  'qClinicalCriteria': 'Clinical Criteria',
  'qConnectToServices': 'Would you like to be connected to services to address your needs?',
  'qDifficultyWalking': 'Does the Member have serious difficulty walking or climbing stairs (5 years old or older)?',
  'qDifficultyDressing': 'Does the Member have difficulty dressing or bathing (5 years old or older)?',
  'qRefrigerationBreastMilk': 'Do you require refrigeration for breast milk storage?',
  'qRefrigerationMedication': 'Do you have any medications that require refrigeration?',
  'qTransportationTroubleReasons': 'Please select all the reasons why you have trouble getting transportation:',
  'qSelectScreening': 'Select a previously conducted AHC screening to validate the member\'s Health-Related Social Needs (HRSNs).',
  'qMedicaidVerification': 'Medicaid High Utilizer - Verification Method',
  'qHealthHomeVerification': 'Enrolled in a NYS-designated Health Home Program - Verification Method',
  // 'qIDDVerification': 'Individuals with Intellectual or Developmental Disabilities - Verification Method',
  // 'qSUDVerificationMethod':  'Individual with Substance Use Disorder (SUD) - Verification Method',
  // 'qMentalHealthVerification': 'Individuals with Serious Mental Health Needs - Verification Method',
  // 'qYouthVerification': 'Juvenile Justice-Involved Youth, Foster Care Youth, or Under Kinship Care - Verification Method',
  'qPregnancyVerificationMethod': 'Pregnant or Postpartum Person - Verification Method',
  'qChildVerificationMethod': 'High-Risk Child Under 18 Years Old - Verification Method',
  'qConditionIncarcerationVerification': 'Individuals with a Chronic Condition Released from Incarceration in the Past 90 Days - Verification Method',
  'qOtherPopulationsVerification': 'Other Eligible Population - Verification Method',
  'qOtherPopulationsVerificationProviderName': 'Other Eligible Population - Attesting Provider\'s Name',
  'qOtherPopulationsVerificationProviderNPI': 'Other Eligible Population - Attesting Provider\'s NPI',
  'qOtherPopulationsVerificationAttestationForm': 'Other Eligible Population - Attestation Form Received and Stored',
  'qOtherPopulationsVerificationMemberAttestation': 'Other Eligible Population - Member Attestation',
  'qChildNutritionVerification': 'High-Risk Children Under 18 Years Old Nutrition Only - Verification Method',
  'qChildNutritionVerificationProviderName': 'High-Risk Children Under 18 Years Old Nutrition Only - Attesting Provider\'s Name',
  'qChildNutritionVerificationProviderNPI': 'High-Risk Children Under 18 Years Old Nutrition Only - Attesting Provider\'s NPI',
  'qChildNutritionVerificationAttestationForm': 'High-Risk Children Under 18 Years Old Nutrition Only - Attestation Form Received and Stored',
};

export const OPTION_DICTIONARY = {
  'pregnantAndPostpartum': 'Pregnant or Postpartum Person',
  'highRiskChildUnderEighteen': 'High-Risk Child Under 18 Years Old',
  'individualsChronicReleased': 'Individuals with a Chronic Condition Released from Incarceration in the Past 90 Days',
  'asthma': 'Uncontrolled Asthma',
  'memberConsentsToConnect': 'Member consents to being connected with enhanced HRSN services.',
  'idd': 'Individuals with Intellectual or Developmental Disabilities',
  'heatRelatedIllness': 'Experienced Heat-Related Illness Requiring ER or Urgent Care Visit',
  'coldRelatedIllness': 'Experienced Cold-Related Illness Requiring ER or Urgent Care Visit',
  'atRiskThermo': 'At Risk of Thermoregulation Interference',
  'sud': 'Individuals Facing Substance Use Challenges',
  'mentalHealth': 'Individuals with Serious Mental Health Needs',
  'chronicCondition': 'Chronic Conditions',
  'juvenileJustice': 'Juvenile Justice-Involved Youth, Foster Care Youth, or Under Kinship Care',
  'enternalOrParenteral': 'Require Enteral or Parenteral Nutrition Therapy',
  'medicaidHighUtilizer': 'Medicaid High Utilizer',
  'enrolledNYS': 'Enrolled in a NYS-designated Health Home Program',
  'otherPopulations': 'Other Eligible Population',
  'childNutrition': 'High-Risk Children Under 18 Years Old Nutrition Only',
};

const matchElement = (q) => {
  const text = q.primary_text;
  let elementName = null;
  let elementType = null;
  let elementVisible = true;
  let isRequired = false;
  let inputType = null;
  let visibleIf = null;
  // by default, element is included in answer only if it has a value
  // if set to true, includes element in the answer payload even if empty
  // if empty, value passed will be ''
  let includeInAnswers = false;
  let page = 2;

  if (q.type === 'datetime') {
    // elementType = 'datetime';
    elementType = 'text';
    inputType = 'date';
  }
  if (q.type === 'select') {
    elementType = 'dropdown';
  }
  if (q.type === 'select_multiple') {
    elementType = 'dropdown-multiselect';
  }

  if (q.type === 'text') {
    elementType = 'text';
  }

  if (text === 'Date of Outreach 1:') {
    page = 1;
    // elementType = 'radiogroup';
    // inputType = null;
    elementName = 'qDateAttempt1';
  }
  if (text === 'Modality of Outreach 1:') {
    page = 1;
    elementType = SURVEYJS_TYPES.RADIO;
    elementName = 'qModalityAttempt1';
  }
  if (text === 'Outcome of Outreach 1:') {
    page = 1;
    elementType = SURVEYJS_TYPES.RADIO;
    elementName = 'qOutcomeAttempt1';
  }
  if (text === 'Date of Outreach 2:') {
    page = 1;
    elementName = 'qDateAttempt2';
    elementVisible = false;
  }
  if (text === 'Modality of Outreach 2:') {
    page = 1;
    elementType = SURVEYJS_TYPES.RADIO;
    elementName = 'qModalityAttempt2';
    elementVisible = false;
  }
  if (text === 'Outcome of Outreach 2:') {
    page = 1;
    elementType = SURVEYJS_TYPES.RADIO;
    elementName = 'qOutcomeAttempt2';
    elementVisible = false;
  }
  if (text === 'Date of Outreach 3:') {
    page = 1;
    elementName = 'qDateAttempt3';
    elementVisible = false;
  }
  if (text === 'Modality of Outreach 3:') {
    page = 1;
    elementType = SURVEYJS_TYPES.RADIO;
    elementName = 'qModalityAttempt3';
    elementVisible = false;
  }
  if (text === 'Outcome of Outreach 3:') {
    page = 1;
    elementType = SURVEYJS_TYPES.RADIO;
    elementName = 'qOutcomeAttempt3';
    elementVisible = false;
  }
  if (text === ELEMENT_DICTIONARY.qVerifyMedicaidEnrollment) {
    isRequired = true;
    elementName = 'qVerifyMedicaidEnrollment';
  }
  if (text === ELEMENT_DICTIONARY.qConfirmAccurateInfo) {
    isRequired = true;
    elementName = 'qConfirmAccurateInfo';
  }
  if (text === ELEMENT_DICTIONARY.qVerifyIdentity) {
    isRequired = true;
    page = 2;
    elementName = 'qVerifyIdentity';
  }
  if (text === 'Member\'s Medicaid Client Identification Number (CIN)') {
    isRequired = false;
    elementName = 'qMemberCIN';
  }
  if (text === 'Check off that each of the following fields were captured in the Profile section of the member\'s facesheet:') {
    isRequired = false;
    elementName = 'qProfileFieldsCaptured';
  }
  if (text === ELEMENT_DICTIONARY.qDifficultyWalking) {
    isRequired = true;
    elementName = 'qDifficultyWalking';
  }
  if (text === ELEMENT_DICTIONARY.qDifficultyDressing) {
    isRequired = true;
    elementName = 'qDifficultyDressing';
  }
  if (text === ELEMENT_DICTIONARY.qSelectScreening) {
    isRequired = true;
    elementType = SURVEYJS_TYPES.SELECT;
    elementName = 'qSelectScreening';
  }

  // After Select Screening
  
  if (text === ELEMENT_DICTIONARY.qEmploymentAssistance) {
    isRequired = true;
    elementName = 'qEmploymentAssistance';
  }
  if (text === ELEMENT_DICTIONARY.qHousingUtilitiesAssistance) {
    isRequired = true;
    elementName = 'qHousingUtilitiesAssistance';
  }
  if (text === ELEMENT_DICTIONARY.qFoodAssistance) {
    isRequired = true;
    elementName = 'qFoodAssistance';
  }
  if (text === ELEMENT_DICTIONARY.qEducationAssistance) {
    isRequired = true;
    elementName = 'qEducationAssistance';
  }
  if (text === ELEMENT_DICTIONARY.qTransportationAssistance) {
    isRequired = true;
    elementName = 'qTransportationAssistance';
  }
  if (text === ELEMENT_DICTIONARY.qEnhancedPopulations) {
    isRequired = false;
    elementName = 'qEnhancedPopulations';
    includeInAnswers = true;
  }
  if (text === ELEMENT_DICTIONARY.qMedicaidVerification) {
    isRequired = true;
    elementName = 'qMedicaidVerification';
    elementVisible = false;
    includeInAnswers = true;
  }

  if (text === ELEMENT_DICTIONARY.qHealthHomeVerification) {
    isRequired = true;
    elementName = 'qHealthHomeVerification';
    elementVisible = false;
    includeInAnswers = true;
  }

  if (text === 'Do you need help with childcare so you can work, attend school, or manage daily activities?') {
    isRequired = false;
    elementName = 'qChildcareHelp';
  }
  
  if (text === 'Pregnant or Postpartum Person - Estimated Delivery Date') {
    isRequired = true;
    elementName = 'qEstimatedDeliveryDate';
    includeInAnswers = true;
  }
  if (text === ELEMENT_DICTIONARY.qPregnancyVerificationMethod) {
    isRequired = true;
    elementName = 'qPregnancyVerificationMethod';
    elementVisible = false;
    includeInAnswers = true;
  }
  if (text === 'Pregnant or Postpartum Person - Attesting Provider\'s Name') {
    isRequired = true;
    elementName = 'qPregnancyProviderName';
  }
  if (text === 'Pregnant or Postpartum Person - Attesting Provider\'s NPI') {
    isRequired = true;
    elementName = 'qPregnancyProviderNPI';
  }
  if (text === 'Pregnant or Postpartum Person - Attestation Form Received and Stored') {
    isRequired = true;
    elementName = 'qPregnancyAttestationStored';
  }
  if (text === 'Pregnant or Postpartum Person - Member Attestation') {
    isRequired = true;
    elementName = 'qPregnancyMemberAttestation';
  }
  if (text === ELEMENT_DICTIONARY.qChildVerificationMethod) {
    isRequired = true;
    elementName = 'qChildVerificationMethod';
    elementVisible = false;
  }
  if (text === 'High-Risk Child Under 18 Years Old - Attesting Provider\'s Name') {
    isRequired = true;
    elementName = 'qChildProviderName';
  }
  if (text === 'High-Risk Child Under 18 Years Old - Attesting Provider\'s NPI') {
    isRequired = true;
    elementName = 'qChildProviderNPI';
  }
  if (text === 'High-Risk Child Under 18 Years Old - Attestation Form Received and Stored') {
    isRequired = true;
    elementName = 'qChildAttestationStored';
  }
  if (text === ELEMENT_DICTIONARY.qConditionIncarcerationVerification) {
    isRequired = true;
    elementName = 'qConditionIncarcerationVerification';
    elementVisible = false;
  }
  if (text === 'Individuals with a Chronic Condition Released from Incarceration in the Past 90 Days - Member Documentation Received and Stored') {
    isRequired = true;
    elementName = 'qConditionIncarcerationDocsStored';
  }

  if (text === ELEMENT_DICTIONARY.qOtherPopulationsVerification) {
    isRequired = true;
    elementName = 'qOtherPopulationsVerification';
    elementVisible = false;
  }

  if (text === ELEMENT_DICTIONARY.qOtherPopulationsVerificationProviderName) {
    isRequired = true;
    elementName = 'qOtherPopulationsVerificationProviderName';
    elementVisible = false;
  }

  if (text === ELEMENT_DICTIONARY.qOtherPopulationsVerificationProviderNPI) {
    isRequired = true;
    elementName = 'qOtherPopulationsVerificationProviderNPI';
    elementVisible = false;
  }

  if (text === ELEMENT_DICTIONARY.qOtherPopulationsVerificationAttestationForm) {
    isRequired = true;
    elementName = 'qOtherPopulationsVerificationAttestationForm';
    elementVisible = false;
  }

  if (text === ELEMENT_DICTIONARY.qOtherPopulationsVerificationMemberAttestation) {
    isRequired = true;
    elementName = 'qOtherPopulationsVerificationMemberAttestation';
    elementVisible = false;
  }

  if (text === ELEMENT_DICTIONARY.qChildNutritionVerification) {
    isRequired = true;
    elementName = 'qChildNutritionVerification';
    elementVisible = false;
  }

  if (text === ELEMENT_DICTIONARY.qChildNutritionVerificationProviderName) {
    isRequired = true;
    elementName = 'qChildNutritionVerificationProviderName';
    elementVisible = false;
  }

  if (text === ELEMENT_DICTIONARY.qChildNutritionVerificationProviderNPI) {
    isRequired = true;
    elementName = 'qChildNutritionVerificationProviderNPI';
    elementVisible = false;
  }

  if (text === ELEMENT_DICTIONARY.qChildNutritionVerificationAttestationForm) {
    isRequired = true;
    elementName = 'qChildNutritionVerificationAttestationForm';
    elementVisible = false;
  }

  // 'qChildNutritionVerification': 'High Risk Children Under 18 Nutrition Only - Verification Method',
  // 'qChildNutritionVerificationProviderName': 'High Risk Children Under 18 Nutrition Only - Attesting Provider\'s Name',
  // 'qChildNutritionVerificationProviderNPI': 'High Risk Children Under 18 Nutrition Only - Attesting Provider\'s NPI',
  // 'qChildNutritionVerificationAttestationForm': 'High Risk Children Under 18 Nutrition Only - Attestation Form Received and Stored',

  // Available only on template V2 (deprecated in V3):
  // -------------------------------
  // if (text === ELEMENT_DICTIONARY.qYouthVerification) {
  //   isRequired = true;
  //   elementName = 'qYouthVerification';
  //   elementVisible = false;
  // }
  // if (text === 'Juvenile Justice-Involved Youth, Foster Care Youth, or Under Kinship Care - Attesting Provider\'s Name') {
  //   isRequired = true;
  //   elementName = 'qYouthProviderName';
  // }
  // if (text === 'Juvenile Justice-Involved Youth, Foster Care Youth, or Under Kinship Care - Attesting Provider\'s NPI') {
  //   isRequired = true;
  //   elementName = 'qYouthProviderNPI';
  // }
  // if (text === 'Juvenile Justice-Involved Youth, Foster Care Youth, or Under Kinship Care - Attestation Form Received and Stored') {
  //   isRequired = true;
  //   elementName = 'qYouthAttestationStored';
  // }
  // if (text === 'Juvenile Justice-Involved Youth, Foster Care Youth, or Under Kinship Care - Member Documentation Received and Stored') {
  //   isRequired = true;
  //   elementName = 'qYouthDocsStored';
  // }
  // -------------------------------

  if (text === 'Do you have difficulty accessing transportation when you need it?') {
    isRequired = false;
    elementName = 'qTransportationAccess';
  }
  if (text === ELEMENT_DICTIONARY.qTransportationTroubleReasons) {
    isRequired = false;
    elementName = 'qTransportationTroubleReasons';
  }
  if (text === 'How does not having transportation affect you?') {
    isRequired = false;
    elementName = 'qTransportationEffect';
  }
  if (text === 'What kind of transportation help do you need? Please select all that apply:') {
    isRequired = false;
    elementName = 'qTransportationHelp';
  }
  if (text === 'Do you already receive any transportation services?') {
    isRequired = false;
    elementName = 'qTransportationServicesReceived';
  }
  if (text === 'Please describe the transportation services you currently receive:') {
    isRequired = false;
    elementName = 'qTransportationServicesDescription';
  }
  if (text === 'What type of food assistance do you need? Select all that apply.') {
    isRequired = false;
    elementName = 'qFoodAssistanceType';
  }
  if (text === 'Do you have the ability to store and prepare food, such as access to a refrigerator and stove?') {
    isRequired = false;
    elementName = 'qFoodPreparationAbility';
  }
  if (text === ELEMENT_DICTIONARY.qRefrigerationBreastMilk) {
    isRequired = false;
    elementName = 'qRefrigerationBreastMilk';
  }
  if (text === ELEMENT_DICTIONARY.qRefrigerationMedication) {
    isRequired = false;
    elementName = 'qRefrigerationMedication';
  }

  if (text === 'Are you currently receiving nutritional counseling or education provided through a New York State (NYS) social services program?') {
    isRequired = false;
    elementName = 'qNutritionalCounseling';
  }

  if (text === 'Are you or your children currently participating in the Women, Infants, and Children (WIC) program provided by New York State (NYS)?') {
    isRequired = false;
    elementName = 'qWICProgram';
  }
  if (text === 'Are you currently receiving help to buy groceries from the Supplemental Nutrition Assistance Program (SNAP), also known as food stamps, provided by New York State (NYS)?') {
    isRequired = false;
    elementName = 'qSNAPBenefits';
  }

  if (text === ELEMENT_DICTIONARY.qFoodServiceDuplicationCheck) {
    isRequired = false;
    elementName = 'qFoodServiceDuplicationCheck';
  }
  if (text === 'Are you currently receiving home-delivered meals or food services through a New York State (NYS) social services program?') {
    isRequired = false;
    elementName = 'qHomeDeliveredMeals';
  }
  if (text === 'Which best describes your current living situation?') {
    isRequired = false;
    elementName = 'qLivingSituation';
  }
  if (text === 'What specific housing issues are you experiencing? Select all that apply.') {
    isRequired = false;
    elementName = 'qHousingIssues';
  }
  if (text === 'Do you have any concerns about the safety or quality of your current housing?') {
    isRequired = false;
    elementName = 'qHousingSafetyConcerns';
  }
  if (text === 'What concerns do you have about your housing? Select all that apply.') {
    isRequired = false;
    elementName = 'qHousingConcerns';
    elementVisible = false;
  }
  if (text === 'Do you currently need care to recover from an illness or injury, prepare for a surgery or procedure, or transition out of a hospital or other facility?') {
    isRequired = false;
    elementName = 'qCareRecoveryNeed';
  }
  if (text === `Are you at risk of needing to go back to the hospital or emergency room if you don't get this care?`) {
    isRequired = false;
    elementName = 'qHospitalRisk';
  }
  if (text === 'Are you currently working with a housing provider or program?') {
    isRequired = false;
    elementName = 'qHousingProvider';
  }
  if (text === 'Do you participate in the Healthy Homes Program or any similar program provided by New York State (NYS) to address home environmental health hazards?') {
    isRequired = false;
    elementName = 'qHealthyHomesProgram';
  }
  if (text === 'Are you on any housing waitlists (e.g., HUD, Section 8, local housing authority, or other voucher programs)?') {
    isRequired = false;
    elementName = 'qHousingWaitlists';
  }
  if (text === 'Are you experiencing any issues with your utilities?') {
    isRequired = false;
    elementName = 'qUtilitiesIssues';
  }

  if (text === 'Which of the following utilities issues are you experiencing?') {
    isRequired = false;
    elementVisible = false;
    elementName = 'qUtilitiesIssuesTypes';
  }
  if (text === 'What type of utilities assistance do you need? Select all that apply.') {
    isRequired = false;
    elementVisible = false;
    elementName = 'qUtilitiesAssistanceType';
  }
  if (text === 'Do you currently receive any utilities assistance programs?') {
    isRequired = false;
    elementVisible = false;
    elementName = 'qUtilitiesProgramsReceived';
  }
  if (text === 'Please specify the utilities assistance programs that you currently receive:') {
    isRequired = false;
    elementName = 'qUtilitiesProgramsDescription';
  }
  if (text === 'Which of the following best describes your current employment situation?') {
    isRequired = false;
    elementName = 'qEmploymentSituation';
  }
  if (text === 'What type of work are you interested in?') {
    isRequired = false;
    elementName = 'qWorkInterest';
    elementVisible = false;
  }
  if (text === 'Do you need assistance with childcare?') {
    isRequired = false;
    elementName = 'qChildcareAssistance';
  }
  if (text === ELEMENT_DICTIONARY.qClinicalCriteria) {
    isRequired = false;
    includeInAnswers = true;
    elementName = 'qClinicalCriteria';
  }
  if (text === 'Chronic Conditions - Verification Method') {
    isRequired = true;
    includeInAnswers = true;
    elementName = 'qChronicConditionsVerification';
  }
  if (text === 'Chronic Conditions - Attesting Provider\'s Name') {
    isRequired = true;
    includeInAnswers = true;
    elementName = 'qChronicConditionsProviderName';
  }
  if (text === 'Chronic Conditions - Attesting Provider\'s NPI') {
    isRequired = true;
    includeInAnswers = true;
    elementName = 'qChronicConditionsProviderNPI';
  }
  if (text === 'Chronic Conditions - Attestation Form Received and Stored') {
    isRequired = true;
    includeInAnswers = true;
    elementName = 'qChronicConditionsAttestationStored';
  }
  if (text === 'Uncontrolled Asthma - Verification Method') {
    isRequired = true;
    elementName = 'qAsthmaVerification';
  }
  if (text === 'Uncontrolled Asthma - Attesting Provider\'s Name') {
    isRequired = true;
    elementName = 'qAsthmaProviderName';
  }
  if (text === 'Uncontrolled Asthma - Attesting Provider\'s NPI') {
    isRequired = true;
    elementName = 'qAsthmaProviderNPI';
  }
  if (text === 'Uncontrolled Asthma - Attestation Form Received and Stored') {
    isRequired = true;
    elementName = 'qAsthmaAttestationStored';
  }
  if (text === 'Require Enteral or Parenteral Nutrition Therapy - Verification Method') {
    isRequired = true;
    elementName = 'qNutritionTherapyVerification';
  }
  if (text === 'Require Enteral or Parenteral Nutrition Therapy - Attesting Provider\'s Name') {
    isRequired = true;
    elementName = 'qNutritionTherapyProviderName';
  }
  if (text === 'Require Enteral or Parenteral Nutrition Therapy - Attesting Provider\'s NPI') {
    isRequired = true;
    elementName = 'qNutritionTherapyProviderNPI';
  }
  if (text === 'Require Enteral or Parenteral Nutrition Therapy - Attestation Form Received and Stored') {
    isRequired = true;
    elementName = 'qNutritionTherapyAttestationStored';
  }
  if (text === 'Experienced Heat-Related Illness Requiring ER or Urgent Care Visit - Verification Method') {
    isRequired = true;
    elementName = 'qHeatIllnessVerification';
  }
  if (text === 'Experienced Heat-Related Illness Requiring ER or Urgent Care Visit - Attesting Provider\'s Name') {
    isRequired = true;
    elementName = 'qHeatIllnessProviderName';
  }
  if (text === 'Experienced Heat-Related Illness Requiring ER or Urgent Care Visit - Attesting Provider\'s NPI') {
    isRequired = true;
    elementName = 'qHeatIllnessProviderNPI';
  }
  if (text === 'Experienced Heat-Related Illness Requiring ER or Urgent Care Visit - Attestation Form Received and Stored') {
    isRequired = true;
    elementName = 'qHeatIllnessAttestationStored';
  }
  if (text === 'Experienced Heat-Related Illness Requiring ER or Urgent Care Visit - Member Attests Illness Occurred at Home') {
    isRequired = true;
    elementName = 'qHeatIllnessAttestHome';
  }
  if (text === 'Experienced Cold-Related Illness Requiring ER or Urgent Care Visit - Verification Method') {
    isRequired = true;
    elementName = 'qColdIllnessVerification';
  }
  if (text === 'Experienced Cold-Related Illness Requiring ER or Urgent Care Visit - Attesting Provider\'s Name') {
    isRequired = true;
    elementName = 'qColdIllnessProviderName';
  }
  if (text === 'Experienced Cold-Related Illness Requiring ER or Urgent Care Visit - Attesting Provider\'s NPI') {
    isRequired = true;
    elementName = 'qColdIllnessProviderNPI';
  }
  if (text === 'Experienced Cold-Related Illness Requiring ER or Urgent Care Visit - Attestation Form Received and Stored') {
    isRequired = true;
    elementName = 'qColdIllnessAttestationStored';
  }
  if (text === 'Experienced Cold-Related Illness Requiring ER or Urgent Care Visit - Member Attests this Occurred at Home') {
    isRequired = true;
    elementName = 'qColdIllnessAttestHome';
  }
  if (text === 'At Risk of Thermoregulation Interference - Verification Method') {
    isRequired = true;
    elementName = 'qThermoregulationRiskVerification';
  }
  if (text === 'At Risk of Thermoregulation Interference - Attesting Provider\'s Name') {
    isRequired = true;
    elementName = 'qThermoregulationRiskProviderName';
  }
  if (text === 'At Risk of Thermoregulation Interference - Attesting Provider\'s NPI') {
    isRequired = true;
    elementName = 'qThermoregulationRiskProviderNPI';
  }
  if (text === 'At Risk of Thermoregulation Interference - Attestation Form Received and Stored') {
    isRequired = true;
    elementName = 'qThermoregulationRiskAttestationStored';
  }
  if (text === 'NYS Service Duplication Check') {
    isRequired = false;
    elementName = 'qServiceDuplicationCheck';
  }
  if (text === 'Are there any other programs or services you\'re currently using that provide help with housing, food, healthcare, or other daily needs?') {
    isRequired = false;
    elementName = 'qOtherPrograms';
  }
  if (text === 'Additional Programs and Services:') {
    isRequired = false;
    elementName = 'qAdditionalPrograms';
  }
  if (text === ELEMENT_DICTIONARY.qConnectToServices) {
    isRequired = false;
    elementName = 'qConnectToServices';
  }
  if (text === 'Navigator Notes:') {
    isRequired = false;
    elementName = 'qNavigatorNotes';
  }

  if (!elementName) {
    elementName = null;
    console.log('MISSING MATCH', q.primary_text);
  }
  if (!elementType) {
    elementType = null;
    console.log('******* NO ELEMENT TYPE FOUND *******', q);
  }

  if (!elementVisible) {
    includeInAnswers = true;
  }

  return {
    elementName,
    elementType,
    visibleIf,
    isRequired,
    elementVisible,
    inputType,
    page,
    includeInAnswers,
  };
};

let htmlIndex = 0;

const createH1Element = (text, name = null) => ({
  "type": "html",
  "name": name || `html_header_${htmlIndex++}`,
  "uuid": null,
  "html": `
    <div class="mb-2 p-3 pb-1 pt-4 bg-gray-100 rounded-lg">
      <h2 class="text-xl font-bold mb-3">${text}</h2>
    </div>
  `,
});

const createH2Element = (text, name = null) => ({
  "type": "html",
  "name": name || `html_header_${htmlIndex++}`,
  "html": `
      <div class="mb-2 p-3 pt-4 pb-1 bg-gray-100 rounded-lg">
        <h2 class="text-xl font-bold mb-3">${text}</h2>
    </div>
  `,
  "uuid": null,
});

const createHTMLElement = (text, name = null) => ({
  "type": "html",
  "name": name || `html_header_${htmlIndex++}`,
  "html": `<h2 class='pb-4'>${text}</h2>`,
  "uuid": null,
});

const createIdentifiedNeedsHTML = (text) => ({
  "type": "html",
  "name": "qIdentifiedNeedsHtml",
  "uuid": null,
  "html": "<p></p>",
  "visible": true,
});

const createStepEightHTML = () => ({
  "type": "html",
  "name": "hStepEightHtml",
  "uuid": null,
  "html": `
    <div class="p-4 bg-gray-100 rounded-lg mb-6">
      <h2 class="text-xl font-bold mb-3">
        Step 8: Confirm that the Medicaid Managed Care Member Wants to Receive Enhanced HRSN Services for Which They are Eligible
      </h2>

      <div>
        <h3 class="font-semibold mb-2">8.1 Enhanced HRSN Services Member is Eligible For:</h3>
      </div>
    </div>
  `
});

const createEnhancedPopulationsHTML = (text) => ({
  "type": "html",
  "name": "hEnhancedPopulationsHtml",
  "uuid": null,
  "html": `
    <div class="p-4 bg-gray-100 rounded-lg mb-6">
      <h2 class="text-xl font-bold mb-3">Step 5: Check if the Member is Included in an Enhanced Population</h2>

      <div class="mb-6">
        <h3 class="font-semibold mb-2">5.1 Enhanced Populations from ESMF</h3>
        <p>
          Based on the Enhanced Services Member File (ESMF), the member is identified as part of the following Enhanced Population(s):
        </p>
      </div>

      <div class="mb-6">
        <h3 class="font-semibold mb-2">5.2. Member Confirmation of Enhanced Populations</h3>
        <div>
          <h4 class="font-semibold mb-2">Suggested Language:</h4>
          <p class="italic">
            "To make sure you receive all the support and services you might be eligible for, I'd like to review some groups that qualify for additional assistance. I'll go through each group one by one. Please let me know if it applies to you."
          </p>
        </div>
      </div>
    </div>
  `
});

const createNYSServiceDuplicationCheckHTML = (text) => ({
  "type": "html",
  "name": "hServiceDuplicationCheckHtml",
  "uuid": null,
  "html": `
    <div class="p-4 bg-gray-100 rounded-lg mb-6">
      <h2 class="text-xl font-bold mb-3">7.3. NYS Service Duplication Check</h2>

      <div class="space-y-6">
        <div>
          <h3 class="font-semibold mb-2">Suggested Language:</h3>
          <p class="italic">
            "To make sure we provide you with the best support without overlapping services you may already have, I'd like to ask about any assistance or programs you're currently receiving. This helps us coordinate your care effectively and ensure you're getting the most appropriate help."
          </p>
        </div>

        <div>
          <h3 class="font-semibold mb-2">Suggested Language:</h3>
          <p class="italic">
            "Are you part of a program called a Health Home that helps organize your healthcare services and appointments?"
          </p>
        </div>

        <div>
          <h3 class="font-semibold mb-2">Suggested Language:</h3>
          <p class="italic">
            "Do you receive specialized services or support through programs for people with developmental disabilities?"
          </p>
        </div>

        <div>
          <h3 class="font-semibold mb-2">Suggested Language:</h3>
          <p class="italic">
            "Do you get any special services or support because you've experienced a traumatic brain injury?"
          </p>
        </div>

        <div>
          <h3 class="font-semibold mb-2">Suggested Language:</h3>
          <p class="italic">
            "Are you part of a program that helps you stay in your home instead of moving to a nursing home, providing you with extra support?"
          </p>
        </div>

        <div>
          <h3 class="font-semibold mb-2">Suggested Language:</h3>
          <p class="italic">
            "Does your child receive special services or support through a program designed for children with significant health needs?"
          </p>
        </div>

        <div>
          <h3 class="font-semibold mb-2">Suggested Language:</h3>
          <p class="italic">
            "Are you enrolled in a health plan that combines Medicaid and Medicare services?"
          </p>
        </div>

        <div>
          <h3 class="font-semibold mb-2">Suggested Language:</h3>
          <p class="italic">
            "Do you have a health plan that helps with long-term care services like home care or adult day care, but doesn't cover all your medical services?"
          </p>
        </div>

        <div>
          <h3 class="font-semibold mb-2">Suggested Language:</h3>
          <p class="italic">
            "Do you receive financial assistance or other support services through a program called TANF, which helps families in need?"
          </p>
        </div>
      </div>
    </div>
  `
});

const createClinicalCriteriaHTML = (text) => ({
  "type": "html",
  "name": "hClinicalCriteriaHtml",
  "uuid": null,
  "html": `
    <div class="p-4 bg-gray-100 rounded-lg mb-6">
      <h2 class="text-xl font-bold mb-3">Step 7: Confirm Member Meets Eligibility Criteria for Specific HRSN Services, Including Ensuring No Service Duplication</h2>

      <div class="mb-6">
        <h3 class="font-semibold mb-2">7.1 Clinical Conditions from ESMF</h3>
        <p>
          Based on the Enhanced Services Member File (ESMF), the member is identified as having the following Clinical Conditions:
        </p>
      </div>

      <div class="mb-6">
        <h3 class="font-semibold mb-2">7.2. Member Confirmation of Clinical Conditions</h3>
        <h4 class="font-semibold mb-2">How to complete this section:</h4>
        <p class="mb-4">
          In the list below, check off all the clinical criteria listed for the member in the ESMF.
        </p>
        <p class="mb-4">
          Then, review the additional clinical criteria with the member one-by-one and check off any additional criteria they indicate apply to them.
        </p>
        <p>
          The suggested language below provides sample language that can be used to describe each clinical criteria to the member.
        </p>
      </div>

      <div class="space-y-6">
        <div>
          <h3 class="font-semibold mb-2">Suggested Language:</h3>
          <p class="italic">
            "To make sure we understand your health needs and connect you with the right services, I'd like to ask you some
            questions about your health conditions. This information will help us determine which programs and support you
            may be eligible for. Is that okay?"
          </p>
        </div>

        <div>
          <h3 class="font-semibold mb-2">Chronic Conditions</h3>
          <p class="italic">
            "Do you have any ongoing health conditions like diabetes, high blood pressure, heart problems, lung issues
            like COPD or asthma?"
          </p>
        </div>

        <div>
          <h3 class="font-semibold mb-2">Uncontrolled Asthma</h3>
          <p class="italic">
            "Do you have asthma that still causes symptoms or attacks even when you're using your medications?"
          </p>
        </div>

        <div>
          <h3 class="font-semibold mb-2">Require Enteral or Parenteral Nutrition Therapy</h3>
          <p class="italic">
            "Do you need to get your nutrition through a feeding tube or through an IV into your veins?"
          </p>
        </div>

        <div>
          <h3 class="font-semibold mb-2">Currently in an Institutional Setting</h3>
          <p class="italic">
            "Are you currently staying in a nursing home, rehabilitation center, or hospital?"
          </p>
        </div>

        <div>
          <h3 class="font-semibold mb-2">
            Experienced Heat-Related Illness Requiring ER or Urgent Care Visit
          </h3>
          <p class="italic">
            "Have you ever needed emergency medical care because of extreme heat, like heat exhaustion or heat stroke?"
          </p>
        </div>

        <div>
          <h3 class="font-semibold mb-2">
            Experienced Cold-Related Illness Requiring ER or Urgent Care Visit
          </h3>
          <p class="italic">
            "Have you ever needed emergency medical care because of extreme cold, like hypothermia or frostbite?"
          </p>
        </div>

        <div>
          <h3 class="font-semibold mb-2">At Risk of Thermoregulation Interference</h3>
          <p class="italic">
            "Do you have any health conditions that make it hard for your body to handle hot or cold temperatures?"
          </p>
        </div>
      </div>
    </div>
  `,
});

const addElementsBefore = (element, elements) => {
  if (element.name === 'qConfirmAccurateInfo') {
    elements.push(createH1Element('Step 1: Collect Member Consent and Attestation to Complete and Accurate Information'));
    elements.push(createH2Element('1.2 Attestation to Accurate and Complete Information'));
  }

  if (element.name === 'qVerifyIdentity') {
    elements.push(createH1Element(`Step 2: Confirm the Member's identity and their Medicaid Enrollment`));
    elements.push(createH2Element('2.1 Verification of Member Identity'));
  }

  if (element.name === 'qProfileFieldsCaptured') {
    elements.push(createH2Element('2.3 Member Demographics and Physical Disabilities'));
  }

  if (element.name === 'qVerifyMedicaidEnrollment') {
    elements.push(createH2Element('2.2 Verification of Medicaid Enrollment'));
  }

  if (element.name === 'qEnhancedPopulations') {
    elements.push(createEnhancedPopulationsHTML());
  }

  if (element.name === 'qTransportationAccess') {
    elements.push(createH1Element('Step 6: Follow-Up Questions for Unmet HRSNs', 'hStepSix'));
    elements.push(createH2Element('Transportation', 'hTransportation'));
  }

  if (element.name === 'qFoodAssistanceType') {
    elements.push(createH2Element('Food', 'hFood'));
  }

  if (element.name === 'qLivingSituation') {
    elements.push(createH2Element('Housing and Utilities', 'hHousingUtilities'));
  }

  if (element.name === 'qEmploymentSituation') {
    elements.push(createH2Element('Employment', 'hEmployment'));
  }

  if (element.name === 'qChildcareHelp') {
    elements.push(createH1Element('Additional Needs', 'hAdditionalNeeds'));
  }

  if (element.name === 'qClinicalCriteria') {
    elements.push(createClinicalCriteriaHTML());
  }

  if (element.name === 'qServiceDuplicationCheck') {
    elements.push(createNYSServiceDuplicationCheckHTML());
  }

  if (element.name === 'qConnectToServices') {
    elements.push(createStepEightHTML());
  }
  
}

const addElementsAfter = (element, elements) => {
  if (element.name === 'qSelectScreening') {
    elements.push(createIdentifiedNeedsHTML());
  }
};

export const createElAssessSurveyJSTemplate = (response, existingAssessment = null) => {
  try {
    const template = {
      configuration: {
        custom: {
          ...response,
        },
        showNavigationButtons: false, // hides the 'next' button at the bottom of the page when there are multiple pages
        title: "Eligibility Assessment V2",
        pages: [
          {
            name: "page1", // outreach page
            elements: []
          },
          {
            name: "page2", // assessment page
            elements: []
          },
        ],
      }
    };

    let n = 0;
    const assessmentScreen = get(existingAssessment, 'data.screen');
    const sortedQuestions = response.questions.sort((a, b) => a.order - b.order);
    const elementsPage1 = [];
    const elementsPage2 = [];
    const questionElementMap = {};
    sortedQuestions.forEach((question, index) => {
      const {
        elementName,
        elementType,
        visibleIf,
        isRequired,
        elementVisible,
        inputType,
        page,
        includeInAnswers,
      } = matchElement(question);

      let choices = question.options?.map((option, j) => {
        return {
          value: option.value.replace(/'/g, ''), // surveyjs element data
          text: option.text, // surveyjs element data
          uuid: option.id, // surveyjs element data
          id: option.id,
          custom: {
            question,
            questionId: question.id,
            elementName: elementName,
            // disabled: get(option, 'value') === 'ESMF',
            disabled: false,
            option: {
              ...option
            }
          }
        };
      });

      const assessmentQuestion = assessmentScreen?.questions?.find(q => q.id === question.id);
      const selectedChoice = choices && choices.find(c => c.id === assessmentQuestion?.answer?.option_id);
     
      if (!question.options) {
        choices = [];
      }
      const title = DEBUG_MODE ? `${question.primary_text} - ${question.type}` : question.primary_text;
      const element = {
        type: elementType,
        name: elementName,
        inputType,
        title,
        visible: elementVisible,
        description: question.instructions || null,
        isRequired,  // TBD
        uuid: question.id,
        id: question.id,
        choices,
        custom: {
          includeInAnswers,
        }
        // defaultValue: selectedChoice?.value || null,
      };

      questionElementMap[elementName] = question;

      if (page === 1) {
        elementsPage1.push(element);
      }
      if (page === 2) {
        addElementsBefore(element, elementsPage2);
        elementsPage2.push(element);
        addElementsAfter(element, elementsPage2);
      }
    });

    template.configuration.pages[0].elements = elementsPage1;
    template.configuration.pages[1].elements = elementsPage2;
    return {
      template,
      questionElementMap,
    };
  } catch (error) {
    console.error('createElAssessSurveyJSTemplate ', error);
  }
}

export default {};
