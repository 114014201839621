import React from 'react';
import { PropTypes } from 'prop-types';
import { CardDetail, CardSubHeader } from 'common/Card';
import { get } from 'lodash';
import { epochToDate } from 'common/utils/utils';
import { Link } from 'react-router';

const ClientDetailsSection = ({
  enrollmentRequest,
  primaryInsurance,
  showClientFacesheetLink,
}) => {
  const externalMemberID = primaryInsurance?.external_member_id;
  return (
    <>
      <CardSubHeader>Client Details</CardSubHeader>
      <CardDetail label="Client Name">
        {
          showClientFacesheetLink ? (
            <Link
              className="text-action-blue"
              to={`/facesheet/${enrollmentRequest.person.id}`}
            >
              {get(enrollmentRequest, 'person.last_name')}, {get(enrollmentRequest, 'person.first_name')}
            </Link>
          ) : (
            <>{get(enrollmentRequest, 'person.last_name')}, {get(enrollmentRequest, 'person.first_name')}</>
          )
        }
      </CardDetail>
      {externalMemberID && (
        <CardDetail label="Client ID#">
          {externalMemberID}
        </CardDetail>
      )}
      {primaryInsurance && (
        <CardDetail label="Insurance Plan">
          {primaryInsurance.plan?.name}
        </CardDetail>
      )}
      <CardDetail label="Date of Birth">{epochToDate(get(enrollmentRequest, 'person.date_of_birth'))}</CardDetail>
    </>
  );
};

ClientDetailsSection.propTypes = {
  enrollmentRequest: PropTypes.object.isRequired,
  primaryInsurance: PropTypes.object.isRequired,
  showClientFacesheetLink: PropTypes.bool.isRequired,
};

export default ClientDetailsSection;
