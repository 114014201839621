import { get } from 'lodash';
import { useFindRecord } from 'src/api/APIHooks';

const useMetafields = (invoice) => {
  const { data } = useFindRecord(
    'fee_schedule_program',
    get(invoice, 'fee_schedule_program_id'),
    {
      queryConfig: {
        placeholderData: undefined,
        refetchOnWindowFocus: false,
        staleTime: Infinity,
      },
    },
  );
  return get(data, 'data.data.metafields', []);
};

export default useMetafields;
