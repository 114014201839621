import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Drawer } from '@unite-us/ui';
import { Serializer, reduxForm7 } from '@unite-us/client-utils';
import NetworkGroupDetails from 'src/components/Groups/GroupDetails/NetworkGroupDetails';
import GroupList from 'src/components/Browse/GroupList/GroupList';
import FilterSummary from 'src/components/Browse/BrowseFilters/components/FilterSummary';
import browse from 'src/components/Browse';
import callOrLog from 'src/common/utils/callOrLog';
import { BROWSE } from 'common/utils/EventTracker/utils/eventConstants';
import {
  hasPaymentsUserAccess,
  hasPaymentsNetworksServicesUserRole,
  serviceAreaSupportForOrgs,
  includePathwaysServices,
  programBasedSearchSelector,
} from 'common/utils/FeatureFlags/flags';
import { serviceTypesFilter } from 'src/components/Dashboard/utils/filter';
import { TOP_NAVIGATION_HEIGHT } from 'src/components/Navigation/constants';
import { REFERRED_OUT_OF_NETWORK } from 'src/components/Referrals/constants';
import {
  fetchCoreLocations,
  fetchCorePrograms,
  fetchCoreProvider,
  fetchCoreProviderServices,
  fetchCoreServiceAreas,
} from 'src/actions/Group/Network';
import Map from '../Map';
import { browserHistory } from '../../../common/utils/browserHistory';
import NetworkBrowseFilters from '../BrowseFilters/NetworkBrowseFilters';
import { requiredMapScript } from '../Map/utils';
import { getOurCoordinates, getGroupCenter, getMarkerObjects } from '../utils/address';
import { getGroupData } from '../utils/display';
import {
  buildBrowseFilters,
  searchParams,
  getSearchRadius,
} from '../utils/filters';
import './NetworkBrowseDirectory.scss';

export class NetworkBrowseDirectory extends Component {
  constructor(props) {
    super(props);

    this.onClearAllProviders = this.onClearAllProviders.bind(this);
    this.onSelectProvider = this.onSelectProvider.bind(this);
    this.onSelectProviderFromDrawer = this.onSelectProviderFromDrawer.bind(this);
    this.clearNetworkBrowseFilters = this.clearNetworkBrowseFilters.bind(this);
    this.fetchGroup = this.fetchGroup.bind(this);
    this.getCurrentSelectedGroupId = this.getCurrentSelectedGroupId.bind(this);
    this.toggleGroupDetail = this.toggleGroupDetail.bind(this);
    this.toggleGroupDetailFromMarker = this.toggleGroupDetailFromMarker.bind(this);
    this.resetSelectedGroup = this.resetSelectedGroup.bind(this);
    this.toggleFiltersDrawer = this.toggleFiltersDrawer.bind(this);
    this.toggleShareDrawer = this.toggleShareDrawer.bind(this);
    this.trackGroupDetailClick = this.trackGroupDetailClick.bind(this);
    this.toggleProviderHover = this.toggleProviderHover.bind(this);

    this.state = {
      filtersDrawerOpen: false,
      selectedProviders: [],
      shareDrawerOpen: false,
      hoverGroupId: '',
      providerLocations: [],
      providerServiceAreas: '',
      programs: [],
      selectedProvider: {},
      providerServices: [],
      serve: false,
    };
  }

  componentDidMount() {
    this.checkRedirect();
  }

  componentDidUpdate(prevProps) {
    // Re-evaluate redirect only if the flags change
    if (this.props.hint13ProgramBasedSearch !== prevProps.hint13ProgramBasedSearch) {
      this.checkRedirect();
    }
  }

  onClearAllProviders() {
    this.setState({ selectedProviders: [] });
  }

  onSelectProvider(provider = {}) {
    const { selectedProviders, shareDrawerOpen } = this.state;
    const { parentState, referralScopes } = this.props;

    const newProvider = { ...provider };
    const networkId = _.get(parentState, 'uiFilters.networks[0]');
    const referral = referralScopes.find((scope) => scope.permitted_network.id === networkId);
    if (referral) {
      const { permitted_network } = referral;
      newProvider.uiFilters = {
        networkId: permitted_network.id,
        networkName: permitted_network.name,
      };
    }

    const updatedSelectedProviders = _.some(selectedProviders, { id: newProvider.id }) ?
      _.reject(selectedProviders, { id: newProvider.id }) :
      [...selectedProviders, newProvider];

    this.setState({
      selectedProviders: updatedSelectedProviders,
      shareDrawerOpen: shareDrawerOpen && updatedSelectedProviders.length > 0,
    });
  }

  onSelectProviderFromDrawer(provider = {}) {
    this.onSelectProvider(provider);
  }

  getCurrentSelectedGroupId() {
    return _.get(this.props, 'parentState.uiCurrentSelectedGroupId');
  }

  checkRedirect = () => {
    const { network, hint13ProgramBasedSearch } = this.props;
    if (hint13ProgramBasedSearch) {
      browserHistory.replace(`/network/${network.id}`);
    }
  };

  toggleFiltersDrawer() {
    const filtersDrawerOpen = !this.state.filtersDrawerOpen;

    if (filtersDrawerOpen) {
      this.resetSelectedGroup();
      this.setState({ filtersDrawerOpen, shareDrawerOpen: false });
    } else {
      this.setState({ filtersDrawerOpen });
    }
  }

  toggleShareDrawer() {
    const shareDrawerOpen = !this.state.shareDrawerOpen;

    if (shareDrawerOpen) {
      this.resetSelectedGroup();
      this.setState({ filtersDrawerOpen: false, shareDrawerOpen });
    } else {
      this.setState({ shareDrawerOpen });
    }
  }

  clearNetworkBrowseFilters(props = this.props) {
    this.props.clearNetworkBrowseGroups();
    const centerCoordinates = getOurCoordinates(props);
    const browseFilterParams = this.props.callParent('buildBrowseFiltersParams', props);

    const sortBy = props.parentState.sortBy;
    const filters = buildBrowseFilters({ ...browseFilterParams, centerCoordinates });

    this.props.callParent('setParentState', {
      uiFilters: {
        ...this.props.parentState.uiFilters,
        ...filters,
      },
      uiOpen: false,
      uiFetching: true,
      uiCenter: centerCoordinates,
      uiCenterForDistance: centerCoordinates,
      sortBy,
    }, () => {
      const q = searchParams({ ...filters, sortBy });

      this.props.callParent('fetchGroups', { reqParams: { q } }, props.networkId);
    });
  }

  /**
   * Filter google params and convert to group object.
   * The object coming in has a marker object and that now contains the groupId key
   * Use this key to find the group object
  */
  toggleGroupDetailFromMarker({ marker }) {
    const { groupId } = marker;
    const { filteredGroups } = this.props;
    const selectedFilteredGroup = _.find(filteredGroups, { id: groupId });

    if (selectedFilteredGroup) {
      this.toggleGroupDetail({ selectedGroup: selectedFilteredGroup });
    }
  }

  resetSelectedGroup() {
    this.props.callParent('setParentState', {
      uiOpen: false,
      uiCurrentSelectedGroupId: null,
      uiSelectedGroupFromMarker: null,
    }, () => {
      this.props.clearNetworkBrowseGroup();
    });
  }

  // TODO: Post migration - update function name
  fetchGroup(selectedGroup) {
    const selectedGroupId = _.get(selectedGroup, 'id');
    const isCurrentGroupSelected = this.getCurrentSelectedGroupId() === selectedGroupId;

    this.setState({
      filtersDrawerOpen: false,
      shareDrawerOpen: false,
    });

    this.props.callParent('setParentState', {
      uiOpen: true,
      uiCurrentSelectedGroupId: selectedGroupId,
      uiSelectedGroupFromMarker: selectedGroupId,
    }, () => {
      if (!isCurrentGroupSelected) {
        this.props.clearNetworkBrowseGroupPrograms();
      }
    });
  }

  /**
   * set selectedGroup based on group object
  */
  toggleGroupDetail({ selectedGroup, serviceAreaServed }) {
    const {
      employeeId,
      parentState,
      usePaymentsUserRole,
      includePathways,
    } = this.props;
    const { uiCurrentSelectedGroupId, uiOpen } = parentState;

    const selectedProviderId = _.get(selectedGroup, 'id');
    const referable = { employee: employeeId };

    this.props.resetForm('share');

    if (uiOpen && this.getCurrentSelectedGroupId() === selectedProviderId) {
      this.resetSelectedGroup();
    } else {
      this.fetchGroup(selectedGroup);
    }

    // Track this only on opening the drawer, or changing groups with drawer open
    if (!uiOpen || selectedGroup.id !== uiCurrentSelectedGroupId) {
      this.trackGroupDetailClick(selectedGroup);
      this.setState({ serve: !!serviceAreaServed });
    }
    this.setState({ providerServiceAreas: serviceAreaServed });

    fetchCoreProvider(selectedProviderId).then((provider) => {
      const selectedProvider = {
        ..._.get(provider, 'attributes', {}),
        id: selectedProviderId,
      };
      this.setState({ selectedProvider });
    });

    fetchCorePrograms({
      provider: selectedProviderId,
      referable,
      usePaymentsUserRole,
      includePathways,
    }).then((programs) => {
      this.setState({ programs });
    });

    fetchCoreLocations(selectedProviderId).then((providerLocations) => {
      this.setState({ providerLocations });
    });

    fetchCoreServiceAreas(selectedProviderId).then((providerServiceAreas) => {
      this.setState({ providerServiceAreas });
    });

    fetchCoreProviderServices(selectedProviderId, includePathways).then((providerServices) => {
      this.setState({ providerServices });
    });
  }

  trackGroupDetailClick(group) {
    const { network } = this.props;
    const slimGroup = Serializer.build({ browseGroupDetails: group });

    callOrLog(() => this.context.eventTracker(BROWSE.viewDrawerOrg, {
      ...slimGroup,
      browse_network_id: network.id,
      browse_network_name: network.name,
    }));
  }

  toggleProviderHover(isHovering, hoverGroupId) {
    const groupId = isHovering ? hoverGroupId : '';
    this.setState({ hoverGroupId: groupId });
  }

  render() {
    const {
      currentUserGroup,
      filteredGroups,
      geoCoordinates,
      ipCoordinates,
      isFetching,
      isCCGroup,
      network,
      paging,
      parentState,
      referralScopes,
      selectedGroup,
      selectedServices,
      serviceTypes,
      usePaymentsNetworksServices,
      usePaymentsUserRole,
      languages,
      serviceAreaSupportForOrgsFlag,
      totalElements,
      totalElementsServiceAreas,
      filteredGroupsServiceArea,
      pagingServiceArea,
      isFetchingServiceArea,
      stateDisplayName,
    } = this.props;

    const {
      filtersDrawerOpen,
      programs,
      providerLocations,
      selectedProviders,
      shareDrawerOpen,
      selectedProvider,
      providerServices,
      providerServiceAreas,
    } = this.state;

    // Filter out providers that only have paid (and possibly default) programs
    // unless the user has payments permissions
    let paymentsFilteredGroups;
    if (usePaymentsUserRole) {
      paymentsFilteredGroups = filteredGroups;
    } else {
      paymentsFilteredGroups = filteredGroups.filter((group) => {
        const hasFeeSchedulePrograms = group.programs.map((p) => p.fee_schedule_program_id).filter(Boolean).length > 0;
        if (!hasFeeSchedulePrograms) return true;
        const visiblePrograms = group.programs.filter((p) => (
          p.name !== REFERRED_OUT_OF_NETWORK && !(p.fee_schedule_program_id)
        ));
        return visiblePrograms.length > 0;
      });
    }

    const groupCenter = getGroupCenter(currentUserGroup);
    const office = {
      key: groupCenter ? _.get(currentUserGroup, 'attributes.name', '') : 'Office',
      position: groupCenter || geoCoordinates || ipCoordinates,
      id: _.get(currentUserGroup, 'id'),
      groupType: 'in_network',
      groupId: currentUserGroup.id,
    };

    const markers = [
      office,
      ...getMarkerObjects(paymentsFilteredGroups),
    ];

    const groupData = getGroupData({
      fetchedGroupData: selectedGroup,
      selectedGroupId: this.getCurrentSelectedGroupId(),
      slimGroups: paymentsFilteredGroups,
    });

    // TODO come back and fix the api call (root cause of problem)
    const hideServiceTypes = (types, hidden) => {
      const sts = [];

      types.forEach((st) => {
        if (hidden.includes(st.code)) {
          return;
        }

        const cst = _.cloneDeep(st);

        if (cst.children) {
          cst.children = hideServiceTypes(cst.children, hidden);
        }

        sts.push(cst);
      });

      return sts;
    };

    // Ticket to revisit: https://uniteus.atlassian.net/browse/PAYS-1222
    const serviceOptions = serviceTypesFilter(
      usePaymentsNetworksServices ? serviceTypes :
        hideServiceTypes(serviceTypes, ['UU-PAYMENTS']),
      _.get(parentState, 'uiFilters.serviceTypes', []),
    ).options;

    return (
      <div className="network-browse-directory browse">
        <Drawer
          className="browse__drawer"
          containerClassName="group-detail-drawer"
          onClose={() => this.props.callParent('onDrawerClose')}
          open={parentState.uiOpen}
          secondary
          topOffset={TOP_NAVIGATION_HEIGHT}
          width="500px"
          theme="dark"
        >
          <NetworkGroupDetails
            center={parentState.uiCenterForDistance}
            onAddProvider={this.onSelectProviderFromDrawer}
            onRemoveProvider={this.onSelectProviderFromDrawer}
            programs={programs}
            providerIsSelected={
              _.some(selectedProviders, { id: groupData.id })
            }
            providerIsSelectable
            selectedServices={selectedServices}
            selectedServiceIds={_.get(this.props, 'uiFilters.serviceTypes', [])}
            network={network}
            allLanguages={languages}
            providerLocations={providerLocations}
            providerServiceAreas={providerServiceAreas}
            selectedProvider={selectedProvider}
            providerServices={providerServices}
            serve={this.state.serve}
            stateDisplayName={stateDisplayName}
          />
        </Drawer>

        <div className="browse__filters">
          {
            !_.isEmpty(currentUserGroup) && (
              <NetworkBrowseFilters
                clearNetworkBrowseFilters={this.clearNetworkBrowseFilters}
                currentUserGroup={currentUserGroup}
                filters={parentState.uiFilters}
                filtersDrawerOpen={filtersDrawerOpen}
                groups={paymentsFilteredGroups}
                isCCGroup={isCCGroup}
                network={network}
                onClearAllProviders={this.onClearAllProviders}
                onFiltersChange={(activeFilters, keys) => {
                  this.props.callParent('onFiltersChange', activeFilters, keys);
                }}
                onSortByChange={(sortBy) => this.props.callParent('onSortByChange', sortBy)}
                onToggleFiltersDrawer={this.toggleFiltersDrawer}
                onToggleShareDrawer={this.toggleShareDrawer}
                referralScopes={referralScopes}
                selectedProviders={selectedProviders}
                serviceOptions={serviceOptions}
                setGeoFilters={(filters) => this.props.callParent('setGeoFilters', filters)}
                shareDrawerOpen={shareDrawerOpen}
                sortBy={parentState.sortBy}
                serviceAreaSupportForOrgsFlag={serviceAreaSupportForOrgsFlag}
              />
            )
          }
        </div>

        <div className="browse__content">
          <div className="browse__side-container">
            <FilterSummary
              className="browse__filter-summary"
              filters={this.props.parentState.uiFilters}
              resultsCount={totalElements + totalElementsServiceAreas}
              serviceOptions={serviceOptions}
              toggleFiltersDrawer={this.toggleFiltersDrawer}
              message={serviceAreaSupportForOrgsFlag ? 'Use the filters to adjust your search.' : ''}
            />
            <div className="browse__content-list">
              <GroupList
                center={this.props.parentState.uiCenterForDistance}
                list={paymentsFilteredGroups}
                loading={isFetching}
                selectedGroupFromMarker={this.props.parentState.uiSelectedGroupFromMarker}
                selectedGroups={selectedProviders}
                selectedServices={selectedServices}
                selectGroup={this.onSelectProvider}
                toggleGroupDetail={(selectedGroupValue) => {
                  this.toggleGroupDetail({ selectedGroup: selectedGroupValue });
                }}
                toggleProviderHover={this.toggleProviderHover}
                title={serviceAreaSupportForOrgsFlag ? 'Nearby Organizations' : ''}
                numberOfElements={totalElements}
                appendData={() => this.props.callParent('appendGroups', { type: 'location' })}
                emptyMessage={null}
                paging={paging}
                serviceAreaSupportForOrgsFlag={serviceAreaSupportForOrgsFlag}
              />
              {serviceAreaSupportForOrgsFlag && (
                <GroupList
                  center={this.props.parentState.uiCenterForDistance}
                  list={filteredGroupsServiceArea}
                  loading={isFetchingServiceArea}
                  selectedGroupFromMarker={this.props.parentState.uiSelectedGroupFromMarker}
                  selectedGroups={selectedProviders}
                  selectedServices={selectedServices}
                  selectGroup={this.onSelectProvider}
                  toggleGroupDetail={(selectedGroupValue, serviceAreaServed) => {
                    this.toggleGroupDetail({ selectedGroup: selectedGroupValue, serviceAreaServed });
                  }}
                  toggleProviderHover={this.toggleProviderHover}
                  title={serviceAreaSupportForOrgsFlag ? 'Also serves this area' : ''}
                  numberOfElements={totalElementsServiceAreas}
                  appendData={() => this.props.callParent('appendGroups', { type: 'serviceArea' })}
                  emptyMessage={null}
                  paging={pagingServiceArea}
                  serviceAreaSupportForOrgsFlag={serviceAreaSupportForOrgsFlag}
                  stateDisplayName={stateDisplayName}
                  serve
                />
              )}
            </div>
          </div>

          <div className="browse__content-map">
            {
              parentState.scriptsLoaded &&
              parentState.uiCenter && (
                <Map
                  center={this.props.parentState.uiCenter}
                  centerForDistance={this.props.parentState.uiCenterForDistance}
                  currentUserGroup={currentUserGroup}
                  google={window.google}
                  handleDragEnd={(newCenter) => this.props.callParent('handleCenterChange', newCenter)}
                  handleSearchAreaChange={() => this.props.callParent('handleSearchAreaChange')}
                  handleSearchAreaClick={
                    (newCenter, formattedAddress) => {
                      this.props.callParent('handleSearchAreaClick', newCenter, formattedAddress);
                    }
                  }
                  markers={markers}
                  onDblClick={(newCenter) => this.props.callParent('handleCenterChange', newCenter)}
                  searchRadius={getSearchRadius(_.get(this.props, 'parentState.uiFilters.distance'))}
                  setSelectedGroupFromMarker={this.toggleGroupDetailFromMarker}
                  showSearchButton={this.props.parentState.uiShowSearchButton}
                  toggleProviderHover={this.toggleProviderHover}
                  hoverGroupId={this.state.hoverGroupId}
                />
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

NetworkBrowseDirectory.propTypes = {
  // Required
  callParent: PropTypes.func.isRequired,
  clearNetworkBrowseGroup: PropTypes.func.isRequired,
  clearNetworkBrowseGroupPrograms: PropTypes.func.isRequired,
  clearNetworkBrowseGroups: PropTypes.func.isRequired,
  employeeId: PropTypes.string.isRequired,
  filteredGroups: PropTypes.array.isRequired,
  filteredGroupsServiceArea: PropTypes.array.isRequired,
  isFetchingServiceArea: PropTypes.bool.isRequired,
  geoCoordinates: PropTypes.object.isRequired,
  isCCGroup: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  ipCoordinates: PropTypes.object.isRequired,
  languages: PropTypes.array.isRequired,
  network: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  parentState: PropTypes.object.isRequired,
  resetForm: PropTypes.func.isRequired,
  selectedGroup: PropTypes.shape({
    id: PropTypes.string,
  }),
  serviceTypes: PropTypes.array.isRequired,
  stateDisplayName: PropTypes.string.isRequired,
  totalElements: PropTypes.number.isRequired,
  totalElementsServiceAreas: PropTypes.number.isRequired,
  usePaymentsNetworksServices: PropTypes.bool.isRequired,
  usePaymentsUserRole: PropTypes.bool.isRequired,

  // Optional
  currentUserGroup: PropTypes.object,
  paging: PropTypes.object,
  pagingServiceArea: PropTypes.object,
  referralScopes: PropTypes.array,
  selectedServices: PropTypes.array,
  serviceAreaSupportForOrgsFlag: PropTypes.bool,
  includePathways: PropTypes.bool,
  hint13ProgramBasedSearch: PropTypes.bool,
};

NetworkBrowseDirectory.defaultProps = {
  currentUserGroup: {},
  paging: {},
  pagingServiceArea: {},
  referralScopes: [],
  selectedGroup: {},
  selectedServices: [],
  serviceAreaSupportForOrgsFlag: false,
  includePathways: false,
  hint13ProgramBasedSearch: false,
};

function mapStateToProps(state) {
  const usePaymentsUserRole = hasPaymentsUserAccess(state);
  const usePaymentsNetworksServices = hasPaymentsNetworksServicesUserRole(state);

  const totalElements = _.get(state, 'browse.paging.total_count', 0);
  const totalElementsServiceAreas = _.get(state, 'browse.pagingServiceArea.total_count', 0);
  const employeeId = _.get(state, 'globalState.currentEmployee.id');

  const hint13ProgramBasedSearch = programBasedSearchSelector(state);

  return {
    employeeId,
    filteredGroups: _.get(state, 'browse.groups', [])
      ?.map((x, i) => ({ position: i + 1, ...x })),
    filteredGroupsServiceArea: _.get(state, 'browse.groupsServiceArea', [])
      ?.map((x, i) => ({ position: i + 1, ...x })),
    serviceAreaSupportForOrgsFlag: serviceAreaSupportForOrgs(state),
    totalElements,
    totalElementsServiceAreas,
    usePaymentsNetworksServices,
    usePaymentsUserRole,
    includePathways: includePathwaysServices(state),
    hint13ProgramBasedSearch,
  };
}

NetworkBrowseDirectory.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  resetForm: (name) => (dispatch) => dispatch(reduxForm7.reset(name)),
})(browse(requiredMapScript)(NetworkBrowseDirectory));
