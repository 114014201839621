import { useFind } from 'src/api/APIHooks';
import { defaultQueryConfig } from 'src/components/Backoffice/api/hooks/apiHookOptions';
import { isEmpty } from 'lodash';

export const useFindNetworksById = (
  networkIds,
) => useFind(
  'network',
  { id: networkIds },
  {
    queryConfig: {
      enabled: !isEmpty(networkIds),
      placeholderData: undefined,
      ...defaultQueryConfig,
    },
  },
);

export const useFindNetworksByName = (
  name,
  state,
  params = { queryConfig: defaultQueryConfig },
) => useFind(
  'network',
  {
    name,
    state,
  },
  {
    queryConfig: {
      enabled: !!name,
      placeholderData: undefined,
    },
    ...params,
  },
);
