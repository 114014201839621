import React, { useRef, useState } from 'react';
import { BackButton } from 'common/buttons';
import { browserHistory } from 'common/utils/browserHistory';
import { Button, Icon } from '@unite-us/ui';
import PropTypes from 'prop-types';
import { useFindDirectory } from 'src/components/Backoffice/api/hooks/prdApi/DirectoryHooks';
import {
  usePublishDirectoryConfiguration,
} from 'src/components/Backoffice/api/hooks/prdApi/DirectoryConfigurationHooks';
import { Spinner } from 'common/spinners';
import DialogV2 from 'common/modal/DialogV2';
import { theme } from 'src/../tailwind.config';
import { PRD_FE_DOMAIN } from 'src/config/env/env.config';
import PrdDetails from './PrdDetails';
import PrdSubHeader from './PrdSubHeader';

const PrdOverview = ({ params }) => {
  const id = params.id;
  const { data: prd, isFetching: isFetchingDirectory } = useFindDirectory(id);
  const directory_configuration_id = prd.draft_configuration_id;
  const PublishConfirmationModal = useRef(null);
  const { mutate: publishDirectoryConfiguration } = usePublishDirectoryConfiguration(prd.draft_configuration_id);
  const isPublished = prd.current_configuration && !prd.has_draft_changes;
  const [publishing, setPublishing] = useState(false);

  const openModal = () => {
    PublishConfirmationModal.current.openDialog();
  };

  const closeModal = () => {
    PublishConfirmationModal.current.closeDialog();
  };

  const confirmationHandler = () => {
    setPublishing(true);
    publishDirectoryConfiguration();
  };

  const BackToPrdTable = () => {
    browserHistory.push({
      pathname: '/backoffice/prds',
    });
  };

  const UNITEUS_PREVIEW_URL = `https://${prd.domain}.${PRD_FE_DOMAIN}/?preview_id=${prd.draft_configuration_id}`;

  const PreviewPrdDirectory = () => {
    const newWindow = window.open('about:blank', '_blank');
    newWindow.location.href = UNITEUS_PREVIEW_URL;
  };

  if (isFetchingDirectory) return <Spinner />;
  return (
    <>
      <div className="detail-view">
        <div className="flex p-4 justify-between bg-white border-b border-solid border-dark-border-blue">
          <div>
            <BackButton navigateTo={BackToPrdTable} dataTestId="back-to-directory-index-button" />
          </div>
          <div className="flex flex-nowrap ">
            <Button
              id="preview-directory-button"
              className="mx-2"
              iconLeft={<Icon color={theme.extend.colors['action-blue']} icon="IconEye" />}
              label="PREVIEW"
              onClick={() => PreviewPrdDirectory()}
              disabled={!prd.has_draft_changes && prd.current_configuration !== null}
              data-testid="preview-directory-button"
            />
            <Button
              data-testid="edit-directory-button"
              id="edit-directory-button"
              className="mx-2"
              iconLeft={<Icon color={theme.extend.colors['action-blue']} icon="V2Edit" />}
              onClick={() => {
                browserHistory.push({ pathname: `/backoffice/prds/${id}/edit` });
              }}
              label="EDIT"
            />
            <Button
              id="publish-directory-button"
              className="mx-2"
              onClick={openModal}
              disabled={isPublished}
              iconLeft={<Icon color={theme.extend.colors['action-blue']} icon="Entrepreneurship" />}
              label="PUBLISH"
              data-testid="publish-directory-button"
            />
          </div>
        </div>
        <PrdSubHeader directory_id={id} />
        <PrdDetails directory_id={id} directory_configuration_id={directory_configuration_id} />
        <DialogV2
          cancelHandler={closeModal}
          confirmationHandler={confirmationHandler}
          confirmLabel={publishing ? 'Publishing...' : 'Publish'}
          confirmationBtnDisabled={publishing}
          id={'publish-prd-modal'}
          ref={PublishConfirmationModal}
          title={'Publish PRD'}
          width={'md'}
        >
          <div className="pt-1 pb-16">
            {'You are about to publish PRD for '}
            <h4 className="inline-block">{prd.customer_name}</h4>
          </div>
        </DialogV2>
      </div>
    </>
  );
};

PrdOverview.propTypes = {
  params: PropTypes.object.isRequired,
};

export default PrdOverview;
