import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { Expandable } from '@unite-us/ui';
import 'src/components/Navigation/stylesheets/navBar.scss';
import RouteNavigationSubItem from './RouteNavigationSubItem';
import {
  createId,
  isChildRouteActive,
  isRouteActive,
  isRouteDisabled,
} from '../utils';
import { selectionKeyCodes } from '../constants';

const extractTextFromElement = (element) => {
  let result = '';
  try {
    if (typeof element === 'string') {
      result = element;
    } else if (React.isValidElement(element)) {
      const children = element.props.children;
      if (Array.isArray(children)) {
        result = children.map(extractTextFromElement).join(' ');
      } else if (children) {
        result = extractTextFromElement(children);
      }
    }
  } catch (error) {
    console.error('Error extracting text from element:', error);
  }
  return result;
};

class RouteNavigationItem extends Component {
  constructor(props) {
    super(props);

    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
    this.handleSubItemClick = this.handleSubItemClick.bind(this);
    this.state = {
      subItemIsActive: isChildRouteActive(props.item, props.location.pathname),
    };
  }

  handleOnClick() {
    this.props.onClick(this.props.item.path, this.props.item);
    this.setState({ subItemIsActive: false });
  }

  handleOnKeyDown(e) {
    const {
      onClick,
    } = this.props;

    if (selectionKeyCodes.has(e.keyCode)) {
      e.preventDefault();
      return onClick(this.props.item.path, this.props.item);
    }
    return false;
  }

  handleSubItemClick() {
    this.setState({ subItemIsActive: true });
  }

  render() {
    const { id, item, location } = this.props;
    const { subItemIsActive } = this.state;

    const itemIsActive = isRouteActive(item.path, location.pathname);

    const listClass = () => classNames({
      'route-navigation-item': true,
      'route-navigation-item--active': itemIsActive && !subItemIsActive,
      'route-navigation-item--disabled': isRouteDisabled(item),
      'list-unstyled': true,
    });

    const labelText = extractTextFromElement(item.label);
    const subItems = _.map(item.children, (subItem = {}, index) => (
      <RouteNavigationSubItem
        id={createId(subItem.path)}
        subItem={subItem}
        key={index}
        onClick={this.props.onClick}
        onKeyDown={this.handleOnKeyDown}
        handleSubItemClick={this.handleSubItemClick}
        location={location}
      />
    ));

    if (!_.isEmpty(item.children)) {
      return (
        <Expandable
          header={item.label}
          className="expandable-sent-card"
          aria-expanded={subItemIsActive}
        >
          <ul
            className="list-unstyled"
            role="list"
          >
            {subItems}
          </ul>
        </Expandable>
      );
    }

    return (
      <li
        id={id}
        className={listClass()}
        role="listitem"
        onClick={this.handleOnClick}
        onKeyDown={this.handleOnKeyDown}
        tabIndex={0}
        aria-label={labelText}
      >
        {item.label}
      </li>
    );
  }
}

RouteNavigationItem.propTypes = {
  id: PropTypes.string.isRequired,
  item: PropTypes.shape({
    children: PropTypes.array,
    label: PropTypes.node.isRequired,
    path: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default RouteNavigationItem;
