import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './EmptyTable.scss';

const EmptyTable = ({ className, message }) => (
  <div className={classNames('empty-table', className)}>
    <div className="empty-table__message">
      <h3 className="p-4 whitespace-pre-line">{message}</h3>
    </div>
  </div>
);

EmptyTable.propTypes = {
  className: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

EmptyTable.defaultProps = {
  className: '',
  message: 'There is no available information to display.',
};

export default EmptyTable;
