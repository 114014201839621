import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { usePopulate } from 'src/api/APIHooks';
import { Spinner } from 'common/spinners';
import WarningMessage from 'common/utils/WarningMessage';

import ProcedureCodeRow from './ProcedureCodeRow';

const ProcedureCodeRows = (props) => {
  const {
    casePayerId,
    feeScheduleProgram,
    fields,
    isEditing,
    providedService,
    registerField,
  } = props;
  const PROCEDURE_CODE_AND_MODIFIER_WARNING =
    // eslint-disable-next-line max-len
    'Note: Some Procedure Codes or Modifiers may be automatically selected and updated based on the contracted program.';

  const rows = fields.provided_service.procedure_code_rows;
  const forceProcedureCodes = feeScheduleProgram.force_procedure_code_associations;
  const forceModifiers = feeScheduleProgram.force_procedure_code_modifier_associations;
  const hideProcedureCodeRows = forceProcedureCodes && forceModifiers;
  const pcEnabled = !!casePayerId && (casePayerId.length === 36) && !!feeScheduleProgram;
  const pcQueryParams = { queryParams: { payer: casePayerId } };

  const pcOptions = { queryConfig: { placeholderData: undefined, enabled: pcEnabled }, ...pcQueryParams };
  const { isLoading: isLoadingPcs, isSuccess: isSuccessPcs } =
    usePopulate('procedure_codes', 'procedure_codes', feeScheduleProgram, pcOptions);

  const pcmOptions = { queryConfig: { placeholderData: undefined, enabled: isSuccessPcs } };
  const { isLoading: isLoadingPcms, isSuccess: isSuccessPcms } =
    usePopulate('procedure_code_modifiers', 'procedure_code_modifiers', feeScheduleProgram.procedure_codes, pcmOptions);

  const fspPcAndPcmIsLoading = isLoadingPcs || isLoadingPcms;
  const fspPcAndPcmIsSuccess = isSuccessPcs && isSuccessPcms;

  const pcWithPcmsOptions = !fspPcAndPcmIsSuccess ? [] : feeScheduleProgram.procedure_codes
    .filter((procedureCode) => (procedureCode.payer?.id === casePayerId))
    .map((procedureCode) => ({
      label: `${procedureCode.code} - ${procedureCode.description}`,
      value: procedureCode.id,
      procedure_code_modifiers: procedureCode.procedure_code_modifiers?.map((modifier) => ({
        label: `${modifier.code} - ${modifier.description}`,
        value: modifier.id,
      })),
    }));

  const idsMatch = (objects1, objects2) => {
    const ids1 = objects1?.map((obj) => (obj.id))?.sort();
    const ids2 = objects2?.map((obj) => (obj.id))?.sort();

    return (ids1?.length === ids2?.length) && ids1?.every((id1, i) => (id1 === ids2[i]));
  };

  const filteredFspPcmsByPsPcs = (fspPcs, psPcs) => {
    const psPcIds = psPcs.map((obj) => (obj.id));
    const filteredFeeScheduleProgramProcedureCodes =
      fspPcs.filter((fspPc) => (psPcIds.some((psPcId) => (psPcId === fspPc.id))));

    return filteredFeeScheduleProgramProcedureCodes.map((fspPc) => (fspPc.procedure_code_modifiers)).flat();
  };

  const warnDueToIdMismatch = () => {
    const filteredFeeScheduleProgramModifiers =
      filteredFspPcmsByPsPcs(feeScheduleProgram.procedure_codes, providedService.procedure_codes);

    const selectedPcIdsMatch = idsMatch(providedService.procedure_codes, feeScheduleProgram.procedure_codes);
    const selectedPcmIdsMatch = idsMatch(providedService.procedure_code_modifiers, filteredFeeScheduleProgramModifiers);

    return (forceProcedureCodes && !selectedPcIdsMatch) || (forceModifiers && !selectedPcmIdsMatch);
  };

  if (!casePayerId || isEmpty(rows) || (!fspPcAndPcmIsLoading && !pcWithPcmsOptions.length)) {
    return null;
  }

  return !fspPcAndPcmIsSuccess ? (<Spinner />) : (
    <>
      {isEditing && warnDueToIdMismatch() && (
        <div className={`flex ${hideProcedureCodeRows ? 'mb-10' : 'mb-2' }`}>
          <WarningMessage>{PROCEDURE_CODE_AND_MODIFIER_WARNING}</WarningMessage>
        </div>
      )}
      {!(hideProcedureCodeRows) && (
        <div className="ui-select-field ui-form-field mt-5 mb-10">
          {rows.map((row, index) => (
            <ProcedureCodeRow
              row={row}
              index={index}
              options={pcWithPcmsOptions}
              fields={fields}
              registerField={registerField}
              feeScheduleProgram={feeScheduleProgram}
            />
          ))}
        </div>
      )}
    </>
  );
};

ProcedureCodeRows.propTypes = {
  casePayerId: PropTypes.string,
  feeScheduleProgram: PropTypes.object,
  fields: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  providedService: PropTypes.object.isRequired,
  registerField: PropTypes.func.isRequired,
};

ProcedureCodeRows.defaultProps = {
  casePayerId: undefined,
  feeScheduleProgram: {},
};

export default ProcedureCodeRows;
