import { useEffect, useRef, useState } from 'react';
 import { debounce } from 'lodash';
import { useFind } from '../../../api/APIHooks';

const useClinicalModificationOptions = (query) => {
  const [code, setCode] = useState(null);

  const debounceSearch = useRef(debounce(setCode, 400)).current;

  useEffect(() => {
    debounceSearch(query);

    return () => {
        debounceSearch.cancel();
    };
  }, [query]);

  const { data: response } = useFind(
    'clinical_modifications',
    {
      code,
    },
    {
      page: {
        number: 1,
        size: 30,
      },
      queryConfig: {
        enabled: !!code,
        placeholderData: undefined,
      },
    },
  );

  return response?.data?.data ?? [];
};

export default useClinicalModificationOptions;
