import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const EmailAddress = ({ email }) => (
  <div className="flex items-center">
    <div className="flex flex-col">
      <span className="break-all max-w-xs">
        <span className="pr-1">{email.email_address}</span>
        {email.is_primary && (
          <span className="text-sm inline-block whitespace-nowrap">(Primary)</span>
        )}
      </span>

      {email.acceptable_communication_types && (
        <span className="text-sm italic">
          {email.acceptable_communication_types
            .map((type) => _.capitalize(type))
            .join(', ')}
        </span>
      )}
    </div>
  </div>
);

EmailAddress.propTypes = {
  email: PropTypes.shape({
    email_address: PropTypes.string.isRequired,
    is_primary: PropTypes.bool,
    acceptable_communication_types: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default EmailAddress;
