const needToCompare = (comparison, key) => (comparison[key][2]);

const removeTimeslotIds = (timeslotsWithIds) => {
  if (!timeslotsWithIds) {
    return null;
  }

  const withoutIds = Object.fromEntries(
    Object.entries(timeslotsWithIds).map(([key, value]) => [
      key,
      value.map(({ id, ...rest }) => rest),
    ]),
  );

  return withoutIds;
};

const mergeTimeslots = (formValues, comparison) => {
  const TIMESLOTS = 'timeslots';
  const default_empty = {};
  if (!needToCompare(comparison, TIMESLOTS)) {
    return removeTimeslotIds(comparison[TIMESLOTS][3]) || default_empty;
  }

  const selected = formValues[TIMESLOTS];
  return removeTimeslotIds(comparison[TIMESLOTS][selected]);
};

const mergeContactNotes = (formValues, comparison) => {
  const CONTACT_NOTES = 'contact_notes';
  if (!needToCompare(comparison, CONTACT_NOTES)) {
    return comparison[CONTACT_NOTES][3];
  }

  const selectedNotes = formValues[CONTACT_NOTES];
  if (!selectedNotes) {
    return '';
  }

  return selectedNotes.join('\n');
};

const mergeMethodsOfContact = (formValues, comparison) => {
  const METHODS_OF_CONTACT = 'methods_of_contact';
  const default_empty = {
    preferred_contact_methods: [],
  };
  if (!needToCompare(comparison, METHODS_OF_CONTACT)) {
    return comparison[METHODS_OF_CONTACT][3] || default_empty;
  }

  const selected = formValues[METHODS_OF_CONTACT];
  return comparison[METHODS_OF_CONTACT][selected];
};

export const mergeContactPreference = (formValues, comparison) => {
  const timeslots = mergeTimeslots(formValues, comparison);
  const contact_notes = mergeContactNotes(formValues, comparison);
  const methods_of_contact = mergeMethodsOfContact(formValues, comparison);

  const data = {
    contact_notes,
    ...methods_of_contact,
  };

  if (Object.keys(timeslots).length > 0) {
    data.timeslots = timeslots;
  }

  return data;
};
