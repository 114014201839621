import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Field, useFormState } from 'react-final-form';
import { validations } from '@unite-us/app-components';
import {
  InputField,
  RadioField,
  DurationField,
  DateField,
} from '@unite-us/ui';
import ErrorMessage from 'src/common/utils/ErrorMessage';
import { useFeatureFlag } from 'common/hooks';
import CurrencyField from 'common/form/CurrencyField';

const PaymentsTrackServiceDetailsForm = ({
  canInvoiceAboveRemainingAuthorizedAmount,
  enforceRequiredFields,
  isBillable,
  isCostType,
  options,
  requestedValueNonValid,
  unitAmountValidations,
  validateInsuranceCoverage,
}) => {
  const formState = useFormState();

  const startDate = formState.values.provided_service.service_start_date;
  const isSingleDateRequested = formState.values.provided_service.period_of_service === 'Single Date';
  const endDate = isSingleDateRequested ? startDate : formState.values.provided_service.service_end_date;
  useEffect(() => {
    validateInsuranceCoverage(startDate, endDate);
  }, [validateInsuranceCoverage, startDate, endDate]);

  const isDateRange = formState.values.provided_service.period_of_service === 'Date Range';
  const hasAmountProvidedFlag = useFeatureFlag('pays-4037-contracted-service-amount-provided');
  const costValidations = (value) => (
    validations.isRequired(value) ||
    validations.isLessThan(value, 'Should be less than $1,000,000', 1_000_000_00)
  );

  return (
    <>
      <div className="grid grid-cols-5 gap-x-6 lg:gap-y-4">
        {isCostType ? (
          <>
            <Field
              name="provided_service.unit_amount"
              validate={enforceRequiredFields ? costValidations : noop}
            >
              {(fieldProps) => (
                <CurrencyField
                  className="col-span-5 lg:col-span-2 pl-0"
                  id="provided-service-unit-amount"
                  label={isBillable ? 'Invoice Amount' : 'Unit Amount'}
                  required={enforceRequiredFields}
                  {...fieldProps}
                />
              )}
            </Field>
            {hasAmountProvidedFlag ? (
              <>
                <Field
                  name="provided_service.actual_unit_amount"
                >
                  {(fieldProps) => (
                    <CurrencyField
                      className="col-span-5 lg:col-span-2 pl-0"
                      id="provided-service-actual-unit-amount"
                      label={'Cost of Service'}
                      {...fieldProps}
                    />
                  )}
                </Field>
                <div className="col-span-1">
                  <label htmlFor="provided-service-unit" className="block">Unit</label>
                  <div id="provided-service-unit" className="mt-3">Dollars ($)</div>
                </div>
                <p className="col-span-5 text-dark-grey text-xs pl-0 lg:-mt-6">
                  If the cost of service is more than the amount you are invoicing,
                  please enter the cost of service.
                </p>
              </>
            ) : (
              <div className="col-span-1">
                <label htmlFor="provided-service-unit" className="block">Unit</label>
                <div id="provided-service-unit" className="mt-3">Dollars ($)</div>
              </div>
            )}
          </>
        ) : (
          <>
            <Field
              name="provided_service.unit_amount"
              validate={unitAmountValidations}
            >
              {(fieldProps) => (
                <InputField
                  className="col-span-5 lg:col-span-2 pl-0"
                  id="provided-service-unit-amount"
                  label={isBillable ? 'Invoice Amount' : 'Unit Amount'}
                  required={enforceRequiredFields}
                  type="number"
                  min="1"
                  {...fieldProps}
                />
              )}
            </Field>
            {hasAmountProvidedFlag ? (
              <>
                <Field
                  name="provided_service.actual_unit_amount"
                >
                  {(fieldProps) => (
                    <InputField
                      className="col-span-5 lg:col-span-2 pl-0"
                      id="provided-service-actual-unit-amount"
                      label={'Amount Provided'}
                      type="number"
                      min="0"
                      {...fieldProps}
                    />
                  )}
                </Field>
                <div className="col-span-5 lg:col-span-1 pl-0">
                  <label htmlFor="provided-service-unit" className="block">Unit</label>
                  <div
                    className="mt-3"
                    id="provided-service-unit"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: formState.values.provided_service.unit }}
                  />
                </div>
                <p className="col-span-5 text-dark-grey text-xs pl-0 lg:-mt-6">
                  If the amount provided is more than the amount you are invoicing,
                  please enter the amount provided.
                </p>
              </>
            ) : (
              <div className="col-span-5 lg:col-span-1 pl-0">
                <label htmlFor="provided-service-unit" className="block">Unit</label>
                <div
                  className="mt-3"
                  id="provided-service-unit"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: formState.values.provided_service.unit }}
                />
              </div>
            )}
          </>
        )}
        {requestedValueNonValid && (
          <div className=" col-span-5 flex space-x-2 px-0 mb-2">
            <ErrorMessage>
              {canInvoiceAboveRemainingAuthorizedAmount ?
                'The invoice amount is greater than the remaining authorized amount. ' +
                'Please check that the invoice amount is correct prior to submitting.' :
                'This contracted service cannot be submitted ' +
                'because the invoice amount is greater than the remaining authorized amount.'}
            </ErrorMessage>
          </div>
        )}
      </div>
      <div className="grid grid-cols-5 gap-x-6 lg:gap-y-4">
        <Field
          name="provided_service.period_of_service"
        >
          {(fieldProps) => (
            <RadioField
              className="col-span-5 lg:col-span-2 px-0"
              id="provided-service-period-of-service"
              label="Period of Service"
              options={options}
              {...fieldProps}
            />
          )}
        </Field>
        <div className="col-span-5 lg:col-span-2 px-0">
          {
            isDateRange ? (
              <Field
                name="provided_service.service_start_date"
                validate={enforceRequiredFields ? (value) => validations.isRequired(value) : noop}
              >
                {(startProps) => (
                  <Field
                    name="provided_service.service_end_date"
                    validate={enforceRequiredFields ? (value) => validations.isRequired(value) : noop}
                  >
                    {(endProps) => (
                      <DurationField
                        id="provided-service-dates"
                        label="Service Dates"
                        startField={{ ...startProps }}
                        endField={{ ...endProps }}
                        inline={false}
                        required={enforceRequiredFields}
                      />
                    )}
                  </Field>
                )}
              </Field>
            ) : (
              <Field
                name="provided_service.service_start_date"
                validate={enforceRequiredFields ? (value) => validations.isRequired(value) : noop}
              >
                {(fieldProps) => (
                  <DateField
                    id="provided-service-date"
                    label="Service Date"
                    required={enforceRequiredFields}
                    {...fieldProps}
                  />
                )}
              </Field>
            )
          }
        </div>
      </div>
    </>
  );
};

PaymentsTrackServiceDetailsForm.propTypes = {
  enforceRequiredFields: PropTypes.bool.isRequired,
  canInvoiceAboveRemainingAuthorizedAmount: PropTypes.bool.isRequired,
  isBillable: PropTypes.bool.isRequired,
  isCostType: PropTypes.bool.isRequired,
  unitAmountValidations: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  requestedValueNonValid: PropTypes.bool.isRequired,
  validateInsuranceCoverage: PropTypes.func.isRequired,
};

export default PaymentsTrackServiceDetailsForm;
