import React from 'react';
import PropTypes from 'prop-types';
import ProviderOrganizationSearchTable from 'components/Backoffice/searchTable/ProviderOrganizationSearchTable';
import NetworkSearchTable from 'components/Backoffice/searchTable/NetworkSearchTable';
import NetworkOrganizationOptions from './NetworkOrganizationOptions';

const AddNetworkModalContent = ({
  viewMode,
  setViewMode,
  optionSelected,
  setOptionSelected,
  selectedNetwork,
  setSelectedNetwork,
  selectedOrganizationIds,
  setSelectedOrganizationIds,
}) => (
  <div className="flex flex-col h-full">
    {viewMode === 'add-network' && (
      <div className="flex flex-col flex-grow overflow-hidden">
        <div className="flex-grow overflow-y-auto">
          <NetworkSearchTable
            setSelectedNetwork={(network) => {
              setSelectedNetwork(network);
              setViewMode('add-network');
            }}
          />
        </div>
      </div>
    )}
    {viewMode === 'choose-options' && (
      <NetworkOrganizationOptions
        setViewMode={setViewMode}
        optionSelected={optionSelected}
        setOptionSelected={setOptionSelected}
        setSelectedOrganizationIds={setSelectedOrganizationIds}
        selectedNetwork={selectedNetwork}
      />
    )}
    {viewMode === 'choose-options' && optionSelected === 'add-orgs-selected' && (
      <div className="flex-grow overflow-y-auto pt-8 mb-4">
        <ProviderOrganizationSearchTable
          selectedOrganizationId={selectedOrganizationIds}
          setSelectedOrganizationId={setSelectedOrganizationIds}
          networkId={selectedNetwork.id}
          isMultiSelect
        />
      </div>
    )}
  </div>
);

AddNetworkModalContent.propTypes = {
  viewMode: PropTypes.string.isRequired,
  setViewMode: PropTypes.func.isRequired,
  optionSelected: PropTypes.string.isRequired,
  setOptionSelected: PropTypes.func.isRequired,
  selectedNetwork: PropTypes.object.isRequired,
  setSelectedNetwork: PropTypes.func.isRequired,
  selectedOrganizationIds: PropTypes.array.isRequired,
  setSelectedOrganizationIds: PropTypes.func.isRequired,
};

export default AddNetworkModalContent;
