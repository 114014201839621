export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_REGISTRATION = 'USER_REGISTRATION';
export const UPDATE_USER = 'UPDATE_USER';
export const SET_REDIRECT_PATH = 'SET_REDIRECT_PATH';
export const CLEAR_REDIRECT_PATH = 'CLEAR_REDIRECT_PATH';
export const UNSET_REFRESH_STATUS = 'UNSET_REFRESH_STATUS';
export const SET_ZENDESK_RETURN_TO_URL = 'SET_ZENDESK_RETURN_TO_URL';
export const CLEAR_ZENDESK_RETURN_TO_URL = 'CLEAR_ZENDESK_RETURN_TO_URL';

export const FETCH_PROFILE = 'FETCH_PROFILE';

export const UPDATE_AVATAR = 'UPDATE_AVATAR';
export const BUILD_SERVICE_TYPES = 'BUILD_SERVICE_TYPES';
export const FETCH_CURRENT_GROUP = 'FETCH_CURRENT_GROUP';
export const SET_CURRENT_GROUP = 'SET_CURRENT_GROUP';

// Browse
export const CLEAR_NETWORK_BROWSE_GROUPS = 'CLEAR_NETWORK_BROWSE_GROUPS';
export const CLEAR_NETWORK_BROWSE_FILTERS = 'CLEAR_NETWORK_BROWSE_FILTERS';
export const CLEAR_NETWORK_BROWSE_GROUP = 'CLEAR_NETWORK_BROWSE_GROUP';
export const SET_NETWORK_BROWSE_GROUPS_FETCHING = 'SET_NETWORK_BROWSE_GROUPS_FETCHING';
export const UNSET_NETWORK_BROWSE_GROUPS_FETCHING = 'UNSET_NETWORK_BROWSE_GROUPS_FETCHING';
export const UNSET_NETWORK_BROWSE_GROUPS_FETCHING_CASES = 'UNSET_NETWORK_BROWSE_GROUPS_FETCHING_CASES';
export const SEARCH_NETWORK_BROWSE_GROUPS = 'SEARCH_NETWORK_BROWSE_GROUPS';
export const CLEAR_NETWORK_BROWSE_GROUP_PROGRAMS = 'CLEAR_NETWORK_BROWSE_GROUP_PROGRAMS';
export const FETCH_NETWORK_BROWSE_GROUP_PROGRAMS = 'FETCH_NETWORK_BROWSE_GROUP_PROGRAMS';
export const SET_BROWSE_GEOGRAPHY = 'SET_BROWSE_GEOGRAPHY';

// Groups
export const SET_IS_FETCHING = 'SET_IS_FETCHING';
export const UNSET_IS_FETCHING = 'UNSET_IS_FETCHING';
export const FETCH_GROUP = 'FETCH_GROUP';
export const FETCH_GROUP_LOCATIONS = 'FETCH_GROUP_LOCATIONS';
export const UPDATE_GROUP_LOCATION = 'UPDATE_GROUP_LOCATION';
export const CREATE_GROUP_LOCATION = 'CREATE_GROUP_LOCATION';
export const DELETE_GROUP_LOCATION = 'DELETE_GROUP_LOCATION';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const CREATE_GROUP_WIZARD = 'CREATE_GROUP_WIZARD';
export const PROVIDER_REFERRAL_SCOPES = 'PROVIDER_REFERRAL_SCOPES';

// Networks/Groups
export const CREATE_OON_GROUP = 'CREATE_OON_GROUP';
export const UPDATE_OON_GROUP = 'UPDATE_OON_GROUP';
export const SET_NETWORK_GROUP_FETCH = 'SET_NETWORK_GROUP_FETCH';
export const UNSET_NETWORK_GROUP_FETCH = 'UNSET_NETWORK_GROUP_FETCH';
export const FETCH_NETWORK_GROUPS = 'FETCH_NETWORK_GROUPS';
export const FETCH_GROUP_FORMS = 'FETCH_GROUP_FORMS';
export const FETCH_CORE_GROUP_FORMS = 'FETCH_CORE_GROUP_FORMS';
export const SET_GROUP_FORMS_FETCHING = 'SET_GROUP_FORMS_FETCHING';
export const UNSET_GROUP_FORMS_FETCHING = 'UNSET_GROUP_FORMS_FETCHING';

// Groups/Invitations
export const CREATE_GROUPS_INVITATION = 'CREATE_GROUPS_INVITATION';
export const FETCH_INVITATIONS = 'FETCH_INVITATIONS';

// Groups/Users
export const FETCH_GROUPS_USERS = 'FETCH_GROUPS_USERS';
export const FETCH_GROUPS_USER = 'FETCH_GROUPS_USER';
export const FETCH_GROUPS_USERS_FROM_PROGRAM = 'FETCH_GROUPS_USERS_FROM_PROGRAM';
export const CREATE_GROUPS_USER = 'CREATE_GROUPS_USER';
export const UPDATE_GROUPS_USER = 'UPDATE_GROUPS_USER';
export const GROUPS_USER_SET_FETCHING = 'GROUPS_USER_SET_FETCHING';
export const GROUPS_USER_UNSET_FETCHING = 'GROUPS_USER_UNSET_FETCHING';

// Groups/Roles
export const FETCH_GROUPS_ROLES = 'FETCH_GROUPS_ROLES';
export const CREATE_GROUPS_ROLE = 'CREATE_GROUPS_ROLE';
export const UPDATE_GROUPS_ROLE = 'UPDATE_GROUPS_ROLE';
export const DELETE_GROUPS_ROLE = 'DELETE_GROUPS_ROLE';

// Groups/Programs
export const GROUPS_PROGRAM_SET_FETCHING = 'GROUPS_PROGRAM_SET_FETCHING';
export const GROUPS_PROGRAM_UNSET_FETCHING = 'GROUPS_PROGRAM_UNSET_FETCHING';
export const FETCH_GROUPS_PROGRAMS = 'FETCH_GROUPS_PROGRAMS';
export const CREATE_GROUPS_PROGRAM = 'CREATE_GROUPS_PROGRAM';
export const UPDATE_GROUPS_PROGRAM = 'UPDATE_GROUPS_PROGRAM';
export const ADD_USER_TO_GROUPS_PROGRAM = 'ADD_USER_TO_GROUPS_PROGRAM';
export const REMOVE_USER_FROM_GROUPS_PROGRAM = 'REMOVE_USER_FROM_GROUPS_PROGRAM';
export const SET_FETCHING_PROGRAM_ENROLLMENT = 'SET_FETCHING_PROGRAM_ENROLLMENT';
export const UNSET_FETCHING_PROGRAM_ENROLLMENT = 'UNSET_FETCHING_PROGRAM_ENROLLMENT';
export const CHECK_PROGRAM_ENROLLMENT = 'CHECK_PROGRAM_ENROLLMENT';
export const CLEAR_PROGRAM_ENROLLMENT = 'CLEAR_PROGRAM_ENROLLMENT';

// Referral Programs
export const FETCH_REFERRAL_PROGRAMS = 'FETCH_REFERRAL_PROGRAMS';
export const REASSIGN_REFERRAL_PROGRAM = 'REASSIGN_REFERRAL_PROGRAM';

// Notifications
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REPLACE_NOTIFICATION = 'REPLACE_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export const FETCH_USER_FOR_SESSION_INITIATED = 'FETCH_USER_FOR_SESSION_INITIATED';
export const FETCH_USER_FOR_SESSION_SUCCESS = 'FETCH_USER_FOR_SESSION_SUCCESS';
export const FETCH_USER_FOR_SESSION_FAILURE = 'FETCH_USER_FOR_SESSION_FAILURE';

// System Notifications
export const SHOW_SYSTEM_NOTIFICATION = 'SHOW_SYSTEM_NOTIFICATION';
export const CLEAR_SYSTEM_NOTIFICATION = 'CLEAR_SYSTEM_NOTIFICATION';

export const APPLICATION_LOAD_FROM_SESSIONSTORAGE = 'APPLICATION_LOAD_FROM_SESSIONSTORAGE';
export const USER_LOAD_FROM_SESSIONSTORAGE = 'USER_LOAD_FROM_SESSIONSTORAGE';
export const SET_AUTH_SESSION = 'SET_AUTH_SESSION';

export const INIT_WIZARD = 'INIT_WIZARD';
export const SET_WIZARD_CURRENT_STEP = 'SET_WIZARD_CURRENT_STEP';
export const SET_WIZARD_STEP_DONE = 'SET_WIZARD_STEP_DONE';
export const SET_INVITED_USER_PASSWORD = 'SET_INVITED_USER_PASSWORD';

export const INIT_FILE_UPLOAD = 'INIT_FILE_UPLOAD';
export const CLEAR_FILE_UPLOAD = 'CLEAR_FILE_UPLOAD';
export const DESTROY_FILE_UPLOAD = 'DESTROY_FILE_UPLOAD';
export const DESTROY_ALL_FILES_UPLOADS = 'DESTROY_ALL_FILES_UPLOADS';
export const FILE_UPLOAD_ADD = 'FILE_UPLOAD_ADD';

export const FETCH_GLOBAL_SETTINGS_SUCCESS = 'FETCH_GLOBAL_SETTINGS_SUCCESS';
export const FETCH_GLOBAL_SETTINGS_ERROR = 'FETCH_GLOBAL_SETTINGS_ERROR';

// Notification Preferences
export const FETCH_NOTIFICATION_PREFERENCES = 'FETCH_NOTIFICATION_PREFERENCES';
export const UPDATE_NOTIFICATION_PREFERENCES_SUCCESS = 'UPDATE_NOTIFICATION_PREFERENCES_SUCCESS';
export const UPDATE_GROUP_NOTIFICATION_PREFERENCES_SUCCESS = 'UPDATE_GROUP_NOTIFICATION_PREFERENCES_SUCCESS';
export const FETCH_GROUP_NOTIFICATION_PREFERENCES = 'FETCH_GROUP_NOTIFICATION_PREFERENCES';
export const UPDATE_NETWORK_NOTIFICATION_PREFERENCES_SUCCESS = 'UPDATE_NETWORK_NOTIFICATION_PREFERENCES_SUCCESS';
export const FETCH_NETWORK_NOTIFICATION_PREFERENCES = 'FETCH_NETWORK_NOTIFICATION_PREFERENCES';
export const UPDATE_CONTACT_NOTIFICATION_PREFERENCES_SUCCESS = 'UPDATE_CONTACT_NOTIFICATION_PREFERENCES_SUCCESS';
export const FETCH_CONTACT_NOTIFICATION_PREFERENCES = 'FETCH_CONTACT_NOTIFICATION_PREFERENCES';
export const UPDATE_PROGRAM_NOTIFICATION_PREFERENCES_SUCCESS = 'UPDATE_PROGRAM_NOTIFICATION_PREFERENCES_SUCCESS';
export const FETCH_PROGRAM_NOTIFICATION_PREFERENCES = 'FETCH_PROGRAM_NOTIFICATION_PREFERENCES';

// Group/Contacts
export const FETCH_GROUP_CONTACT = 'FETCH_GROUP_CONTACT';
export const SEARCH_GROUP_CONTACTS = 'SEARCH_GROUP_CONTACTS';
export const TOGGLE_GROUP_CONTACT_FLAG = 'TOGGLE_GROUP_CONTACT_FLAG';
export const UPDATE_GROUP_CONTACT = 'UPDATE_GROUP_CONTACT';
export const UPDATE_CONSENT_CONTACT = 'UPDATE_CONSENT_CONTACT';
export const SELECT_CONTACT = 'SELECT_CONTACT';
export const SET_CONTACTS_IS_FETCHING = 'SET_CONTACTS_IS_FETCHING';
export const UNSET_CONTACTS_IS_FETCHING = 'UNSET_CONTACTS_IS_FETCHING';
export const SET_FETCHING_INVOICED_SPENDS = 'SET_FETCHING_INVOICED_SPENDS';
export const UNSET_FETCHING_INVOICED_SPENDS = 'UNSET_FETCHING_INVOICED_SPENDS';
export const FETCH_INVOICED_SPENDS = 'FETCH_INVOICED_SPENDS';
export const FETCH_REFERRAL_CONTACT = 'FETCH_REFERRAL_CONTACT';
export const UPDATE_REFERRAL_CONTACT = 'UPDATE_REFERRAL_CONTACT';
export const FETCH_CONTACTS_BY_PRIMARY_WORKER = 'FETCH_CONTACTS_BY_PRIMARY_WORKER';
export const RENAME_CONTACT_DOCUMENT = 'RENAME_CONTACT_DOCUMENT';

// Group/Contacts/Consent
export const CONTACT_CONSENT_ACCEPTED = 'CONTACT_CONSENT_ACCEPTED';

// Groups/Contacts/PhoneNumbers
export const ADD_PHONE_NUMBER_TO_CONTACT = 'ADD_PHONE_NUMBER_TO_CONTACT';
export const ADD_PHONE_NUMBERS_TO_CONTACT = 'ADD_PHONE_NUMBERS_TO_CONTACT';
export const UPDATE_CONTACT_PHONE_NUMBER = 'UPDATE_CONTACT_PHONE_NUMBER';
export const DELETE_CONTACT_PHONE_NUMBER = 'DELETE_CONTACT_PHONE_NUMBER';

// Groups/Contacts/EmailAddresses
export const ADD_EMAIL_ADDRESS_TO_CONTACT = 'ADD_EMAIL_ADDRESS_TO_CONTACT';
export const UPDATE_CONTACT_EMAIL_ADDRESS = 'UPDATE_CONTACT_EMAIL_ADDRESS';
export const DELETE_CONTACT_EMAIL_ADDRESS = 'DELETE_CONTACT_EMAIL_ADDRESS';

// Groups/Contacts/AddressFields
export const ADD_ADDRESS_FIELD_TO_CONTACT = 'ADD_ADDRESS_FIELD_TO_CONTACT';
export const UPDATE_CONTACT_ADDRESS_FIELD = 'UPDATE_CONTACT_ADDRESS_FIELD';
export const DELETE_CONTACT_ADDRESS_FIELD = 'DELETE_CONTACT_ADDRESS_FIELD';

// Groups/Contacts/Households/Members
export const UPDATE_CONTACT_HOUSEHOLD_MEMBER = 'UPDATE_CONTACT_HOUSEHOLD_MEMBER';
export const ADD_HOUSEHOLD_MEMBER_TO_CONTACT = 'ADD_HOUSEHOLD_MEMBER_TO_CONTACT';
export const DELETE_CONTACT_HOUSEHOLD_MEMBER = 'DELETE_CONTACT_HOUSEHOLD_MEMBER';
export const SUBMIT_HOUSEHOLD_COUNT = 'SUBMIT_HOUSEHOLD_COUNT';
export const UPDATE_DYNAMIC_HOUSEHOLD_COUNT = 'UPDATE_DYNAMIC_HOUSEHOLD_COUNT';
export const SET_STATIC_HOUSEHOLD_COUNT = 'SET_STATIC_HOUSEHOLD_COUNT';
export const GET_HOUSEHOLD_COUNT = 'GET_HOUSEHOLD_COUNT';

// Groups/Contacts/Interactions
export const ADD_CONTACT_INTERACTION = 'ADD_CONTACT_INTERACTION';
export const ADD_CONTACT_PROVIDED_SERVICE = 'ADD_CONTACT_PROVIDED_SERVICE';

// Contacts
export const CREATE_GROUP_CONTACT = 'CREATE_GROUP_CONTACT';
export const RETRIEVE_STORED_SEARCHED_CONTACTS = 'RETRIEVE_STORED_SEARCHED_CONTACTS';
export const RETRIEVE_SELECTED_CONTACT = 'RETRIEVE_SELECTED_CONTACT';
export const REMOVE_SELECTED_CONTACT = 'REMOVE_SELECTED_CONTACT';
export const CLEAR_SEARCHED_CONTACTS = 'CLEAR_SEARCHED_CONTACTS';
export const REPLACE_SEARCHED_CONTACT = 'REPLACE_SEARCHED_CONTACT';

// Contact/Documents
export const CREATE_CONTACT_CASE_DOCUMENTS = 'CREATE_CONTACT_CASE_DOCUMENTS';
export const CREATE_CONTACT_DOCUMENTS = 'CREATE_CONTACT_DOCUMENTS';
export const UPDATE_CONTACT_DOCUMENT = 'UPDATE_CONTACT_DOCUMENT';
export const FETCH_CONTACT_DOCUMENTS = 'FETCH_CONTACT_DOCUMENTS';
export const FETCH_CONTACT_DOCUMENT = 'FETCH_CONTACT_DOCUMENT';
export const FIND_CONTACT_DOCUMENTS = 'FIND_CONTACT_DOCUMENTS';
export const REMOVE_CONTACT_DOCUMENT_FROM_RESOURCES = 'REMOVE_CONTACT_DOCUMENT_FROM_RESOURCES';
export const REPLACE_CONTACT_DOCUMENTS = 'REPLACE_CONTACT_DOCUMENTS';

// Group/contact/CareTeam
export const FETCH_CARE_TEAM_MEMBERS = 'FETCH_CARE_TEAM_MEMBERS';
export const SET_IS_FETCHING_CARE_TEAM_MEMBERS = 'SET_IS_FETCHING_CARE_TEAM_MEMBERS';
export const UNSET_IS_FETCHING_CARE_TEAM_MEMBERS = 'UNSET_IS_FETCHING_CARE_TEAM_MEMBERS';
export const CLEAR_CARE_TEAM_MEMBERS = 'CLEAR_CARE_TEAM_MEMBERS';
export const CREATE_CARE_TEAM_MEMBER = 'CREATE_CARE_TEAM_MEMBER';
export const DELETE_CARE_TEAM_MEMBER = 'DELETE_CARE_TEAM_MEMBER';
export const UPDATE_CARE_TEAM_MEMBER = 'UPDATE_CARE_TEAM_MEMBER';

// Service Types
export const FETCH_SERVICE_TYPES = 'FETCH_SERVICE_TYPES';
export const FETCH_GROUP_PROGRAM_SERVICE_TYPES = 'FETCH_GROUP_PROGRAM_SERVICE_TYPES';

// outcomes
export const FETCH_GROUP_OUTCOMES = 'FETCH_GROUP_OUTCOMES';
export const FETCH_NETWORK_OUTCOMES = 'FETCH_NETWORK_OUTCOMES';
export const SET_NETWORK_OUTCOMES_FETCHING = 'SET_NETWORK_OUTCOMES_FETCHING';
export const SET_GROUP_OUTCOMES_FETCHING = 'SET_GROUP_OUTCOMES_FETCHING';

// Payments Insurance
export const CREATE_INSURANCE_ENTRY_ERROR = 'CREATE_INSURANCE_ENTRY_ERROR';
export const CREATE_INSURANCE_ENTRY_STARTED = 'CREATE_INSURANCE_ENTRY_STARTED';
export const CREATE_INSURANCE_ENTRY_SUCCESS = 'CREATE_INSURANCE_ENTRY_SUCCESS';
export const DELETE_INSURANCE_ENTRY_ERROR = 'DELETE_INSURANCE_ENTRY_ERROR';
export const DELETE_INSURANCE_ENTRY_STARTED = 'DELETE_INSURANCE_ENTRY_STARTED';
export const DELETE_INSURANCE_ENTRY_SUCCESS = 'DELETE_INSURANCE_ENTRY_SUCCESS';
export const EDIT_INSURANCE_ENTRY_ERROR = 'EDIT_INSURANCE_ENTRY_ERROR';
export const EDIT_INSURANCE_ENTRY_STARTED = 'EDIT_INSURANCE_ENTRY_STARTED';
export const EDIT_INSURANCE_ENTRY_SUCCESS = 'EDIT_INSURANCE_ENTRY_SUCCESS';
export const FETCH_PAYMENTS_INSURANCE_ERROR = 'FETCH_PAYMENTS_INSURANCE_ERROR';
export const FETCH_PAYMENTS_INSURANCE_STARTED = 'FETCH_PAYMENTS_INSURANCE_STARTED';
export const FETCH_PAYMENTS_INSURANCE_SUCCESS = 'FETCH_PAYMENTS_INSURANCE_SUCCESS';
export const FETCH_PLAN_INFORMATION_ERROR = 'FETCH_PLAN_INFORMATION_ERROR';
export const FETCH_PLAN_INFORMATION_STARTED = 'FETCH_PLAN_INFORMATION_STARTED';
export const FETCH_PLAN_INFORMATION_SUCCESS = 'FETCH_PLAN_INFORMATION_SUCCESS';

// Payer
export const CREATE_PAYER_ERROR = 'CREATE_PAYER_ERROR';
export const CREATE_PAYER_STARTED = 'CREATE_PAYER_STARTED';
export const CREATE_PAYER_SUCCESS = 'CREATE_PAYER_SUCCESS';
export const FETCH_PAYERS_ERROR = 'FETCH_PAYERS_ERROR';
export const FETCH_PAYERS_STARTED = 'FETCH_PAYERS_STARTED';
export const FETCH_PAYERS_SUCCESS = 'FETCH_PAYERS_SUCCESS';
export const UPDATE_PAYER_ERROR = 'UPDATE_PAYER_ERROR';
export const UPDATE_PAYER_STARTED = 'UPDATE_PAYER_STARTED';
export const UPDATE_PAYER_SUCCESS = 'UPDATE_PAYER_SUCCESS';

// Plan
export const CREATE_PLAN_ERROR = 'CREATE_PLAN_ERROR';
export const CREATE_PLAN_STARTED = 'CREATE_PLAN_STARTED';
export const CREATE_PLAN_SUCCESS = 'CREATE_PLAN_SUCCESS';

// Referrals
export const REFERRALS_SET_FETCHING = 'REFERRALS_SET_FETCHING';
export const REFERRALS_UNSET_FETCHING = 'REFERRALS_UNSET_FETCHING';
export const FETCH_RECEIVED_REFERRALS = 'FETCH_RECEIVED_REFERRALS';
export const FETCH_SENT_REFERRALS = 'FETCH_SENT_REFERRALS';
export const FETCH_GROUPS_REFERRAL_SENT = 'FETCH_GROUPS_REFERRAL_SENT';
export const FETCH_GROUPS_REFERRAL_RECEIVED = 'FETCH_GROUPS_REFERRAL_RECEIVED';
export const FETCH_CONTACT_REFERRALS = 'FETCH_CONTACT_REFERRALS';
export const SEND_REFERRAL = 'SEND_REFERRAL';
export const SUBMIT_REFERRAL = 'SUBMIT_REFERRAL';
export const SET_REFERRAL_IS_SAVING = 'SET_REFERRAL_IS_SAVING';
export const UNSET_REFERRAL_IS_SAVING = 'UNSET_REFERRAL_IS_SAVING';
export const ACCEPT_REFERRAL_SENT = 'ACCEPT_REFERRAL_SENT';
export const ACCEPT_REFERRAL_RECEIVED = 'ACCEPT_REFERRAL_RECEIVED';
export const HOLD_REFERRAL_SENT = 'HOLD_REFERRAL_SENT';
export const HOLD_REFERRAL_RECEIVED = 'HOLD_REFERRAL_RECEIVED';
export const RECALL_REFERRAL_SENT = 'RECALL_REFERRAL_SENT';
export const RECALL_REFERRAL_RECEIVED = 'RECALL_REFERRAL_RECEIVED';
export const REJECT_REFERRAL_SENT = 'REJECT_REFERRAL_SENT';
export const REJECT_REFERRAL_RECEIVED = 'REJECT_REFERRAL_RECEIVED';
export const UPDATE_REFERRAL = 'UPDATE_REFERRAL';
export const FETCH_REFERRAL_ASSESSMENTS = 'FETCH_REFERRAL_ASSESSMENTS';
export const RETURNED_ASSESSMENT = 'RETURNED_ASSESSMENT';
export const CLEAN_RETURNED_ASSESSMENTS = 'CLEAN_RETURNED_ASSESSMENTS';
export const UPDATE_PRIMARY_REFERRAL_WORKER_SENT = 'UPDATE_PRIMARY_REFERRAL_WORKER_SENT';
export const UPDATE_PRIMARY_REFERRAL_WORKER_RECEIVED = 'UPDATE_PRIMARY_REFERRAL_WORKER_RECEIVED';
export const REFERRALS_SET_REFETCH = 'REFERRALS_SET_REFETCH';
export const REFERRALS_UNSET_REFETCH = 'REFERRALS_UNSET_REFETCH';
export const CHECK_REFERRAL_PROGRAM_ENROLLMENT = 'CHECK_REFERRAL_PROGRAM_ENROLLMENT';
export const CLEAR_REFERRAL_PROGRAM_ENROLLMENT = 'CLEAR_REFERRAL_PROGRAM_ENROLLMENT';
export const SET_FETCHING_REFERRAL_PROGRAM_ENROLLMENT = 'SET_FETCHING_REFERRAL_PROGRAM_ENROLLMENT';
export const UNSET_FETCHING_REFERRAL_PROGRAM_ENROLLMENT = 'UNSET_FETCHING_REFERRAL_PROGRAM_ENROLLMENT';
export const CLOSE_REFERRAL_SENT = 'CLOSE_REFERRAL_SENT';
export const CLOSE_REFERRAL_RECEIVED = 'CLOSE_REFERRAL_RECEIVED';

// Referral Documents
export const ADD_REFERRAL_DOCUMENTS = 'ADD_REFERRAL_DOCUMENTS';
export const FETCH_REFERRAL_DOCUMENTS = 'FETCH_REFERRAL_DOCUMENTS';
export const REMOVE_REFERRAL_DOCUMENT = 'REMOVE_REFERRAL_DOCUMENT';
export const RENAME_REFERRAL_DOCUMENT = 'RENAME_REFERRAL_DOCUMENT';

// Referral Interactions
export const ADD_REFERRAL_INTERACTION = 'ADD_REFERRAL_INTERACTION';

// Service Case Interactions
export const ADD_NOTE_INTERACTION = 'ADD_NOTE_INTERACTION';

// Payments Fee Schedule Provided Service
export const ADD_PAYMENTS_PROVIDED_SERVICE_STARTED = 'ADD_PAYMENTS_PROVIDED_SERVICE_STARTED';
export const ADD_PAYMENTS_PROVIDED_SERVICE_SUCCESS = 'ADD_PAYMENTS_PROVIDED_SERVICE_SUCCESS';
export const ADD_PAYMENTS_PROVIDED_SERVICE_ERROR = 'ADD_PAYMENTS_PROVIDED_SERVICE_ERROR';
export const FETCH_PROVIDED_SERVICES_STARTED = 'FETCH_PROVIDED_SERVICES_STARTED';
export const FETCH_PROVIDED_SERVICES_SUCCESS = 'FETCH_PROVIDED_SERVICES_SUCCESS';
export const FETCH_PROVIDED_SERVICES_ERROR = 'FETCH_PROVIDED_SERVICES_ERROR';
export const SUBMIT_PAYMENT_PROVIDED_SERVICE_STARTED = 'SUBMIT_PAYMENT_PROVIDED_SERVICE_STARTED';
export const SUBMIT_PAYMENT_PROVIDED_SERVICE_SUCCESS = 'SUBMIT_PAYMENT_PROVIDED_SERVICE_SUCCESS';
export const SUBMIT_PAYMENT_PROVIDED_SERVICE_ERROR = 'SUBMIT_PAYMENT_PROVIDED_SERVICE_ERROR';
export const UPDATE_PAYMENTS_PROVIDED_SERVICE_STARTED = 'UPDATE_PAYMENTS_PROVIDED_SERVICE_STARTED';
export const UPDATE_PAYMENTS_PROVIDED_SERVICE_SUCCESS = 'UPDATE_PAYMENTS_PROVIDED_SERVICE_SUCCESS';
export const UPDATE_PAYMENTS_PROVIDED_SERVICE_ERROR = 'UPDATE_PAYMENTS_PROVIDED_SERVICE_ERROR';

// Session
export const SET_IS_COORDINATION_GROUP = 'SET_IS_COORDINATION_GROUP';
export const FETCH_BOOTSTRAP_ENUMS = 'FETCH_BOOTSTRAP_ENUMS';
export const SEARCH_GROUPS = 'SEARCH_GROUPS';
export const SEARCH_CONTACT = 'SEARCH_CONTACT';
export const FETCH_GEO_POSITION = 'FETCH_GEO_POSITION';
export const FETCH_IP_POSITION = 'FETCH_IP_POSITION';
export const FETCH_SERVICE_TYPES_FLATTENED = 'FETCH_SERVICE_TYPES_FLATTENED';

// Activity Stream
export const APPEND_CONTACT_ACTIVITY_STREAM = 'APPEND_CONTACT_ACTIVITY_STREAM';
export const FETCH_CONTACT_ACTIVITY_STREAM = 'FETCH_CONTACT_ACTIVITY_STREAM';
export const FILTERED_APPEND_CONTACT_ACTIVITY_STREAM = 'FILTERED_APPEND_CONTACT_ACTIVITY_STREAM';
export const FILTER_CONTACT_ACTIVITY_STREAM = 'FILTER_CONTACT_ACTIVITY_STREAM';
export const UNSET_REFETCH_CONTACT_ACTIVITY_STREAM = 'UNSET_REFETCH_CONTACT_ACTIVITY_STREAM';
export const SET_REFETCH_CONTACT_ACTIVITY_STREAM = 'SET_REFETCH_CONTACT_ACTIVITY_STREAM';

// Dashboard
export const APPEND_DASHBOARD_ACTIVITY_STREAM = 'APPEND_DASHBOARD_ACTIVITY_STREAM';
export const FETCH_DASHBOARD_ACTIVITY_STREAM = 'FETCH_DASHBOARD_ACTIVITY_STREAM';
export const FILTER_DASHBOARD_ACTIVITY_STREAM = 'FILTER_DASHBOARD_ACTIVITY_STREAM';
export const FILTERED_APPEND_DASHBOARD_ACTIVITY_STREAM = 'FILTERED_APPEND_DASHBOARD_ACTIVITY_STREAM';
export const RESET_DASHBOARD_ACTIVITY_STREAM = 'RESET_DASHBOARD_ACTIVITY_STREAM';
export const SET_DASHBOARD_ACTIVITY_STREAM_ERROR = 'SET_DASHBOARD_ACTIVITY_STREAM_ERROR';
export const UNSET_DASHBOARD_ACTIVITY_STREAM_ERROR = 'UNSET_DASHBOARD_ACTIVITY_STREAM_ERROR';
export const SET_DASHBOARD_REFETCH = 'SET_DASHBOARD_REFETCH';

// Referral Activity Stream
export const APPEND_REFERRAL_ACTIVITY_STREAM = 'APPEND_REFERRAL_ACTIVITY_STREAM';
export const FETCH_REFERRAL_ACTIVITY_STREAM = 'FETCH_REFERRAL_ACTIVITY_STREAM';
export const FILTERED_APPEND_REFERRAL_ACTIVITY_STREAM = 'FILTERED_APPEND_REFERRAL_ACTIVITY_STREAM';
export const FILTER_REFERRAL_ACTIVITY_STREAM = 'FILTER_REFERRAL_ACTIVITY_STREAM';
export const UNSET_REFETCH_REFERRAL_ACTIVITY_STREAM = 'UNSET_REFETCH_REFERRAL_ACTIVITY_STREAM';
export const SET_REFETCH_REFERRAL_ACTIVITY_STREAM = 'SET_REFETCH_REFERRAL_ACTIVITY_STREAM';
export const UNSET_REFERRAL_ACTIVITY_STREAM_ERROR = 'UNSET_REFERRAL_ACTIVITY_STREAM_ERROR';
export const SET_REFERRAL_ACTIVITY_STREAM_ERROR = 'SET_REFERRAL_ACTIVITY_STREAM_ERROR';

// ServiceCase Activity Stream
export const APPEND_SERVICE_CASE_ACTIVITY_STREAM = 'APPEND_SERVICE_CASE_ACTIVITY_STREAM';
export const FETCH_SERVICE_CASE_ACTIVITY_STREAM = 'FETCH_SERVICE_CASE_ACTIVITY_STREAM';
export const FILTERED_APPEND_SERVICE_CASE_ACTIVITY_STREAM = 'FILTERED_APPEND_SERVICE_CASE_ACTIVITY_STREAM';
export const FILTER_SERVICE_CASE_ACTIVITY_STREAM = 'FILTER_SERVICE_CASE_ACTIVITY_STREAM';
export const UNSET_REFETCH_SERVICE_CASE_ACTIVITY_STREAM = 'UNSET_REFETCH_SERVICE_CASE_ACTIVITY_STREAM';
export const SET_REFETCH_SERVICE_CASE_ACTIVITY_STREAM = 'SET_REFETCH_SERVICE_CASE_ACTIVITY_STREAM';

// Service Case
export const FETCH_SERVICE_CASE = 'FETCH_SERVICE_CASE';
export const UNSET_FETCH_SERVICE_CASES = 'UNSET_FETCH_SERVICE_CASES';
export const FETCH_SERVICE_CASES = 'FETCH_SERVICE_CASES';
export const SET_FETCH_SERVICE_CASES = 'SET_FETCH_SERVICE_CASES';
export const UPDATE_SERVICE_CASE = 'UPDATE_SERVICE_CASE';
export const CREATE_SERVICE_CASE = 'CREATE_SERVICE_CASE';
export const CREATE_SERVICE_CASE_SET_SUBMITTING = 'CREATE_SERVICE_CASE_SET_SUBMITTING';
export const CREATE_SERVICE_CASE_UNSET_SUBMITTING = 'CREATE_SERVICE_CASE_UNSET_SUBMITTING';
export const CLOSE_SERVICE_CASE = 'CLOSE_SERVICE_CASE';
export const FETCH_SERVICE_CASE_ASSESSMENTS = 'FETCH_SERVICE_CASE_ASSESSMENTS';
export const UNSET_SERVICE_CASES_REFETCH = 'UNSET_SERVICE_CASES_REFETCH';
export const SET_SERVICE_CASES_REFETCH = 'SET_SERVICE_CASES_REFETCH';
export const DELETE_SERVICE_CASE_DOCUMENT = 'DELETE_SERVICE_CASE_DOCUMENT';
export const UPDATE_PROGRAM_INFORMATION = 'UPDATE_PROGRAM_INFORMATION';
export const REOPEN_SERVICE_CASE = 'REOPEN_SERVICE_CASE';

// Case Documents
export const ADD_CASE_DOCUMENTS = 'ADD_CASE_DOCUMENTS';
export const REMOVE_CASE_DOCUMENT = 'REMOVE_CASE_DOCUMENT';
export const RENAME_CASE_DOCUMENT = 'RENAME_CASE_DOCUMENT';

// Assistance Requests
export const ASSISTANCE_REQUESTS_START_FETCHING = 'ASSISTANCE_REQUESTS_START_FETCHING';
export const ASSISTANCE_REQUESTS_END_FETCHING = 'ASSISTANCE_REQUESTS_END_FETCHING';
export const FETCH_ASSISTANCE_REQUESTS_CONFIG = 'FETCH_ASSISTANCE_REQUESTS_CONFIG';
export const FETCH_ASSISTANCE_REQUESTS = 'FETCH_ASSISTANCE_REQUESTS';
export const FETCH_ASSISTANCE_REQUEST = 'FETCH_ASSISTANCE_REQUEST';
export const FETCH_ASSISTANCE_REQUEST_FORM = 'FETCH_ASSISTANCE_REQUEST_FORM';
export const FETCH_ASSISTANCE_REQUEST_FORMS = 'FETCH_ASSISTANCE_REQUEST_FORMS';
export const ADD_ASSISTANCE_REQUEST_INTERACTION = 'ADD_ASSISTANCE_REQUEST_INTERACTION';
export const CLOSE_ASSISTANCE_REQUEST = 'CLOSE_ASSISTANCE_REQUEST';

// Assistance Request Activity Stream
export const APPEND_ASSISTANCE_REQUEST_ACTIVITY_STREAM = 'APPEND_ASSISTANCE_REQUEST_ACTIVITY_STREAM';
export const FETCH_ASSISTANCE_REQUEST_ACTIVITY_STREAM = 'FETCH_ASSISTANCE_REQUEST_ACTIVITY_STREAM';
export const FILTERED_APPEND_ASSISTANCE_REQUEST_ACTIVITY_STREAM = 'FILTERED_APPEND_ASSISTANCE_REQUEST_ACTIVITY_STREAM';
export const FILTER_ASSISTANCE_REQUEST_ACTIVITY_STREAM = 'FILTER_ASSISTANCE_REQUEST_ACTIVITY_STREAM';
export const UNSET_REFETCH_ASSISTANCE_REQUEST_ACTIVITY_STREAM = 'UNSET_REFETCH_ASSISTANCE_REQUEST_ACTIVITY_STREAM';
export const SET_REFETCH_ASSISTANCE_REQUEST_ACTIVITY_STREAM = 'SET_REFETCH_ASSISTANCE_REQUEST_ACTIVITY_STREAM';

// THUNK
export const THUNK_START = 'THUNK_START';
export const THUNK_END = 'THUNK_END';
export const THUNK_FAILED = 'THUNK_FAILED';

// Networks
export const SEARCH_NETWORK_GROUPS = 'SEARCH_NETWORK_GROUPS';
export const SEARCH_NETWORK_GROUPS_BY_REFERRAL_SCOPE = 'SEARCH_NETWORK_GROUPS_BY_REFERRAL_SCOPE';
export const FETCH_NETWORK_GROUP = 'FETCH_NETWORK_GROUP';
export const FETCH_NETWORK_SERVICE_TYPES = 'FETCH_NETWORK_SERVICE_TYPES';
export const FETCH_NETWORK_USERS = 'FETCH_NETWORK_USERS';
export const FETCH_CURRENT_NETWORK = 'FETCH_CURRENT_NETWORK';
export const SET_CURRENT_NETWORK = 'SET_CURRENT_NETWORK';
export const SET_NETWORK_GROUP_FETCHING = 'SET_NETWORK_GROUP_FETCHING';
export const UNSET_NETWORK_USERS_FETCHING = 'UNSET_NETWORK_USERS_FETCHING';
export const SET_NETWORK_USERS_FETCHING = 'SET_NETWORK_USERS_FETCHING';
export const UNSET_NETWORK_GROUP_FETCHING = 'UNSET_NETWORK_GROUP_FETCHING';
export const SET_NETWORK_GROUPS_FETCHING = 'SET_NETWORK_GROUPS_FETCHING';
export const SET_NETWORK_GROUPS = 'SET_NETWORK_GROUPS';
export const UNSET_NETWORK_GROUPS_FETCHING = 'UNSET_NETWORK_GROUPS_FETCHING';
export const CLEAR_NETWORK_GROUP = 'CLEAR_NETWORK_GROUP';
export const CLEAR_NETWORK_GROUPS = 'CLEAR_NETWORK_GROUPS';
export const CLEAR_NETWORK_GROUP_SEARCH = 'CLEAR_NETWORK_GROUP_SEARCH';
export const FETCH_NETWORK_GROUP_PROGRAMS = 'FETCH_NETWORK_GROUP_PROGRAMS';

// new actions for setting status for network users
export const FETCH_NETWORK_USERS_STARTED = 'FETCH_NETWORK_USERS_STARTED';
export const FETCH_NETWORK_USERS_SUCCESS = 'FETCH_NETWORK_USERS_SUCCESS';
export const FETCH_NETWORK_USERS_ERROR = 'FETCH_NETWORK_USERS_ERROR';

// Account Confirmation
export const FETCH_CONFIRMATION_DETAILS = 'FETCH_CONFIRMATION_DETAILS';
export const SUBMIT_CONFIRMATION_DETAILS = 'SUBMIT_CONFIRMATION_DETAILS';

// Assessments
export const FETCH_CASE_ASSESSMENTS = 'FETCH_CASE_ASSESSMENTS';
export const FETCH_ASSESSMENTS = 'FETCH_ASSESSMENTS';
export const ASSESSMENTS_SET_FETCHING = 'ASSESSMENTS_SET_FETCHING';
export const ASSESSMENTS_UNSET_FETCHING = 'ASSESSMENTS_UNSET_FETCHING';
export const FETCH_ASSESSMENT = 'FETCH_ASSESSMENT';
export const PATCH_ASSESSMENT = 'PATCH_ASSESSMENT';
export const FETCH_ASSESSMENT_FROM_REFERRAL = 'FETCH_ASSESSMENT_FROM_REFERRAL';
export const PATCH_ASSESSMENT_FROM_REFERRAL = 'PATCH_ASSESSMENT_FROM_REFERRAL';
export const SET_ASSESSMENTS_RESPONSES = 'SET_ASSESSMENTS_RESPONSES';

// Session - Support
export const EULA_AGREEMENT_ACCEPTED = 'EULA_AGREEMENT_ACCEPTED';
export const SET_EULA_ACCEPTANCE = 'SET_EULA_ACCEPTANCE';
export const FETCH_SUPPORT = 'FETCH_SUPPORT';
export const UNITE_US_PARTICIPATION_ACCEPTED = 'UNITE_US_PARTICIPATION_ACCEPTED';

// RESET STATE
export const RESET_GROUP_CONTACTS_TO_DEFAULT_STATE = 'RESET_GROUP_CONTACTS_TO_DEFAULT_STATE';

// Universal Search
export const SET_SEARCH_FETCHING = 'SET_SEARCH_FETCHING';
export const UNSET_SEARCH_FETCHING = 'UNSET_SEARCH_FETCHING';
export const FETCH_SEARCH_INDEX_RESULTS = 'FETCH_SEARCH_INDEX_RESULTS';
export const SET_SEARCH_INDEX_FETCHING = 'SET_SEARCH_INDEX_FETCHING';
export const UNSET_SEARCH_INDEX_FETCHING = 'UNSET_SEARCH_INDEX_FETCHING';

// Contact Intakes
export const SET_CONTACT_INTAKES_FETCHING = 'SET_CONTACT_INTAKES_FETCHING';
export const UNSET_CONTACT_INTAKES_FETCHING = 'UNSET_CONTACT_INTAKES_FETCHING';
export const FETCH_GROUP_INTAKE = 'FETCH_GROUP_INTAKE';
export const FETCH_CONTACT_INTAKES = 'FETCH_CONTACT_INTAKES';
export const RESET_CONTACT_INTAKES = 'RESET_CONTACT_INTAKES';
export const SET_GROUP_INTAKE_FETCHING = 'SET_GROUP_INTAKE_FETCHING';
export const UNSET_GROUP_INTAKE_FETCHING = 'UNSET_GROUP_INTAKE_FETCHING';
export const DELETE_GROUP_INTAKE = 'DELETE_GROUP_INTAKE';
export const DELETE_CONTACT_INTAKE = 'DELETE_CONTACT_INTAKE';
export const FETCH_CONTACT_INTAKE = 'FETCH_CONTACT_INTAKE';
export const SET_CONTACT_INTAKE_FETCHING = 'SET_CONTACT_INTAKE_FETCHING';
export const UNSET_CONTACT_INTAKE_FETCHING = 'UNSET_CONTACT_INTAKE_FETCHING';
export const FETCH_GROUPS_USERS_WITH_CONTACTS = 'FETCH_GROUPS_USERS_WITH_CONTACTS';
export const FETCH_GROUPS_USERS_WITH_CONTACTS_FAIL = 'FETCH_GROUPS_USERS_WITH_CONTACTS_FAIL';
export const RESET_INTAKES = 'RESET_INTAKES';
export const ADD_INTAKE_DOCUMENTS = 'ADD_INTAKE_DOCUMENTS';
export const REMOVE_INTAKE_DOCUMENT = 'REMOVE_INTAKE_DOCUMENT';
export const RENAME_INTAKE_DOCUMENT = 'RENAME_INTAKE_DOCUMENT';

// Client Forms
export const SET_PROFILE_FORMS_FETCHING = 'SET_PROFILE_FORMS_FETCHING';
export const UNSET_PROFILE_FORMS_FETCHING = 'UNSET_PROFILE_FORMS_FETCHING';
export const FETCH_PROFILE_FORMS = 'FETCH_PROFILE_FORMS';

// Intake Needs
export const FETCH_NEED_FORM = 'FETCH_NEED_FORM';

// unite-us client actions
export const FORM_SAVING = 'FORM_SAVING';
export const FORM_COMPLETE = 'FORM_COMPLETE';

// Report Exports
export const FETCH_CASES_REPORT = 'FETCH_CASES_REPORT';
export const FETCH_CONTACTS_REPORT = 'FETCH_CONTACTS_REPORT';
export const FETCH_GROUP_USERS_REPORT = 'FETCH_GROUP_USERS_REPORT';
export const FETCH_NETWORK_USERS_REPORT = 'FETCH_NETWORK_USERS_REPORT';
export const FETCH_REFERRALS_REPORT = 'FETCH_REFERRALS_REPORT';

// Dashboard
export const SET_DASHBOARD_FETCHING = 'SET_DASHBOARD_FETCHING';
export const UNSET_DASHBOARD_FETCHING = 'UNSET_DASHBOARD_FETCHING';
export const DASHBOARD_FETCH_REFERRALS = 'DASHBOARD_FETCH_REFERRALS';
export const DASHBOARD_FETCH_ASSISTANCE_REQUESTS = 'DASHBOARD_FETCH_ASSISTANCE_REQUESTS';
export const DASHBOARD_FETCH_CASES = 'DASHBOARD_FETCH_CASES';
export const DASHBOARD_FETCH_CONTACTS = 'DASHBOARD_FETCH_CONTACTS';
export const DASHBOARD_FETCH_INTAKES = 'DASHBOARD_FETCH_INTAKES';
export const DASHBOARD_FETCH_ACTIONABLES = 'DASHBOARD_FETCH_ACTIONABLES';
export const UNSET_REFETCH_ACTIONABLES = 'UNSET_REFETCH_ACTIONABLES';
export const RESET_DASHBOARD = 'RESET_DASHBOARD';
export const DASHBOARD_SET_ACTIONABLES_IS_FETCHING = 'DASHBOARD_SET_ACTIONABLES_IS_FETCHING';
export const DASHBOARD_UNSET_ACTIONABLES_IS_FETCHING = 'DASHBOARD_UNSET_ACTIONABLES_IS_FETCHING';
export const REMOVE_DELETED_DRAFT_REFERRAL = 'REMOVE_DELETED_DRAFT_REFERRAL';
export const DASHBOARD_FETCH_SCREENINGS = 'DASHBOARD_FETCH_SCREENINGS';
export const DASHBOARD_FETCH_EXTERNAL_SCREENINGS = 'DASHBOARD_FETCH_EXTERNAL_SCREENINGS';

// Exports
export const FETCH_EXPORTS = 'FETCH_EXPORTS';
export const SET_EXPORTS_REFETCH = 'SET_EXPORTS_REFETCH';
export const CREATE_EXPORT = 'CREATE_EXPORT';

// Websockets
export const WS_INCREMENT_COUNT = 'WS_INCREMENT_COUNT';
export const WS_DECREMENT_COUNT = 'WS_DECREMENT_COUNT';
export const WS_HANDLE_MESSAGE = 'WS_HANDLE_MESSAGE';
export const WS_CONNECT = 'WS_CONNECT';
export const WS_DISCONNECT = 'WS_DISCONNECT';

// Screenings
export const FETCH_CONTACT_SCREENING = 'FETCH_CONTACT_SCREENING';
export const FETCH_CONTACT_SCREENINGS = 'FETCH_CONTACT_SCREENINGS';
export const SET_CONTACT_SCREENINGS_FETCHING = 'SET_CONTACT_SCREENINGS_FETCHING';
export const UNSET_CONTACT_SCREENINGS_FETCHING = 'UNSET_CONTACT_SCREENINGS_FETCHING';
export const FETCH_GROUP_SCREENING = 'FETCH_GROUP_SCREENING';
export const SCREENING_CONTACTS_FETCHED = 'SCREENING_CONTACTS_FETCHED';
export const CREATE_CONTACT_SCREENING = 'CREATE_CONTACT_SCREENING';
export const UPDATE_CONTACT_SCREENING = 'UPDATE_CONTACT_SCREENING';

// Insights
export const FETCH_INSIGHTS = 'FETCH_INSIGHTS';
export const SET_INSIGHTS_REFETCH = 'SET_EXPORTS_REFETCH';

// Impersonations
export const SET_IMPERSONATION = 'SET_IMPERSONATION';

// Feature Flags
export const FEATURE_FLAG_UPDATE = 'FEATURE_FLAG_UPDATE';

// Employees
export const FETCH_EMPLOYEES_STARTED = 'FETCH_EMPLOYEES_STARTED';
export const FETCH_EMPLOYEES_SUCCESS = 'FETCH_EMPLOYEES_SUCCESS';
export const FETCH_EMPLOYEES_ERROR = 'FETCH_EMPLOYEES_ERROR';
export const SET_EMPLOYEES_PAGING = 'SET_EMPLOYEES_PAGING';
export const GET_CACHED_EMPLOYEES = 'GET_CACHED_EMPLOYEES';
export const UPDATE_EMPLOYEE_STARTED = 'UPDATE_EMPLOYEE_STARTED';
export const UPDATE_EMPLOYEE_SUCCESS = 'UPDATE_EMPLOYEE_SUCCESS';
export const UPDATE_EMPLOYEE_ERROR = 'UPDATE_EMPLOYEE_ERROR';

// Fee Schedules
export const CREATE_FEE_SCHEDULE_STARTED = 'CREATE_FEE_SCHEDULE_STARTED';
export const CREATE_FEE_SCHEDULE_SUCCESS = 'CREATE_FEE_SCHEDULE_SUCCESS';
export const CREATE_FEE_SCHEDULE_ERROR = 'CREATE_FEE_SCHEDULE_ERROR';
export const FETCH_FEE_SCHEDULES_STARTED = 'FETCH_FEE_SCHEDULES_STARTED';
export const FETCH_FEE_SCHEDULES_SUCCESS = 'FETCH_FEE_SCHEDULES_SUCCESS';
export const FETCH_FEE_SCHEDULES_ERROR = 'FETCH_FEE_SCHEDULES_ERROR';
export const UPDATE_FEE_SCHEDULE_STARTED = 'UPDATE_FEE_SCHEDULE_STARTED';
export const UPDATE_FEE_SCHEDULE_SUCCESS = 'UPDATE_FEE_SCHEDULE_SUCCESS';
export const UPDATE_FEE_SCHEDULE_ERROR = 'UPDATE_FEE_SCHEDULE_ERROR';

// Fee Schedule Programs
export const CREATE_FEE_SCHEDULE_PROGRAM_STARTED = 'CREATE_FEE_SCHEDULE_PROGRAM_STARTED';
export const CREATE_FEE_SCHEDULE_PROGRAM_SUCCESS = 'CREATE_FEE_SCHEDULE_PROGRAM_SUCCESS';
export const CREATE_FEE_SCHEDULE_PROGRAM_ERROR = 'CREATE_FEE_SCHEDULE_PROGRAM_ERROR';
export const FETCH_FEE_SCHEDULE_PROGRAM_STARTED = 'FETCH_FEE_SCHEDULE_PROGRAM_STARTED';
export const FETCH_FEE_SCHEDULE_PROGRAM_SUCCESS = 'FETCH_FEE_SCHEDULE_PROGRAM_SUCCESS';
export const FETCH_FEE_SCHEDULE_PROGRAM_ERROR = 'FETCH_FEE_SCHEDULE_PROGRAM_ERROR';
export const UPDATE_FEE_SCHEDULE_PROGRAM_STARTED = 'UPDATE_FEE_SCHEDULE_PROGRAM_STARTED';
export const UPDATE_FEE_SCHEDULE_PROGRAM_SUCCESS = 'UPDATE_FEE_SCHEDULE_PROGRAM_SUCCESS';
export const UPDATE_FEE_SCHEDULE_PROGRAM_ERROR = 'UPDATE_FEE_SCHEDULE_PROGRAM_ERROR';

// Subscriptions
export const FETCH_SUBSCRIPTIONS_STARTED = 'FETCH_SUBSCRIPTIONS_STARTED';
export const FETCH_SUBSCRIPTIONS_SUCCESS = 'FETCH_SUBSCRIPTIONS_SUCCESS';
export const FETCH_SUBSCRIPTIONS_ERROR = 'FETCH_SUBSCRIPTIONS_ERROR';

// Roles (CORE-API)
export const FETCH_ROLES_STARTED = 'FETCH_ROLES_STARTED';
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_ERROR = 'FETCH_ROLES_ERROR';

// Account Roles (CORE-API)
export const FETCH_ACCOUNT_ROLES_STARTED = 'FETCH_ACCOUNT_ROLES_STARTED';
export const FETCH_ACCOUNT_ROLES_SUCCESS = 'FETCH_ACCOUNT_ROLES_SUCCESS';
export const FETCH_ACCOUNT_ROLES_ERROR = 'FETCH_ACCOUNT_ROLES_ERROR';

// File Uploads (CORE-API)
export const FETCH_FILE_UPLOADS_STARTED = 'FETCH_FILE_UPLOADS_STARTED';
export const FETCH_FILE_UPLOADS_SUCCESS = 'FETCH_FILE_UPLOADS_SUCCESS';
export const FETCH_FILE_UPLOADS_ERROR = 'FETCH_FILE_UPLOADS_ERROR';
export const DELETE_FILE_UPLOAD_SUCCESS = 'DELETE_FILE_UPLOAD_SUCCESS ';

// Tags (CORE-API)
export const FETCH_PROVIDER_TAGS_STARTED = 'FETCH_PROVIDER_TAGS_STARTED';
export const FETCH_PROVIDER_TAGS_SUCCESS = 'FETCH_PROVIDER_TAGS_SUCCESS';
export const FETCH_PROVIDER_TAGS_ERROR = 'FETCH_PROVIDER_TAGS_ERROR';

// Users V2
export const FETCH_USERS_V2_STARTED = 'FETCH_USERS_V2_STARTED';
export const FETCH_USERS_V2_SUCCESS = 'FETCH_USERS_V2_SUCCESS';
export const FETCH_USERS_V2_ERROR = 'FETCH_USERS_V2_ERROR';

// users Auth Service
export const FETCH_AUTH_USERS_STARTED = 'FETCH_AUTH_USERS_STARTED';
export const FETCH_AUTH_USERS_SUCCESS = 'FETCH_AUTH_USERS_SUCCESS';
export const FETCH_AUTH_USERS_ERROR = 'FETCH_AUTH_USERS_ERROR';

// CORE actions:

// Global Reducer
export const UPDATE_GLOBAL_STATE = 'UPDATE_GLOBAL_STATE';
