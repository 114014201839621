import React from 'react';
import { Badge } from '@unite-us/ui';
import { filterIndexTabs } from 'src/components/Dashboard/utils/dataHelpers';
import { removeOptionalParamsFromPath } from 'common/utils/Navigation';
import _ from 'lodash';
import 'src/components/Dashboard/stylesheets/dashboard.scss';
import canViewNavItem from './canViewNavItem';

function countBadge(route, actionables) {
  const countPath = _.get(route, 'countPath', null);
  if (!countPath) {
    return null;
  }
  const count = _.get(actionables, countPath, 0);
  if (!count) {
    return null;
  }

  return (
    <Badge
      text={count.toString()}
      style={{
        backgroundColor: '#D0021B',
      }}
    />
  );
}

function reduceRouteChildren({
  route, path, actionables, flagEnabled, currentNetwork, isCCUser, groupId, user,
}) {
  return _.reduce(_.get(route, 'childRoutes', []), (acc, childRoute) => {
    if (!canViewNavItem({
      route, childRoute, currentNetwork, isCCUser, groupId, user, actionables, flagEnabled,
    })) {
      return acc;
    }
    const count = countBadge(childRoute, actionables);
    const newPath = removeOptionalParamsFromPath(`${path}/${childRoute.path}`);

    return [
      ...acc,
      _.isEmpty(childRoute.childRoutes) ? {
        label: (<div className="count-badge">{childRoute.label}{count}</div>),
        path: newPath,
        disabled: false,
        eventConstant: childRoute.eventConstant,
      } : {
        label: childRoute.label,
        path: newPath,
        disabled: true,
        children: reduceRouteChildren({
          actionables,
          flagEnabled,
          isCCUser,
          path: newPath,
          route: childRoute,
        }),
      },
    ];
  }, []);
}

function routesToNavConfig({
  route, actionables, flagEnabled, isCCUser, allNetworks, referralScopes, groupId, currentNetwork, user = {},
} = {}) {
  const routeTabs = _.reduce(_.get(route, 'childRoutes', []), (acc, childRoute) => {
    const path = removeOptionalParamsFromPath(`/${route.path}/${childRoute.path}`);

    return [...acc, {
      label: childRoute.label,
      path,
      disabled: false,
      expandable: false,
      children: reduceRouteChildren({
        actionables,
        flagEnabled,
        groupId,
        currentNetwork,
        isCCUser,
        allNetworks,
        path,
        route: childRoute,
        referralScopes,
        user,
      }),
    }];
  }, []);

  return filterIndexTabs(routeTabs);
}

export default routesToNavConfig;
