import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'common/utils/browserHistory';
import { Tabs, Tab } from '@unite-us/app-components';
import PlanSummary from './PlanSummary';

function urlMappings(payerId, planId, feeScheduleId) {
  const feeSchedulePath = `/backoffice/fee_schedules/${feeScheduleId}/plans/${planId}`;
  const payerPath = `/backoffice/payers/${payerId}/plans/${planId}`;
  const baseUrl = feeScheduleId ? feeSchedulePath : payerPath;

  return {
    overview: `${baseUrl}`,
    networks: `${baseUrl}/networks`,
    attachments: `${baseUrl}/attachments`,
  };
}

const PlanSubHeader = ({ plan, payerId, feeScheduleId }) => {
  const availableTabs = ['overview', 'networks', 'attachments'];
  const initialLocation = () => {
    const path = window.location.pathname.split('/').pop();
    return availableTabs.includes(path) ? path : 'overview';
  };
  const [location, setLocation] = useState(initialLocation);
  const handleTabChange = (value) => {
    setLocation(value);
    browserHistory.push(urlMappings(payerId, plan.id, feeScheduleId)[value]);
  };

  return (
    <div className="px-6 pt-5 fee-schedule-program-sub-header-items">
      <PlanSummary plan={plan} feeScheduleId={feeScheduleId} />
      <Tabs
        id="plan-tabs"
        className="plan-tabs mt-5"
        onChange={handleTabChange}
        value={location}
      >
        <Tab id="plan-overview-tab" label="Overview Test" value="overview" />
        {plan.plan_type === 'social' && (
          [
            <Tab id="plan-networks-tab" label="Network/Organization" value="networks" />,
            <Tab id="plan-attachments-tab" label="Enrollments Attachments" value="attachments" />,
          ]
        )}
      </Tabs>
    </div>
  );
};

PlanSubHeader.propTypes = {
  payerId: PropTypes.string.isRequired,
  plan: PropTypes.object.isRequired,
  feeScheduleId: PropTypes.string,
};

PlanSubHeader.defaultProps = {
  feeScheduleId: '',
};

export default PlanSubHeader;
