import { canHoldForReview } from '@unite-us/app-create-referral';
import isServiceTypeInProgram from 'src/components/Referrals/ReferralDetail/utils/isServiceTypeInProgram';
import returnNoServiceTypeInProgramMessage from 'src/components/Referrals/ReferralDetail/utils/returnNoServiceTypeInProgramMessage';
import { HOLD_MODAL_DIALOG } from '../../constants';

// TODO: might have to look within the Referral History hook instead of making the api call to
// `referral` in order to fetch the case referrals
const holdForReview = async ({
  referral,
  caseReferrals,
  groupId,
  user,
  openModal,
}) => {
  try {
    const sentReferrals = caseReferrals.filter((r) => r.state === 'sent');
    if (!canHoldForReview({ user, referral, groupId }) || sentReferrals.length > 1) {
      return null;
    }

    return {
      label: 'Hold For Review',
      value: 'holdForReview',
      className: 'take-action-hold-for-review',
      icon: 'Clock',
      description: isServiceTypeInProgram(referral) ?
        'Hold the referral until you’re sure you can help.' :
        returnNoServiceTypeInProgramMessage({ action: 'holdForReview' }),
      action: () => openModal(HOLD_MODAL_DIALOG),
      disabled: !isServiceTypeInProgram(referral),
    };
  } catch (error) {
    return error;
  }
};

export default holdForReview;
