import React from 'react';
import PropTypes from 'prop-types';
import { InvoiceWorkqueue } from './InvoiceWorkqueue';
import { SUBMITTED_CBO_ADMIN_STATUSES } from '../constants';
import { NoInvoicesFoundCard } from '../components';

const SubmittedInvoicesDWQ = ({ children }) => {
  const bulkActions = ['IconDownloadV2', 'IconArchive'];

  return (
    <InvoiceWorkqueue
      bulkActions={bulkActions}
      cboStatuses={SUBMITTED_CBO_ADMIN_STATUSES}
      renderNoInvoicesFound={({ isFiltered, resetFilters }) => (
        <NoInvoicesFoundCard isFiltered={isFiltered} status="submitted" resetFilters={resetFilters} />
      )}
      pageName="Submitted"
      path="submitted/"
    >
      {children}
    </InvoiceWorkqueue>
  );
};

SubmittedInvoicesDWQ.propTypes = {
  children: PropTypes.node,
};

SubmittedInvoicesDWQ.defaultProps = {
  children: undefined,
};
export default SubmittedInvoicesDWQ;
