import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, get, isFunction, find } from 'lodash';
import { browserHistory } from 'common/utils/browserHistory';
import {
  uup459SupersetPhase2,
  hasLimitedGenderOptions,
  cl787SexualityFields,
  scrn7ScreeningsCompletion,
  cl1018PreferredLanguagesFields,
} from 'common/utils/FeatureFlags/flags';
import { Button, validations } from '@unite-us/app-components';
import { isHttpError } from 'common/utils/Error';
import { OverlaySpinner, Spinner } from 'common/spinners';
import findCurrentGroup from 'common/utils/findCurrentGroup';
import { getGroup } from 'common/utils/stateHelpers';
import { validateReduxForm } from 'common/form';
import { getEnumsFromState } from 'common/utils/Enums';
import { getInitialPaymentsFormData } from 'src/components/Insurance/utils/';
import { fetchGroupContact, fetchProfileForms } from 'actions/Contact/Group';
import { fetchGroup } from 'actions/Group';
import { removeSelectedContact } from 'actions/Contact';
import PaymentsInformation from 'src/components/Insurance/PaymentsInformation';
import { DOMAIN_CONSTANTS } from 'src/common/constants';
import enforcedConsentRoute from 'common/utils/Navigation/enforcedConsentRoute';
import AddSupportingContactDetailsStep from 'pages/components/AddSupportingContactDetailsStep';
import Header from './Header';
import BasicInformation from './BasicInformation';
import ContactInformation from './ContactInformation';
import SupplementalInformation from './SupplementalInformation';
import ContactRequirementsForm from './ContactRequirementsForm';
import MilitaryInformation from './MilitaryInformation';
import {
  CONTACT_INFORMATION_FORM,
  COPY_CREATE_CLIENT,
  COPY_UPDATE_CLIENT,
  NOT_FOUND_HEADER_COPY,
  REGULAR_HEADER_COPY,
} from '../constants';
import {
  goToSearchStep,
  formatNewContactDataWithNoInsurance,
  defaultPhoneNumber,
  defaultEmailAddress,
  defaultAddress,
  getInitialValuesFromContact,
} from '../utils';

const initialPhoneNumber = defaultPhoneNumber(true);
const initialEmail = defaultEmailAddress(true);
const initialAddress = defaultAddress({ is_primary: true });

export class AddContactInformationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetching: false,
      isSubmitting: false,
      initialPaymentsIds: [],
      profileForms: [],
      showProfileForms: false,
    };

    this.onBackClick = this.onBackClick.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.finalizeStep = this.finalizeStep.bind(this);
  }

  componentDidMount() {
    const {
      contactId,
      completeGroup,
      groupId,
      insurances,
      selectedContact,
      checkForAttachedForms,
    } = this.props;

    const initialIds = insurances
      .filter((insurance) => insurance.id !== '')
      .map((insurance) => ({
        id: insurance.id,
        plan_type: insurance.relationships.plan.data.planType,
      }));

    this.setInitialPaymentsIds(initialIds);

    if (contactId && isEmpty(selectedContact)) {
      this.setState({ isFetching: true });
      this.props.fetchGroupContact(groupId, contactId).then(() => {
        this.setState({ isFetching: false });
      });
    }

    if (!completeGroup) {
      this.props.fetchGroup(this.props.groupId);
    }

    if (checkForAttachedForms) {
      this.fetchProfileForms();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.insurances !== prevProps.insurances) {
      const currentInitialIds = this.props.insurances
        .filter((insurance) => insurance.id !== '')
        .map((insurance) => ({
          id: insurance.id,
          plan_type: insurance.relationships.plan.data.planType,
        }));

      this.setInitialPaymentsIds(currentInitialIds);
    }
  }

  onBackClick() {
    const { context, activeStep } = this.props;

    if (isFunction(activeStep.onBackClick)) {
      return activeStep.onBackClick();
    }
    return goToSearchStep({ context });
  }

  onSubmit(values = {}) {
    const {
      createOrUpdateContact,
      fields,
      checkForAttachedForms,
      contactId,
      isSupersetEnabled,
      context,
    } = this.props;
    const { profileForms } = this.state;
    const newValues = { ...values };

    this.setState({ isSubmitting: true });

    return createOrUpdateContact(
      formatNewContactDataWithNoInsurance({ fields, values: newValues }),
      fields,
      newValues.insurance,
      this.state.initialPaymentsIds,
    ).then((payload) => {
      this.setState({ isSubmitting: false }, () => {
        if (isSupersetEnabled && checkForAttachedForms && this.state.profileForms.length) {
          this.setState({ showProfileForms: true });
        } else if (context.includes(DOMAIN_CONSTANTS.REFERRAL) && isSupersetEnabled && contactId != null) {
            browserHistory.push({
              pathname: '/referrals/create/add-resources',
              search: `?person=${contactId}`,
              state: { source: 'client' },
            });
        } else if (!checkForAttachedForms || !profileForms.length) {
          this.finalizeStep(payload, newValues);
        } else {
          this.setState({ showProfileForms: true });
        }
      });
    });
  }

  setInitialPaymentsIds(initialIds) {
    this.setState({
      initialPaymentsIds: initialIds,
    });
  }

  async fetchProfileForms() {
    const { data: { data } } = await this.props.fetchProfileForms(this.props.groupId);

    if (!data.length) return;

    this.setState({
      profileForms: data,
    });
  }

  finalizeStep(payload) {
    if (!isHttpError(payload)) {
      const contact = get(payload, 'data.data', {});
      const {
        activeStep,
        contactId,
        isClientRoute,
        context,
        scrn7ScreeningsCompletionEnabled,
      } = this.props;

      if (
        scrn7ScreeningsCompletionEnabled &&
        context.includes(DOMAIN_CONSTANTS.SCREENING)
      ) {
        const screeningPath = `/facesheet/${contact?.id}/screenings`;
        enforcedConsentRoute({
          contact,
          itemType: DOMAIN_CONSTANTS.SCREENING,
          to: screeningPath,
        });
      } else if (!isClientRoute && activeStep.onStepComplete) {
        activeStep.onStepComplete({
          contact,
          contactId,
          eventTracker: this.context.eventTracker,
          removeSelectedContact: this.props.removeSelectedContact,
        });
      } else {
        enforcedConsentRoute({
          contact,
          itemType: DOMAIN_CONSTANTS.CONTACT,
          to: `/facesheet/${contact.id}`,
        });
      }
    }
  }

  render() {
    const {
      addressTypeahead,
      completeGroup,
      contactId,
      currentGroup,
      enums,
      fields,
      handleSubmit,
      integrationContact,
      registerField,
      submitFailed,
      untouch,
      limitedGenderOptions,
      shouldShowSexualityFields,
      shouldShowPreferredLanguagesFields,
    } = this.props;

    const { isFetching, isSubmitting } = this.state;
    const existingContact = contactId || integrationContact;
    const header = existingContact ? COPY_UPDATE_CLIENT : COPY_CREATE_CLIENT;
    const subHeader = existingContact ? REGULAR_HEADER_COPY : `${NOT_FOUND_HEADER_COPY} ${REGULAR_HEADER_COPY}`;
    const submittingText = existingContact ? 'Updating Client...' : 'Creating Client...';

    return (
      <div className="add-contact-information-form">
        {this.state.showProfileForms ? (
          <div>
            <AddSupportingContactDetailsStep
              activeStep={this.props.activeStep}
              profileForms={this.state.profileForms}
              contact={this.props.contact}
              groupId={this.props.groupId}
              contactId={this.props.contactId}
              header={header}
              checkForForms={this.props.checkForAttachedForms}
              clientRoute={this.props.isClientRoute}
              context={this.props.context}
            />
          </div>
        ) : (
          <div>
            <Header header={header} subHeader={subHeader} />
            <form>
              <OverlaySpinner text={submittingText} show={isSubmitting} />
              <OverlaySpinner text="Fetching Client..." show={isFetching} />
              <BasicInformation
                registerField={registerField}
                fields={fields}
                validations={validations}
                enums={enums}
                limitedGenderOptions={limitedGenderOptions}
                shouldShowSexualityFields={shouldShowSexualityFields}
                isExistingContact={!!contactId}
              />
              <ContactInformation
                registerField={registerField}
                fields={fields}
                validations={validations}
                addressTypeahead={addressTypeahead}
              />
              <ContactRequirementsForm
                enums={enums}
                fields={fields}
                registerField={registerField}
                submitFailed={submitFailed}
                validations={validations}
                requireContactPreferences={completeGroup ? completeGroup.require_contact_preferences : false}
                shouldShowPreferredLanguagesFields={shouldShowPreferredLanguagesFields}
              />
              {completeGroup ? (
                <SupplementalInformation
                  contactId={contactId}
                  currentGroup={currentGroup}
                  fields={fields}
                  registerField={registerField}
                  untouch={untouch}
                />
              ) : (
                <Spinner />
              )}
              <footer className="mt-6 text-right add-contact-information-form__footer">
                <Button
                  id="go-back-btn"
                  onClick={this.onBackClick}
                  label="Go Back"
                  style={{ marginRight: '10px' }}
                  disabled={isSubmitting}
                />
                <Button
                  id="save-client-btn"
                  onClick={handleSubmit(this.onSubmit)}
                  label="Save Client"
                  primary
                  disabled={isSubmitting}
                />
              </footer>
            </form>
          </div>
        )}
      </div>
    );
  }
}

AddContactInformationForm.propTypes = {
  activeStep: PropTypes.object.isRequired,
  completeGroup: PropTypes.object,
  contactId: PropTypes.string,
  context: PropTypes.string,
  createOrUpdateContact: PropTypes.func.isRequired,
  currentGroup: PropTypes.object.isRequired,
  enums: PropTypes.object.isRequired,
  fetchGroup: PropTypes.func.isRequired,
  fetchGroupContact: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  groupId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  insurances: PropTypes.array,
  integrationContact: PropTypes.bool,
  submitFailed: PropTypes.bool.isRequired,
  registerField: PropTypes.func.isRequired,
  removeSelectedContact: PropTypes.func.isRequired,
  selectedContact: PropTypes.object,
  untouch: PropTypes.func.isRequired,
  fetchProfileForms: PropTypes.func.isRequired,
  contact: PropTypes.object.isRequired,
  checkForAttachedForms: PropTypes.bool.isRequired,
  isClientRoute: PropTypes.bool.isRequired,
  addressTypeahead: PropTypes.bool,
  isSupersetEnabled: PropTypes.bool,
  scrn7ScreeningsCompletionEnabled: PropTypes.bool,
  limitedGenderOptions: PropTypes.bool.isRequired,
  shouldShowSexualityFields: PropTypes.bool.isRequired,
  shouldShowPreferredLanguagesFields: PropTypes.bool.isRequired,
};

AddContactInformationForm.defaultProps = {
  addressTypeahead: false,
  completeGroup: null,
  contactId: null,
  context: '',
  insurances: [],
  integrationContact: false,
  selectedContact: {},
  isSupersetEnabled: false,
  scrn7ScreeningsCompletionEnabled: false,
};

AddContactInformationForm.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const {
    user,
    session,
    session: { groupId },
  } = state;

  const {
    selectedContact, insurances, contactId,
  } = ownProps;

  const contacts = get(state, 'contacts.contacts', []);
  const contact =
    find([...state.searchedContacts, ...contacts], { id: contactId }) || {};

  const initialValues = {
    gender: 'undisclosed',
    marital_status: 'undisclosed',
    race: 'undisclosed',
    ethnicity: 'undisclosed',
    phone_numbers: [initialPhoneNumber],
    email_addresses: [initialEmail],
    addresses: [initialAddress],
    ...getInitialValuesFromContact(ownProps.contactData),
    ...getInitialValuesFromContact(selectedContact || {}),
    ...getInitialPaymentsFormData(insurances || []),
  };

  return {
    completeGroup: getGroup(state, groupId),
    contact,
    contactId,
    currentGroup: findCurrentGroup(user, session),
    enums: getEnumsFromState(state),
    groupId,
    initialValues,
    isSupersetEnabled: uup459SupersetPhase2(state),
    scrn7ScreeningsCompletionEnabled: scrn7ScreeningsCompletion(state),
    limitedGenderOptions: hasLimitedGenderOptions(state),
    shouldShowPreferredLanguagesFields: cl1018PreferredLanguagesFields(state),
    shouldShowSexualityFields: cl787SexualityFields(state),
  };
}

export default validateReduxForm(
  {
    form: CONTACT_INFORMATION_FORM,
    fields: [
      ...BasicInformation.fields,
      ...ContactInformation.fields,
      ...ContactRequirementsForm.fields,
      ...MilitaryInformation.fields,
      ...PaymentsInformation.fields,
    ],
    destroyOnUnmount: true,
  },
  mapStateToProps,
  {
    fetchGroupContact,
    fetchGroup,
    removeSelectedContact,
    fetchProfileForms,
  },
)(AddContactInformationForm);
