import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'common/spinners';
import {
  useFindNetworksByName,
} from 'src/components/Backoffice/api/hooks/v1/networkHooks';
import { DEFAULT_PAGE_SIZE } from 'src/components/Backoffice/backofficeUtils';
import DynamicSearchTable from './DynamicSearchTable';

const NetworkSearchTable = ({ setSelectedNetwork }) => {
  const activeState = 'active';
  const [networkSearch, setNetworkSearch] = useState('');
  const [selectedNetworkId, setSelectedNetworkId] = useState('');
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [pageNumber, setPageNumber] = useState(1);
  const { data: networksResponse, isLoading } = useFindNetworksByName(
    networkSearch,
    activeState,
    {
      page: {
        size: pageSize,
        number: pageNumber,
      },
    },
  );
  const { data: networks, paging = { total_count: 0, total_pages: 0 } } = networksResponse?.data || [];

  useEffect(() => {
    setPageNumber(1);
  }, [networkSearch]);

  useEffect(() => {
    const filteredNetworks = networks?.filter((network) => network.id === selectedNetworkId) || [];
    if (filteredNetworks.length > 0) {
      const [networkObject] = filteredNetworks.map(({ id, name }) => ({ id, name }));
      setSelectedNetwork(networkObject);
    }
  }, [selectedNetworkId, networks]);

  const generateDataForTable = () => {
    const data = [];
    if (networks) {
      networks.forEach((network) => {
        data.push({
          id: network.id,
          data: [network.name, network.id],
        });
      });
    }
    return data;
  };

  if (isLoading) return <Spinner />;

  return (
    <>
      <DynamicSearchTable
        searchResults={generateDataForTable()}
        rowHeaders={['Network Name', 'Network ID']}
        selectedRowIds={selectedNetworkId}
        setSelectedRowIds={setSelectedNetworkId}
        setSearch={setNetworkSearch}
        emptyTableMessage={'No Results Found. Enter Network to view results.'}
        searchBarPlaceHolder={'Enter the Network Name...'}
        searchTitle={'Network'}
        dataTestId={'network-search'}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        paging={paging}
      />
    </>
  );
};

NetworkSearchTable.propTypes = {
  setSelectedNetwork: PropTypes.func.isRequired,
};

export default NetworkSearchTable;
