import { hasUserRole } from '.';

export function isCareNavigator(user, groupId) {
  return hasUserRole({
    groupId,
    name: 'Social Care Navigation',
    roleType: 'feature',
    user,
  });
}

export default isCareNavigator;
