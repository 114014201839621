import { template } from 'lodash';

export const INVOICE_STATUS = {
  accepted_by_payer: 'accepted_by_payer',
  paid: 'paid',
  rejected_by_cbo_admin: 'rejected_by_cbo_admin',
  rejected_by_network_lead: 'rejected_by_network_lead',
  rejected_by_payer: 'rejected_by_payer',
  submitted_contracted_service_note: 'submitted_contracted_service_note',
  submitted_to_network_lead: 'submitted_to_network_lead',
  submitted_to_payer: 'submitted_to_payer',
  transmitted_to_payer: 'transmitted_to_payer',
  under_dispute: 'under_dispute',
};
export const INVOICE_STATUSES = Object.keys(INVOICE_STATUS);

export const ACCEPTED = 'Accepted';
export const IN_DISPUTE = 'In Dispute';
export const IN_QUEUE_TO_PAYER = 'In Queue to Payer';
export const REJECTED = 'Rejected';
export const REJECTED_BY_PAYER = 'Rejected by Payer';
export const REJECTED_BY_NETWORK_LEAD = 'Rejected by Network Lead';
export const SUBMITTED_TO_NETWORK_LEAD = 'Submitted to Network Lead';
export const TRANSMITTED_TO_PAYER = 'Submitted to Payer';
export const PAID = 'Paid';
export const NEEDS_ATTENTION = 'Needs Attention';
export const AWAITING_TRANSMISSION = 'Awaiting Transmission';

export const PENDING_AND_APPROVED_STATUSES = [
  'submitted_contracted_service_note',
  'submitted_to_network_lead',
  'submitted_to_payer',
  'transmitted_to_payer',
  'accepted_by_payer',
  'paid',
];

export const READ_ONLY_BULK_ACTION_ICONS = [
  'Download',
  'Archive',
  'Unarchive',
];

export const ROLES = {
  networkLead: 'network_lead',
  payerProvider: 'payer_provider',
  cboAdmin: 'cbo_admin',
};

export const REJECTED_OPTIONS = [
  INVOICE_STATUS.rejected_by_network_lead,
  INVOICE_STATUS.rejected_by_payer,
  INVOICE_STATUS.rejected_by_cbo_admin,
];
export const DISPUTABLE_STATUSES = [
  INVOICE_STATUS.paid,
  INVOICE_STATUS.accepted_by_payer,
  INVOICE_STATUS.rejected_by_payer,
];

export const HISTORY_ICONS = {
  submitted_contracted_service_note: 'IconPlusCircle',
  submitted_to_network_lead: 'IconSubmit',
  submitted_to_payer: 'IconSubmit',
  transmitted_to_payer: 'IconSubmit',
  rejected_by_network_lead: 'IconTimesCircle',
  rejected_by_payer: 'IconTimesCircle',
  rejected_by_cbo_admin: 'IconTimesCircle',
  accepted_by_payer: 'V2CheckCircle',
  under_dispute: 'IconExclamationCircle',
  payment_received: 'IconDollarSign',
  edited: 'V2Edit',
};

export const HISTORY_MESSAGES = {
  submitted_contracted_service_note: template('Created by <%= employeeName %>'),
  submitted_to_network_lead: template('Submitted by <%= employeeName %> to Network Lead'),
  submitted_to_payer: template('Submitted by <%= employeeName %> to Payer'),
  rejected_by_network_lead: template('Rejected by <%= employeeName %>'),
  rejected_by_payer: template('Rejected by <%= employeeName %>'),
  rejected_by_cbo_admin: template('Rejected by <%= employeeName %>'),
  accepted_by_payer: template('Accepted by <%= employeeName %>'),
  transmitted_to_payer: template('Transmitted to Payer'),
  under_dispute: template('Disputed by <%= employeeName %>'),
  payment_received: template('Payment received by <%= employeeName %>'),
  edited: template('Edited by <%= employeeName %>'),
};

export const ACCEPTED_CBO_STATUSES = [INVOICE_STATUS.accepted_by_payer, INVOICE_STATUS.paid];
export const ARCHIVED_CBO_ADMIN_STATUSES = [
  INVOICE_STATUS.submitted_contracted_service_note,
  INVOICE_STATUS.accepted_by_payer,
  INVOICE_STATUS.paid,
  INVOICE_STATUS.rejected_by_cbo_admin,
  INVOICE_STATUS.rejected_by_network_lead,
  INVOICE_STATUS.rejected_by_payer,
];
export const ARCHIVED_PAYER_STATUSES = [INVOICE_STATUS.paid, INVOICE_STATUS.rejected_by_payer];
export const ARCHIVED_NL_STATUSES = [
  INVOICE_STATUS.submitted_to_network_lead,
  INVOICE_STATUS.accepted_by_payer,
  INVOICE_STATUS.paid,
  INVOICE_STATUS.rejected_by_network_lead,
  INVOICE_STATUS.rejected_by_payer,
];
export const CLOSED_NL_STATUSES = [
  INVOICE_STATUS.accepted_by_payer,
  INVOICE_STATUS.paid,
  INVOICE_STATUS.rejected_by_network_lead,
  INVOICE_STATUS.rejected_by_payer,
];
export const CLOSED_PAYER_STATUSES = [INVOICE_STATUS.paid, INVOICE_STATUS.rejected_by_payer];
export const DISPUTED_STATUSES = [
  INVOICE_STATUS.rejected_by_payer,
  INVOICE_STATUS.accepted_by_payer,
  INVOICE_STATUS.paid,
];
export const DRAFT_CBO_ADMIN_STATUSES = [
  INVOICE_STATUS.submitted_contracted_service_note,
  INVOICE_STATUS.rejected_by_cbo_admin,
];
export const OPEN_NL_STATUSES = [
  INVOICE_STATUS.submitted_to_network_lead,
  INVOICE_STATUS.submitted_to_payer,
  INVOICE_STATUS.transmitted_to_payer,
];
export const OPEN_PAYER_STATUSES = [
  INVOICE_STATUS.submitted_to_payer,
  INVOICE_STATUS.transmitted_to_payer,
  INVOICE_STATUS.accepted_by_payer,
];
export const REJECTED_CBO_ADMIN_STATUSES = [INVOICE_STATUS.rejected_by_network_lead, INVOICE_STATUS.rejected_by_payer];
export const SUBMITTED_CBO_ADMIN_STATUSES = [
  INVOICE_STATUS.submitted_to_network_lead,
  INVOICE_STATUS.submitted_to_payer,
  INVOICE_STATUS.transmitted_to_payer,
];
export const ALL_CBO_ADMIN_STATUSES = [
  INVOICE_STATUS.submitted_contracted_service_note,
  INVOICE_STATUS.submitted_to_network_lead,
  INVOICE_STATUS.submitted_to_payer,
  INVOICE_STATUS.transmitted_to_payer,
  INVOICE_STATUS.accepted_by_payer,
  INVOICE_STATUS.paid,
  INVOICE_STATUS.rejected_by_cbo_admin,
  INVOICE_STATUS.rejected_by_network_lead,
  INVOICE_STATUS.rejected_by_payer,
];
export const ALL_NL_STATUSES = [
  INVOICE_STATUS.submitted_to_network_lead,
  INVOICE_STATUS.submitted_to_payer,
  INVOICE_STATUS.transmitted_to_payer,
  INVOICE_STATUS.accepted_by_payer,
  INVOICE_STATUS.paid,
  INVOICE_STATUS.rejected_by_network_lead,
  INVOICE_STATUS.rejected_by_payer,
];
export const ALL_PAYER_STATUSES = [
  INVOICE_STATUS.submitted_to_payer,
  INVOICE_STATUS.transmitted_to_payer,
  INVOICE_STATUS.accepted_by_payer,
  INVOICE_STATUS.paid,
  INVOICE_STATUS.rejected_by_payer,
];

export const INVOICE_TYPES = {
  SERVICE: { label: 'Service', value: 'provided_service' },
  SCREENING: { label: 'Screening', value: 'screening' },
};
