import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import DialogV2 from 'common/modal/DialogV2';
import { ENROLLMENT_BULK_ACTION_LIMIT } from 'src/pages/enrollments/constants';
import BulkActionButton from './BulkActionButton';

const WorkqueueBulkAction = ({
  action,
  open,
  children,
  close,
  dialogRef,
  onSubmit,
  bulkActionIcon,
  dialogTitle,
  disableActionButton,
  selectedRows,
  erroredRows,
}) => {
  useEffect(() => {
    if (selectedRows.length === 0) {
      close();
    }
  }, [selectedRows]);
  return (
    <div className="flex items-end mb-2">
      <BulkActionButton
        action={action}
        open={open}
        icon={bulkActionIcon}
        disableOpenModal={disableActionButton}
      />
      <DialogV2
        ref={dialogRef}
        title={dialogTitle}
        confirmLabel={action}
        confirmationHandler={onSubmit}
        cancelHandler={close}
        width="3xl"
        showIconWithConfirmLabel
        confirmButtonIcon={bulkActionIcon}
        confirmButtonIconColor="white"
        confirmationBtnDisabled={erroredRows || selectedRows.length > ENROLLMENT_BULK_ACTION_LIMIT}
      >
        {children}
      </DialogV2>
    </div>
  );
};

WorkqueueBulkAction.propTypes = {
  dialogTitle: PropTypes.string.isRequired,
  bulkActionIcon: PropTypes.string.isRequired,
  open: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  close: PropTypes.func.isRequired,
  dialogRef: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disableActionButton: PropTypes.bool.isRequired,
  selectedRows: PropTypes.array.isRequired,
  erroredRows: PropTypes.array.isRequired,
};

export default WorkqueueBulkAction;
