import React from 'react';
import PropTypes from 'prop-types';

const PreferredLanguages = ({ recordLanguages }) => {
  const writtenLanguages = recordLanguages?.filter((item) => item.attributes.record_language_type === 'written')
    .map((item) => item.attributes.language_name)
    .sort();

  const spokenLanguages = recordLanguages?.filter((item) => item.attributes.record_language_type === 'spoken')
    .map((item) => item.attributes.language_name)
    .sort();

  return (
    <div className="flex items-center">
      <div className="flex flex-col space-y-1 max-w-xs break-words">
        <div className="block">
          Spoken: {spokenLanguages?.length > 0 ? spokenLanguages.join(', ') : '--'}
        </div>
        <div className="block">
          Written: {writtenLanguages?.length > 0 ? writtenLanguages.join(', ') : '--'}
        </div>
      </div>
    </div>
  );
};

PreferredLanguages.propTypes = {
  recordLanguages: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PreferredLanguages;
