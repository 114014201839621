import { useQuery } from 'react-query';
import { get } from 'lodash';
import { useFeatureFlag } from 'common/hooks';
import { screeningsApiClient } from 'src/api/config';
import {
  ELIGIBILITY_ASSESSMENT_STATUS,
  DECLINED_REASON,
  formatDateToScreeningFormat,
} from 'src/pages/facesheet/_id/eligibility/utils';
import { createElAssessSurveyJSTemplate } from 'src/pages/facesheet/_id/eligibility/surveyjsTemplates/createElAssessTemplate';
import {
  useFind,
} from 'src/api/APIHooks';

export const USE_MY_SCREENINGS_KEY = 'EligibilityAssessmentMyScreenings';
export const USE_SCREENINGS_KEY = 'EligibilityAssessmentScreening';
export const USE_TEMPLATE_V2 = 'EligibilityAssessmentTemplateV2';

// possible values for consent attribute are accepted or null
export const CONSENT_VALUE = {
  ACCEPTED: 'accepted',
  NULL: null,
};

export const useFlagESMFEnabled = () => {
  const flag = useFeatureFlag('scrn-424-enable-esmf-in-el-assessment');
  const isEnabled = get(flag, 'enabled') === true;
  return isEnabled ? flag : false;
};

export const useFindConditions = (personId) => {
  const isESMFEnabled = useFlagESMFEnabled();
  const filters = {};
  const options = {
    page: { number: 1, size: 100 },
    queryConfig: {
      placeholderData: undefined,
      enabled: Boolean(isESMFEnabled),
    },
  };
  const { data: response, isLoading } = useFind(
    `people/${personId}/initiative_conditions`,
    filters,
    options,
  ) || {};
  const results = response?.data?.data ?? [];
  const populations = results.filter((result) => result.is_enhanced);
  const conditions = results.filter((result) => !result.is_enhanced);

  const ESMF_MAPPING = get(isESMFEnabled, 'optionsMapping', {});

  const uConditions = [];
  const uPopulations = [];
  conditions.forEach((condition) => {
    const value = ESMF_MAPPING[condition.initiative_description];
    if (
      value && !uConditions.includes(value)
    ) {
      uConditions.push(value);
    }
  });

  populations.forEach((condition) => {
    const value = ESMF_MAPPING[condition.initiative_description];
    if (
      value && !uPopulations.includes(value)
    ) {
      uPopulations.push(value);
    }
  });

  if (!isESMFEnabled) {
    return {
      conditions: undefined,
      populations: undefined,
      isLoading: false,
    };
  }

  return {
    conditions: uConditions,
    populations: uPopulations,
    isLoading,
  };
};

export const useElAssessmentTemplateId = () => {
  const templateIdFlag = useFeatureFlag('scrn340-el-assessment-template');
  const templateId = (!templateIdFlag || templateIdFlag === 'null') ? false : templateIdFlag;
  return templateId;
};

export const getScreeningName = (screen) => (
  `Screening ${formatDateToScreeningFormat(screen.updated_at)}`
);

export const useMyScreenings = (person_id) => (
  useQuery(
    [USE_MY_SCREENINGS_KEY],
    async () => {
      const queryParams = {
        person_id,
        type: 'screening',
        status: 'complete',
      };
      const response = await screeningsApiClient.get('/screenings', { params: queryParams });
      const screensList = get(response, 'data.screens', []);
      const templateIds = [];
      const screens = screensList.map((i) => {
        const screen = { ...i };
        screen.name = getScreeningName(screen);
        if (!templateIds.includes(screen.templateId)) {
          templateIds.push(screen.templateId);
        }
        return screen;
      });

      return screens;
    },
    {
      staleTime: Infinity,
      refetchInterval: false,
      retry: false,
    },
  )
);

export const useScreening = (screeningId, { enabled }) => (
  useQuery(
    [USE_SCREENINGS_KEY, screeningId],
    async () => {
      const response = await screeningsApiClient.get(`/screenings/${screeningId}`);
      return response.data;
    },
    {
      staleTime: Infinity,
      refetchInterval: false,
      retry: false,
      enabled: Boolean(enabled),
    },
  )
);

// assessmentId is null if its a new el assessment; not null if it's an existing one
export const useTemplateV2 = ({ templateId }) => (
  useQuery(
    [USE_TEMPLATE_V2],
    async () => {
      const response = await screeningsApiClient.get(`/templates/${templateId}`);
      const {
        template: surveyjsTemplate,
        questionElementMap,
      } = createElAssessSurveyJSTemplate(response.data.template);
      return {
        // response,
        surveyjsTemplate,
        questionElementMap,
      };
    },
    {
      staleTime: Infinity,
      refetchInterval: false,
      retry: false,
      enabled: Boolean(templateId),
    },
  )
);

export const updateAssessment = async ({
  personId,
  status,
  answers,
  related_screen_id,
  outreach_status,
  templateId,
  consent,
  assessmentId,
  eligible_services,
}) => {
  const payload = {
    source: 'web_app',
    template_id: templateId,
    status,
    person_id: personId,
    related_screen_id,
    consent,
    ...(outreach_status ? { outreach_status } : {}),
    ...(eligible_services ? { eligible_services } : {}),
  };

  if (status === ELIGIBILITY_ASSESSMENT_STATUS.DECLINED) {
    payload.decline_reason = {
      key: 'other',
      note: DECLINED_REASON,
    };
  }
  if (
    status === ELIGIBILITY_ASSESSMENT_STATUS.COMPLETE ||
    status === ELIGIBILITY_ASSESSMENT_STATUS.DRAFT
  ) {
    if (answers) {
      payload.answers = answers;
    }
  }

  const response = await screeningsApiClient.put(`/screenings/${assessmentId}`, payload);
  return response;
};

// facilitator is the employee
// new assessments are created with consent defaulting to null
// assessmentId is not available/will be created by this call
export const createNewAssessment = async ({
  personId,
  status,
  answers,
  related_screen_id,
  outreach_status, // success or declined or null
  templateId,
}) => {
  const payload = {
    source: 'web_app',
    template_id: templateId,
    status,
    person_id: personId,
    related_screen_id,
    answers,
    ...(outreach_status ? { outreach_status } : {}),
  };

  const response = await screeningsApiClient.post('/screenings', payload);
  return response;
};
