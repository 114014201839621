import React from 'react';
import PropTypes from 'prop-types';

const METHOD_LABELS_MAPPING = {
  phone: 'Phone Call',
  sms: 'Text',
  email: 'Email',
  mail: 'Letter By Mail',
};

const VOICE_MAIL_LABEL_MAPPING = {
  true: 'Okay to leave a voicemail',
  false: 'Not okay to leave a voicemail',
};

const MethodOfContact = ({ value }) => {
  const { preferred_contact_methods, voice_mail_ok } = value;
  const label_contact_methods = preferred_contact_methods.map((method) => METHOD_LABELS_MAPPING[method]).join(', ');
  const voice_mail_label = VOICE_MAIL_LABEL_MAPPING[voice_mail_ok];

  return (
    <div className="flex items-center">
      <div className="flex flex-col gap-2">
        <span>{label_contact_methods}</span>
        {voice_mail_label && <span>{voice_mail_label}</span>}
      </div>
    </div>
  );
};

MethodOfContact.propTypes = {
  value: PropTypes.shape({
    preferred_contact_methods: PropTypes.array,
    voice_mail_ok: PropTypes.bool,
  }).isRequired,
};

export default MethodOfContact;
