import React from 'react';
import PropTypes from 'prop-types';
import PlanDetail from './PlanDetail';

const PlanOverview = ({ plan }) => (
  <>
    <PlanDetail plan={plan} />
  </>
);

PlanOverview.propTypes = {
  plan: PropTypes.object.isRequired,
};

export default PlanOverview;
