import React from 'react';
import PropTypes from 'prop-types';

const ContactNote = ({ value }) => (
  <div className="whitespace-pre-wrap"><span>{value}</span></div>
);

ContactNote.propTypes = {
  value: PropTypes.string.isRequired,
};

export default ContactNote;
