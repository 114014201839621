import useFindFeeSchedulesByPlanForPayers from './useFindFeeSchedulesByPlanForPayers';
import useFindFeeSchedulesByNetworkLeadRelationshipForNonPayers
  from './useFindFeeSchedulesByNetworkLeadRelationshipForNonPayers';

const useFindCurrentEmployeesFeeScheduleIds = () => {
  const payerProviderFeeScheduleIds = useFindFeeSchedulesByPlanForPayers();
  const providerFeeScheduleIds = useFindFeeSchedulesByNetworkLeadRelationshipForNonPayers();
  return (payerProviderFeeScheduleIds ?? providerFeeScheduleIds) ?? [];
};

export default useFindCurrentEmployeesFeeScheduleIds;
