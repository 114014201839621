/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import {
  BaseCard,
  BaseCardBody,
  Button,
} from '@unite-us/app-components';
import { Icon } from '@unite-us/ui';
import { Spinner } from 'common/spinners';
import { browserHistory } from 'common/utils/browserHistory';
import { BackButton } from 'common/buttons';
import {
  useInvalidateQueries,
} from 'src/api/APIHooks';
import {
  formatDateToScreeningFormat,
  ELIGIBILITY_ASSESSMENT_STATUS,
  getEligibilityAssessmentStatusLabel,
  getEligibleServicesUtil,
  getServiceNeed,
} from 'src/pages/facesheet/_id/eligibility/utils';
import StatusBadge from 'src/pages/facesheet/_id/eligibility/components/StatusBadge';
import {
  useElAssessmentTemplateId,
  useTemplateV2,
  useScreening,
  USE_SCREENINGS_KEY,
  getScreeningName,
} from 'src/pages/facesheet/_id/eligibility/hooks/eligibilityApi';
import {
  useElAssessment,
  EL_ASSESSMENT_QUERY_KEYS,
} from 'src/pages/facesheet/_id/eligibility/hooks/useEligibilityAssessments';
import { ELEMENT_DICTIONARY } from 'src/pages/facesheet/_id/eligibility/surveyjsTemplates/createElAssessTemplate';

const ViewEligibilityAssessment = (props) => {
  const invalidateQueries = useInvalidateQueries();
  const templateId = useElAssessmentTemplateId();
  const { data: templateData } = useTemplateV2({ templateId });
  const [eligibleServices, setEligibleServices] = useState([]);
  const assessment_id = get(props, 'params.assessment_id');
  const contactId = get(props, 'params.id');

  const {
    data: assessment,
    isFetching: isFetchingScreen,
  } = useElAssessment(
    assessment_id,
    {
      enabled: Boolean(assessment_id),
      template: templateData,
    },
  );
  const related_screen_id = get(assessment, 'data.screen.related_screen_id');
  const { data: relatedScreening, isFetching: isFetchingRelatedScreen } = useScreening(
    related_screen_id,
    { enabled: Boolean(related_screen_id) },
  );

  useEffect(() => {
    if (!relatedScreening){ return; }
    const identifiedNeedsObject = get(relatedScreening, 'identified_needs', {});
    const identifiedNeeds = identifiedNeedsObject ? Object.keys(identifiedNeedsObject) : [];

    const eligible_services = getEligibleServicesUtil(assessment, identifiedNeeds);
    setEligibleServices(eligible_services);
  }, [relatedScreening, assessment]);

  useEffect(() => {
    invalidateQueries(USE_SCREENINGS_KEY);
    invalidateQueries(EL_ASSESSMENT_QUERY_KEYS.ASSESSMENT);
  }, []);

  const onBackClick = () => {
    browserHistory.push(`/facesheet/${contactId}/eligibility/all`);
  };

  if (!assessment || isFetchingScreen || isFetchingRelatedScreen) {
    return <Spinner />;
  }

  const addSocialCoverage = () => (
    browserHistory.push(`/facesheet/${contactId}/profile`)
  );

  const searchForResources = () => (
    browserHistory.push(`/referrals/create/add-resources?person=${contactId}`)
  );

  const edit = () => {
    const outreach_status = get(assessment, 'data.screen.outreach_status');
    const consent = get(assessment, 'data.screen.consent');

    if (!outreach_status) {
      return browserHistory.push(`/facesheet/${contactId}/eligibility/edit/${assessment_id}/outreach`);
    }
    if (outreach_status === 'success' && !consent) {
      return browserHistory.push(`/facesheet/${contactId}/eligibility/edit/${assessment_id}/consent`);
    }
    if (outreach_status === 'success' && consent === 'accepted') {
      return browserHistory.push(`/facesheet/${contactId}/eligibility/edit/${assessment_id}/assessment`);
    }
    return false;
  };

  const renderQuestions = get(assessment, 'renderQuestions', []);
  const status = get(assessment, 'data.screen.status', '');
  const updatedAt = get(assessment, 'data.screen.updated_at');
  const savedDate = updatedAt ? `Saved ${formatDateToScreeningFormat(updatedAt)}` : null;
  const hasNoAnswers = renderQuestions.filter((q) => get(q, 'answer.string', null)).length === 0;

  const draftMessage = 'Click Edit and complete the assessment to determine the client\'s' +
    ' eligibility for enhanced health-related social need services.';
  const completeAndNotEligibleMessage = 'Based on the assessment the client is not eligible for enhanced ' +
    'HRSN services. You can refer the client to non-enhanced services for any identified needs.';
  const isDraft = status === ELIGIBILITY_ASSESSMENT_STATUS.DRAFT;
  const isComplete = status === ELIGIBILITY_ASSESSMENT_STATUS.COMPLETE;
  const isDeclined = status === ELIGIBILITY_ASSESSMENT_STATUS.DECLINED;
  const outreachStatus = get(assessment, 'data.screen.outreach_status');
  const isOutreachStatusDeclined = outreachStatus === 'declined';

  const eligibleServicesByNeed = [];

  let rightSideTitle = '';
  let RightSideComponent = null;
  if (isDraft) {
    rightSideTitle = 'Assessment Saved as Draft';
    RightSideComponent = () => (
      <div>
        <p className="mb-4">
          { draftMessage }
        </p>
        <Button
          id="search-for-resources-btn"
          onClick={searchForResources}
          label="Search for Resources"
          className="w-full"
          primary
        />
      </div>
    );
  }
  if (isDeclined) {
    rightSideTitle = 'Client Declined';
    RightSideComponent = () => (
      <div>
        <p className="mb-4">
          The client has declined to conduct an eligibility assessment. You can
          refer the client to existing services for any identified needs.
        </p>
        <Button
          id="search-for-resources-btn"
          onClick={searchForResources}
          label="Search for Resources"
          className="w-full"
          primary
        />
      </div>
    );
  }
  if (isOutreachStatusDeclined) {
    rightSideTitle = 'Outreach Failed';
    RightSideComponent = () => (
      <div>
        <p className="mb-4">
          Outreach for an eligibility assessment failed after 3 attempts.
          Start a new Eligibility Assessment and attempt outreach at 
          another time to determine the client's eligibility for 
          enhanced enhanced health-related social need (HRSN) programs.
        </p>
      </div>
    );
  }
  if (isComplete && eligibleServices.length) {
    rightSideTitle = 'Client Eligible';
    RightSideComponent = () => (
      <div>
        <p className="mb-4">
          <p>
            The client is eligible for the following enhanced health-related social 
            need (HRSN) programs. Add social care coverage to connect them with 
            these resources.
          </p>
          <ul className="list-disc pl-4 mt-3">
            {
              eligibleServices.map((s) => (
                <li>{ s } <span className="italic">({ getServiceNeed(s) })</span></li>
              ))
            }
          </ul>
        </p>
        <Button
          id="add-social-coverage-btn"
          onClick={addSocialCoverage}
          label="Add Social Care Coverage"
          className="w-full"
          primary
        />
      </div>
    );
  }
  if (isComplete && !eligibleServices.length) {
    rightSideTitle = 'Client Not Eligible';
    RightSideComponent = () => (
      <div>
        <p className="mb-4">
          { completeAndNotEligibleMessage }
        </p>
        <Button
          id="search-for-resources-btn"
          onClick={searchForResources}
          label="Search for Resources"
          className="w-full"
          primary
        />
      </div>
    );
  }

  if (!assessment) { return null; }

  // only display Edit button for assessments created with template V3
  const template_version = get(assessment, 'data.screen.template_version');
  const showEditButton = Boolean(!isOutreachStatusDeclined && isDraft) && template_version === '3';
  const statusLabel = getEligibilityAssessmentStatusLabel(status, outreachStatus);
  return (
    <div>
      <BackButton
        label="Back to Eligibility Assessments"
        navigateTo={onBackClick}
      />
      <div className="mt-6">
        <div className="w-2/3 inline-block">
          <BaseCard className="bg-white">
            <div
              className="border border-solid border-medium-border-blue border-l-0 border-r-0 border-t-0"
            >
              <div className="px-10 py-8">
                <h2 className="text-2xl font-bold mb-2">Eligibility Assessment</h2>
                {/* <p className="text-md">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                <div className="mt-5">
                  <div className="mr-3 inline-block">
                    <StatusBadge status={statusLabel} />
                  </div>
                  { savedDate ? <p className="inline-block">{ savedDate }</p> : null }
                  {
                    showEditButton ? (
                      <div className="inline-block float-right align-top">
                        <button type="button" onClick={edit}>
                          <Icon icon="IconPencil" className="mt-quarter" />
                          <span className="inline-block pt-1 pl-1 align-top text-md font-bold">
                            Edit
                          </span>
                        </button>
                      </div>
                    ) : null
                  }
                </div>
              </div>
            </div>
            <BaseCardBody>
              <div className="ml-10 my-8">
                {
                  hasNoAnswers ? (
                    <p className="text-dark-blue font-light text-base">
                      No answers have been provided for this eligibility assessment.
                    </p>
                  ) : null
                }
                {
                  renderQuestions
                    .filter((q) => {
                      const answers = q.answers;
                      const answer = q.answer;
                      if ((!answers || !answers.length) && !answer) {
                        return false;
                      }
                      return true;
                    })
                    .map((q) => {
                      let answerString = '';
                      if (q.answer) {
                        answerString = q.answer.string || q.answer.value;
                      }
                      if (q.answers) {
                        answerString = q.answers.map((o) => (
                          <p className="text-brand-blue font-light text-base">
                            { o.string }
                          </p>
                        ));
                      }
                      if (q.primary_text === ELEMENT_DICTIONARY.qSelectScreening) {
                        answerString = getScreeningName(get(assessment, 'data.screen'));
                      }
                      return (
                        <div className="mb-16">
                          <p className="text-dark-blue font-black text-base mb-2">
                            { q.primary_text }
                          </p>
                          <p className="text-brand-blue font-light text-base">
                            { answerString }
                          </p>
                        </div>
                      );
                    })
                }
              </div>
            </BaseCardBody>
          </BaseCard>
        </div>
        <div className="w-1/3 inline-block align-top">
          <div className="ml-5 bg-light-fill-grey">
            <BaseCard className="">
              <div className="px-6 py-6">
                <h2 className="text-xl font-bold mb-2">
                  { rightSideTitle }
                </h2>
                <RightSideComponent />
              </div>
            </BaseCard>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewEligibilityAssessment;
