import uploadAndAttachDocumentsToResources from 'common/form/DocumentUploader/actions/uploadAndAttachDocumentsToResources';

function uploadAndAttachContactDocuments({
  attachedDocuments,
  contactId,
  uploadedDocuments,
  additionalData,
}) {
  return () => uploadAndAttachDocumentsToResources({
    contactId,
    attachedDocuments,
    uploadedDocuments,
    additionalData,
  });
}

export default uploadAndAttachContactDocuments;
