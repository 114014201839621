import React from 'react';
import PropTypes from 'prop-types';
import { LogoCard } from 'src/common/TailwindComponents';
import { InvoiceWorkqueue } from './InvoiceWorkqueue';
import { ARCHIVED_CBO_ADMIN_STATUSES, ARCHIVED_NL_STATUSES, ARCHIVED_PAYER_STATUSES } from '../constants';
import { NoFilteredInvoicesFound } from '../components';

const NoInvoicesExist = () => (
  <>
    <h4 className="font-heavy-font text-base text-dark-grey">
      You don&apos;t have any invoices yet.
    </h4>
    <p className="max-w-xs text-dark-grey">
      Invoices are added to this list whenever a contracted service has been submitted for a client.
    </p>
  </>
);

const ArchivedInvoicesDWQ = ({ children }) => {
  const bulkActions = ['IconDownloadV2', 'IconUndo'];

  return (
    <InvoiceWorkqueue
      bulkActions={bulkActions}
      cboStatuses={ARCHIVED_CBO_ADMIN_STATUSES}
      networkLeadStatuses={ARCHIVED_NL_STATUSES}
      pageName="Archived"
      path="archive/"
      payerStatuses={ARCHIVED_PAYER_STATUSES}
      renderNoInvoicesFound={({ isFiltered, resetFilters }) => (
        <LogoCard>
          {isFiltered ? (
            <NoFilteredInvoicesFound resetFilters={resetFilters} />
          ) : (
            <NoInvoicesExist />
          )}
        </LogoCard>
      )}
      showArchived
    >
      {children}
    </InvoiceWorkqueue>
  );
};

ArchivedInvoicesDWQ.propTypes = {
  children: PropTypes.node,
};

ArchivedInvoicesDWQ.defaultProps = {
  children: undefined,
};

export default ArchivedInvoicesDWQ;
