import React, { useContext, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router';
import { TrackerContext } from '@unite-us/client-utils';
import { Button, Dialog, Icon } from '@unite-us/ui';
import { browserHistory } from 'common/utils/browserHistory';
import mapProviderIdToProps from 'src/components/Organization/utils/mapProviderIdToProps';
import { ORG_TABS, ORG_STATES } from 'src/components/Organization/constants';
import { useFindProvider, useUpdateProvider } from 'src/components/Organization/api/hooks/v1';
import { ORGANIZATION_SELF_ONBOARDING } from 'common/utils/EventTracker/utils/eventConstants';
import callOrLog from 'src/common/utils/callOrLog';
import { notifySomethingWentWrong } from '../api/hooks/v1/apiHookOptions';
import Notifier from '../../../common/helpers/Notifier';
import { saveKeyToSessionStorage, loadKeyFromSessionStorage } from '../../../common/utils/utils';
import { containsOrgStateParam, mapOrgStateToParam, updateOrgStateParam, getOrgPathname } from '../utils/organizationRouting';

const SESSION_KEY_DRAFT_STATE_NOTIFICATION = 'draftStateNotification';
const SESSION_KEY_SUBMIT_STATE_NOTIFICATION = 'submitStateNotification';

const NavItem = ({
  children,
  href,
  isActive,
  testId,
  ariaLabel,
}) => (
  <li
    className={classNames('flex items-center py-6', {
      'border-b-2 border-solid border-action-blue': isActive,
    })}
  >
    <Link
      data-test-element={testId}
      to={href}
      className={classNames('text-text-blue text-lg', {
        'font-regular-font text-opacity-75': !isActive,
        'pointer-events-none': isActive,
      })}
      onlyActiveOnIndex
      aria-label={ariaLabel}
    >
      {children}
    </Link>
  </li>
);

NavItem.propTypes = {
  isActive: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  href: PropTypes.string.isRequired,
  testId: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string,
};

NavItem.defaultProps = {
  ariaLabel: '',
};

const OrganizationHeader = ({
  currentTab,
  currentProviderId,
  showOrgStatus,
  location,
}) => {
  const {
    data: {
      state, name,
    },
  } = useFindProvider({ providerId: currentProviderId });
  const { updateRecord: updateProvider } = useUpdateProvider(
    {
      onError: () => {
        notifySomethingWentWrong();
      },
    },
    'Organization submitted. Our team will review your submission and send an email with next steps.',
    3600000,
  );

  const dialogRef = useRef();

  useEffect(() => {
    const sessionDraftState = loadKeyFromSessionStorage(SESSION_KEY_DRAFT_STATE_NOTIFICATION);
    const sessionSubmitState = loadKeyFromSessionStorage(SESSION_KEY_SUBMIT_STATE_NOTIFICATION);
    if (!state) return;

    const stateParam = mapOrgStateToParam(state);
    if (stateParam && !containsOrgStateParam(location?.search, stateParam)) {
      browserHistory.replace({
        pathname: getOrgPathname(location?.pathname),
        search: updateOrgStateParam(location?.search, stateParam),
      });
      return;
    }

    if (state === ORG_STATES.draft && !sessionDraftState) {
      Notifier.dispatch('success', 'Welcome to Unite Us! Get started by adding your ' +
        'organization’s information, then add locations, programs and users. ' +
        'Submit your organization for review when you’re done.', 60000);
      saveKeyToSessionStorage(SESSION_KEY_DRAFT_STATE_NOTIFICATION, true);
    }
    if (state !== ORG_STATES.draft && state !== ORG_STATES.active &&
      !sessionSubmitState && !sessionDraftState) {
      Notifier.dispatch(
        'information',
        'Your organization is currently in review and cannot be edited.' +
        ' You will be able to edit your organization once it is live on the Unite Us Platform.',
        60000,
      );
      saveKeyToSessionStorage(SESSION_KEY_SUBMIT_STATE_NOTIFICATION, true);
    }
  }, [location?.pathname, location?.search, state]);

  const eventTracker = useContext(TrackerContext);

  const closeDialog = () => dialogRef.current.closeDialog();

  const handleClick = () => {
    closeDialog();
    updateProvider(currentProviderId, {
      state: 'draft_submitted',
    });
    callOrLog(() => eventTracker(ORGANIZATION_SELF_ONBOARDING.submit));
  };

  const handleCancel = () => {
    closeDialog();
    callOrLog(() => eventTracker(ORGANIZATION_SELF_ONBOARDING.cancel));
  };

  const showDraftStepper = state === ORG_STATES.draft;

  const spanWrapperClass = 'flex items-center';
  const spanCircleClass = 'w-3 h-3 rounded-full mx-2';
  const headerClass = showDraftStepper ? '' : 'bg-white sticky';
  const navClass = showDraftStepper ? 'justify-end' : 'justify-between';

  const ORGANIZATION_STATUS = {
    draft: () => (
      <span className={spanWrapperClass}><span className={`bg-action-blue ${spanCircleClass}`} />
        Draft
      </span>
    ),
    draft_submitted: () => (
      <span className={spanWrapperClass}><span className={`bg-yellow  ${spanCircleClass}`} />
        Submitted
      </span>
    ),
    draft_in_review: () => (
      <span className={spanWrapperClass}><span className={`bg-purple ${spanCircleClass}`} />
        In Review
      </span>
    ),
    draft_needs_action: () => (
      <span className={spanWrapperClass}><span className={`bg-orange ${spanCircleClass}`} />
        Needs Action
      </span>
    ),
    draft_pre_launch_configured: () => (
      <span className={spanWrapperClass}><span className={`bg-light-green ${spanCircleClass}`} />
        Pre-Launch Configured
      </span>
    ),
    active: () => (
      <span className={spanWrapperClass}><span className={`bg-dark-green ${spanCircleClass}`} />
        Live
      </span>
    ),
  };

  return (
    <header data-test-element="org_header" className={`${headerClass} -mx-container-padding top-0 z-drawer`}>
      <nav className={`flex ${navClass} h-full items-center px-4`}>
        { !showDraftStepper && (
          <div className="flex h-full items-center space-x-2">
            <h1
              data-test-element="org_header_name"
              className="font-extrabold font-heavy-font text-xl"
            >
              {name}
            </h1>
            <ul className="flex h-full space-x-10">
              <NavItem
                isActive={currentTab === ORG_TABS.organization}
                href="/organization/settings"
                testId="org_header_settings"
                ariaLabel="Organization Tab"
              >
                Organization
              </NavItem>
              <NavItem
                isActive={currentTab === ORG_TABS.users}
                href={'/organization/settings/users'}
                testId="org_header_users"
                ariaLabel="Users Tab"
              >
                Users
              </NavItem>
            </ul>
          </div>
          )}
        { showOrgStatus && (
          <div className="flex items-center space-x-4">
            <p data-test-element="org_status" className="inline-flex text-dark-grey">
              Organization Status: {ORGANIZATION_STATUS[state] && ORGANIZATION_STATUS[state]()}
            </p>
            { !showDraftStepper && (
              <Dialog
                id="upload-payments-documents"
                modal
                onRequestClose={() => closeDialog()}
                ref={dialogRef}
                title="Submit Organization"
                removeCloseIcon
                showBorderRadius
              >
                <div>
                  <div className="mb-6">
                    <p className="my-2 mx-2">Are you sure you want to submit your organization for review?</p>
                    <p className="my-3 mx-2">
                      Once you submit your organization, we will review it before it goes live on the Unite Us Platform.
                      You won’t be able to edit your organization while we review it,
                      but you can edit your organization after it goes live on the Unite Us Platform.
                    </p>
                  </div>
                  <div className="flex flex-row-reverse">
                    <Button
                      id="submit_for_review-btn"
                      primary
                      className="ml-2"
                      label="Submit"
                      onClick={handleClick}
                    />
                    <Button
                      id="cancel_review-btn1"
                      label="Cancel"
                      onClick={() => handleCancel()}
                    />
                  </div>
                </div>
              </Dialog>
            )}
            {(
              state !== ORG_STATES.draft && state !== ORG_STATES.active && (
                <Button
                  id="submitted_for_review-btn"
                  primary
                  label="Organization Submitted"
                  iconLeft={<Icon icon="IconCheckCircle" />}
                  disabled
                />
              )
            )}
          </div>
        )}
      </nav>
    </header>
  );
};

OrganizationHeader.defaultProps = {
  showOrgStatus: false,
};

OrganizationHeader.propTypes = {
  currentProviderId: PropTypes.string.isRequired,
  currentTab: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  showOrgStatus: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    currentProviderId: mapProviderIdToProps(state).currentProviderId,
  };
}

export default connect(mapStateToProps)(OrganizationHeader);
