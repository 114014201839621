import { validations } from '@unite-us/app-components';
import { compact, uniq } from 'lodash';

const validateUnitAmount = (value, enforceRequiredFields = true) => {
  const validation = uniq(compact([
    validations.isInteger(value),
    validations.isGreaterThan(value, null, 0),
    validations.isLessThan(value, 'Should be less than 1,000,000', 1_000_000),
    enforceRequiredFields ? validations.isRequired(value) : null,
    Number.isNaN(value) ? 'Required' : null,
  ]));
  return validation.length > 0 ? validation : null;
};

export default validateUnitAmount;
