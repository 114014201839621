import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'common/utils/browserHistory';
import { coreApi } from 'api/config';
import FacesheetSubHeader from 'src/components/Facesheet/components/FacesheetSubHeader';
import { destroyForm } from 'actions/Form';
import { fetchGroupContact } from 'actions/Contact/Group';
import { fetchServiceCases } from 'actions/Case/Contact/Group';
import { getActiveFacesheetTab } from 'src/components/Facesheet/utils';
import { selectContact } from 'actions/Contact';
import { CREATE_REFERRAL_FORM } from 'src/components/Referrals/constants';
import { Spinner } from 'common/spinners';
import { wget } from 'lodash';
import findCurrentGroup from 'common/utils/findCurrentGroup';
import { getEmployeeNetworks } from 'src/components/Employee/employeeGetters';
import { fetchClientRelationship, updateClientRelationship } from 'actions/CareCoordinator/Contact/Group';
import fetchProvidersUserCore from 'src/actions/UserProvider/fetchProvidersUser';
import DuplicatesModal from 'src/components/Facesheet/components/DuplicatesModal';
import {
  uup459SupersetPhase2,
  hasClientLinkOnClientFacesheet,
  isClientMergingEnabled as isClientMergingEnabledFF,
  cerb1367ResourceListDetailEnhancements as cerb1367ResourceListDetailEnhancementsSelector,
  hbh1025ShowEligibilityAssessmentTab as hbh1025ShowEligibilityAssessmentTabSelector,
  cl1224UnconsentedClientFacesheetAccess as cl1224UnconsentedClientFacesheetAccessFF,
} from 'common/utils/FeatureFlags/flags';
import { isOrgAdmin } from 'components/User/utils';

// Style Imports
import './Facesheet.scss';

const DEFAULT_VISIBILITY_CONFIG = {
  overview: {
    showTab: true,
    showCareCoordinator: true,
    showCareTeam: true,
    showFamilyMembers: true,
    showOpenCases: true,
    showNotes: true,
    showMessages: true,
  },
  profile: { showTab: true },
  cases: { showTab: true },
  screenings: { showTab: true },
  eligibility: { showTab: true },
  forms: { showTab: true },
  uploads: { showTab: true },
  referrals: { showTab: true },
  resource: { showTab: true },
};

const fetchVisibilityConfig = async (contactResponse, flagEnabled) => {
  const contact = contactResponse?.data?.data;
  const contactConsented = contact?.consent?.state === 'accepted';

  if (flagEnabled && !contactConsented) {
    return {
      data: {
        data: {
          overview: {
            showTab: true,
            showCareCoordinator: true,
            showCareTeam: false,
            showFamilyMembers: false,
            showOpenCases: true,
            showNotes: true,
            showMessages: true,
          },
          profile: { showTab: true },
          cases: { showTab: true },
          screenings: { showTab: true },
          eligibility: { showTab: true },
          forms: { showTab: true },
          uploads: { showTab: true },
          referrals: { showTab: false },
          resource: { showTab: false },
        },
      },
    };
  }

  return { data: { data: DEFAULT_VISIBILITY_CONFIG } };
};

export const Facesheet = (props) => {
  const {
    contactId,
    groupId,
    activeTab,
    children,
    enums,
    locationSrc,
    isSupersetEnabled,
    hasClientLink,
    userIsAdmin,
    isClientMergingEnabled,
    cerb1367ResourceListDetailEnhancements,
    hbh1025ShowEligibilityAssessmentTab,
    cl1224UnconsentedClientFacesheetAccess,
  } = props;

  const [contact, setContact] = useState(null);
  const [visibilityConfig, setVisibilityConfig] = useState(DEFAULT_VISIBILITY_CONFIG);
  const [isFetching, setIsFetching] = useState(true);
  const [clientRelationship, setClientRelationship] = useState({});
  const [hasDuplicates, setHasDuplicates] = useState(false);

  Facesheet.goToCreateReferral = (location) => {
    const queryString = location ? `?src=${location}` : '';
    const path = isSupersetEnabled ?
      `/referrals/create/add-resources?person=${contactId}` :
      `/referrals/new/add-service-types${queryString}`;
    browserHistory.push(path);
  };

  const handleOnCheck = async (e) => {
    const relationship = await updateClientRelationship(clientRelationship, e.target.checked);
    setClientRelationship(relationship);
  };

  const onCreateReferral = () => {
    props.selectContact(contactId);
    props.destroyForm(CREATE_REFERRAL_FORM);
    window.localStorage.removeItem('referralsProgress');
    Facesheet.goToCreateReferral(locationSrc);
  };

  const getClientRelationship = async () => {
    const relationship = await fetchClientRelationship(contactId, groupId);
    setClientRelationship(relationship);
  };

  const handleContactResponse = (response) => {
    if (response?.response?.data?.status === 301) {
      browserHistory.push(`/facesheet/${response.response.data.merged_person_id}`);
    } else {
      setContact(response?.data?.data);
    }
  };

  const handleVisibilityConfigResponse = (response) => {
    setVisibilityConfig(response?.data?.data);
  };

  const fetchFacesheetData = async (providerId, personId) => {
    setIsFetching(true);

    try {
      const contactResponse = await props.fetchGroupContact(providerId, personId);
      handleContactResponse(contactResponse);

      // TODO: This should be refactored to use a visibility config endpoint in core
      const visibilityResponse = await fetchVisibilityConfig(contactResponse, cl1224UnconsentedClientFacesheetAccess);
      handleVisibilityConfigResponse(visibilityResponse);
    } finally {
      setIsFetching(false);
    }
  };

  const checkForDuplicates = async () => {
    const response = await coreApi.query(
      `people/${contact.id}/duplicates`,
      {},
      { page: { number: 1, size: 1 } },
    );

    setHasDuplicates(response.data.meta.page.total_count > 1);
  };

  useEffect(() => {
    props.fetchProvidersUserCore({
      providers: groupId,
    });
  }, []);

  useEffect(() => {
    if (contact && isClientMergingEnabled && userIsAdmin) {
      checkForDuplicates();
    }
  }, [contact, isClientMergingEnabled, userIsAdmin]);

  useEffect(() => {
    fetchFacesheetData(groupId, contactId);
    props.fetchServiceCases(groupId, contactId);
    getClientRelationship();
  }, [contactId]);

  return (
    <div className="facesheet-index" id="facesheet-container">
      <div className="row facesheet-index__subheader-container">
        <div className="col-sm-10 offset-sm-1">
          {contact && (
            <FacesheetSubHeader
              contact={contact}
              contactId={contactId}
              activeTab={activeTab}
              onButtonClick={onCreateReferral}
              enums={enums}
              isMilitaryFocused
              isFetching={isFetching}
              handleOnCheck={handleOnCheck}
              clientRelationship={clientRelationship}
              isSupersetEnabled={isSupersetEnabled}
              resourceListDetailEnhancements={cerb1367ResourceListDetailEnhancements}
              hasClientLink={hasClientLink}
              showDuplicatesLink={hasDuplicates}
              hbh1025ShowEligibilityAssessmentTab={hbh1025ShowEligibilityAssessmentTab}
              visibilityConfig={visibilityConfig}
            />
          )}
        </div>
      </div>
      <div className="facesheet-index__container row" id="facesheet-container">
        <div className="col-sm-10 offset-sm-1">
          {
            isFetching ? (
              <div className="facesheet-index__content-spinner" aria-live="polite">
                <Spinner />
              </div>
            ) :
              React.isValidElement(children) &&
              React.Children.map(
                children,
                (child) => React.cloneElement(child, {
                  visibilityConfig,
                  getClientRelationship,
                  setHeaderContact: setContact,
                }),
              )
          }
        </div>
        {hasDuplicates && <DuplicatesModal contactId={contact.id} />}
      </div>
    </div>
  );
};

Facesheet.propTypes = {
  activeTab: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  contactId: PropTypes.string.isRequired,
  destroyForm: PropTypes.func.isRequired,
  fetchGroupContact: PropTypes.func.isRequired,
  fetchProvidersUserCore: PropTypes.func.isRequired,
  fetchServiceCases: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  locationSrc: PropTypes.string,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  selectContact: PropTypes.func.isRequired,
  isSupersetEnabled: PropTypes.bool,
  hasClientLink: PropTypes.bool,
  enums: PropTypes.object.isRequired,
  userIsAdmin: PropTypes.bool.isRequired,
  isClientMergingEnabled: PropTypes.bool.isRequired,
  cerb1367ResourceListDetailEnhancements: PropTypes.bool.isRequired,
  hbh1025ShowEligibilityAssessmentTab: PropTypes.bool.isRequired,
  cl1224UnconsentedClientFacesheetAccess: PropTypes.bool.isRequired,
};

Facesheet.defaultProps = {
  locationSrc: undefined,
  isSupersetEnabled: false,
  hasClientLink: false,
};

function mapStateToProps(state, ownProps) {
  const { session } = state;
  const { location, params } = ownProps;
  const groupId = wget(session, 'groupId', '');
  const enums = wget(session, 'enums');
  const group = findCurrentGroup(state.user, state.session);
  const networks = getEmployeeNetworks({ state });
  const locationSrc = undefined;

  return {
    groupId,
    contactId: params.id,
    activeTab: getActiveFacesheetTab(location.pathname),
    enums,
    group,
    networks,
    locationSrc,
    isSupersetEnabled: uup459SupersetPhase2(state),
    hasClientLink: hasClientLinkOnClientFacesheet(state),
    isClientMergingEnabled: isClientMergingEnabledFF(state),
    userIsAdmin: isOrgAdmin(state.user, session.groupId),
    cerb1367ResourceListDetailEnhancements: cerb1367ResourceListDetailEnhancementsSelector(state),
    hbh1025ShowEligibilityAssessmentTab: hbh1025ShowEligibilityAssessmentTabSelector(state),
    cl1224UnconsentedClientFacesheetAccess: cl1224UnconsentedClientFacesheetAccessFF(state),
  };
}

export default connect(mapStateToProps, {
  destroyForm,
  fetchGroupContact,
  fetchProvidersUserCore,
  fetchServiceCases,
  selectContact,
})(Facesheet);
