import PropTypes from 'prop-types';
import React from 'react';
import { Button } from '@unite-us/ui';
import './UploadedDocumentPdf.scss';

export default class UploadedDocumentPdf extends React.Component {
  constructor(props) {
    super(props);

    this.onDocumentComplete = this.onDocumentComplete.bind(this);
    this.onPageCompleted = this.onPageCompleted.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);
    this.handleNext = this.handleNext.bind(this);
  }

  handlePrevious() {
    this.setState({ page: this.state.page - 1 });
  }

  handleNext() {
    this.setState({ page: this.state.page + 1 });
  }

  onPageCompleted(page) {
    this.setState({ page });
  }

  onDocumentComplete(pages) {
    this.setState({ page: 1, pages });
  }

  uploadedDocumentArrowProps() {
    const { page, pages } = this.state;
    const { styles } = this.props;
    return {
      page,
      pages,
      styles,
      handlePrevious: this.handlePrevious,
      handleNext: this.handleNext,
    };
  }

  render() {
    const { doc } = this.props;

    const isImage = doc.content_type && doc.content_type.search('image') > -1;
    return (
      <div className="uploaded-document-pdf content-with-actions">
        <div className="content-container">
          {
            isImage ? (
              <img
                className="uploaded-document-pdf__image"
                src={doc.url}
                alt="No Preview Available"
              />
            ) : (
              <embed
                className="uploaded-document-pdf__embed"
                src={doc.url}
                type={doc.content_type}
              />
            )
          }
        </div>
        <div
          className="actions uploaded-document-pdf__open-button"
          style={{ textAlign: 'center' }}
        >
          <a
            className="uploaded-document-pdf__link"
            style={{ textDecoration: 'none' }}
            role="link"
            href={doc.url}
            download={doc.title}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button id="download-document-btn" label="Download" />
          </a>
        </div>
      </div>
    );
  }
}

UploadedDocumentPdf.propTypes = {
  doc: PropTypes.shape({
    content_type: PropTypes.string,
    title: PropTypes.string.isRequired,
    url: PropTypes.string,
  }).isRequired,
  styles: PropTypes.shape({
    icon: PropTypes.object.isRequired,
  }).isRequired,
};

UploadedDocumentPdf.defaultProps = {
  styles: {
    icon: { width: '20px', height: '20px' },
  },
};
