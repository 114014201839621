import React from 'react';
import PropTypes from 'prop-types';
import { Icon, InputField } from '@unite-us/ui';
import { Form, Field } from 'react-final-form';
import { theme } from 'src/../tailwind.config';

const SearchBar = ({
  className, inputClassName, placeholder, onSubmit, dataTestId, autoSubmit, autoComplete,
}) => (
  <div className={className}>
    <Icon
      className="absolute pt-2 pl-3"
      icon="IconSearch"
      size={20}
      color={theme.extend.colors['brand-blue']}
    />
    <Form
      onSubmit={
        onSubmit
      }
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field
            name="search"
          >
            {(params) => (
              <InputField
                className={inputClassName}
                autoComplete={autoComplete}
                hideError
                style={{ input: { paddingLeft: '2.5rem' } }}
                placeholder={placeholder}
                label="search" id="search" hideLabel
                dataTestId={dataTestId}
                {...params}
                onChange={(event) => {
                  if (autoSubmit && (event.target.value === '' || event.target.value.length > 2)) {
                    handleSubmit();
                  }
                }}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    handleSubmit();
                  }
                }}
              />
            )}
          </Field>
        </form>
      )}
    />
  </div>
);

SearchBar.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  dataTestId: PropTypes.string,
  autoSubmit: PropTypes.bool,
  autoComplete: PropTypes.string,

};

SearchBar.defaultProps = {
  className: 'flex-grow pl-5',
  inputClassName: 'md:w-4/5',
  placeholder: 'Search by Keyword',
  dataTestId: '',
  autoSubmit: true,
  autoComplete: 'off',
};

export default SearchBar;
