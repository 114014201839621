import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import DialogV2 from 'common/modal/DialogV2';
import { Spinner } from 'common/spinners';
import DynamicSearchTable from 'components/Backoffice/searchTable/DynamicSearchTable';
import { useFeeScheduleScreeningProgramsAssociations, ASSOCIATION_STEPS } from './useFeeScheduleScreeningProgramsAssociations';

const FeeScheduleScreeningProgramsAssociations = forwardRef(({ feeScheduleScreening }, ref) => {
  const {
    currentSelectedProviderName,
    step,
    selectedProviderId,
    selectedProgramsIds,
    searchedProviders,
    searchedProvidersPaging,
    searchedPrograms,
    searchedProgramsPaging,
    searchProvidersPageSize,
    searchProvidersPageNumber,
    searchProgramPageSize,
    searchProgramPageNumber,
    isSearchProvidersLoading,
    isSearchProgramsLoading,
    setSearchProviderName,
    setSearchProvidersPageSize,
    setSearchProvidersPageNumber,
    setSearchProgramName,
    setSearchProgramPageSize,
    setSearchProgramPageNumber,
    setSelectedProviderId,
    onSelectProgram,
    onAddProgramConfirmation,
    resetPrograms,
    resetProviders,
    shouldShowCheckedProgram,
  } = useFeeScheduleScreeningProgramsAssociations(feeScheduleScreening);

  const onCancelAddProgramModal = () => {
    if (step === ASSOCIATION_STEPS.PROGRAM_SELECTION) {
      resetPrograms();
    } else {
      ref.current.closeDialog();
      resetProviders();
    }
  };

  const onCloseAddProgramModal = () => {
    resetPrograms();
    resetProviders();
    ref.current.closeDialog();
  };

  return (
    <DialogV2
      cancelHandler={onCancelAddProgramModal}
      onXClose={onCloseAddProgramModal}
      cancelLabel={step === ASSOCIATION_STEPS.PROVIDER_SELECTION ? 'Cancel' : 'Back'}
      confirmationHandler={onAddProgramConfirmation}
      confirmLabel={step === ASSOCIATION_STEPS.PROVIDER_SELECTION ? 'Next' : 'Confirm'}
      id="add-fss-program-dialog"
      ref={ref}
      title="Add Program"
      confirmationBtnDisabled={
        step === ASSOCIATION_STEPS.PROVIDER_SELECTION ? !selectedProviderId : !selectedProgramsIds.length
      }
      width="6xl"
      dialogDescriptionClass="flex flex-col h-409px overflow-hidden bg-light-border-grey"
      scrollable={false}
      dialogWrapperPadding="p-8"
    >
      {step === ASSOCIATION_STEPS.PROVIDER_SELECTION ? (
        <>
          {isSearchProvidersLoading ? (
            <Spinner />
          ) : (
            <DynamicSearchTable
              searchResults={searchedProviders}
              rowHeaders={['Name', 'Organization ID']}
              selectedRowIds={selectedProviderId}
              setSelectedRowIds={setSelectedProviderId}
              setSearch={setSearchProviderName}
              emptyTableMessage="No Results Found. Enter Organization to view programs."
              searchBarPlaceHolder="Enter the Organization Name..."
              searchTitle="Organization Name"
              tableDescription={`
                This search only shows organizations that have been added to the screening on the CBOs tab. 
                To add programs from an organization, 
                the organization must first be added to the screening on the CBOs tab.
              `}
              dataTestId="organization-search"
              pageSize={searchProvidersPageSize}
              setPageSize={setSearchProvidersPageSize}
              pageNumber={searchProvidersPageNumber}
              setPageNumber={setSearchProvidersPageNumber}
              paging={searchedProvidersPaging}
            />
          )}
        </>
      ) : (
        <>
          {isSearchProgramsLoading ? (
            <Spinner />
          ) : (
            <DynamicSearchTable
              searchResults={searchedPrograms}
              rowHeaders={['Program Name', 'Program ID', 'Included']}
              selectedRowIds={selectedProgramsIds}
              setSelectedRowIds={onSelectProgram}
              setSearch={setSearchProgramName}
              emptyTableMessage="No Programs Found."
              searchBarPlaceHolder="Enter the Program Name..."
              searchTitle="Program Name"
              tableTitle={`Programs under ${currentSelectedProviderName}`}
              dataTestId="program-search"
              pageSize={searchProgramPageSize}
              setPageSize={setSearchProgramPageSize}
              pageNumber={searchProgramPageNumber}
              setPageNumber={setSearchProgramPageNumber}
              paging={searchedProgramsPaging}
              isMultiSelect
              shouldShowCheckIcon={shouldShowCheckedProgram}
            />
          )}
        </>
      )}
    </DialogV2>
  );
});

FeeScheduleScreeningProgramsAssociations.propTypes = {
  feeScheduleScreening: PropTypes.object.isRequired,
};

export default FeeScheduleScreeningProgramsAssociations;
