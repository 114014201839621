import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { noop } from 'lodash';
import {
  InputField,
  RadioField,
  DurationField,
  DateField,
} from '@unite-us/ui';
import { validations } from '@unite-us/app-components';
import { useFeatureFlag } from 'common/hooks';
import ErrorMessage from 'common/utils/ErrorMessage';
import WarningMessage from 'common/utils/WarningMessage';
import CurrencyField from 'common/form/CurrencyField';

const PaymentsTrackServiceDetailsForm = ({
  enforceRequiredFields,
  feeScheduleProgramName,
  fields,
  canInvoiceAboveRemainingAuthorizedAmount,
  ignoreSocialCareExpiredDates,
  insuranceDateNonValid,
  insuranceExpired,
  isBillable,
  isCostType,
  options,
  registerField,
  unitAmountValidations,
  requestedDatesNonValid,
  requestedValueNonValid,
}) => {
  const isDateRange = fields.provided_service.period_of_service.value === 'Date Range';
  const hasSelectedDate = !!fields.provided_service.service_start_date.value;
  const hasAmountProvidedFlag = useFeatureFlag('pays-4037-contracted-service-amount-provided');
  const costValidations = (value) => (
      validations.isRequired(value) ||
      validations.isLessThan(value, 'Should be less than $1,000,000', 1_000_000_00)
    );

  return (
    <>
      <div className="grid grid-cols-5 gap-x-6 lg:gap-y-4">
        {isCostType ? (
          <>
            <CurrencyField
              className="col-span-5 lg:col-span-2 pl-0"
              field={fields.provided_service.unit_amount}
              id="provided-service-unit-amount"
              label={isBillable ? 'Invoice Amount' : 'Unit Amount'}
              required={enforceRequiredFields}
              ref={registerField}
              validations={enforceRequiredFields ? costValidations : noop}
            />
            {hasAmountProvidedFlag ? (
              <>
                <CurrencyField
                  className="col-span-5 lg:col-span-2 pl-0"
                  field={fields.provided_service.actual_unit_amount}
                  id="provided-service-actual-unit-amount"
                  label={'Cost of Service'}
                />
                <div className="col-span-1">
                  <label htmlFor="provided-service-unit" className="block">Unit</label>
                  <div id="provided-service-unit" className="mt-3">Dollars ($)</div>
                </div>
                <p className="col-span-5 text-dark-grey text-xs pl-0 lg:-mt-6">
                  If the cost of service is more than the amount you are invoicing,
                  please enter the cost of service.
                </p>
              </>
            ) : (
              <div className="col-span-1">
                <label htmlFor="provided-service-unit" className="block">Unit</label>
                <div id="provided-service-unit" className="mt-3">Dollars ($)</div>
              </div>
            )}
          </>
        ) : (
          <>
            <InputField
              className="col-span-5 lg:col-span-2 pl-0"
              field={fields.provided_service.unit_amount}
              id="provided-service-unit-amount"
              label={isBillable ? 'Invoice Amount' : 'Unit Amount'}
              required={enforceRequiredFields}
              ref={registerField}
              type="number"
              min="1"
              validations={unitAmountValidations}
            />
            {hasAmountProvidedFlag ? (
              <>
                <InputField
                  className="col-span-5 lg:col-span-2 pl-0"
                  field={fields.provided_service.actual_unit_amount}
                  id="provided-service-actual-unit-amount"
                  label={'Amount Provided'}
                  ref={registerField}
                  type="number"
                  min="0"
                />
                <div className="col-span-5 lg:col-span-1 pl-0">
                  <label htmlFor="provided-service-unit" className="block">Unit</label>
                  <div
                    className="mt-3"
                    id="provided-service-unit"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: fields.provided_service.unit.value }}
                  />
                </div>
                <p className="col-span-5 text-dark-grey text-xs pl-0 lg:-mt-6">
                  If the amount provided is more than the amount you are invoicing,
                  please enter the amount provided.
                </p>
              </>
            ) : (
              <div className="col-span-5 lg:col-span-1 pl-0">
                <label htmlFor="provided-service-unit" className="block">Unit</label>
                <div
                  className="mt-3"
                  id="provided-service-unit"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: fields.provided_service.unit.value }}
                />
              </div>
            )}
          </>
        )}
        {requestedValueNonValid && (
          <div className=" col-span-5 flex space-x-2 px-0 mb-2">
            <ErrorMessage>
              {canInvoiceAboveRemainingAuthorizedAmount ?
                'The invoice amount is greater than the remaining authorized amount. ' +
                'Please check that the invoice amount is correct prior to submitting.' :
                'This contracted service cannot be submitted ' +
                'because the invoice amount is greater than the remaining authorized amount.'}
            </ErrorMessage>
          </div>
        )}
      </div>
      <div className="grid grid-cols-5 gap-x-6 lg:gap-y-4">
        <RadioField
          className="col-span-5 lg:col-span-2 px-0"
          field={fields.provided_service.period_of_service}
          id="provided-service-period-of-service"
          label="Period of Service"
          options={options}
        />
        <div className={cx('col-span-5 lg:col-span-2 px-0', isDateRange ? '' : 'hidden')}>
          <DurationField
            id="provided-service-dates"
            label="Service Dates"
            startField={fields.provided_service.service_start_date}
            endField={fields.provided_service.service_end_date}
            inline={false}
            required={isDateRange && enforceRequiredFields}
            validations={isDateRange && enforceRequiredFields ? validations.isRequired : noop}
          />
        </div>
        <div className={cx('col-span-5 lg:col-span-2 px-0', isDateRange ? 'hidden' : '')}>
          <DateField
            field={fields.provided_service.service_start_date}
            id="provided-service-date"
            label="Service Date"
            ref={registerField}
            required={!isDateRange && enforceRequiredFields}
            validations={!isDateRange && enforceRequiredFields ? validations.isRequired : noop}
          />
        </div>
      </div>
      {requestedDatesNonValid && (
      <div className="col-span-5 px-0 flex space-x-2 -mt-6 mb-8">
        <ErrorMessage>
          {canInvoiceAboveRemainingAuthorizedAmount ?
            'The selected service delivery dates fall outside the authorized service delivery dates. ' +
          'Please check that the correct dates are selected prior to submitting.' :
          'This contracted service cannot be submitted ' +
          'because the selected service delivery dates fall outside the authorized service delivery dates.'}
        </ErrorMessage>
      </div>
      )}
      {!ignoreSocialCareExpiredDates && insuranceDateNonValid && hasSelectedDate && (
        <div className="col-span-5 px-0 flex space-x-2 -mt-6 mb-8">
          <ErrorMessage>
            Client must be enrolled in a social insurance plan
            with the {feeScheduleProgramName} contracted program during the selected service dates.
          </ErrorMessage>
        </div>
      )}
      {ignoreSocialCareExpiredDates && hasSelectedDate && insuranceDateNonValid && (
        <div className="col-span-5 px-0 flex space-x-2 -mt-6 mb-8">
          <ErrorMessage>
            The selected service dates cannot be prior to the client’s social care coverage enrollment start date.
          </ErrorMessage>
        </div>
      )}
      {ignoreSocialCareExpiredDates && hasSelectedDate && insuranceExpired && (
        <div className="col-span-5 px-0 flex space-x-2 -mt-6 mb-8">
          <WarningMessage>
            The client’s social care coverage ended prior to or during the selected service delivery date(s).
            Please verify the service date(s) before submitting.
          </WarningMessage>
        </div>
      )}
    </>
  );
};

PaymentsTrackServiceDetailsForm.propTypes = {
  enforceRequiredFields: PropTypes.bool.isRequired,
  feeScheduleProgramName: PropTypes.string.isRequired,
  fields: PropTypes.object.isRequired,
  canInvoiceAboveRemainingAuthorizedAmount: PropTypes.bool.isRequired,
  ignoreSocialCareExpiredDates: PropTypes.bool.isRequired,
  insuranceDateNonValid: PropTypes.bool.isRequired,
  insuranceExpired: PropTypes.bool.isRequired,
  isBillable: PropTypes.bool.isRequired,
  isCostType: PropTypes.bool.isRequired,
  registerField: PropTypes.func.isRequired,
  unitAmountValidations: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  requestedDatesNonValid: PropTypes.bool.isRequired,
  requestedValueNonValid: PropTypes.bool.isRequired,
};

export default PaymentsTrackServiceDetailsForm;
