import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from '@unite-us/ui';

const EditButton = ({
  edit,
  id,
  label,
  'aria-label': ariaLabel,
}) => (
  <button
    className="button-flex"
    id={id}
    aria-label={ariaLabel}
    onClick={edit}
    type="button"
    tabIndex={0}
  >
    <div className="flex space-x-2 align-center my-2">
      <Icon icon="IconPencil" className="mt-quarter" />
      <p className="my-1">{label}</p>
    </div>
  </button>
);

EditButton.propTypes = {
  edit: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  'aria-label': PropTypes.string,
};

EditButton.defaultProps = {
  label: 'Edit',
  'aria-label': undefined,
};

export default EditButton;
