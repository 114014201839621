import { useFind } from 'src/api/APIHooks';
import { get, first } from 'lodash';
import { NON_SOCIAL_PLAN_TYPES } from '../constants';

const useInsuranceExternalMemberID = (personId, payerId) => {
  const { isFetching, isLoading, data } = useFind(
    'insurances',
    {
      person: personId,
      state: 'active',
      'plan.plan_type': NON_SOCIAL_PLAN_TYPES,
      'plan.payer': payerId,
    },
    {
      queryConfig: {
        enabled: !!personId,
        placeholderData: undefined,
      },
    },
  );

  const insurances = get(data, 'data.data', []);
  const firstInsuranceWithAnExternalMemberID = !isFetching && insurances.filter((insurance) => (
    get(insurance, 'external_member_id') !== null
  ));

  return {
    isLoading,
    isFetching,
    externalMemberID: get(first(firstInsuranceWithAnExternalMemberID), 'external_member_id', ''),
  };
};

export default useInsuranceExternalMemberID;
