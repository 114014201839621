import { useFind } from 'src/api/APIHooks';
import { sortBy } from 'lodash';
import { useCurrentProviderId, useIsNetworkLead } from 'common/contexts/CurrentProviderContext';

const useInvoicePayerOptions = () => {
  const isNetworkLead = useIsNetworkLead();
  const providerId = useCurrentProviderId();
  const filterOptions = {
      ...(isNetworkLead ? { network_lead: providerId } : { provider: providerId }),
  };

  const { data } = useFind(
    'payers',
    filterOptions,
    {
      queryConfig: {
        enabled: !!providerId,
        placeholderData: undefined,
        refetchOnWindowFocus: false,
        staleTime: Infinity,
      },
    },
  );

  const payerData = data?.data?.data ?? [];

  const payerOptions = payerData.map((payer) => ({
    label: payer.name,
    value: payer.id,
  }));
  return sortBy(payerOptions, 'label');
};

export default useInvoicePayerOptions;
