import { useUpdateRecord, useInvalidateQueries } from 'src/api/APIHooks';
import Notifier from 'common/helpers/Notifier';
import { every, get } from 'lodash';

export const useBulkUpdate = (model, ids, params, successMessage) => {
  const invalidateQueries = useInvalidateQueries();
  const { updateRecord } = useUpdateRecord(model, {
    mutationConfig: {
      onError: () => {
        Notifier.dispatch('error', 'Something went wrong...');
      },
     },
  });
  return async () => {
    const successfulRequestIds = [];
    const erroredRequestIds = [];
    const results = await Promise.allSettled(ids.map((id) => updateRecord(id, params)));

    results.forEach((result) => {
      if (result.status === 'fulfilled') {
        const successfulRequestId = get(result, 'value.data.data.id');
        successfulRequestIds.push(successfulRequestId);
      } else if (result.status === 'rejected') {
        const erroredRequestId = get(result, 'reason.config.url').split('/').pop();
        erroredRequestIds.push(erroredRequestId);
      }
    });

    const httpResponseSuccess = every(results.map((promiseResult) => promiseResult.status === 'fulfilled'));
    if (httpResponseSuccess) {
      Notifier.dispatch(
        results[0].value.status,
        successMessage,
      );
    } else {
      Notifier.handleErrors('There was an error. Some rows were not properly updated.');
    }
    invalidateQueries(`${model}s`);
    return { successfulRequestIds, erroredRequestIds };
  };
};
