import React from 'react';
import { Button } from '@unite-us/app-components';
import { useIsCBOProvider, useIsNetworkLead, useIsPayer } from 'common/contexts/CurrentProviderContext';
import PropTypes from 'prop-types';
import { PayerProviderFilter } from './PayerProviderFilter';
import { ManagedProviderFilter } from './ManagedProviderFilter';
import { ServiceProvidedFilter } from './ServiceProvidedFilter';
import { InvoiceStatusFilter } from './InvoiceStatusFilter';
import { ShortIdFilter } from './ShortIdFilter';
import { InvoiceTypeFilter } from './InvoiceTypeFilter';
import { INVOICE_STATUSES } from '../../constants';

const FilterSelectors = ({
  availableStatuses,
  filterSetters,
  filterValues,
  invoicesQuery,
  invoiceTypes,
  openFilterDrawer,
  showDisputed,
  showInvoiceType,
}) => {
  const isNetworkLead = useIsNetworkLead();
  const isPayer = useIsPayer();
  const isCBO = useIsCBOProvider();

  return (
    <div className="flex items-end space-x-4">
      <InvoiceStatusFilter
        availableStatuses={availableStatuses}
        className="w-48"
        showDisputed={showDisputed}
        onChange={filterSetters.setStatus}
        value={filterValues.status}
      />
      {showInvoiceType && (
        <InvoiceTypeFilter
          className="w-56"
          invoiceTypes={invoiceTypes}
          onChange={filterSetters.setType}
          value={filterValues.type}
        />
      )}
      <ShortIdFilter
        className="w-52"
        invoicesQuery={invoicesQuery}
        onChange={filterSetters.setShortId}
        value={filterValues.shortId}
      />
      {isPayer && (
        <PayerProviderFilter
          className="w-48"
          onChange={filterSetters.setPayerProvider}
          value={filterValues.payerProvider}
        />
      )}
      {isNetworkLead && (
        <ManagedProviderFilter
          className="w-72"
          onChange={filterSetters.setManagedProvider}
          value={filterValues.managedProvider}
        />
      )}
      {isCBO && (
        <ServiceProvidedFilter
          className="w-80"
          onChange={filterSetters.setFeeScheduleProgram}
          value={filterValues.feeScheduleProgram}
        />
      )}
      <Button
        className="border-none text-action-blue px-0 py-2"
        icon={{
          name: 'Filter',
          color: '#2C405A',
          position: 'left',
        }}
        label="All Filters"
        onClick={openFilterDrawer}
      />
    </div>
  );
};

FilterSelectors.propTypes = {
  availableStatuses: PropTypes.arrayOf(PropTypes.oneOf(INVOICE_STATUSES)).isRequired,
  filterSetters: PropTypes.shape({
    setShortId: PropTypes.func.isRequired,
    setFeeScheduleProgram: PropTypes.func.isRequired,
    setManagedProvider: PropTypes.func.isRequired,
    setPayerProvider: PropTypes.func.isRequired,
    setStatus: PropTypes.func.isRequired,
    setType: PropTypes.func.isRequired,
  }).isRequired,
  filterValues: PropTypes.shape({
    shortId: PropTypes.string,
    feeScheduleProgram: PropTypes.string,
    managedProvider: PropTypes.string,
    payerProvider: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  invoicesQuery: PropTypes.object.isRequired,
  invoiceTypes: PropTypes.array,
  openFilterDrawer: PropTypes.func.isRequired,
  showDisputed: PropTypes.oneOf([true, false, null]),
  showInvoiceType: PropTypes.bool.isRequired,
};

FilterSelectors.defaultProps = {
  invoiceTypes: [],
  showDisputed: false,
};

export default FilterSelectors;
