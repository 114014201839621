import { useState } from 'react';
import { useFind, usePopulate } from 'api/APIHooks';
import { DEFAULT_PAGE_SIZE } from 'components/Backoffice/backofficeUtils';
import { useUpdateFeeScheduleScreening } from 'components/Backoffice/api/hooks/v1/feeScheduleScreeningHooks';

export const useProgramTable = (feeScheduleScreening) => {
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedProgramsForRemove, setSelectedProgramsForRemove] = useState([]);

  const { data: programData, isLoading } = useFind(
    'program',
    { fee_schedule_screenings: feeScheduleScreening.id },
    {
      queryConfig: { placeholderData: undefined },
      page: { size: pageSize, number: pageNumber },
    },
  );

  const programs = programData?.data?.data || [];
  const paging = programData?.data?.paging || {};

  const { isLoading: isLoadingProviders } = usePopulate(
    'provider',
    'provider',
    programs,
    { queryConfig: { placeholderData: undefined } },
  );

  const { updateRecord: updateFeeScheduleScreening } = useUpdateFeeScheduleScreening();

  const onCheckAllProgramsForRemove = () => {
    setSelectedProgramsForRemove(
      selectedProgramsForRemove.length === programs.length ? [] : programs.map((program) => program.id),
    );
  };

  const onCheckProgramForRemove = (programId) => () => {
    setSelectedProgramsForRemove((prevSelected) => {
      if (prevSelected.includes(programId)) {
        return prevSelected.filter((id) => id !== programId);
      }
      return [...prevSelected, programId];
    });
  };

  const onRemovePrograms = async () => {
    const updatedPrograms = feeScheduleScreening.programs.filter(
      (program) => !selectedProgramsForRemove.includes(program.id),
    );
    await updateFeeScheduleScreening(
      feeScheduleScreening.id,
      { programs: updatedPrograms },
    );
    setSelectedProgramsForRemove([]);
  };

  return {
    programs,
    paging,
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    isLoading: isLoading || isLoadingProviders,
    selectedProgramsForRemove,
    onCheckAllProgramsForRemove,
    onCheckProgramForRemove,
    onRemovePrograms,
  };
};
