import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { browserHistory } from 'common/utils/browserHistory';
import {
  useFindPlan,
  useUpdatePlan,
} from 'src/components/Backoffice/api/hooks/v1/planHooks';
import { constructPlanInitialFormValues, constructPlanFormPayload } from '../../utils';
import PlanForm from './PlanForm';

const EditPlan = ({ params }) => {
  const planId = params.plan_id;
  const { status, data } = useFindPlan(planId);
  const payerId = params.payer_id ?? data.payer?.id;
  const feeScheduleId = params.fee_schedule_id ?? '';

  const { updateRecord: updatePlan } = useUpdatePlan();
  const initialValues = constructPlanInitialFormValues(data);
  const showPending = (status === 'loading' && isEmpty(initialValues));

  const onSubmit = async (plan) => {
    const payload = constructPlanFormPayload(plan, payerId);
    const response = await updatePlan(planId, payload);
    const payersPath = `/backoffice/payers/${payerId}/plans/${planId}`;
    const feeSchedulePath = `/backoffice/fee_schedules/${feeScheduleId}/plans/${planId}`;
    if (response) {
      browserHistory.push({
        pathname: feeScheduleId ? feeSchedulePath : payersPath,
      });
    }
  };

  return (
    <PlanForm
      initialValues={initialValues} onSubmit={onSubmit} payerId={payerId}
      planId={planId} showPending={showPending} feeScheduleId={feeScheduleId}
    />
  );
};

EditPlan.propTypes = {
  params: PropTypes.object.isRequired,
};

export default EditPlan;
