import { useFind } from '../../../api/APIHooks';
import { selectUniqueDisplayValues } from '../utils/selectUniqueDisplayValues';
import useFindCurrentEmployeesFeeScheduleIds from './useFindCurrentEmployeesFeeScheduleIds';

const useInvoiceDisputeResolutionReasonOptions = () => {
  const feeScheduleIds = useFindCurrentEmployeesFeeScheduleIds();
  const { data } = useFind(
    'invoice_dispute_resolution_reasons',
    {
      fee_schedule: feeScheduleIds.join(),
    },
    {
      queryConfig: {
        enabled: feeScheduleIds.length > 0,
        placeholderData: undefined,
      },
    },
  );

  return selectUniqueDisplayValues(data?.data?.data);
};

export default useInvoiceDisputeResolutionReasonOptions;
