import React from 'react';
import PropTypes from 'prop-types';
import { SelectField } from 'components/Backoffice/form/SelectField';
import { useInvoiceDisputeResolutionReasonOptions } from '../../hooks';

export const DisputeResolutionReasonFilter = ({
  onChange,
  value,
}) => {
  const disputeResolutionReasonOptions = useInvoiceDisputeResolutionReasonOptions();

  return (
    <SelectField
      label="Dispute Resolution"
      labelClassName="tracking-normal"
      onChange={({ value: val }) => onChange(val)}
      options={disputeResolutionReasonOptions}
      value={value ?? ''}
      placeholder="Choose a dispute resolution"
      clearable
    />
  );
};

DisputeResolutionReasonFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

DisputeResolutionReasonFilter.defaultProps = {
  value: undefined,
};
