import Notifier from 'common/helpers/Notifier';
import { coreApi } from 'src/api/config';

/**
 * Fetches user and submitter information based on provided IDs.
 * @param {Array<string>} clientsIds - Array of client IDs.
 * @param {Array<string>} submittersIds - Array of submitter IDs.
 * @returns { clients: Array, submitters: Array } - User and submitter data.
 */
async function fetchUsersInfo(clientsIds = [], submittersIds = []) {
  try {
    const [users, submitters] = await Promise.all([
      clientsIds.length > 0 ?
        coreApi.query('people', { ids: clientsIds }) :
        Promise.resolve({ data: { data: [] } }),
      submittersIds.length > 0 ?
        coreApi.query('employee', { ids: submittersIds }) :
        Promise.resolve({ data: { data: [] } }),
    ]);

    const {
      data: { data: clients },
    } = users;
    const {
      data: { data: submittersData },
    } = submitters;

    return {
      clients,
      submitters: submittersData,
    };
  } catch (error) {
    Notifier.handleErrors(error);
    return {
      clients: [],
      submitters: [],
    };
  }
}

export default fetchUsersInfo;
