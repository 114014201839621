import { get, isEmpty } from 'lodash';
import {
  SET_DASHBOARD_FETCHING,
  DASHBOARD_FETCH_ASSISTANCE_REQUESTS,
} from 'actions';
import Notifier from 'common/helpers/Notifier';
import { unsetDashboardFetching } from 'actions/Dashboard';
import { coreApi } from 'src/api/config';

const defaultOptions = {
  target: 'assistanceRequests',
  sidx: 'created_at',
  sort: 'asc',
  filters: {},
  page: 1,
  size: 50,
};

const onError = (error) => {
  Notifier.handleErrors(error);
  return error;
};

const fetchDashboardPendingAssistanceRequests = (groupId, opts = {}) => (
  async (dispatch) => {
    try {
      const options = { ...defaultOptions, ...opts };

      dispatch({
        type: SET_DASHBOARD_FETCHING,
        target: options.target,
        filters: options.filters,
      });

      const service = get(options, 'filters.service_types', []);
      const careCoordinatorUsers = get(options, 'filters.care_coordinator_users', []);
      const careCoordinators = careCoordinatorUsers.filter((c) => c !== 'none');
      const noneAssigned = careCoordinatorUsers.includes('none');
      const startDate = get(options, 'filters.startDate', []);
      const endDate = get(options, 'filters.endDate', []);

      const response = await coreApi.query('assistance_requests', {
        originating_provider: groupId,
        ...(service.length ? { service: service.join(',') } : null),
        ...(!isEmpty(careCoordinators) ?
          { 'person.client_relationships.care_coordinator': careCoordinators.join(',') } : null),
        ...(!isEmpty(startDate) && !isEmpty(endDate) ? { start_date: startDate, end_date: endDate } : null),
        ...(noneAssigned ? {
          'person.client_relationships.has_care_coordinator': false,
        } : null),
      }, {
        page: {
          number: options.page,
          size: options.size,
        },
        sort: options.sort,
      });
      dispatch(unsetDashboardFetching(response, options.target));

      const assistanceRequests = response.data.data;
      await Promise.all([
        coreApi.populateRelationship('service', 'service', assistanceRequests),
        coreApi.populateRelationship('provider', 'provider', assistanceRequests),
      ]);

      const mapped = assistanceRequests.map((assistanceRequest) => {
        const full_name =
          `${assistanceRequest.person_first_name} ${assistanceRequest.person_last_name}`;

        return {
          id: assistanceRequest.id,
          permissions: assistanceRequest.permissions,
          created_at: assistanceRequest.created_at,
          group: assistanceRequest.provider,
          service_type: assistanceRequest.service,
          requestor: { full_name },
        };
      });
      response.data.data = mapped;

      dispatch({
        type: DASHBOARD_FETCH_ASSISTANCE_REQUESTS,
        payload: response,
        target: options.target,
        filters: options.filters,
      });

      return response;
    } catch (error) {
      return onError(error);
    }
  }
);

export default fetchDashboardPendingAssistanceRequests;
