import React, { useState, useContext } from 'react';
import AssessmentDetailsView from 'src/components/Contacts/components/AssessmentDetailsView';
import { useFindRecord } from 'src/api/APIHooks';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { CurrentEmployeeContext } from 'src/common/contexts/CurrentEmployeeContext';

export default function EnrollmentAssessmentDetailsView(props) {
  const { params } = props;
  const submissionId = params.submission_id;
  const { data: formResponse } = useFindRecord(
    'form',
    params.assessment_id,
    { queryConfig: { placeholderData: undefined } },
  );
  const assessment = formResponse?.data?.data ?? {};
  const [editMode, setEditMode] = useState(false);

  const onCancel = () => {
    window.history.back();
  };

  const onEditClick = () => {
    setEditMode(true);
  };
  return (
    <div className="contact-assessment-details">
      {
        !isEmpty(assessment) && (
          <AssessmentDetailsView
            assessment={assessment}
            submissionId={submissionId}
            editMode={editMode}
            showEditButton
            onEditClick={onEditClick}
            afterSubmit={onCancel}
            onCancel={onCancel}
            contextType="person"
            context={params.person_id}
            currentEmployee={useContext(CurrentEmployeeContext)}
            showLatestFormSubmission
          />
        )
      }
    </div>
  );
}

EnrollmentAssessmentDetailsView.propTypes = {
  params: PropTypes.shape({
    submission_id: PropTypes.string.isRequired,
    assessment_id: PropTypes.string.isRequired,
    person_id: PropTypes.string.isRequired,
  }).isRequired,
};

EnrollmentAssessmentDetailsView.defaultProps = {
};
