import {
  useSetRelationship,
  useCreateRecord,
  useFind,
  useFindRecord,
  useInvalidateQueries,
  useUpdateRecord,
} from 'src/api/APIHooks';
import { defaultQueryConfig, defaultMutationConfig } from '../apiHookOptions';

export const useCreatePlan = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  return useCreateRecord('plan', {
    mutationConfig: {
      onSuccess: () => {
        invalidateQueries('plan');
        invalidateQueries('payer');
        invalidateQueries('network');
      },
      ...defaultMutationConfig,
      ...mutationConfig,
    },
  });
};

export const useFindAllPlan = (ids, params = { queryConfig: defaultQueryConfig }) => useFind(
  'plans',
  {
    id: ids,
  },
  {
    queryConfig: {
      placeholderData: undefined,
      enabled: !!ids,
    },
    ...params,
  },
);

export const useFindPlan = (id, params = { queryConfig: defaultQueryConfig }) => useFindRecord('plan', id, {
  queryConfig: {
    placeholderData: undefined,
    enabled: !!id,
  },
  ...params,
});

export const useFindPlansByName = (planName, params = { queryConfig: defaultQueryConfig }) => useFind(
  'plans',
  {
    name: planName,
  },
  {
    queryConfig: {
      enabled: !!planName,
      placeholderData: undefined,
    },
    ...params,
  },
);

export const useSetPlanFeeSchedule = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  return useSetRelationship('plan', 'fee_schedule', {
    mutationConfig: {
      onSuccess: () => {
        invalidateQueries('plan');
        invalidateQueries('fee_schedule');
      },
      ...defaultMutationConfig,
      ...mutationConfig,
    },
  });
};

export const useUpdatePlan = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  return useUpdateRecord('plan', {
    mutationConfig: {
      onSuccess: () => {
        invalidateQueries('plan');
        invalidateQueries('payer');
        invalidateQueries('network');
      },
      ...defaultMutationConfig,
      ...mutationConfig,
    },
  });
};

export default useFindPlan;
