import Notifier from 'common/helpers/Notifier';
import { useInvalidateQueries, useUpdateRecord } from 'src/api/APIHooks';
import { isHttpSuccess } from 'src/api/utils/httpStatus';

export const useUpdateServiceAuthorization = (onErrorCallback) => {
  const invalidateQueries = useInvalidateQueries();
  const { updateRecord } = useUpdateRecord('service_authorization', {
    mutationConfig: {
      onError: (error) => {
        if (onErrorCallback) {
          onErrorCallback(error);
        } else {
          Notifier.dispatch('error', 'Something went wrong...');
        }
      },
    },
  });

  return async (id, updatedFields, successMessage) => {
    const updateResponse = await updateRecord(id, updatedFields);
    if (isHttpSuccess(updateResponse.status)) {
      Notifier.dispatch(updateResponse.status, successMessage);
    }
    invalidateQueries(['service_authorization', id]);
  };
};

export const useAcceptServiceAuthorization = () => {
  const update = useUpdateServiceAuthorization();

  return async ({
    authorization,
    reviewer,
    reviewer_note,
    payer_authorization_number,
    approved_cents,
    approved_unit_amount,
    approved_starts_at,
    approved_ends_at,
    clinical_modifications,
  }) => {
    const updateFields = {
      state: 'approved',
      reviewer,
      payer_authorization_number,
      approved_cents,
      approved_unit_amount,
      approved_starts_at,
      approved_ends_at,
      ...(reviewer_note && { reviewer_note }),
    };

    if (clinical_modifications[0].id) {
      updateFields.clinical_modifications = clinical_modifications;
    }

   return update(
    authorization.id,
    updateFields,
    `Authorization Request #${authorization.short_id} has been approved and accepted!`,
  );
  };
};

export const useRejectServiceAuthorization = () => {
  const update = useUpdateServiceAuthorization();

  return async ({
    authorization,
    reviewer,
    denial_reason,
    reviewer_note,
  }) => update(
    authorization.id,
    {
      state: 'denied',
      reviewer,
      service_authorization_denial_reason: denial_reason,
      ...(reviewer_note && { reviewer_note }),
    },
    'Service Authorization Rejected.',
  );
};
