import { useFind } from 'src/api/APIHooks';

const useInvoiceRejectionReasons = (feeScheduleId) => {
  const { data: response } = useFind(
    'invoice_rejection_reasons',
    { fee_schedule: feeScheduleId },
    {
      queryConfig: {
        enabled: !!feeScheduleId,
        placeholderData: undefined,
        refetchOnWindowFocus: false,
        staleTime: Infinity,
      },
    },
  );

  return response?.data?.data ?? [];
};

export default useInvoiceRejectionReasons;
