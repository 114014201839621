import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { DAYS_OF_THE_WEEK } from '@unite-us/client-utils';

function timeslotLabel(timeslot) {
  const start_label = moment(timeslot.start_time, 'HH:mm').format('h:mm A');
  const end_label = moment(timeslot.end_time, 'HH:mm').format('h:mm A');
  return `${start_label} - ${end_label}`;
}

const Timeslots = ({ value }) => {
  const daysInValue = Object.keys(value);
  return (
    <div className="flex flex-col gap-2">
      {DAYS_OF_THE_WEEK.map((day_of_week) => {
        const day = day_of_week.toLowerCase();
        if (daysInValue.includes(day)) {
          const [firstTimeslot, ...restOfTimeslots] = value[day];
          return (
            <div key={day} data-testid={`${day}-times`}>
              <div className="flex justify-between">
                <div className="mr-2">{day.substring(0, 3)}</div>
                <div>{timeslotLabel(firstTimeslot)}</div>
              </div>
              <div className="flex justify-end">
                {restOfTimeslots.map((t) => (
                  <div key={t.id}>{timeslotLabel(t)}</div>
                ))}
              </div>
            </div>
          );
        }

        return (
          <div key={day} className="flex justify-between" data-testid={`${day}-times`}>
            <div>{day.substring(0, 3)}</div>
            <div>Do not contact</div>
          </div>
        );
      })}
    </div>
  );
};

Timeslots.propTypes = {
  value: PropTypes.shape({
    monday: PropTypes.array,
    tuesday: PropTypes.array,
    wednesday: PropTypes.array,
    thursday: PropTypes.array,
    friday: PropTypes.array,
    saturday: PropTypes.array,
    sunday: PropTypes.array,
  }).isRequired,
};

export default Timeslots;
