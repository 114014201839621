import React, { useCallback, useContext, useState } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { AppReferrals, useAppCreateReferralContext } from '@unite-us/app-create-referral';
import { getAuthToken, coreApi } from 'src/api/config';
import { CORE_API, SHARES_URL, CONSENT_APP_URL, GOOGLE_MAPS_API_KEY } from 'src/config/env/env.config';
import { TrackerContext } from '@unite-us/client-utils';
import { ComponentLibraryLoader } from '@unite-us/app-components';
import Notifier from 'common/helpers/Notifier';
import { connect } from 'react-redux';
import { getEnumsFromState } from 'common/utils/Enums';
import { browserHistory } from 'common/utils/browserHistory';
import PropTypes from 'prop-types';
import { useSelectedPrograms } from '@unite-us/app-search';
import { omitBy, isNil, isString, isEmpty } from 'lodash';
import {
  serializeQueryParams,
} from '@unite-us/json-api-resources/dist/utils/serializeQueryParams';
import { ClientHeader } from '@unite-us/app-client-profile';
import { updateGlobalState } from 'src/actions/Global/globalActions';
import {
  uup459SupersetPhase2,
  crtb1121HideFieldInForm,
  crtb1127AuthPaymentProgramsInReferrals as crtb1127AuthPaymentProgramsInReferralsSelector,
  crtb1239AdaptDraftReferralFlow as crtb1239AdaptDraftReferralFlowSelector,
  crtb824AddOpenCloseDateOffPlatformCase as crtb824AddOpenCloseDateOffPlatformCaseSelector,
  crtb854ForwardingSuperset as crtb854ForwardingSupersetSelector,
  crtb1285DisplayProgramEligReferralWorkflow as crtb1285DisplayProgramEligReferralWorkflowSelector,
  crtb1299HighRejectionSolution as crtb1299HighRejectionSolutionSelector,
  hint542SupersetUnlistedPrograms as hint542SupersetUnlistedProgramsSelector,
  hint1980ShowSharesMessageBox as hint1980ShowSharesMessageBoxSelector,
  pays3551AuthInSuperset as pays3551AuthInSupersetSelector,
  pays5590RefactorInsuranceFilters as pays5590RefactorInsuranceFiltersSelector,
} from '../../../common/utils/FeatureFlags/flags';
import { ConsentReferrals } from '../../../components/InformedConsent/components/ConsentReferrals';

const getBasename = ({ pathname, route, basename = '' }) => {
  const path = pathname.split(route).at(0);
  return `${basename}${path}${route}`;
};

const ShareDrawerLoader = ComponentLibraryLoader({
  loadFn: () => import('@unite-us/shares-utils'),
  component: 'ShareDrawer',
  loadingApp: 'app-client',
  source: 'shares-utils',
  datadogRum,
});

function Referrals({
  location,
  currentEmployee,
  currentProvider,
  enums,
  initialAddresses,
  isSupersetEnabled,
  crtb1121HideFieldInFormEnabled,
  crtb1127AuthPaymentProgramsInReferrals,
  crtb1239AdaptDraftReferralFlow,
  crtb1285DisplayProgramEligReferralWorkflow,
  crtb1299HighRejectionSolution,
  crtb824AddOpenCloseDateOffPlatformCase,
  crtb854ForwardingSuperset,
  hint542SupersetUnlistedPrograms,
  hint1980ShowSharesMessageBox,
  pays3551AuthInSuperset,
  pays5590RefactorInsuranceFilters,
}) {
  const basename = getBasename({
    basename: location.basename,
    pathname: location.pathname,
    route: 'referrals/2',
  });

  const [showHeader, setShowHeader] = useState(true);
  const trackEvent = useContext(TrackerContext);

  const {
    state: {
      person: maybePerson,
      assistanceRequestId: assistanceRequest,
      resourceListId,
      referrals,
      formSubmissionId,
      serviceIds,
      workflow,
      isForwardReferral,
    },
  } = useAppCreateReferralContext();

  if (!isSupersetEnabled) {
    browserHistory.replace('/');
    return null;
  }

  const { dispatchAddPrograms, dispatchRemoveAllPrograms, dispatchRemoveProgram } = useSelectedPrograms();

  const personId = isString(maybePerson) ? maybePerson : maybePerson?.id;
  const assistanceRequestId = isString(assistanceRequest) ? assistanceRequest : assistanceRequest?.id;
  const isSearchWorkflow = workflow === 'search';
  const isResourceWorkflow = !isEmpty(resourceListId);

  const navigateBack = useCallback(({ addSelectClient, referralId, referralDescription }) => {
    if (isSearchWorkflow) {
      const pathToSearch = '/referrals/create/add-resources';

      // Create the existing search query parameters as an object
      const searchQueryParams = omitBy(
        {
          person: personId,
          resource_list: resourceListId,
          form_submission: formSubmissionId,
          assistance_request: assistanceRequestId,
          services: serviceIds?.join(','),
        },
        isNil,
      );

      const forwardReferralParams = {};
      if (crtb854ForwardingSuperset) {
        // Extract additional parameters from the current URL if the flag is enabled
        const currentLocation = browserHistory.getCurrentLocation();
        const queryParams = new URLSearchParams(currentLocation.search);

        // Define the parameters to forward
        const params = ['referral-id', 'sensitive', 'referral-type', 'service-type-name'];
        params.forEach((key) => {
          const value = queryParams.get(key);
          if (value !== null && value !== undefined) {
            forwardReferralParams[key] = value;
          }
        });
      }

      const combinedQueryParams = new URLSearchParams({
        ...searchQueryParams,
        ...forwardReferralParams,
        preserve_cart: 'true',
      });

      // Define state based on whether addSelectClient is true
      const state = addSelectClient ?
        { source: 'client', referralId, referralDescription } :
        { referralId, referralDescription };

      browserHistory.replace({
        pathname: pathToSearch,
        search: `?${combinedQueryParams.toString()}`,
        state,
      });
    } else if (isResourceWorkflow) {
      const pathToSearch = `/facesheet/${personId}/resource-lists/${resourceListId}`;
      browserHistory.replace({
        pathname: pathToSearch,
      });
    } else {
      browserHistory.goBack();
    }
  });

  const navigateToAddResources = useCallback(() => {
    dispatchRemoveAllPrograms();
    // For now we build the programs object to match the app-search context
    const buildProgramDetails = (program, referral) => ({
      id: program.id,
      name: program.name,
      providerId: program.provider.id,
      providerLicensed: program.provider.licensed,
      providerName: program.provider.name,
      receivingReferrals: program.receiving_referrals,
      service: referral.service.id,
      status: program.status,
    });
    const programs = referrals.flatMap((r) => r.programs.map((p) => buildProgramDetails(p, r)));
    if (programs.length > 0) {
      dispatchAddPrograms(programs);
    }

    const pathToSearch = '/referrals/create/add-resources';
    const searchQueryParams = serializeQueryParams(omitBy({
      person: personId,
    }, isNil));

    browserHistory.replace({
      pathname: pathToSearch,
      search: `?${searchQueryParams}&preserve_cart=true`,
    });
  });

  return (
    <>
      <div className="mb-6 bg-white -mx-container-padding">
        {showHeader && (
          <ClientHeader
            adapters={{ coreApi }}
            appState={{
              providerId: currentProvider?.id,
              personId,
              enums,
              crtb854ForwardingSuperset,
              isForwardReferral,
            }}
            mode="lite"
          />
        )}
      </div>
      <AppReferrals
        appSettings={
          {
            env: {
              getAuthToken,
              coreUrl: CORE_API,
              employeeId: currentEmployee?.id,
              providerId: currentEmployee?.provider.id,
              sharesUrl: SHARES_URL,
              consentUrl: CONSENT_APP_URL,
              googleMapsApiKey: GOOGLE_MAPS_API_KEY,
            },
            feature_flags: {
              crtb1121HideFieldInFormEnabled,
              crtb1127AuthPaymentProgramsInReferrals,
              crtb1239AdaptDraftReferralFlow,
              crtb1285DisplayProgramEligReferralWorkflow,
              crtb1299HighRejectionSolution,
              crtb824AddOpenCloseDateOffPlatformCase,
              crtb854ForwardingSuperset,
              hint542SupersetUnlistedPrograms,
              hint1980ShowSharesMessageBox,
              pays3551AuthInSuperset,
              pays5590RefactorInsuranceFilters,
            },
            basename,
            initialAddresses,
          }
        }
        appState={{
          currentEmployee,
          currentProvider,
          enums,
          source: 'app-client',
        }}
        callbacks={{
          notify: {
            error: (message) => Notifier.dispatch('error', message),
            success: (message) => Notifier.dispatch('success', message),
            warn: (message) => Notifier.dispatch('warning', message),
          },
          trackEventCallback: trackEvent,
          consent: {
            updateGlobalState,
          },
          onReferralCompleted: () => {
            browserHistory.push({
              pathname: `/facesheet/${personId}`,
            });
          },
          onSaveDraftReferral: () => {
            browserHistory.push({
              pathname: '/dashboard/referrals/sent/draft',
            });
          },
          onDeselectProgram: isSearchWorkflow ? dispatchRemoveProgram : () => { },
          // TODO: No need for both of these callbacks.
          onNavigateBack: navigateBack,
          onNavigateToAddResources: navigateToAddResources,
          onSubmitConsent: async () => {
            // refresh ClientHeader to refetch person to show new consent status
            setShowHeader(false);
            setTimeout(() => {
              setShowHeader(true);
            });
          },
        }}
        components={{
          consent: ConsentReferrals,
          ShareDrawer: ShareDrawerLoader,
        }}
      />
    </>
  );
}

function mapStateToProps(state) {
  const {
    currentEmployee: {
      addresses: userAddresses,
      provider: { addresses: groupAddresses },
    },
  } = state.globalState;

  const contact = state.contacts?.contacts?.find((contactItem) => contactItem.id === state.selectedContact);

  return {
    currentEmployee: state.globalState.currentEmployee,
    currentProvider: state.globalState.currentProvider?.group,
    enums: getEnumsFromState(state),
    isSupersetEnabled: uup459SupersetPhase2(state),
    crtb1121HideFieldInFormEnabled: crtb1121HideFieldInForm(state),
    crtb1127AuthPaymentProgramsInReferrals: crtb1127AuthPaymentProgramsInReferralsSelector(state),
    crtb1239AdaptDraftReferralFlow: crtb1239AdaptDraftReferralFlowSelector(state),
    crtb1285DisplayProgramEligReferralWorkflow: crtb1285DisplayProgramEligReferralWorkflowSelector(state),
    crtb1299HighRejectionSolution: crtb1299HighRejectionSolutionSelector(state),
    crtb824AddOpenCloseDateOffPlatformCase: crtb824AddOpenCloseDateOffPlatformCaseSelector(state),
    crtb854ForwardingSuperset: crtb854ForwardingSupersetSelector(state),
    hint542SupersetUnlistedPrograms: hint542SupersetUnlistedProgramsSelector(state),
    hint1980ShowSharesMessageBox: hint1980ShowSharesMessageBoxSelector(state),
    initialAddresses: {
      client: contact?.addresses,
      user: userAddresses,
      ours: groupAddresses,
    },
    pays3551AuthInSuperset: pays3551AuthInSupersetSelector(state),
    pays5590RefactorInsuranceFilters: pays5590RefactorInsuranceFiltersSelector(state),
  };
}

// Should match shape of address validator in Core
const addressShape = {
  address_type: PropTypes.string.isRequired,
  line_1: PropTypes.string,
  line_2: PropTypes.string,
  city: PropTypes.string.isRequired,
  county: PropTypes.string,
  state: PropTypes.string.isRequired,
  postal_code: PropTypes.string,
  country: PropTypes.string.isRequired,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  is_primary: PropTypes.bool,
};

Referrals.propTypes = {
  currentEmployee: PropTypes.object.isRequired,
  currentProvider: PropTypes.object.isRequired,
  enums: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  isSupersetEnabled: PropTypes.bool.isRequired,
  crtb1121HideFieldInFormEnabled: PropTypes.bool.isRequired,
  crtb1127AuthPaymentProgramsInReferrals: PropTypes.bool.isRequired,
  crtb1239AdaptDraftReferralFlow: PropTypes.bool.isRequired,
  crtb1285DisplayProgramEligReferralWorkflow: PropTypes.bool.isRequired,
  crtb1299HighRejectionSolution: PropTypes.bool.isRequired,
  crtb824AddOpenCloseDateOffPlatformCase: PropTypes.bool.isRequired,
  crtb854ForwardingSuperset: PropTypes.bool.isRequired,
  hint542SupersetUnlistedPrograms: PropTypes.bool.isRequired,
  hint1980ShowSharesMessageBox: PropTypes.bool.isRequired,
  initialAddresses: PropTypes.shape({
    CLIENT: PropTypes.arrayOf(PropTypes.shape(addressShape)),
    USER: PropTypes.arrayOf(PropTypes.shape(addressShape)),
    GROUP: PropTypes.arrayOf(PropTypes.shape(addressShape)),
  }).isRequired,
  pays3551AuthInSuperset: PropTypes.bool.isRequired,
  pays5590RefactorInsuranceFilters: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(Referrals);
