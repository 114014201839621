import React from 'react';
import PropTypes from 'prop-types';
import { SelectField } from 'components/Backoffice/form/SelectField';
import { useIsNetworkLead, useIsPayer } from 'common/contexts/CurrentProviderContext';
import { useFeatureFlag } from 'common/hooks';
import {
  ACCEPTED,
  AWAITING_TRANSMISSION,
  IN_DISPUTE,
  IN_QUEUE_TO_PAYER,
  INVOICE_STATUS,
  INVOICE_STATUSES,
  NEEDS_ATTENTION,
  PAID,
  REJECTED,
  REJECTED_BY_NETWORK_LEAD,
  REJECTED_BY_PAYER,
  SUBMITTED_TO_NETWORK_LEAD,
  TRANSMITTED_TO_PAYER,
} from '../../constants';

const getStatusLabel = (status, isPayer, isNetworkLead) => {
  switch (status) {
    case INVOICE_STATUS.accepted_by_payer:
      return ACCEPTED;
    case INVOICE_STATUS.paid:
      return PAID;
    case INVOICE_STATUS.rejected_by_cbo_admin:
      return REJECTED;
    case INVOICE_STATUS.rejected_by_network_lead:
      return isNetworkLead ? REJECTED : REJECTED_BY_NETWORK_LEAD;
    case INVOICE_STATUS.rejected_by_payer:
      return isPayer ? REJECTED : REJECTED_BY_PAYER;
    case INVOICE_STATUS.submitted_contracted_service_note:
      return NEEDS_ATTENTION;
    case INVOICE_STATUS.submitted_to_network_lead:
      return isNetworkLead ? NEEDS_ATTENTION : SUBMITTED_TO_NETWORK_LEAD;
    case INVOICE_STATUS.submitted_to_payer:
      return isPayer ? AWAITING_TRANSMISSION : IN_QUEUE_TO_PAYER;
    case INVOICE_STATUS.transmitted_to_payer:
      return isPayer ? NEEDS_ATTENTION : TRANSMITTED_TO_PAYER;
    case INVOICE_STATUS.under_dispute:
      return IN_DISPUTE;
    default: return 'Error';
  }
};

export const InvoiceStatusFilter = ({
  availableStatuses,
  className,
  label,
  labelClassName,
  onChange,
  showDisputed,
  value,
}) => {
  const isPayer = useIsPayer();
  const isNetworkLead = useIsNetworkLead();
  const showInvoiceDisputeWorkflow = useFeatureFlag('useInvoiceDisputeWorkflow');

  const shouldShowUnderDispute = showInvoiceDisputeWorkflow && showDisputed === null;
  const statusArrayCopy = [];
  for (let i = 0; i < availableStatuses.length; i++) {
    if (shouldShowUnderDispute && availableStatuses[i] === INVOICE_STATUS.accepted_by_payer) {
      statusArrayCopy.push(INVOICE_STATUS.under_dispute);
    }
    statusArrayCopy.push(availableStatuses[i]);
  }
  const statusOptions = statusArrayCopy.map((status) => ({
    value: status,
    label: getStatusLabel(status, isPayer, isNetworkLead),
  }));

  return (
    <SelectField
      className={className}
      label={label}
      labelClassName={labelClassName}
      onChange={({ value: val }) => onChange(val)}
      options={statusOptions}
      value={value ?? ''}
      placeholder="Choose Status"
      clearable
    />
  );
};

InvoiceStatusFilter.propTypes = {
  availableStatuses: PropTypes.arrayOf(PropTypes.oneOf(INVOICE_STATUSES)).isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  showDisputed: PropTypes.oneOf([true, false, null]),
  value: PropTypes.oneOf([...INVOICE_STATUSES, '']),
};

InvoiceStatusFilter.defaultProps = {
  className: undefined,
  label: 'Status',
  labelClassName: undefined,
  showDisputed: false,
  value: undefined,
};
