import React from 'react';
import PropTypes from 'prop-types';
import { SelectField } from 'components/Backoffice/form/SelectField';

export const InvoiceTypeFilter = ({
  className,
  invoiceTypes,
  labelClassName,
  onChange,
  value,
}) => (
  <SelectField
    className={className}
    label="Invoice Type"
    labelClassName={labelClassName}
    onChange={({ value: val }) => onChange(val)}
    options={invoiceTypes}
    value={value ?? ''}
    placeholder="Choose an Invoice Type"
    clearable
  />
);

InvoiceTypeFilter.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  labelClassName: PropTypes.string,
  invoiceTypes: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOf(['screening', 'provided_service', '']),
    label: PropTypes.string,
  })),
  value: PropTypes.oneOf(['screening', 'provided_service', '']),
};

InvoiceTypeFilter.defaultProps = {
  className: undefined,
  labelClassName: undefined,
  invoiceTypes: [],
  value: undefined,
};
