import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SelectField } from '@unite-us/ui';
import { isEmpty } from 'lodash';
import { validations } from '@unite-us/app-components';
import { getGroupOptions } from '../utils';

const validateReferralGroupSelect = (groupErrors) => {
  if (isEmpty(groupErrors)) {
    return null;
  }

  return groupErrors;
};

const updateGroupField = (groupField, groupErrors) => {
  if (!isEmpty(groupErrors)) {
    // eslint-disable-next-line no-param-reassign
    groupField.touched = true;
  }
  return groupField;
};

class ReferralGroupSelect extends Component {
  constructor(props) {
    super(props);

    this.onGroupSelect = this.onGroupSelect.bind(this);
  }

  onGroupSelect(selected) {
    const { index } = this.props;
    this.props.onGroupSelect(selected, index);
  }

  render() {
    const {
      allowEmptyGroups,
      canPaginateNetworkGroups,
      groupField,
      hidden,
      originCoordinates,
      index,
      debouncedSearchNetworkGroups,
      selectedGroupIds,
      shouldSort,
      suggestedGroups,
      serviceAreaSupportForOrgsFlag,
      registerField,
      groupErrors,
    } = this.props;

    const placeHolderKey = serviceAreaSupportForOrgsFlag ? 'top' : 'closest';
    return (
      <SelectField
        ref={registerField}
        required
        validations={[
          { func: (value) => (allowEmptyGroups ? null : validations.isRequired(value)) },
          { func: () => validateReferralGroupSelect(groupErrors) },
        ]}
        className="referral-group-select"
        field={updateGroupField(groupField, groupErrors)}
        hidden={hidden}
        hideLabel
        id={`select-field-group-${index}`}
        inline={false}
        label="group-select"
        labelKey="displayName"
        loadOptions={
          canPaginateNetworkGroups ?
            (search) => debouncedSearchNetworkGroups(search, 'in-network') :
            undefined
        }
        onChange={this.onGroupSelect}
        options={getGroupOptions({
          canPaginateNetworkGroups,
          groups: suggestedGroups?.map((x, i) => ({ position: i + 1, ...x })),
          selectedGroupIds,
          originCoordinates,
        })}
        placeholder={canPaginateNetworkGroups ? 'Choose an organization' : ''}
        searchPlaceholderValue={canPaginateNetworkGroups ?
          `Displaying ${placeHolderKey} 50 - Type to search for more` : ''}
        shouldSort={shouldSort}
        valueKey="id"
      />
    );
  }
}

ReferralGroupSelect.propTypes = {
  allowEmptyGroups: PropTypes.bool,
  canPaginateNetworkGroups: PropTypes.bool.isRequired,
  groupField: PropTypes.object.isRequired,
  hidden: PropTypes.bool,
  index: PropTypes.number.isRequired,
  originCoordinates: PropTypes.array.isRequired,
  onGroupSelect: PropTypes.func.isRequired,
  registerField: PropTypes.func.isRequired,
  debouncedSearchNetworkGroups: PropTypes.func.isRequired,
  selectedGroupIds: PropTypes.array,
  groupErrors: PropTypes.array,
  shouldSort: PropTypes.bool,
  suggestedGroups: PropTypes.array.isRequired,
  serviceAreaSupportForOrgsFlag: PropTypes.bool,
};

ReferralGroupSelect.defaultProps = {
  hidden: false,
  selectedGroupIds: [],
  shouldSort: true,
  serviceAreaSupportForOrgsFlag: false,
  groupErrors: [],
  allowEmptyGroups: false,
};

export default ReferralGroupSelect;
