/* eslint-disable */
import React from 'react';
import { withRouter } from 'react-router';
import { messagingApiClient, coreApi } from 'src/api/config';
import { useTwilioClient } from 'src/api/twilioHooks';
import { connect } from 'react-redux';
import * as apiHooks from 'src/api/APIHooks';
import * as standardHooks from 'src/api/standardHooks';
import Notifier from 'common/helpers/Notifier';
import { isMessagingEnabledFlag, isMessagingArchiveEnabledFlag } from 'common/utils/FeatureFlags/flags';
import { UULogoLoader, datadogRum } from '@unite-us/client-utils';
import { ComponentLibraryLoader } from '@unite-us/app-components';
import { isNetworkUser } from '../../../components/User/utils';
import findCurrentGroup from 'common/utils/findCurrentGroup';
import { datadogLogs } from '@datadog/browser-logs';

const DRAFT_MESSAGE_ITEM = 'message-center-textarea';

const AppMessageCenter = ComponentLibraryLoader({
  loadFn: () => import('@unite-us/app-message-center'),
  component: 'MessageCenter',
  loadingApp: 'app-client',
  source: 'app-message-center',
  datadogRum,
});

const Loading = () => <>
  <div className="flex-1 text-center w-full h-full pt-20">
    <UULogoLoader
      height={40}
    />
  </div>
</>;

const saveDraftMessage = (message) => {
  localStorage.setItem(DRAFT_MESSAGE_ITEM, message);
}

const clearDraftMessage = () => {
  localStorage.setItem(DRAFT_MESSAGE_ITEM, '');
}

const MessageCenter = (props) => {
  const { currentEmployeeId, isMessagingEnabled, archiveEnabled, router, currentEmployee, user, currentGroup } = props;
  const { data: twilioClient, isSuccess: twilioClientReady } = useTwilioClient(currentEmployeeId);
    //Disable acces to message-center if employee only has 1 primary role that it is network_user
  const primaryRoles = currentEmployee?.roles?.filter(obj => obj.role_type === "primary");

  if (!isMessagingEnabled || (primaryRoles?.length === 1 && isNetworkUser(user,currentGroup.group.id))) {
    router.push('/');
  }

  const shouldNavigateAway = () => {
    const textArea = document.getElementById(DRAFT_MESSAGE_ITEM)?.value || '';
    if (textArea.length) {
      const ok = confirm("Are you sure you want to leave this page?");
      if (!ok) {
        saveDraftMessage(textArea);
      }
      clearDraftMessage();
      return ok;
    }
    clearDraftMessage();
    return true;
  };

  props.router.setRouteLeaveHook(props.route, shouldNavigateAway)

  window.onbeforeunload = function () {
    const textArea = document.getElementById(DRAFT_MESSAGE_ITEM)?.value || '';
    if (textArea.length) {
      saveDraftMessage(textArea);
      return true;
    }
    clearDraftMessage();
    return null;
  };

  if (!twilioClientReady) return <Loading />;

  datadogLogs.createLogger('app-message-center', {
    level: 'error',
    handler: 'http',
    context: {
      package: 'app-message-center',
    },
  });

  const messageCenterLogger = datadogLogs.getLogger('app-message-center');
  
  return (
    <div className='app-message-center-container'>
      <AppMessageCenter
        source='web-app'
        currentEmployee={currentEmployeeId}
        adapters={{
          apiMessagingService: messagingApiClient,
          twilioClient,
          coreApi,
        }}
        callbacks={{
          messageCenterLogger: (message, context, error) => messageCenterLogger.error(message, context, error),
          toast: {
            success: (message) => Notifier.dispatch(200, message),
            error: (message) => Notifier.dispatch(400, message),
            warn: (message) => Notifier.dispatch(100, message),
          },
        }}
        hooks={{
          ...apiHooks,
          ...standardHooks,
        }}
        constants={{
          TEXT_AREA_ID: DRAFT_MESSAGE_ITEM,
        }}
       options={{
          headerLinksEnabled: true,
          archiveEnabled,
        }}
      />
    </div>
  );
}

function mapStateToProps(state) {
  const isMessagingEnabled = isMessagingEnabledFlag(state);
  const archiveEnabled = isMessagingArchiveEnabledFlag(state);
  return {
    currentGroup: findCurrentGroup(state.user, state.session),
    user: state.user,
    currentEmployee: state.globalState.currentEmployee,
    currentEmployeeId: state.globalState.currentEmployee?.id,
    isMessagingEnabled,
    archiveEnabled,
  };
}

export default connect(mapStateToProps)(withRouter(MessageCenter));
