import { useEffect, useState } from 'react';
import {
  useCurrentPayerId,
  useCurrentProviderId,
  useIsCBOProvider,
  useIsNetworkLead,
  useIsPayer,
} from 'common/contexts/CurrentProviderContext';
import moment from 'moment';

const useInvoiceFilters = ({
  availableStatuses,
  onUpdate,
  showArchived,
  showDisputed,
}) => {
  const isNetworkLead = useIsNetworkLead();
  const isPayer = useIsPayer();
  const isCBO = useIsCBOProvider();
  const currentProviderId = useCurrentProviderId();
  const payerId = useCurrentPayerId();

  const defaultFilterValues = {};

  const [clientName, setClientName] = useState(defaultFilterValues.clientName);
  const [disputeReason, setDisputeReason] = useState(defaultFilterValues.disputeReason);
  const [disputeResolution, setDisputeResolution] = useState(defaultFilterValues.disputeResolution);
  const [feeScheduleProgram, setFeeScheduleProgram] = useState(defaultFilterValues.feeScheduleProgram);
  const [feeScheduleScreening, setFeeScheduleScreening] = useState(defaultFilterValues.feeScheduleScreening);
  const [lastUpdated, setLastUpdated] = useState(defaultFilterValues.lastUpdated);
  const [managedProvider, setManagedProvider] = useState(defaultFilterValues.managedProvider);
  const [payer, setPayer] = useState(defaultFilterValues.payer);
  const [payerProvider, setPayerProvider] = useState(defaultFilterValues.payerProvider);
  const [providedServiceEndsAt, setProvidedServiceEndsAt] = useState(defaultFilterValues.providedServiceEndsAt);
  const [providedServiceStartsAt, setProvidedServiceStartsAt] = useState(defaultFilterValues.providedServiceStartsAt);
  const [shortId, setShortId] = useState(defaultFilterValues.shortId);
  const [status, setStatus] = useState(defaultFilterValues.status);
  const [type, setType] = useState(defaultFilterValues.type);

  const filterValues = {
    clientName,
    disputeReason,
    disputeResolution,
    feeScheduleProgram,
    feeScheduleScreening,
    lastUpdated,
    managedProvider,
    payer,
    payerProvider,
    providedServiceEndsAt,
    providedServiceStartsAt,
    shortId,
    status,
    type,
  };
  const filterValuesArray = Object.values(filterValues);

  const filterSetters = {
    setClientName,
    setDisputeReason,
    setDisputeResolution,
    setFeeScheduleProgram,
    setFeeScheduleScreening,
    setLastUpdated,
    setManagedProvider,
    setPayer,
    setPayerProvider,
    setProvidedServiceEndsAt,
    setProvidedServiceStartsAt,
    setShortId,
    setStatus,
    setType,
  };
  const filterSettersArray = Object.values(filterSetters);

  useEffect(() => {
    onUpdate();
  }, filterValuesArray);

  const isFiltered = filterValuesArray.some((filterValue) => !!filterValue);
  const isFilteredOnFakeUnderDisputeStatus = status === 'under_dispute';

  const resetFilters = () => filterSettersArray.forEach((setter) => setter(undefined));

  const statusWithUndefinedInjectedOnAllPageForNonPayers = showArchived === null && showDisputed === null && !isPayer ?
    undefined :
    availableStatuses;

  const convertDateToUnixString = (value) => (value ? moment.unix(value).toString() : undefined);

  const invoicesQuery = {
    client_name_like: clientName,
    fee_schedule_program: feeScheduleProgram,
    fee_schedule_screening: feeScheduleScreening,
    has_user_archive: showArchived,
    invoice_dispute_reason: disputeReason,
    invoice_dispute_resolution_reason: disputeResolution,
    invoice_status: isFilteredOnFakeUnderDisputeStatus || !status ?
      statusWithUndefinedInjectedOnAllPageForNonPayers?.join(',') :
      status,
    invoice_type: type,
    last_updated: lastUpdated,
    network_lead: isNetworkLead ? currentProviderId : undefined,
    payer: isPayer ? payerId : payer,
    provided_service_ends_at: convertDateToUnixString(providedServiceEndsAt),
    provided_service_starts_at: convertDateToUnixString(providedServiceStartsAt),
    provider: isCBO ? currentProviderId : managedProvider || payerProvider,
    short_id_like: shortId,
    under_dispute: isFilteredOnFakeUnderDisputeStatus || showDisputed,
  };

  if (isCBO) {
    invoicesQuery.under_dispute = isFilteredOnFakeUnderDisputeStatus ||
      (showDisputed === false ? false : undefined);
    invoicesQuery.has_disputed_resolved = showDisputed || undefined;
  }

  return {
    defaultFilterValues,
    filterSetters,
    filterValues,
    invoicesQuery,
    isFiltered,
    resetFilters,
  };
};

export default useInvoiceFilters;
