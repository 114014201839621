import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SearchableField } from 'components/Backoffice/form/SearchableField';
import { useInvoiceShortIdOptions } from '../../hooks';

export const ShortIdFilter = ({
  className,
  invoicesQuery,
  onChange,
  value,
}) => {
  const [shortIdSearchQuery, setShortIdSearchQuery] = useState(value);

  useEffect(() => {
    setShortIdSearchQuery(value);
  }, [value]);

  const shortIdOptions = useInvoiceShortIdOptions(shortIdSearchQuery, invoicesQuery);

  return (
    <SearchableField
      className={className}
      label="Invoice ID"
      labelClassName="tracking-normal"
      onChange={({ value: val }) => onChange(val)}
      searchQuery={shortIdSearchQuery}
      options={shortIdOptions}
      onInputChange={setShortIdSearchQuery}
      placeholder="Search for an invoice"
      truncateOptions={false}
      clearable
    />
  );
};

ShortIdFilter.propTypes = {
  className: PropTypes.string,
  invoicesQuery: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

ShortIdFilter.defaultProps = {
  className: undefined,
  value: undefined,
};
