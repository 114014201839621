import { useFind } from 'src/api/APIHooks';
import { useCurrentProviderId, useIsCBOProvider, useIsNetworkLead } from 'common/contexts/CurrentProviderContext';
import { useCurrentEmployeeId } from 'common/contexts/CurrentEmployeeContext';

const useFindFeeSchedulesByNetworkLeadRelationshipForNonPayers = () => {
  const isNetworkLead = useIsNetworkLead();
  const isCBO = useIsCBOProvider();
  const employeeId = useCurrentEmployeeId();
  const providerId = useCurrentProviderId();

  const query = isNetworkLead ? ({ network_lead: providerId }) : ({ provider: providerId, employee: employeeId });

  const { data } = useFind(
    'network_lead_relationships',
    query,
    {
      page: { size: Infinity },
      queryConfig: {
        enabled: !!providerId && (isNetworkLead || isCBO),
        placeholderData: undefined,
        refetchOnWindowFocus: false,
        staleTime: Infinity,
      },
    },
  );

  return data?.data?.data?.map((nlr) => nlr.fee_schedule.id);
};

export default useFindFeeSchedulesByNetworkLeadRelationshipForNonPayers;
