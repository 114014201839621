import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { get, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import moment from 'moment';
import { feeSchedulePrograms as fspUtils } from '@unite-us/client-utils';
import { dates, validations } from '@unite-us/app-components';
import {
  DurationField,
  InputField,
} from '@unite-us/ui';
import { usePopulate, useFind } from 'api/APIHooks';
import {
  displayNode,
  displayText,
  generateColumn,
} from 'common/display/SingleItemDetails/utils/common';
import { DollarAmount } from 'common/display/Money';
import { LineItem } from 'common/display/LineItem';
import EditButton from 'common/display/Profile/components/EditButton';
import DetailLabelWrapper from 'common/display/SingleItemDetails/DetailLabelWrapper';
import { validateUnitAmount } from 'common/form/Interactions/utils';
import DialogV2 from 'common/modal/DialogV2';
import { Spinner } from 'common/spinners';
import {
  AUTHORIZATION_STATUSES_TO_TEXT,
  AUTHORIZATION_STATUSES_TO_COLOR,
  AUTHORIZATION_STATUSES,
} from 'common/Status/authorizationConstants';
import {
  UTILIZATION_ERROR,
} from 'common/messageConstants';
import unixToISO from 'common/utils/Dates/unixToISO';
import {
  showSCC as showSCCFeatureFlag,
  hasPaymentsUserAccess,
  hasInvoicesAccess,
} from 'common/utils/FeatureFlags/flags';
import dollarsToCents from 'common/utils/Money/dollarsToCents';
import ErrorMessage from 'common/utils/ErrorMessage';
import { isReferralsUserAndAbove } from 'components/User/utils';
import coreErrorHandler from 'components/ServiceAuthorization/coreErrorHandler';
import { useUpdateServiceAuthorization } from 'pages/service-authorizations/hooks/useUpdateServiceAuthorization';
import usePreventWheel from 'common/hooks/usePreventWheel';
import CapInformationSection from './CapInformationSection';
import InsuranceStatusIndicator from './InsuranceStatusIndicator';

export const ReferralServiceAuthorizationInformation = ({
  serviceAuthorization,
  showRequestedValues,
  showSCC,
}) => {
  usePopulate(
    'fee_schedule_program',
    'fee_schedule_program',
    serviceAuthorization,
    { queryConfig: { placeholderData: undefined } },
  );

  usePopulate(
    'clinical_modifications',
    'clinical_modifications',
    serviceAuthorization,
    { queryConfig: { placeholderData: undefined } },
  );
  const { isSuccess: isFeeScheduleLoaded } = usePopulate(
    'fee_schedule_program.fee_schedule',
    'fee_schedule',
    serviceAuthorization,
    { queryConfig: { placeholderData: undefined } },
  );
  const refQueryConfig = { queryConfig: { enabled: showSCC, placeholderData: undefined } };
  const { isSuccess: isInsuranceLoaded } = usePopulate('insurance', 'insurance', serviceAuthorization, refQueryConfig);
  usePopulate('insurance.plan', 'plan', serviceAuthorization, refQueryConfig);

  const editDialogRef = useRef();
  const openDialog = () => {
    if (editDialogRef.current) editDialogRef.current.openDialog();
  };
  const closeDialog = () => {
    if (editDialogRef.current) editDialogRef.current.closeDialog();
  };
  const [isSubmitting, setIsSubmitting] = React.useState();

  const feeScheduleProgram = get(serviceAuthorization, 'fee_schedule_program', {});
  const isUnitBased = !fspUtils.isCostPaymentType(feeScheduleProgram.payment_type);
  const updateServiceAuthorization = useUpdateServiceAuthorization(
    (error) => {
      coreErrorHandler(error, serviceAuthorization?.insurance?.plan?.name);
    },
  );

  const onSubmit = async (formValues, form) => {
    const updateValues = {
      requested_starts_at: unixToISO(formValues.range_date_start),
      requested_ends_at: unixToISO(formValues.range_date_end),
      requested_unit_amount: isUnitBased ? parseInt(formValues.amount_requested, 10) : undefined,
      requested_cents: isUnitBased ? undefined : dollarsToCents(formValues.amount_requested),
    };
    setIsSubmitting(true);
    try {
      await updateServiceAuthorization(
      serviceAuthorization.id,
      updateValues,
      'Authorization request updated successfully.',
    );
    } catch (error) {
      setIsSubmitting(false);
      closeDialog();
    }
    setIsSubmitting(false);
    closeDialog();
    form.reset();
  };

  const status = displayNode({
    label: 'Authorization status',
    node: (
      <div className="flex" style={{ alignItems: 'center' }}>
        <div
          className="mr-1 h-2 w-2 rounded-full"
          style={{
            background: AUTHORIZATION_STATUSES_TO_COLOR[serviceAuthorization.state],
          }}
        />
        {AUTHORIZATION_STATUSES_TO_TEXT[serviceAuthorization.state]}
      </div>
    ),
  });

  const authorizedAmount = (serviceAuthorization.approved_cents ||
    (serviceAuthorization.approved_unit_amount && serviceAuthorization.fee_schedule_program.unit)
  ) ? displayNode({
    label: 'Authorized amount',
    node: serviceAuthorization.approved_cents ?
      <DollarAmount value={serviceAuthorization.approved_cents} convertCents /> :
      `${serviceAuthorization.approved_unit_amount} 
      ${pluralize(serviceAuthorization.fee_schedule_program.unit, serviceAuthorization.approved_unit_amount)}`,
  }) : null;

  const requestedAmount = (serviceAuthorization.requested_cents ||
    (serviceAuthorization.requested_unit_amount && serviceAuthorization.fee_schedule_program.unit)
  ) ? displayNode({
    label: 'Requested amount',
    node: serviceAuthorization.requested_cents ?
      <DollarAmount value={serviceAuthorization.requested_cents} convertCents /> :
      `${serviceAuthorization.requested_unit_amount} 
      ${pluralize(serviceAuthorization.fee_schedule_program.unit, serviceAuthorization.requested_unit_amount)}`,
  }) : null;

  const unit = !serviceAuthorization.requested_cents &&
   serviceAuthorization.fee_schedule_program?.unit ? displayNode({
    label: 'Unit',
    node: (
      <span className="capitalize">
        {serviceAuthorization.fee_schedule_program.unit}
      </span>),
  }) : null;

  const rate = serviceAuthorization.fee_schedule_program?.unit_rate &&
  serviceAuthorization.fee_schedule_program?.unit ? displayNode({
    label: 'Rate',
    node: (
      <>
        <DollarAmount value={serviceAuthorization.fee_schedule_program?.unit_rate} convertCents />
        {' per '}{serviceAuthorization.fee_schedule_program?.unit}
      </>),
  }) : null;

  const cap = serviceAuthorization.fee_schedule_program?.cap_information ?
  displayNode({
    label: 'Program Cap',
    node: (
      // eslint-disable-next-line react/no-danger
      <div dangerouslySetInnerHTML={{ __html: serviceAuthorization.fee_schedule_program?.cap_information }} />
    ),
  }) : null;

  const authorizedDates = (serviceAuthorization.approved_starts_at || serviceAuthorization.approved_ends_at) ?
  displayNode({
    label: 'Authorized service delivery date(s)',
    node: serviceAuthorization.approved_starts_at && serviceAuthorization.approved_ends_at &&
    serviceAuthorization.approved_starts_at !== serviceAuthorization.approved_ends_at ?
    `${dates.formatDate(serviceAuthorization.approved_starts_at)} - ${
      dates.formatDate(serviceAuthorization.approved_ends_at)}` :
    `${dates.formatDate(serviceAuthorization.approved_starts_at || serviceAuthorization.approved_ends_at)}`,
  }) : null;

  const requestedDates = (serviceAuthorization.requested_starts_at || serviceAuthorization.requested_ends_at) ?
  displayNode({
    label: 'Requested service delivery date(s)',
    node: serviceAuthorization.requested_starts_at && serviceAuthorization.requested_ends_at &&
    serviceAuthorization.requested_starts_at !== serviceAuthorization.requested_ends_at ?
    `${dates.formatDate(serviceAuthorization.requested_starts_at)} - ${
      dates.formatDate(serviceAuthorization.requested_ends_at)}` :
    `${dates.formatDate(serviceAuthorization.requested_starts_at || serviceAuthorization.requested_ends_at)}`,
  }) : null;

  const authorizationId = serviceAuthorization.short_id ? displayText(serviceAuthorization, {
    label: 'Unite Us authorization id',
    key: 'short_id',
  }) : null;

  const payerAuthorizationNumber = serviceAuthorization.payer_authorization_number ?
  displayText(serviceAuthorization, {
    label: 'Payer authorization id',
    key: 'payer_authorization_number',
  }) : null;

  const socialCareCoveragePlan = showSCC && serviceAuthorization.insurance?.plan ? displayNode({
    label: 'Social care coverage plan',
    node: (
      <>
        {serviceAuthorization.insurance.plan.name}
        <br />
        {dates.formatDate(serviceAuthorization.insurance.enrolled_at)}{' - '}
        {serviceAuthorization.insurance?.expired_at ?
           dates.formatDate(serviceAuthorization.insurance.expired_at) : 'present'}
      </>),
  }) : null;

  const insuranceStatus = serviceAuthorization.insurance?.insurance_status;
  const ignoreExpiration = !!serviceAuthorization.fee_schedule_program?.fee_schedule?.ignore_social_care_expired_dates;
  const socialCareCoverageStatus = showSCC && isInsuranceLoaded && isFeeScheduleLoaded ? displayNode({
    label: 'Social care coverage status',
    node: (
      <InsuranceStatusIndicator
        ignoreExpiration={ignoreExpiration}
        showTooltip
        status={insuranceStatus}
      />
    ),
  }) : null;

  const rejectionReason = serviceAuthorization.service_authorization_denial_reason?.display_name ?
  displayText(serviceAuthorization, {
    label: 'Authorization rejection reason',
    key: 'service_authorization_denial_reason.display_name',
  }) : null;

  const cmCodeRequired = feeScheduleProgram?.cm_code_required_on_authorization;

  const clinicalModifications = cmCodeRequired ? {
    label: 'ICD-10 Code',
    value: serviceAuthorization.clinical_modifications?.map((cm) => cm.code).join(','),
    clickable: false,
  } : null;

  const isAccepted = serviceAuthorization.state === AUTHORIZATION_STATUSES.accepted;
  const isDraft = serviceAuthorization.state === AUTHORIZATION_STATUSES.draft;
  const hasServiceDeliveryDatesSelected = !!(
    serviceAuthorization.requested_starts_at &&
    serviceAuthorization.requested_ends_at
  );

  const reviewerNoteLabel = isAccepted ? 'Notes' : 'Rejection note';
  const reviewerNote = serviceAuthorization.reviewer_note ?
    displayText(serviceAuthorization, {
      label: reviewerNoteLabel,
      key: 'reviewer_note',
    }) : null;

  const firstColumn = generateColumn([
    status,
    authorizedAmount,
    authorizedDates,
    rejectionReason,
    ...(showRequestedValues ?
      [authorizationId, payerAuthorizationNumber, socialCareCoveragePlan, socialCareCoverageStatus] :
      [unit, rate, cap, clinicalModifications]
    ),
    reviewerNote,
  ]);

  const secondColumn = generateColumn(
    showRequestedValues ?
    [requestedAmount, requestedDates, clinicalModifications, unit, rate, cap] :
    [authorizationId, payerAuthorizationNumber, socialCareCoveragePlan, socialCareCoverageStatus],
  );

  const FORM_ERRORS = {
    AMOUNT_EXCEEDED: 'amount_exceeded',
  };

  const [availableAmount, setAvailableAmount] = React.useState();

  const validateAmountVsCap = (values) => {
    const start = moment.unix(values.range_date_start);
    const end = moment.unix(values.range_date_end);
    const hasCap = start && end && feeScheduleProgram.has_cap;
    const totalCap = isUnitBased ? availableAmount : availableAmount / 100;
    const validation = hasCap && values.amount_requested > totalCap;
    if (validation) {
      return { [FORM_ERROR]: FORM_ERRORS.AMOUNT_EXCEEDED };
    }
    return {};
  };

  const filters = {
    fee_schedule_program: serviceAuthorization?.fee_schedule_program?.id,
    person: serviceAuthorization?.person?.id,
    requested_starts_at: serviceAuthorization?.requested_starts_at,
    requested_ends_at: serviceAuthorization?.requested_ends_at,
  };

  const {
    isFetching,
    isLoading,
    data: authorizedSpendsData,
  } = useFind(
    'authorized_spend',
    filters,
    {
      queryConfig: {
        enabled: !isEmpty(serviceAuthorization) && isDraft,
        placeholderData: undefined,
      },
    },
  );
  const authorizationSpendsErrors = get(authorizedSpendsData, 'data.meta.errors', []);
  // PAYS-5235 TODO use error types from backend
  const showClientUtilizationError = authorizationSpendsErrors.some((e) => e.title === UTILIZATION_ERROR);

  const costValidations = (value) => (
    validations.isRequired(value) ||
    validations.isLessThan(value, 'Should be less than $1,000,000', 1_000_000)
  );

  usePreventWheel('edit-service-authorization-amount-requested');

  if (showRequestedValues || isAccepted) {
    return (
      <div className="relative">
        <DetailLabelWrapper data={[firstColumn, secondColumn]} />
        {isDraft && (
          <>
            <div className="absolute top-0 right-0">
              <EditButton
                edit={openDialog}
                id="edit-service-authorization-button"
              />
            </div>
            <Form
              initialValues={{
                amount_requested: isUnitBased ?
                  serviceAuthorization.requested_unit_amount :
                  serviceAuthorization.requested_cents / 100,
                range_date_start: Date.parse(serviceAuthorization.requested_starts_at) / 1000,
                range_date_end: Date.parse(serviceAuthorization.requested_ends_at) / 1000,
              }}
              validate={validateAmountVsCap}
              onSubmit={onSubmit}
              render={({
                handleSubmit,
                valid,
                values,
                error,
              }) => (
                <DialogV2
                  cancelHandler={closeDialog}
                  confirmationBtnDisabled={!valid || isSubmitting || showClientUtilizationError}
                  confirmationHandler={handleSubmit}
                  confirmLabel="Save"
                  dialogDescriptionClass=""
                  ref={editDialogRef}
                  title="Edit Authorization Request"
                  width="3xl"
                >
                  {isSubmitting || isFetching || isLoading ? (
                    <Spinner />
                  ) : (
                    <>
                      <dl className="m-0 grid grid-cols-2 gap-2">
                        <LineItem dataTestElement="edit-modal-contracted-program" field="Contracted Program">
                          {feeScheduleProgram.name}
                        </LineItem>
                        <LineItem dataTestElement="edit-modal-payment-approach" field="Payment Approach">
                          {fspUtils.PAYMENT_TYPES[feeScheduleProgram.payment_type]}
                        </LineItem>
                        {isUnitBased && (
                          <>
                            <LineItem dataTestElement="edit-modal-unit" field="Unit">
                              <span className="capitalize">{feeScheduleProgram.unit}</span>
                            </LineItem>
                            <LineItem dataTestElement="edit-modal-rate" field="Rate">
                              <DollarAmount value={feeScheduleProgram.unit_rate} convertCents />
                              {' '}per{' '}
                              {feeScheduleProgram.unit}
                            </LineItem>
                          </>
                        )}
                        {feeScheduleProgram.cap_information && (
                          <LineItem dataTestElement="edit-modal-cap" field="Program Cap">
                            {/* eslint-disable-next-line react/no-danger */}
                            <div dangerouslySetInnerHTML={{ __html: feeScheduleProgram.cap_information }} />
                          </LineItem>
                        )}
                      </dl>
                      <hr className="my-4" />
                      <div className="grid grid-cols-2 gap-2">
                        <div className="mr-8">
                          {isUnitBased ? (
                            <Field
                              name="amount_requested"
                              validate={validateUnitAmount}
                            >
                              {(props) => (
                                <InputField
                                  {...props}
                                  id="edit-service-authorization-amount-requested"
                                  label="Amount Requested"
                                  required
                                  type="number"
                                />
                              )}
                            </Field>
                          ) : (
                            <Field
                              name="amount_requested"
                              validate={costValidations}
                            >
                              {(props) => (
                                <InputField
                                  {...props}
                                  id="edit-service-authorization-amount-requested"
                                  label="Amount Requested"
                                  placeholder="0.00"
                                  required
                                  step="0.1"
                                  type="number"
                                />
                              )}
                            </Field>
                          )}
                        </div>
                        <div className="ml-10">
                          <label className="block" htmlFor="edit-service-authorization-unit-info">Unit</label>
                          <p className="capitalize leading-10" id="edit-service-authorization-unit-info">
                            {get(serviceAuthorization, 'fee_schedule_program.unit', '') || 'Dollars'}
                          </p>
                        </div>
                        <Field name="range_date_start" validate={(value) => validations.isRequired(value)}>
                          {(startProps) => (
                            <Field name="range_date_end" validate={(value) => validations.isRequired(value)}>
                              {(endProps) => (
                                <>
                                  <DurationField
                                    id="duration_field"
                                    isUTC
                                    label="SERVICE DELIVERY DATES"
                                    startField={startProps}
                                    endField={endProps}
                                    required
                                  />
                                </>
                              )}
                            </Field>
                          )}
                        </Field>
                      </div>
                      {showClientUtilizationError && (
                        <div className="col-span-2 px-0 flex space-x-2 -mt-0.5 mb-0">
                          <ErrorMessage>
                            This passthrough referral cannot be sent because the client has already used this service.
                            Please select a different program.
                          </ErrorMessage>
                        </div>
                      )}
                      {error === FORM_ERRORS.AMOUNT_EXCEEDED && (
                        <div className="flex mb-6">
                          <ErrorMessage>
                            The amount requested is more than the amount available for authorization.
                            Please adjust the amount requested, or search
                            for another program to meet the client’s needs.
                          </ErrorMessage>
                        </div>
                      )}
                      {!showClientUtilizationError && hasServiceDeliveryDatesSelected && (
                        <>
                          <hr className="mt-3 col-span-2" />
                          <p className="my-6">
                            This section shows the amount available for authorization,
                            and related information based on the selected service delivery dates.
                          </p>
                          <CapInformationSection
                            feeScheduleProgram={feeScheduleProgram}
                            startDate={moment.unix(values.range_date_start)}
                            endDate={moment.unix(values.range_date_end)}
                            personId={serviceAuthorization.person.id}
                            setAvailableAmount={setAvailableAmount}
                          />
                        </>
                      )}
                    </>
                  )}
                </DialogV2>
              )}
            />
          </>
        )}
      </div>
    );
  }
  return null;
};

ReferralServiceAuthorizationInformation.propTypes = {
  serviceAuthorization: PropTypes.object.isRequired,
  showRequestedValues: PropTypes.bool,
  showSCC: PropTypes.bool.isRequired,
};

ReferralServiceAuthorizationInformation.defaultProps = {
  showRequestedValues: false,
};

function mapStateToProps(state) {
  const user = state.user;
  const groupId = get(state, 'session.groupId');

  const isReferralsUser = isReferralsUserAndAbove(user, groupId);
  return {
    showSCC: (hasInvoicesAccess(state) ||
    (hasPaymentsUserAccess(state) && isReferralsUser)) && showSCCFeatureFlag(state),
  };
}

export default connect(mapStateToProps)(ReferralServiceAuthorizationInformation);
