import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import { SelectField } from '@unite-us/ui';
import {
  showProgramStatusToggle,
  hasPaymentsUserAccess,
  includePathwaysServices,
  pays6694RemoveContextPerson,
} from 'common/utils/FeatureFlags/flags';
import callOrLog from 'common/utils/callOrLog';
import { REFERRAL } from 'common/utils/EventTracker/utils/eventConstants';
import { Serializer } from '@unite-us/client-utils';
import { validations } from '@unite-us/app-components';
import { fetchCorePrograms, fetchCoreFeeScheduleProgram } from 'src/actions/Group/Network';

const programButtonClass = (programField = {}, programs = []) => classNames({
  'referral-program-select__program-wrapper': true,
  'referral-program-select__program-wrapper--empty': _.isEmpty(programs),
  hide: !_.isEmpty(programField.value),
  'fade-in': _.isEmpty(programField.value),
});

const programSelectClass = (programField = {}) => classNames({
  'referral-program__select-field': true,
  hide: _.isEmpty(programField.value),
  'fade-in': !_.isEmpty(programField.value),
});

class ReferralProgramSelect extends Component {
  static async populateFeeScheduleProgram(program) {
    const fspId = _.get(program, 'relationships.fee_schedule_program.data.id', '');
    const fspData = await fetchCoreFeeScheduleProgram(fspId);
    const { relationships, ...restProgram } = program;
    const augmentedProgram = {
      ...restProgram,
      relationships: {
        ...relationships,
        fee_schedule_program: {
          ...relationships.fee_schedule_program,
          data: {
            ...relationships.fee_schedule_program.data,
            authorization_required: fspData?.attributes?.authorization_required || false,
          },
        },
      },
    };
    return augmentedProgram;
  }

  constructor(props) {
    super(props);

    this.fetchProgramOptions = this.fetchProgramOptions.bind(this);
    this.initProgramField = this.initProgramField.bind(this);
    this.onTrackProgram = this.onTrackProgram.bind(this);
    this.onProgramSelect = this.onProgramSelect.bind(this);

    this.state = {
      programs: [],
    };
  }

  componentDidMount() {
    this.fetchProgramOptions();
  }

  onProgramSelect(program) {
    const { index } = this.props;
    this.onTrackProgram(program);
    this.props.onProgramSelect(program, index);
  }

  onTrackProgram(program) {
    const { contact } = this.props;
    if (program) {
      const eventPayload = Serializer.build({ contact });
      callOrLog(() => this.context.eventTracker(REFERRAL.programSelected, { contact: eventPayload }));
    }
  }

  async fetchProgramOptions() {
    const {
      contact,
      employeeId,
      selectedGroupId,
      serviceType,
      useReferralToggle: receiving_referrals,
      usePaymentsUserRole,
      excludeAuthorizationRequired,
      includePathways,
      feeScheduleProgramId,
      pays6694RemoveContextPersonFlag,
    } = this.props;

    const eventPayload = Serializer.build({ contact });

    const referable = { referable: { person: contact.id, employee: employeeId } };

    const filterByFspId = feeScheduleProgramId ? { feeScheduleProgramId } : {};

    const programs = await fetchCorePrograms({
      ...(pays6694RemoveContextPersonFlag ? {} : { clientId: contact.id }), // remove PAYS-7131
      provider: selectedGroupId,
      receiving_referrals,
      services: serviceType.id,
      usePaymentsUserRole,
      excludeAuthorizationRequired,
      includePathways,
      ...referable,
      ...filterByFspId,
    });

    if (usePaymentsUserRole) {
      const augmentedProgramPromises = programs.map(async (program) => {
        if (_.get(program, 'relationships.fee_schedule_program.data')) {
          const augmentedProgram = await ReferralProgramSelect.populateFeeScheduleProgram(program);
          return {
            ...augmentedProgram,
            attributes: {
              ...augmentedProgram.attributes,
              name: `${augmentedProgram.attributes.name} (Contracted Program)`,
            },
          };
        }
        return program;
      });

      const augmentedPrograms = await Promise.all(augmentedProgramPromises);
      this.setState({ programs: augmentedPrograms });
    } else {
      this.setState({ programs });
    }

    callOrLog(() => this.context.eventTracker(REFERRAL.selectProgram, { contact: eventPayload }));
  }

  /**
   * Attach a blank id to programField to expose the input
  */
  initProgramField() {
    const { programField } = this.props;

    programField.onChange({
      id: '',
    });
  }

  render() {
    const {
      programField,
      index,
      feeScheduleProgramId,
      registerField,
    } = this.props;

    const { programs } = this.state;
    const autoSelect = !!feeScheduleProgramId;
    return (
      <div className="referral-program-select">
        <div className={programButtonClass(programField, programs)}>
          {
            _.isEmpty(programs) ? <div /> : (
              <a
                className={
                  classNames(
                    'mt-one',
                    `referral-program-selected__add-program-${index}`,
                    { 'text-red': programField.touched && !programField.valid },
                  )
                }
                role="button"
                onClick={this.initProgramField}
                tabIndex="0"
              >
                Select Program
              </a>
            )
          }
        </div>
        <SelectField
          id={`select-field-program-${index}`}
          className={programSelectClass(programField)}
          ref={registerField}
          label="program-select"
          hideLabel
          labelKey="attributes.name"
          valueKey="id"
          onChange={this.onProgramSelect}
          options={autoSelect && programs ? programs.slice(0, 1) : programs}
          autoSelectValue={autoSelect}
          field={programField}
          required
          searchResultLimit={50}
          validations={[{ func: (value) => validations.isRequired(value?.id) }]}
        />
      </div>
    );
  }
}

ReferralProgramSelect.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

ReferralProgramSelect.propTypes = {
  contact: PropTypes.object,
  employeeId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  programField: PropTypes.object.isRequired,
  registerField: PropTypes.func.isRequired,
  selectedGroupId: PropTypes.string.isRequired,
  serviceType: PropTypes.object.isRequired,
  usePaymentsUserRole: PropTypes.bool.isRequired,
  useReferralToggle: PropTypes.bool.isRequired,
  excludeAuthorizationRequired: PropTypes.bool,
  includePathways: PropTypes.bool.isRequired,
  onProgramSelect: PropTypes.func.isRequired,
  feeScheduleProgramId: PropTypes.string,
  pays6694RemoveContextPersonFlag: PropTypes.bool.isRequired,
};

ReferralProgramSelect.defaultProps = {
  contact: {},
  feeScheduleProgramId: null,
  excludeAuthorizationRequired: false,
};

const mapStateToProps = (state) => {
  const usePaymentsUserRole = hasPaymentsUserAccess(state);
  const useReferralToggle = showProgramStatusToggle(state);
  const employeeId = _.get(state, 'globalState.currentEmployee.id');
  const includePathways = includePathwaysServices(state);
  const pays6694RemoveContextPersonFlag = pays6694RemoveContextPerson(state);

  return {
    employeeId,
    usePaymentsUserRole,
    useReferralToggle,
    includePathways,
    pays6694RemoveContextPersonFlag,
  };
};

export default connect(mapStateToProps)(ReferralProgramSelect);
