import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { dates } from '@unite-us/app-components';
import PhoneNumberDisplay from 'common/display/PhoneNumberDisplay';
import RowActionsMenu from './RowActionsMenu';
import { isLockedRow, formatLocalTableDate } from '../utils';

const DynamicTableRowColumnData = ({
  columnLabel,
  data,
  initClick,
  link,
  style,
}) => {
  if (_.isFunction(columnLabel.cannotView) && columnLabel.cannotView(data)) {
    return null;
  }

  if (_.isFunction(columnLabel.formatData)) {
    return (
      <span className={columnLabel.className} style={style}>
        {columnLabel.formatData(data)}
      </span>
    );
  }

  const value = _.get(data, columnLabel.sortColumn);

  // @todo Is there a better way to render data in the columns?
  // Maybe we could at least pass a formatter function/node through that renders custom data.
  switch (columnLabel.type) {
    case 'date':
      return (
        <span className={columnLabel.className} style={style}>
          {formatLocalTableDate(value || '')}
        </span>
      );
    case 'dateOfBirth':
      return <span className={columnLabel.className} style={style}>{dates.formatDate(value)}</span>;
    case 'phoneNumber':
      return (
        <PhoneNumberDisplay
          hidePrimaryDisplay
          item={value || {}}
          shortDisplay
          noLink
        />
      );

    case 'takeAction': {
      const actions = _.get(columnLabel, 'actions', []);
      return !_.isEmpty(actions) ?
        <RowActionsMenu actions={actions} data={data} /> :
        null;
    }

    case 'download': {
      const href = _.get(data, columnLabel.href);
      return (
        <a
          download
          href={href}
          onClick={initClick}
          role="button"
          style={style}
          className={columnLabel.className}
          target="_blank"
          rel="noreferrer noopener"
        >
          {value}
        </a>
      );
    }

    default: {
      const permissions = _.get(data, 'permissions');
      if (link && !isLockedRow(permissions)) {
        return (
          <a
            onClick={initClick}
            role="button"
            style={style}
            className={columnLabel.className}
          >
            {value}
          </a>
        );
      }
      return <span className={columnLabel.className} style={style}>{value}</span>;
    }
  }
};

DynamicTableRowColumnData.propTypes = {
  columnLabel: PropTypes.shape({
    cannotView: PropTypes.func,
    formatData: PropTypes.func,
    href: PropTypes.string,
    type: PropTypes.string,
    sortColumn: PropTypes.string,
    className: PropTypes.string,
  }).isRequired,
  data: PropTypes.object.isRequired,
  initClick: PropTypes.func,
  link: PropTypes.bool,
  style: PropTypes.object,
};

DynamicTableRowColumnData.defaultProps = {
  initClick: _.noop,
  link: false,
  style: {},
};

export default DynamicTableRowColumnData;
