/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Field } from 'react-final-form';
import { validations } from '@unite-us/client-utils';
import { PropTypes } from 'prop-types';
import SearchableSelectField from '../../../components/Backoffice/form/SearchableSelectField';
import useClinicalModificationOptions from '../hooks/useClinicalModificationsOptions';

export const ClinicalModificationsDropdown = ({ initialValue }) => {
  const [codeQuery, setCodeQuery] = useState(initialValue?.label || '');
  const [dropdownLabel, setDropdownLabel] = useState(initialValue?.label);

  const clinicalModificationCodes = useClinicalModificationOptions(codeQuery);

  const displayOptions = clinicalModificationCodes.map((cm) => ({
    value: cm.code,
    label: cm.code,
    id: cm.id,
  }));

  return (
    <Field
      name="clinical_modification_code"
      validate={(value) => validations.isRequired(value)}
    >
      {(props) => {
        // Set initial value in react final form
        if (dropdownLabel && !props.input.value) {
          props.input.onChange(initialValue);
        }

        const onSelectionChange = (newValue) => {
          props.input.onChange(newValue);
          setCodeQuery(newValue.label);
          setDropdownLabel(newValue.label);
        };

        const onInputChange = (inputText) => {
          setCodeQuery(inputText);
          props.input.onChange('');
        };
        const hasError = props.meta.invalid && (props.meta.visited || props.meta.modified || props.meta.touched);

        return (
          <div className="mb-0 ml-0 row-start-4" data-testid="clinical-modifications-dropdown">
            <SearchableSelectField
              clearable
              errorMsg={props.meta.error}
              hasError={hasError}
              inputValue={codeQuery}
              label="ICD-10 CODE"
              labelClassName={hasError ? 'text-red' : 'text-blue'}
              onInputChange={onInputChange}
              options={displayOptions}
              onChange={onSelectionChange}
              placeholder="Select an ICD-10 code"
              required
              value={dropdownLabel}
            />
          </div>
        );
      }}
    </Field>
  );
};

ClinicalModificationsDropdown.propTypes = {
  initialValue: PropTypes.object.isRequired,
};
