import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'common/spinners';
import {
  useFindProvidersByNameAndNetwork,
} from 'src/components/Backoffice/api/hooks/v1/providerHooks';
import { DEFAULT_PAGE_SIZE } from 'src/components/Backoffice/backofficeUtils';
import DynamicSearchTable from './DynamicSearchTable';

const ProviderOrganizationSearchTable = ({
  selectedOrganizationId,
  setSelectedOrganizationId,
  isMultiSelect,
  networkId,
}) => {
  const activeStateFilter = 'active,draft';
  const licensedFilter = true;
  const [providerSearch, setProviderSearch] = useState('');
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [pageNumber, setPageNumber] = useState(1);
  const { data: providersResponse, isLoading } = useFindProvidersByNameAndNetwork(
    providerSearch,
    networkId,
    activeStateFilter,
    licensedFilter,
    {
      page: {
        size: pageSize,
        number: pageNumber,
      },
    },
  );
  const { data: providers, paging = { total_count: 0, total_pages: 0 } } = providersResponse?.data || {};

  useEffect(() => {
    setPageNumber(1);
  }, [providerSearch]);

  const generateDataForTable = () => {
    const data = [];
    if (providers) {
      providers.forEach((provider) => {
        data.push({
          id: provider.id,
          data: [provider.name, provider.id],
        });
      });
    }
    return data;
  };

  if (isLoading) return <Spinner />;

  return (
    <>
      <DynamicSearchTable
        searchResults={generateDataForTable()}
        rowHeaders={['Name', 'Organization ID']}
        selectedRowIds={selectedOrganizationId}
        setSelectedRowIds={setSelectedOrganizationId}
        setSearch={setProviderSearch}
        emptyTableMessage="No Results Found. Enter Organization to view users."
        searchBarPlaceHolder="Enter the Organization Name..."
        searchTitle="Organizations"
        dataTestId="organization-search"
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        paging={paging}
        isMultiSelect={isMultiSelect}
        scrollable
      />
    </>
  );
};

ProviderOrganizationSearchTable.propTypes = {
  selectedOrganizationId: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]).isRequired,
  setSelectedOrganizationId: PropTypes.func.isRequired,
  isMultiSelect: PropTypes.bool,
  networkId: PropTypes.string.isRequired,
};

ProviderOrganizationSearchTable.defaultProps = {
  isMultiSelect: false,
};

export default ProviderOrganizationSearchTable;
