import React from 'react';
import PropTypes from 'prop-types';
import { NoInvoicesFoundCard } from '../components';
import { CLOSED_NL_STATUSES, CLOSED_PAYER_STATUSES } from '../constants';
import { InvoiceWorkqueue } from './InvoiceWorkqueue';

const ClosedInvoices = ({ children }) => {
  const bulkActions = ['IconDownloadV2', 'IconArchive'];

  return (
    <InvoiceWorkqueue
      bulkActions={bulkActions}
      networkLeadStatuses={CLOSED_NL_STATUSES}
      pageName="Closed"
      path="closed/"
      payerStatuses={CLOSED_PAYER_STATUSES}
      renderNoInvoicesFound={({ isFiltered, resetFilters }) => (
        <NoInvoicesFoundCard isFiltered={isFiltered} status="closed" resetFilters={resetFilters} />
      )}
    >
      {children}
    </InvoiceWorkqueue>
  );
};

ClosedInvoices.propTypes = {
  children: PropTypes.node,
};

ClosedInvoices.defaultProps = {
  children: undefined,
};

export default ClosedInvoices;
