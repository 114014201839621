import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'src/common/utils/browserHistory';
import { ContactScreeningDetailsView as ContactDetailsView } from '@unite-us/app-screenings';
import { connect } from 'react-redux';
import { BackButton } from 'common/buttons';
import Stepper from 'common/Stepper/Stepper';
import addNotification from 'common/utils/Notifications/actions/AddNotification';
import callOrLog from 'src/common/utils/callOrLog';
import Notifier from 'common/helpers/Notifier';
import { getAuthToken } from 'src/api/config';
import { CORE_API } from 'src/config/env/env.config';
import {
  useCreateRecord,
  useFind,
  useFindRecord,
  useInvalidateQueries,
} from 'src/api/APIHooks';
import { ASSESSMENTS } from 'common/utils/EventTracker/utils/eventConstants';
import {
  scrn7ScreeningsCompletion,
  scrn468ScreeningTimeTracking,
} from 'common/utils/FeatureFlags/flags';

function ContactScreeningDetailsView({
  params,
  providerId,
  currentEmployee,
  scrn7ScreeningsCompletionEnabled,
  scrn468ScreeningTimeTrackingEnabled,
}) {
  return (
    <ContactDetailsView
      source="web_app"
      appState={{
        template_id: params.assessment_id,
        id: params.id,
        providerId,
        currentEmployee,
        getAuthToken,
      }}
      appSettings={{
        env: {
          getAuthToken,
          coreUrl: CORE_API,
          employeeId: currentEmployee?.id,
          providerId,
        },
      }}
      callbacks={{
        notify: {
          error: (message) => Notifier.dispatch('error', message),
          success: (message) => Notifier.dispatch('success', message),
          warn: (message) => Notifier.dispatch('warning', message),
        },
        goBack: () => {
          browserHistory.goBack();
        },
        replace: (path) => {
          browserHistory.replace(path);
        },
        pushInHistory: (path) => {
          browserHistory.push(path);
        },
      }}
      utils={{ callOrLog, addNotification }}
      constants={{ ASSESSMENTS }}
      components={{ BackButton, Stepper }}
      flags={{
        scrn7ScreeningsCompletionEnabled,
        scrn468ScreeningTimeTrackingEnabled,
      }}
      hooks={{
        useFindRecord,
        useFind,
        useInvalidateQueries,
        useCreateRecord,
      }}
    />
  );
}

const mapStateToProps = (state) => ({
  providerId: state.session.groupId,
  currentEmployee: state.globalState.currentEmployee,
  scrn7ScreeningsCompletionEnabled: scrn7ScreeningsCompletion(state),
  scrn468ScreeningTimeTrackingEnabled: scrn468ScreeningTimeTracking(state),
});

ContactScreeningDetailsView.propTypes = {
  params: PropTypes.object,
  providerId: PropTypes.string.isRequired,
  currentEmployee: PropTypes.object.isRequired,
  scrn7ScreeningsCompletionEnabled: PropTypes.bool.isRequired,
  scrn468ScreeningTimeTrackingEnabled: PropTypes.bool.isRequired,
};

ContactScreeningDetailsView.defaultProps = {
  params: {},
};
export default connect(mapStateToProps)(ContactScreeningDetailsView);
