import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { hasPayerInvoicesRole as getHasPayerInvoicesRole } from 'common/utils/FeatureFlags/flags';
import { NoInvoicesFoundCard } from '../components';
import { OPEN_NL_STATUSES, OPEN_PAYER_STATUSES } from '../constants';
import { InvoiceWorkqueue } from './InvoiceWorkqueue';

const OpenInvoices = ({ children, hasPayerInvoicesRole }) => {
  let bulkActions;
  if (hasPayerInvoicesRole) {
    bulkActions = ['LogoShield', 'IconCheckCircle', 'IconCrossCircleV2', 'IconDownloadV2'];
  } else {
    bulkActions = ['IconDownloadV2'];
  }

  return (
    <InvoiceWorkqueue
      bulkActions={bulkActions}
      networkLeadStatuses={OPEN_NL_STATUSES}
      pageName="Open"
      path="open/"
      payerStatuses={OPEN_PAYER_STATUSES}
      renderNoInvoicesFound={({ isFiltered, resetFilters }) => (
        <NoInvoicesFoundCard isFiltered={isFiltered} status="open" resetFilters={resetFilters} />
      )}
      showInvoiceAging
    >
      {children}
    </InvoiceWorkqueue>
  );
};

OpenInvoices.propTypes = {
  children: PropTypes.node,
  hasPayerInvoicesRole: PropTypes.bool,
};

OpenInvoices.defaultProps = {
  children: undefined,
  hasPayerInvoicesRole: false,
};

const mapStateToProps = (state) => ({
  hasPayerInvoicesRole: getHasPayerInvoicesRole(state),
});

export default connect(mapStateToProps)(OpenInvoices);
