import { useFind } from 'src/api/APIHooks';
import { groupBy, map } from 'lodash';

const useInvoiceShortIdOptions = (
  shortIdLike,
  invoiceFilters,
) => {
  const { data } = useFind(
    'invoices',
    {
      ...invoiceFilters,
      short_id_like: shortIdLike,
    },
    {
      queryConfig: { enabled: !!shortIdLike, placeholderData: undefined },
    },
  );

  const invoices = groupBy(data?.data?.data ?? [], 'short_id');
  const invoiceShortIdOptions = map(invoices, (val, key) => ({
    label: key,
    value: map(val, 'short_id').join(','),
  }));

  return invoiceShortIdOptions.slice(0, 30);
};

export default useInvoiceShortIdOptions;
