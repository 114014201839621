import { screeningsApiClient } from 'src/api/config';
import Notifier from 'common/helpers/Notifier';
import { get } from 'lodash';

const getOffset = (pageNumber) => (pageNumber - 1) * 25;
const defaultOptions = {
  page: 1,
  type: 'screening',
  offset: getOffset(1),
  limit: 25,
};

async function fetchScreeningsTemplates(options = defaultOptions) {
  try {
    const queryParams = {
      type: 'screening',
      limit: options.limit,
      offset: options.offset,
    };
    const response = await screeningsApiClient.get('/templates', {
      params: queryParams,
    });

    return get(response, 'data.templates', []);
  } catch (error) {
    Notifier.handleErrors(error);
    return error;
  }
}

export default fetchScreeningsTemplates;
