import { useCurrentPayerId, useIsPayer } from 'common/contexts/CurrentProviderContext';
import { useFind } from '../../../api/APIHooks';

const useFindFeeSchedulesByPlanForPayers = () => {
  const isPayer = useIsPayer();
  const payerId = useCurrentPayerId();
  const { data } = useFind(
    'plan',
    { payer: payerId },
    {
      queryConfig: {
        enabled: !!payerId && isPayer,
        placeholderData: undefined,
        refetchOnWindowFocus: false,
        staleTime: Infinity,
      },
    },
  );

  return data?.data?.data?.flatMap((plan) => plan.fee_schedules).map((feeSchedule) => feeSchedule.id);
};

export default useFindFeeSchedulesByPlanForPayers;
