import { useFind } from 'src/api/APIHooks';
import { isEmpty, map, sortBy, uniqBy } from 'lodash';

function getFullName (invoice) {
  return [invoice.client_first_name, ' ', invoice.client_last_name].join('');
}

function sortInvoices (invoices) {
  const uniqueNames = uniqBy(invoices, 'label').slice(0, 25);
  return !isEmpty(uniqueNames) ? sortBy(uniqueNames, 'label') : [];
}
const useInvoiceClientNameOptions = (
  clientNameLike,
  invoiceFilters,
) => {
  const { data } = useFind(
    'invoices',
    {
      ...invoiceFilters,
      client_name_like: clientNameLike,
    },
    {
      queryConfig: { enabled: !!clientNameLike, placeholderData: undefined },
    },
  );

  const invoicesClientName = data?.data?.data ?? [];
  const invoicesClientNameOptions = map(invoicesClientName, (key) => ({
    label: getFullName(key),
    value: getFullName(key),
  }));

  return sortInvoices(invoicesClientNameOptions);
};

export default useInvoiceClientNameOptions;
