import React from 'react';
import PropTypes from 'prop-types';
import enumDisplayName from '../utils/enumDisplayName';

const SexualityDisplay = ({ value }) => {
  const sexuality =
    value.sexuality.map((sexualityOption) => enumDisplayName(sexualityOption, 'people.sexual_orientations')).join(', ');
  const sexualityOther = value.sexuality_other;

  return (
    <div>
      <div>{sexuality}&nbsp;</div>
      {sexualityOther && (<div>Other: {sexualityOther}</div>)}
    </div>
  );
};

SexualityDisplay.propTypes = {
  value: PropTypes.object.isRequired,
};

export default SexualityDisplay;
