import _ from 'lodash';
import isReferralForwarded from './isReferralForwarded';
import isReferralClosedByCoordinationCenter from './isReferralClosedByCoordinationCenter';

function returnReferralStatus({ serviceCase, referral }) {
  const referralStates = {
    sent: 'Sent',
    in_review: 'In Review',
    accepted: 'Accepted',
    declined: 'Rejected',
    recalled: 'Recalled',
    draft: 'Draft',
    off_platform: 'Off Platform',
    auto_recalled: 'Auto Recalled',
    pending_authorization: 'Pending Authorization',
    denied_authorization: 'Denied Authorization',
  };

  if (isReferralForwarded({ serviceCase, referral })) return 'Forwarded';
  if (isReferralClosedByCoordinationCenter(referral)) return 'Reviewed';
  return referralStates[_.get(referral, 'status')];
}

export default returnReferralStatus;
