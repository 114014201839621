import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import {
  BaseCard,
  BaseCardHeader,
  BaseCardBody,
  Button,
  Dialog,
  Icon,
  RadioField,
} from '@unite-us/ui';
import { Field } from 'react-final-form';
import Checkboxes from 'src/components/Organization/components/Checkboxes';
import ToolTip from 'src/common/ToolTip';
import { theme } from 'src/../tailwind.config';

const OffPlatformLabel = (
  <div className="inline-flex">
    <div>
      Off-platform Referrals Only
    </div>
    <ToolTip
      enableHover
      buttonComponentContent={() => (
        <Icon
          icon="IconQuestionCircleO"
        />
      )}
      panelClassName="w-5/6 border bg-white border-solid border-gray-400
      rounded p-4 text-gray-600 tracking-wide leading-5 shadow-md normal-case"
      buttonClassName="px-2 focus:outline-none"
      panelTextContent="Select this option if this program is accepting referrals,
      but not through the Unite Us Platform. If you select this option, providers may
       contact your organization directly about providing services to their clients through this program."
    />
  </div>
);

const StatusInfoContent = (
  <p className="mb-2 text-dark-grey">
    Is this program currently accepting referrals of new clients? If not, please indicate why.
  </p>
);

const ProgramDeliveryInfoContent = (
  <p className="mb-3 text-dark-grey">
    How or where will these services be administered?
  </p>
);

export const ProgramAccessForm = (props) => {
  const {
    deliveryOptions,
    serviceAreaSupportForOrgsFlag,
    isDraft,
  } = props;
  const acceptingReferralsModal = useRef();

  const acceptingReferralsModalHandlerOpen = (value) => {
    if (value === 'Accepting Referrals' && !isDraft) {
      acceptingReferralsModal.current.openDialog();
    }
  };

  const acceptingReferralsModalHandlerClose = () => {
    acceptingReferralsModal.current.closeDialog();
  };

  const statusOptions = [
    { value: 'Accepting Referrals', label: 'Accepting Referrals' },
    { value: 'Off-platform Referrals Only', label: OffPlatformLabel },
    { value: 'On Vacation', label: 'No Referrals: On Vacation' },
    { value: 'Off Season', label: 'No Referrals: Off Season' },
    { value: 'At Capacity', label: 'No Referrals: At Capacity' },
    { value: 'Out of Funds', label: 'No Referrals: Out of Funds' },
    { value: 'National/Regional Emergency', label: 'No Referrals: National/Regional Emergency' },
  ];

  let deliveryOptionsToDisplay = deliveryOptions;

  if (serviceAreaSupportForOrgsFlag) {
    deliveryOptionsToDisplay = deliveryOptions.map(({ display_name, value }) => {
      if (value === 'in_office') {
        return { display_name: `${display_name} (requires Program Location)`, value };
      }

      return { display_name: `${display_name}`, value };
    });
  }

  return (
    <BaseCard className="mt-6">
      <BaseCardHeader
        title="Program Access"
      />
      <BaseCardBody className="px-8 py-10 grid grid-cols-2">
        <Checkboxes
          fieldKey="delivery_options"
          headingText="Program Delivery"
          options={deliveryOptionsToDisplay}
          postLabelContent={ProgramDeliveryInfoContent}
        />
        <div>
          <Field
            name="status"
            data-test-element="status"
            type="select"
          >
            {(params) => (
              <RadioField
                testId="program-status-radio-field"
                id="status"
                label="Program Status"
                required
                options={statusOptions}
                postLabelContent={StatusInfoContent}
                onChange={(e) => { acceptingReferralsModalHandlerOpen(e.target.value); }}
                {...params}
              />
            )}
          </Field>
        </div>
      </BaseCardBody>
      <Dialog
        ref={acceptingReferralsModal}
        actions={(
          <Button
            fullWidth
            label="Acknowledge"
            primary
            onClick={acceptingReferralsModalHandlerClose}
          />
        )}
        dialogContentStyles={{
          background: 'white',
          padding: '0',
          display: 'flex',
          flexDirection: 'column',
          color: theme.extend.colors['text-blue'],
          fontSize: '1.25rem',
          fontFamily: theme.extend.fontFamily['regular-font'],
          lineHeight: '1rem',
        }}
        modal
        removeCloseIcon
        showBorderRadius
        size="small"
        title={'   '}
        titleStyles={{
          borderBottom: 'none',
        }}
        footerStyles={{
          borderTop: 'none',
          paddingTop: '20px',
          paddingBottom: '20px',
        }}
      >
        <>
          <div className="flex justify-center" style={{ paddingBottom: '1.25rem' }}>
            <Icon
              className="flex"
              ariaLabel="Organization"
              icon="IconExclamationCircle"
              color={theme.extend.colors['text-blue']}
              size={48}
              canReceiveFocus={false}
            />
          </div>
          <div className="flex justify-center" style={{ lineHeight: '1.75rem', paddingBottom: '0.5rem' }}>
            You&apos;re now accepting referrals.
          </div>
          <div className="flex justify-center" style={{ lineHeight: '1rem', fontSize: '0.75rem' }}>
            Review the remaining program information for accuracy.
          </div>
        </>
      </Dialog>
    </BaseCard>
  );
};

ProgramAccessForm.propTypes = {
  deliveryOptions: PropTypes.array.isRequired,
  serviceAreaSupportForOrgsFlag: PropTypes.bool,
  isDraft: PropTypes.bool.isRequired,
};

ProgramAccessForm.defaultProps = {
  serviceAreaSupportForOrgsFlag: false,
};

const mapStateToProps = (state) => (
  {
    deliveryOptions: get(state, 'session.enums.groups.programs.delivery_options', []),
  }
);

export default connect(
  mapStateToProps,
)(ProgramAccessForm);
