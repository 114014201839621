import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import 'src/components/RouteNavigation/stylesheets/routeNavigation.scss';
import RouteNavigationItem from './RouteNavigationItem';
import {
  createId,
  isRouteDisabled,
} from '../utils';

function RouteNavigationItemGroup(props) {
  const {
    id, route, location, onClick,
  } = props;
  const routeDisabled = isRouteDisabled(route);

  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      onClick(route.path, route);
    }
  };

  const subItems = _.map(route.children, (item, index) => (
    <RouteNavigationItem
      id={createId(item.path)}
      key={index}
      location={location}
      onClick={onClick}
      item={item}
    />
  ));

  return (
    <div className="route-navigation-item-group">
      <div className="navigation-group-section">
        <h3
          id={id}
          className={
            routeDisabled ?
              'navigation-card-header navigation-header-title--disabled' :
              'navigation-card-header navigation-header-title'
          }
          tabIndex={0}
          role="button"
          aria-expanded={false}
          onKeyDown={handleOnKeyDown}
        >
          {route.label}
        </h3>
        <div className="navigation-card-text">
          <ul className="route-navigation-item-group__list list-unstyled">
            {subItems}
          </ul>
        </div>
      </div>
    </div>
  );
}

RouteNavigationItemGroup.propTypes = {
  id: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  route: PropTypes.shape({
    children: PropTypes.array,
    label: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }).isRequired,
};

export default RouteNavigationItemGroup;
