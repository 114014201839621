import React from 'react';
import PropTypes from 'prop-types';
import { NoInvoicesFoundCard } from '../components';
import { REJECTED_CBO_ADMIN_STATUSES } from '../constants';
import { InvoiceWorkqueue } from './InvoiceWorkqueue';

const RejectedInvoicesDWQ = ({ children }) => {
  const bulkActions = ['IconDownloadV2', 'IconArchive'];

  return (
    <InvoiceWorkqueue
      bulkActions={bulkActions}
      cboStatuses={REJECTED_CBO_ADMIN_STATUSES}
      renderNoInvoicesFound={({ isFiltered, resetFilters }) => (
        <NoInvoicesFoundCard isFiltered={isFiltered} status="rejected" resetFilters={resetFilters} />
      )}
      pageName="Rejected"
      path="rejected/"
    >
      {children}
    </InvoiceWorkqueue>
  );
};

RejectedInvoicesDWQ.propTypes = {
  children: PropTypes.node,
};

RejectedInvoicesDWQ.defaultProps = {
  children: undefined,
};
export default RejectedInvoicesDWQ;
