import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import NetworkSubHeader from 'src/components/Network/components/NetworkSubHeader';
import { searchNetworkGroups } from 'actions/Group/Network';
import { DEFAULT_SEARCH_PARAMS } from 'actions/Group/Network/searchNetworkGroups';
import {
  clearNetworkBrowseFilters,
  clearNetworkBrowseGroups,
} from 'actions/Browse';
import {
  clearNetworkGroupSearch,
  clearNetworkGroups,
  fetchGroup,
} from 'actions/Group';
import { getProviderReferralScopes } from 'src/components/Groups/utils/referralScope';
import { getGroup } from 'common/utils/stateHelpers';
import { isCoordinationCenter } from 'common/utils/Group';
import { getReferralScopesFromState } from 'src/components/Referrals/utils/scopes';
import isSuperNetwork from 'src/components/Network/utils/isSuperNetwork';
import { isNetworkUser } from 'src/components/User/utils';
import { uup459SupersetPhase2, cerb1367ResourceListDetailEnhancements as cerb1367ResourceListDetailEnhancementsSelector } from 'common/utils/FeatureFlags/flags';
import _ from 'lodash';
import './Network.scss';

export class Network extends Component {
  constructor(props) {
    super(props);

    this.state = {
      referralScopes: props.referralScopes,
      shoppingCartBtnRef: {
        current: null,
      },
    };
  }

  componentDidMount() {
    const {
      groupId,
      groupSearchParams,
      params: { networkId },
      superNetwork,
      user,
    } = this.props;

    this.props.fetchGroup(groupId);
    this.props.getProviderReferralScopes(groupId);

    if (networkId && !superNetwork && !isNetworkUser(user, groupId)) {
      this.props.searchNetworkGroups(networkId, groupSearchParams);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.shouldFetchNetworkGroups(nextProps)) {
      this.props.searchNetworkGroups(nextProps.params.networkId);
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.referralScopes, this.props.referralScopes)) {
      this.setState({ referralScopes: this.props.referralScopes });
    }
  }

  componentWillUnmount() {
    this.props.clearNetworkBrowseFilters();
    this.props.clearNetworkBrowseGroups();
    this.props.clearNetworkGroups();
    this.props.clearNetworkGroupSearch();
  }

  setShoppingCartDrawerRef = (btnElement) => {
    if (btnElement) {
      this.setState({ shoppingCartBtnRef: { current: btnElement } });
    }
  };

  shouldFetchNetworkGroups(nextProps) {
    return nextProps.params.networkId !== this.props.params.networkId;
  }

  render() {
    const {
      children,
      currentUserGroup,
      groupId,
      isCCGroup,
      location: { pathname },
      networkServiceTypes,
      params: { networkId },
      superNetwork,
      isSupersetEnabled,
      isEnhancedRLDetailEnabled,
    } = this.props;

    const { referralScopes } = this.state;

    return (
      <div className={classNames(isEnhancedRLDetailEnabled ? 'network_enhanced' : 'network')}>
        <div className="network__content">
          {/* eslint-disable-next-line max-len */}
          <div className={classNames(isEnhancedRLDetailEnabled ? 'network_enhanced__content-tabs' : 'network__content-tabs')}>
            {(isSupersetEnabled || networkId) && (
              <NetworkSubHeader
                networkId={networkId}
                pathname={pathname}
                setShoppingCartDrawerRef={this.setShoppingCartDrawerRef}
              />
            )}
          </div>
          <div className="network__content-children">
            {
              React.Children.map(
                children,
                (child) => React.cloneElement(child, {
                  currentUserGroup,
                  currentGroupId: groupId,
                  isCCGroup,
                  isSuperNetwork: superNetwork,
                  networkId,
                  networkServiceTypes,
                  referralScopes,
                  shoppingCartBtnRef: this.state.shoppingCartBtnRef,
                  setShoppingCartDrawerRef: this.setShoppingCartDrawerRef,
                }),
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

Network.propTypes = {
  children: PropTypes.object.isRequired,
  clearNetworkBrowseFilters: PropTypes.func.isRequired,
  clearNetworkBrowseGroups: PropTypes.func.isRequired,
  clearNetworkGroups: PropTypes.func.isRequired,
  clearNetworkGroupSearch: PropTypes.func.isRequired,
  currentUserGroup: PropTypes.object.isRequired,
  fetchGroup: PropTypes.func.isRequired,
  getProviderReferralScopes: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  groupSearchParams: PropTypes.object.isRequired,
  isCCGroup: PropTypes.bool.isRequired,
  isSupersetEnabled: PropTypes.bool.isRequired,
  isEnhancedRLDetailEnabled: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  networkServiceTypes: PropTypes.array.isRequired,
  params: PropTypes.shape({
    networkId: PropTypes.string.isRequired,
  }),
  referralScopes: PropTypes.array.isRequired,
  searchNetworkGroups: PropTypes.func.isRequired,
  superNetwork: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
};

Network.defaultProps = {
  params: {
    pathname: '/nice-path',
  },
};

function mapStateToProps(state, ownProps) {
  const groupId = state.session.groupId;
  const currentUserGroup = getGroup(state, groupId);
  // CORE-WORK - Need to resolve network.is_super_network
  const userNetworks = _.get(state, 'user.networks', []);
  const networkServiceTypes = _.get(ownProps.params.networkId, 'service_types', []);
  const groupSearchParams = _.get(state, 'networks.groupSearchParams', DEFAULT_SEARCH_PARAMS);
  const referralScopes = getReferralScopesFromState(state, groupId);
  const isCCGroup = Boolean(ownProps.params.networkId) && isCoordinationCenter([ownProps.params.networkId], groupId);
  const user = state.user;
  const isSupersetEnabled = uup459SupersetPhase2(state);
  const isEnhancedRLDetailEnabled = cerb1367ResourceListDetailEnhancementsSelector(state);

  return {
    currentUserGroup,
    groupId,
    groupSearchParams,
    isCCGroup,
    isSupersetEnabled,
    networkServiceTypes,
    referralScopes,
    superNetwork: isSuperNetwork(userNetworks, groupId),
    user,
    isEnhancedRLDetailEnabled,
  };
}

export default connect(mapStateToProps, {
  clearNetworkBrowseFilters,
  clearNetworkBrowseGroups,
  clearNetworkGroups,
  clearNetworkGroupSearch,
  fetchGroup,
  getProviderReferralScopes,
  searchNetworkGroups,
})(Network);
