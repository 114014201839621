import { serializeMergeData } from './serializeMergeData';
import { mergeEmailAddresses } from './mergeEmailAddresses';
import { mergePhoneNumbers } from './mergePhoneNumbers';
import { mergeAddresses } from './mergeAddresses';
import { mergeContactPreference } from './mergeContactPreference';

export const MULTIPLE_VALUES_ATTRIBUTES = ['email_addresses', 'phone_numbers', 'addresses', 'contact_notes'];
const RELATIONSHIP_ATTRIBUTES = ['record_languages'];

export const mergeClientData = (
  formValues,
  clientsComparison,
  differentFields,
  defaultFields = {},
  newestClientIndex,
) => {
  const comparison = clientsComparison?.data?.data || {};
  const singleValuesKeys = Object.keys(comparison).filter(
    (key) => !MULTIPLE_VALUES_ATTRIBUTES.includes(key) || !RELATIONSHIP_ATTRIBUTES.includes(key),
  );

  const values = singleValuesKeys.reduce((acc, key) => {
    if (differentFields[key]) {
      // if fields are different, get selected form value
      // `formValues[key]` is a string "0" or "1", but we need an integer to index the array.
      acc[key] = differentFields[key][parseInt(formValues[key], 10)];
    } else {
      // if fields are same, pick the first
      // if only one field exists, pick the existing one
      acc[key] = comparison[key][0] || comparison[key][1];
    }
    return acc;
  }, {});

  return {
    ...serializeMergeData(
      {
        ...values,
        email_addresses: mergeEmailAddresses(
          formValues,
          comparison,
          newestClientIndex,
        ),
        phone_numbers: mergePhoneNumbers(
          formValues,
          comparison,
          newestClientIndex,
        ),
        addresses: mergeAddresses(formValues, comparison),
        contact_preference: mergeContactPreference(formValues, comparison),
        record_languages: formValues?.record_languages ?
          comparison?.record_languages[formValues.record_languages] :
          comparison?.record_languages[3],
      },
      defaultFields,
    ),
  };
};
