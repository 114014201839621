import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Card } from 'common/Card';
import { useCurrentEmployeeId } from 'common/contexts/CurrentEmployeeContext';
import { Drawer } from 'common/Drawer';
import { Spinner } from 'common/spinners';
import {
  useCurrentPayerId,
  useCurrentProviderId,
  useIsNetworkLead,
  useIsPayer,
} from 'common/contexts/CurrentProviderContext';
import { useFind } from 'src/api/APIHooks';
import { useFeatureFlag } from 'src/common/hooks';
import { apiV1 } from 'src/api/config';
import { BulkActions, InvoiceDetails, InvoicePagination, InvoicesTable } from '../components';
import { FilterSelectors } from '../components/Filter';
import {
  useInvoiceDrawer,
  useInvoiceExports,
  useInvoiceFilters,
  useInvoicePagination,
  useInvoiceTypes,
  useInvoiceSorting,
  usePayerWQInteractiveView,
} from '../hooks';
import getSort from '../utils/getSort';
import AllFiltersDrawer from '../components/Filter/AllFiltersDrawer';
import { INVOICE_STATUS, INVOICE_STATUSES } from '../constants';

export const InvoiceWorkqueue = ({
  showArchived,
  bulkActions,
  children,
  showDisputed,
  cboStatuses,
  networkLeadStatuses,
  payerStatuses,
  pageName,
  path,
  renderNoInvoicesFound,
  showInvoiceAging,
}) => {
  const pays5214BillingForScreenings = !!useFeatureFlag('pays-5214-billing-for-screenings');
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [isDetailDrawerOpen, setIsDetailDrawerOpen] = useState(false);
  const isNetworkLead = useIsNetworkLead();
  const isPayer = useIsPayer();
  const currentProviderId = useCurrentProviderId();
  const payerId = useCurrentPayerId();
  const currentEmployeeId = useCurrentEmployeeId();
  const { isLoading: isPayerInteractiveLoading, payerWQInteractiveView } = usePayerWQInteractiveView(payerId);

  let availableStatuses = cboStatuses;
  if (isNetworkLead) availableStatuses = networkLeadStatuses;
  if (isPayer) {
    const filterOutReadOnlySpecificStatus = (statuses) => statuses.filter(
      (status) => status !== INVOICE_STATUS.submitted_to_payer,
    );
    availableStatuses = payerWQInteractiveView ? filterOutReadOnlySpecificStatus(payerStatuses) : payerStatuses;
  }

  const paginationProps = useInvoicePagination();
  const sortProps = useInvoiceSorting({ onUpdate: paginationProps.resetPagination });

  let onUpdateFilter = () => {};
  const {
    defaultFilterValues,
    filterSetters,
    filterValues,
    invoicesQuery,
    isFiltered,
    resetFilters,
  } = useInvoiceFilters({
    availableStatuses,
    showArchived,
    showDisputed,
    onUpdate: () => onUpdateFilter(), // hack - must be an anonymous function, not onUpdateFilter passed directly
    // When bulk actions can be refactored, remove the circular dependencies so we don't need to do this
  });

  const { isLoading: isInvoicesLoading, data } = useFind(
    'invoice',
    invoicesQuery,
    {
      include: 'submitter',
      page: {
        number: paginationProps.pageNumber,
        size: paginationProps.pageSize,
      },
      queryConfig: {
        enabled: !!currentProviderId && !isPayerInteractiveLoading && !isDetailDrawerOpen,
        placeholderData: undefined,
      },
      sort: getSort(sortProps),
    },
  );

  const fetchAllInvoices = useCallback(async () => (
    apiV1.query(
      'invoice',
      invoicesQuery,
      {
        page: {
          number: 1,
          size: paginationProps.totalItemCount,
        },
      },
    )
  ), [invoicesQuery, paginationProps]);

  const isLoading = isPayerInteractiveLoading || isInvoicesLoading;

  const exportProps = useInvoiceExports({ currentEmployeeId, fetchAllInvoices });

  onUpdateFilter = () => {
    paginationProps.resetPagination();
    exportProps.deselectAll();
  };

  const invoices = data?.data?.data ?? [];
  const totalItemCount = data?.data?.paging?.total_count ?? 0;
  const totalPageCount = data?.data?.paging?.total_pages ?? 0;

  const drawerProps = useInvoiceDrawer({
    invoices,
    pageNumber: paginationProps.pageNumber,
    pageSize: paginationProps.pageSize,
    setPageNumber: paginationProps.setPageNumber,
    totalPageCount,
    open: isDetailDrawerOpen,
    setOpen: setIsDetailDrawerOpen,
  });

  const invoiceTypes = useInvoiceTypes();
  const showInvoiceType = pays5214BillingForScreenings && invoiceTypes.length > 1;

  if (!availableStatuses) {
    // eslint-disable-next-line no-console
    console.error('no available statuses, do you have the invoices role and the right provider type for this page?');
    return null;
  }

  if (!isEmpty(children)) {
    return React.cloneElement(children, { showInvoiceType });
  }

  return (
    <div className="divide-y divide-solid divide-dark-border-blue">
      <div className="px-3 py-3">
        {/* eslint-disable no-nested-ternary */}
        {isLoading ? (
          <div role="alert"><Spinner /></div>
        ) : (
          !isEmpty(invoices) ? (
            <>
              <Card className="bg-white py-10 pt-30 overflow-x-scroll">
                <h1 className="text-text-blue font-extrabold font-heavy-font text-2xl mb-6 px-8">
                  {`${pageName} Invoices`}
                </h1>
                <div className="flex items-end m-9 space-x-4">
                  <BulkActions
                    className="mb-2"
                    bulkActions={bulkActions}
                    {...exportProps}
                    onClose={() => drawerProps.setOpen(false)}
                    invoices={invoices}
                    currentEmployeeId={currentEmployeeId}
                    payerWQInteractiveView={payerWQInteractiveView}
                  />
                  <FilterSelectors
                    availableStatuses={availableStatuses}
                    filterSetters={filterSetters}
                    filterValues={filterValues}
                    openFilterDrawer={() => setIsFilterDrawerOpen(true)}
                    invoicesQuery={invoicesQuery}
                    invoiceTypes={invoiceTypes}
                    showArchived={showArchived}
                    showDisputed={showDisputed}
                    showInvoiceType={showInvoiceType}
                  />
                </div>
                <InvoicesTable
                  invoices={invoices}
                  openDrawer={drawerProps.openDrawer}
                  showInvoiceAging={showInvoiceAging}
                  showInvoiceType={showInvoiceType}
                  path={path}
                  {...sortProps}
                  {...exportProps}
                />
                <InvoicePagination
                  {...paginationProps}
                  totalItemCount={totalItemCount}
                  totalPageCount={totalPageCount}
                />
              </Card>
            </>
          ) : (
            renderNoInvoicesFound({
              isFiltered,
              resetFilters: () => {
                resetFilters();
                onUpdateFilter();
              },
            })
          )
        )}
        {/* eslint-enable no-nested-ternary */}
        <Drawer open={drawerProps.open} setOpen={drawerProps.setOpen}>
          {
            (isLoading || !drawerProps.clickedInvoice) ? (
              <div className="pt-8" role="alert" aria-live="polite"><Spinner /></div>
            ) : (
              <InvoiceDetails
                getNextInvoice={drawerProps.getNextInvoice}
                getPrevInvoice={drawerProps.getPrevInvoice}
                showInvoiceType={showInvoiceType}
                invoice={drawerProps.clickedInvoice}
                isNetworkLead={isNetworkLead}
                onClose={() => drawerProps.setOpen(false)}
                deselectAll={exportProps.deselectAll}
                payerWQInteractiveView={payerWQInteractiveView}
                path={path}
              />
            )
          }
        </Drawer>
        <Drawer open={isFilterDrawerOpen} setOpen={setIsFilterDrawerOpen}>
          <AllFiltersDrawer
            availableStatuses={availableStatuses}
            close={() => setIsFilterDrawerOpen(false)}
            defaultFilterValues={defaultFilterValues}
            filterSetters={filterSetters}
            filterValues={filterValues}
            invoicesQuery={invoicesQuery}
            invoiceTypes={invoiceTypes}
            showArchived={showArchived}
            showDisputed={showDisputed}
            showInvoiceType={showInvoiceType}
          />
        </Drawer>
      </div>
    </div>
  );
};

InvoiceWorkqueue.propTypes = {
  bulkActions: PropTypes.array,
  cboStatuses: PropTypes.arrayOf(PropTypes.oneOf(INVOICE_STATUSES)),
  children: PropTypes.node,
  networkLeadStatuses: PropTypes.arrayOf(PropTypes.oneOf(INVOICE_STATUSES)),
  pageName: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  payerStatuses: PropTypes.arrayOf(PropTypes.oneOf(INVOICE_STATUSES)),
  renderNoInvoicesFound: PropTypes.func.isRequired,
  showArchived: PropTypes.oneOf([true, false, null]),
  showDisputed: PropTypes.oneOf([true, false, null]),
  showInvoiceAging: PropTypes.bool,
};

InvoiceWorkqueue.defaultProps = {
  bulkActions: [],
  children: undefined,
  cboStatuses: undefined,
  networkLeadStatuses: undefined,
  payerStatuses: undefined,
  showArchived: false,
  showDisputed: false,
  showInvoiceAging: false,
};
