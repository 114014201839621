import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { compact, isEmpty } from 'lodash';
import {
  InputField,
  BaseCard,
  BaseCardBody,
} from '@unite-us/ui';
import {
  LocationAddressField,
  validations,
} from '@unite-us/client-utils';
import composeValidators from 'src/components/Organization/utils/composeValidators';
import normalizeHours from 'src/components/Organization/utils/normalizeHours';
import HoursOfOperationsEdit from 'src/components/Organization/components/HoursOfOperationsEdit';
import ButtonAddField from 'src/components/Organization/components/ButtonAddField';
import ButtonFormSubmit from 'src/components/Organization/components/ButtonFormSubmit';
import ButtonRemoveField from 'src/components/Organization/components/ButtonRemoveField';
import Fields from 'src/components/Organization/components/Fields';
import PhoneNumbersField from '../../components/PhoneNumbersField';
import './Location.scss';

const addressFieldNames = [
  'line_1',
  'line_2',
  'city',
  'county',
  'state',
  'postal_code',
  'country',
  'latitude',
  'longitude',
];
const { isEmail, isRequired } = validations;

const LocationForm = ({
  onDelete,
  onSubmit,
  initialValues,
}) => {
  const onSubmitForm = (values) => {
    const formattedValues = {
      ...values,
      // Pass null fields to clear old values.
      ...(addressFieldNames.reduce((acc, fieldName) => ({
        ...acc,
        [fieldName]: values[fieldName] || null,
      }), {})),
      name: values.name || null,
      email_addresses: compact(values.email_addresses?.map((email) => email.email_address)),
      phone_numbers: values.phone_numbers?.filter((phone) => !isEmpty(phone)),
      hours: normalizeHours.transformToApiFormat(values.hours || []),
    };
    onSubmit(formattedValues);
  };

  return (
    <Form
      keepDirtyOnReinitialize
      onSubmit={onSubmitForm}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
      }}
      render={({
        handleSubmit,
        form: {
          mutators: { push },
        },
        submitting,
        pristine,
        valid,
        // TODO: the valid/invalid flags are not being propagated up from address field to the form
        // city is being used as a temp indicator of address field validity.
        values: { city: isAddressValid = '' },
      }) => (
        <form onSubmit={handleSubmit}>
          <BaseCard>
            <BaseCardBody className="px-8 py-10">
              <Field
                name="name"
                data-test-element="name"
              >
                {(props) => (
                  <InputField
                    autoFocus
                    id="location-name"
                    label="Location Name"
                    {...props}
                  />
                )}
              </Field>

              <Fields
                names={addressFieldNames}
                data-test-element="address"
              >
                {(props) => (
                  <LocationAddressField
                    id="location-address"
                    label="Address"
                    google={window.google}
                    fieldNamePath={null}
                    {...props}
                  />
                )}
              </Fields>

              <Field
                name="line_2"
                data-test-element="line_2"
              >
                {(props) => (
                  <InputField
                    id="location-line2"
                    label="Apt/Suite/Unit/Building (optional)"
                    {...props}
                  />
                )}
              </Field>

              <hr className="mb-6" />
              <div className="relative">
                <PhoneNumbersField
                  name="phone_numbers"
                  userPhoneTypes={false}
                  initializeWithBlankPhoneNumber
                  withExtension={false}
                />
              </div>
              <hr className="mb-6" />
              <div className="relative">
                <ButtonAddField
                  data-test-element="add_email"
                  className="h-auto"
                  isPositioned
                  label="Add Email"
                  onClick={() => push('email_addresses', {})}
                />
              </div>
              <FieldArray name="email_addresses">
                {({ fields }) => (fields.length ? fields.map((fieldName, index) => (
                  <div key={`location-fieldset-${fieldName}`} className="flex">
                    <div className="flex w-full">
                      <div className="flex w-9/12" data-test-element={`email_${index}`}>
                        <Field
                          name={`${fieldName}.email_address`}
                          required
                          validate={composeValidators(isRequired, isEmail)}
                        >
                          {(params) => (
                            <InputField
                              label="Location Email Address"
                              id={`location-email-${index}`}
                              hideLabel={!!index}
                              {...params}
                            />
                          )}
                        </Field>
                      </div>
                      <ButtonRemoveField
                        onClick={() => fields.remove(index)}
                        testId={`email_trash_${index}`}
                      />
                    </div>
                  </div>
                )) : <div className="h-16" />)}
              </FieldArray>
              <hr className="mb-6" />
              <HoursOfOperationsEdit
                name="hours"
                push={push}
              />
            </BaseCardBody>
          </BaseCard>
          <ButtonFormSubmit
            onDelete={onDelete}
            isSaveDisabled={submitting || pristine || !isAddressValid || !valid}
          />
        </form>
      )}
    />
  );
};

LocationForm.propTypes = {
  onDelete: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

LocationForm.defaultProps = {
  onDelete: null,
  initialValues: {},
};

export default LocationForm;
