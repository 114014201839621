import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@unite-us/ui';
import FileUpload from 'common/form/FileUpload';
import ContactDocumentsSelector from './ContactDocumentsSelector';
import DocumentVisibility from './DocumentVisibility';

class UploadAndAttachDocumentsFormFields extends PureComponent {
  render() {
    const {
      dropzoneName,
      fields,
      formIndex,
      persistOnUnmount,
      registerField,
      warningText,
      showDocumentVisibility,
      hasSocialCareNavigationRole,
    } = this.props;

    return (
      <div className="upload-and-attach-documents-form-fields">
        <ContactDocumentsSelector
          formIndex={formIndex}
          documentFields={fields.attachableDocuments}
        />

        <FileUpload
          ref={registerField}
          name={dropzoneName}
          file={fields.uploadedDocuments}
          field={fields.uploadedDocuments}
          multiple
          persistOnUnmount={persistOnUnmount}
        >
          <div className="text-center">
            <Icon
              className="mb-3 text-text-blue"
              icon={'V2Upload'}
              size={16}
              color={'none'}
            />
            <p className="mb-2 text-base text-text-blue">
              Drag and drop files or <span className="text-16px text-action-blue">Browse</span>
            </p>
            <p className="text-xs">
              Supported: csv, doc, docx, pdf, rtf, txt, xls, xlsx, gif, jpg, jpeg, png, mp3, m4a, wav, wma
            </p>
            <p className="text-xs">Maximum file size 20 mb</p>
          </div>
        </FileUpload>
        {showDocumentVisibility && (
          <DocumentVisibility
            privateToOrg={fields.privateToOrg}
            privateToRole={fields.privateToRole}
            hasSocialCareNavigationRole={hasSocialCareNavigationRole}
          />
        )}
        <div className="ui-form-field__hint">
          {warningText}
        </div>
      </div>
    );
  }
}

UploadAndAttachDocumentsFormFields.propTypes = {
  dropzoneName: PropTypes.string.isRequired,
  fields: PropTypes.shape({
    attachableDocuments: PropTypes.array,
    uploadedDocuments: PropTypes.object,
    privateToOrg: PropTypes.bool,
    privateToRole: PropTypes.bool,
  }).isRequired,
  formIndex: PropTypes.number,
  persistOnUnmount: PropTypes.bool,
  registerField: PropTypes.func.isRequired,
  warningText: PropTypes.string,
  showDocumentVisibility: PropTypes.bool,
  hasSocialCareNavigationRole: PropTypes.bool,
};

UploadAndAttachDocumentsFormFields.defaultProps = {
  persistOnUnmount: false,
  formIndex: undefined,
};

export default UploadAndAttachDocumentsFormFields;
