import React, { Component } from 'react';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import PropTypes from 'prop-types';
import Autologout, { AutoLogoutDialog, mockUpdate, selectLogoutSeconds } from 'src/components/App/AutoLogout';
import { connect } from 'react-redux';
import { browserHistory } from 'common/utils/browserHistory';
import _ from 'lodash';
import { datadogLogs } from '@datadog/browser-logs';
import { trackEvent, UULogoLoader } from '@unite-us/client-utils';
import { IntercomHelper, IntercomWrapper } from '@unite-us/app-components';
import { LOGIN } from 'common/utils/EventTracker/utils/eventConstants';
import callOrLog from 'common/utils/callOrLog';
import {
  clearRedirectPath,
  fetchCurrentGroup,
  fetchIpPosition,
  fetchPosition,
  fetchUserFromSessionId,
  resetStoreConfigs,
  setCurrentGroup,
  setCurrentNetwork,
  setRedirectPath,
  setZendeskReturnToUrl,
} from 'common/utils/Session/actions';
import {
  updateDataDogSessionUser,
} from 'src/components/App/actions/dataDogHelper';
import { coordinatedNetwork, isLoggedIn } from 'common/utils/utils';
import setCurrentProvider from 'src/components/App/actions/setCurrentProvider';
import { loadGoogleMapsAPI } from 'src/components/Browse/Map/utils/requiredMapScript';
import { isNetworkUser } from 'src/components/User/utils';
import submitLogoutForm from 'src/common/utils/Auth/submitLogoutForm';
import { LogoutAuth } from 'common/form';
import { logoutUser } from 'actions/Login';
import sessionExpiredNotification from 'common/utils/SystemNotifications/actions/SessionExpiredNotification';
import EulaAgreement from 'pages/account/EulaAgreement';
import UniteUsParticipationAgreement from 'src/components/SystemAgreements/UniteUsParticipationAgreement';
import { RETRY_LIMIT, RETRY_TIMEOUT } from 'common/utils/SystemNotifications/constants/systemNotifications';
import { rollbarCheckIgnore, rollbarPayloadTransformer } from 'common/utils/Rollbar';
import getZendeskUrl from 'src/common/utils/Zendesk/getZendeskUrl';
import goToInvoicesIndex from 'src/pages/invoices/utils/goToInvoicesIndex';
import refreshSession from 'common/utils/Session/actions/RefreshSession';
import GroupSelector from 'src/common/display/GroupSelector';
import {
  hasPayerInvoicesRole,
  showPendo,
  hbh1430AudioEyeEnabled,
} from 'src/common/utils/FeatureFlags/flags';
import { INTERCOM_APP_ID, ROUTE_BASEPATH } from 'src/config/env/env.config';
import { get as getCookie, remove as removeCookie } from 'es-cookie';
import fetchActiveNetworks from 'src/actions/Networks/fetchActiveNetworks';
import {
  addUnauthorizeInterceptor,
  addEmployeeIDHeader,
  addProviderIDHeader,
  getAuthToken,
  getAuthTokenDetails,
} from 'src/api/config';
import { isOrgInDraft } from 'src/components/Organization/utils/organizationRouting';
import {
  isUser,
  requiresEula,
} from '../helper';
import GroupWrapper from './GroupWrapper';
import { getIntercomParams, getPendoParams } from '../utils';
import '../stylesheets/app.scss';
import { PRODUCT_NAME, DISPLAY_PENDO } from '../constants';
import { audioEye } from '../utils/audioEye';

const queryClient = new QueryClient();
const INTERCOM_SOURCE = 'appclient';
const { pendo } = window;

const isOrganizationAdministrator = ({ userRoles = [], allRoles = {} }) => {
  if (
    !allRoles.systemRoles ||
    !allRoles.systemRoles.length ||
    !userRoles.length
  ) {
    return false;
  }
  const orgAdminRole = allRoles.primaryRoles.find((role) => (role.key === 'org_admin'));
  const { id: orgAdminId } = orgAdminRole;
  return userRoles.find((role) => role.id === orgAdminId);
};

const addVisibilityChangeEventListener = (logoutLogs) => {
  if (window.hasVisibilityChangeListener) { return; }
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      if (logoutLogs) {
        datadogLogs.logger.info('LogoutLogs: Event visibilitychange = visible. Getting auth token..');
      }
      const authToken = getAuthToken(logoutLogs);
      if (logoutLogs) {
        datadogLogs.logger.info('LogoutLogs: App.jsx: authToken is', { authToken });
      }
    }
  });
  window.hasVisibilityChangeListener = true;
};

export class App extends Component {
  constructor(props) {
    super(props);

    this.fetchUser = this.fetchUser.bind(this);
    this.setGroupId = this.setGroupId.bind(this);
    this.setNetworkId = this.setNetworkId.bind(this);

    // Avoid using state because the callback using this property is not lifecycle-dependent
    this.isLoggingOut = false;
    this.pendoMounted = false;
  }

  getChildContext() {
    const { enums, groupId } = this.props;

    return {
      enums,
      groupId,
    };
  }

  componentDidMount() {
    const {
      currentUser,
      refreshTokenPoll,
      useAuthPoll,
      zendeskReturnToUrl,
      logoutLogs,
      globalState: { impersonation, currentEmployee },
      impersonationType,
      isImpersonation,
    } = this.props;

    if (logoutLogs) {
      datadogLogs.logger.info('LogoutLogs: App.jsx: componentDidMount', {
        sessionImpersonationType: impersonationType || false,
        globalStateImpersonation: impersonation || false,
        isImpersonation,
      });
    }

    this.props.fetchPosition();
    this.props.fetchIpPosition();
    loadGoogleMapsAPI();
    if (useAuthPoll) {
      this.setRefreshPoll(refreshTokenPoll);
    }
    if (currentEmployee) {
      updateDataDogSessionUser({
        employee: currentEmployee.id,
        email: currentEmployee.email,
        provider: currentEmployee.provider.id,
        name: currentEmployee.full_name,
        impersonation: impersonation || isImpersonation,
      });
    }
    addVisibilityChangeEventListener(logoutLogs);

    addUnauthorizeInterceptor((response) => response, (error) => {
      if (_.get(error, 'response.status', null) === 401) {
        if (logoutLogs) {
          datadogLogs.logger.error('LogoutLogs: addUnauthorizeInterceptor 401 response', {
            error,
            response: _.get(error, 'response'),
            currentTokenDetails: getAuthTokenDetails(logoutLogs),
            now: Date.now(),
            refreshTokenPoll,
            impersonation,
          });
        }
        // Prevent multiple logout requests from taking place, possibly without token after clearing session
        if (!this.isLoggingOut) {
          this.isLoggingOut = true;
          submitLogoutForm();
          IntercomHelper.shutdown();
          this.props.sessionExpiredNotification(error);
        }

        return Promise.resolve();
      }
      return Promise.reject(error);
    });

    if (window.Rollbar) {
      window.Rollbar.configure({
        checkIgnore: rollbarCheckIgnore,
        transform: (payload) => rollbarPayloadTransformer(payload, currentUser),
      });
    }

    if (!_.isEmpty(zendeskReturnToUrl)) {
      this.props.setZendeskReturnToUrl(zendeskReturnToUrl);
    }

    if (isLoggedIn()) {
      const {
        globalState: { currentProvider },
      } = this.props;
      const groupId = _.get(this.props, 'session.groupId');
      if (!currentProvider && groupId) {
        this.props.setCurrentProvider(groupId);
      }

      const cbFromCookie = getCookie('uniteusCallbackUrl');

      if (currentProvider && currentProvider.group.state !== 'active') {
        this.redirectUser('/organization/settings');
      } else {
        if (cbFromCookie) {
          this.props.setRedirectPath(JSON.parse(cbFromCookie));
          removeCookie('uniteusCallbackUrl');
        } else {
          let locationCookie = window.location;

          if (ROUTE_BASEPATH !== '/') {
            locationCookie = {
              ...window.location,
              pathname: `/${window.location.pathname.split('/').splice(2).join('/')}`,
            };
          }
          this.props.setRedirectPath(locationCookie);
        }

        if (!isUser(currentUser)) {
          this.fetchUser({ retries: 0 });
        }

        if (groupId) {
          this.props
            .fetchCurrentGroup(groupId)
            .then(() => this.redirectUser(_.get(this.props, 'session.redirectPath.path', '/')));
        }
      }
    } else if (!_.isEmpty(zendeskReturnToUrl)) {
      browserHistory.push('/');
    } else {
      // not logged in
      this.props
        .setRedirectPath(this.props.location)
        .then(({ groupId }) => groupId && this.props.setCurrentGroup(groupId))
        .then(() => browserHistory.push('/'));
    }
    if (this.props.showAudioEye) {
      audioEye();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const currentUser = nextProps.currentUser;
    const hasFetchedUser =
      _.get(this.props.currentUser, 'fetchingUser') && !_.get(nextProps.currentUser, 'fetchingUser');
    const hasUserEmail = _.get(nextProps.currentUser, 'email');

    if (hasFetchedUser && hasUserEmail) {
      callOrLog(() => trackEvent(LOGIN.signedIn));
    }

    if (isLoggedIn()) {
      if (!currentUser.fetchingUser && currentUser.fetchUserRetries > 0) {
        const sessionToken = nextProps.session.token;
        const retries = currentUser.fetchUserRetries;
        if (retries <= RETRY_LIMIT) {
          this.fetchUser({ sessionToken, retries });
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      globalState: { currentEmployee, impersonation, activeNetworks },
      currentUser,
      showPendoFlag,
      isImpersonation,
    } = this.props;
    const {
      globalState: { currentEmployee: prevCurrentEmployee },
    } = prevProps;

    if (!prevCurrentEmployee && currentEmployee && !impersonation) {
      const {
        globalState: {
          intercom: { companies: intercomCompanies, userHash: intercomUserHash },
        },
      } = this.props;
      const { userName, userId, userEmail } = getIntercomParams(currentEmployee);
      IntercomHelper.intercomUserUpdate(
        intercomUserHash,
        userId,
        INTERCOM_APP_ID,
        PRODUCT_NAME,
        intercomCompanies,
        userName,
        userEmail,
      );
    }

    if (currentEmployee && currentEmployee !== prevCurrentEmployee) {
      updateDataDogSessionUser({
        employee: currentEmployee.id,
        email: currentEmployee.email,
        provider: currentEmployee.provider.id,
        name: currentEmployee.full_name,
        impersonation: impersonation || isImpersonation,
      });
    }

    const shouldShowPendo = pendo && showPendoFlag !== DISPLAY_PENDO.OFF && !this.PendoMounted;
    const usersPopulated = currentEmployee && currentUser.fetchingUser === false;
    if (shouldShowPendo && usersPopulated && activeNetworks.length) {
      const {
        userFirstName,
        userLastName,
        userId,
        userEmail,
        userRoles,
        employeeId,
        providerName,
        providerId,
        providerType,
        providerSensitive,
      } = getPendoParams(currentEmployee);
      pendo.initialize({
        visitor: {
          id: userId,
          employee_id: employeeId,
          email: userEmail,
          roles: userRoles,
          first_name: userFirstName,
          last_name: userLastName,
        },
        account: {
          id: providerId,
          provider_name: providerName,
          provider_type: providerType,
          provider_sensitive: providerSensitive,
          active_networks: activeNetworks.map((n) => n.id).join(', '),
        },
      });
    }
  }

  setGroupId(groupId) {
    const {
      globalState: { employees, providers, impersonation },
    } = this.props;

    this.props.setCurrentProvider(groupId);
    const currentEmployee = employees.find((e) => _.get(e, 'provider.id', false) === groupId);
    if (currentEmployee) {
      addEmployeeIDHeader(currentEmployee.id);
      addProviderIDHeader(groupId);
    }

    const { agreements = [] } = providers.find((provider) => provider.group.id === groupId) || {};

    const isOrgAdmin = isOrganizationAdministrator({
      userRoles: _.get(this.props, 'globalState.currentEmployee.roles', []),
      allRoles: this.props.globalState.roles,
    });

    // verify provider agreements
    if (isOrgAdmin && agreements.length === 0 && !impersonation) {
      return;
    }
    const selectedProvider = providers.find((provider) => provider.group.id === groupId) || {};
    const selectedProviderId = _.get(selectedProvider, 'group.id', '');
    const selectedProviderState = _.get(selectedProvider, 'group.state', '');

    this.setNetworkId(selectedProviderId);
    this.props.resetStoreConfigs();
    this.props.fetchCurrentGroup(groupId);
    this.props.setCurrentGroup(groupId).then(() => {
      const { redirectPath } = this.props.session;
      const isNetworkLead = _.get(this.props.globalState, 'currentProvider.group.provider_type') === 'network_lead';
      if (selectedProviderState !== 'active') {
        this.redirectUser('/organization/settings');
      } else if (redirectPath) {
        this.redirectUser(redirectPath.path);
      } else if (isNetworkLead || this.props.showPayerInvoices) {
        goToInvoicesIndex();
        browserHistory.push('/');
      }
    });
  }

  setNetworkId(providerId) {
    const { user } = this.props;
    this.props.fetchActiveNetworks(providerId).then((activeNetworks) => {
      const network = coordinatedNetwork(activeNetworks, providerId);
      const currentNetworkId = !_.isEmpty(network) ? network[0].id : activeNetworks[0].id;
      this.props.setCurrentNetwork(currentNetworkId);
      if (isNetworkUser(user, providerId) && !this.props.showPayerInvoices) {
        this.redirectUser(`/network/${currentNetworkId}`);
      }
    });
  }

  setRefreshPoll(pollInterval) {
    try {
      clearInterval(this.pollIntervalId);
      this.pollIntervalId = setInterval(() => {
        this.props.refreshSession(submitLogoutForm);
      }, pollInterval * 1000);
    } catch (error) {
      if (window.Rollbar) {
        window.Rollbar.error(`App.jsx setRefreshPoll exception ${pollInterval}, error: ${error}`);
      }
    }
  }

  redirectUser(path) {
    browserHistory.push(path);
    this.props.clearRedirectPath();
  }

  fetchUser({ retries }) {
    const timeout = retries > 0 ? RETRY_TIMEOUT : 0;

    setTimeout(() => {
      // Check again that user is logged in because the user may have logged
      // out during the timeout.
      if (isLoggedIn()) {
        this.props.fetchUserFromSessionId({ retries });
      }
    }, timeout);
  }

  render() {
    const {
      currentUser,
      session,
      useAutoLogout,
      logoutSeconds,
      warningSeconds,
      globalState: {
        currentEmployee,
        providers,
        currentProvider,
        impersonation,
        intercom: { companies: intercomCompanies, userHash: intercomUserHash },
      },
    } = this.props;
    const isFetchingUser = _.get(currentUser, 'fetchingUser', false);

    // eventTracker should be a global function or a function that can be imported
    // making it globally accessible does not cause harm
    if (!window.eventTracker && this.context.eventTracker) {
      window.eventTracker = this.context.eventTracker;
    }

    if (isUser(currentUser) && requiresEula(session)) {
      const isUserOrgAdmin = isOrganizationAdministrator({
        userRoles: _.get(currentUser, 'groups[0].roles', []),
        allRoles: this.props.globalState.roles,
      });
      const noRedirect = isUserOrgAdmin && providers && providers.length === 1;
      return <EulaAgreement user={currentUser} noRedirect={noRedirect} onAccept={this.setEula} />;
    }

    const isOrgAdmin = isOrganizationAdministrator({
      userRoles: _.get(this.props, 'globalState.currentEmployee.roles', []),
      allRoles: this.props.globalState.roles,
    });

    if (
      isOrgAdmin &&
      isUser(currentUser) &&
      currentProvider &&
      currentProvider.agreements &&
      currentProvider.agreements.length === 0 &&
      !impersonation
    ) {
      return (
        <UniteUsParticipationAgreement
          group={{
            id: null,
            name: currentProvider.group.name,
            provider: currentProvider.group.id,
            employeeId: currentProvider.user.employee.id,
          }}
          onRedirect={!isOrgInDraft(currentProvider.group.state) ?
            undefined :
            () => {
              this.redirectUser('/organization/settings');
            }}
        />
      );
    }

    if (!session.user_support || _.isEmpty(currentUser) || isFetchingUser) {
      return (
        <div className="app__logo-loader">
          <UULogoLoader height={100} />
          <LogoutAuth />
        </div>
      );
    }

    const handleAutoLogout = () => {
      console.log('LogoutLogs: App.jsx: handleAutoLogout called. Logging out.');
      submitLogoutForm();
    };

    const updateActivity = useAutoLogout && mockUpdate(logoutSeconds);
    const { userName, userId, userEmail } = getIntercomParams(currentEmployee);

    return (
      <QueryClientProvider client={queryClient}>
        <IntercomWrapper
          intercomId={INTERCOM_APP_ID}
          session={session}
          source={INTERCOM_SOURCE}
          companies={intercomCompanies}
          userHash={intercomUserHash}
          name={userName}
          userId={userId}
          userEmail={userEmail}
          {...this.props}
        >
          <>
            {(useAutoLogout) && (
              <Autologout
                handleLogout={handleAutoLogout}
                warningSeconds={warningSeconds}
                logoutSeconds={logoutSeconds}
                updateActivity={updateActivity}
              >
                {(args) => (
                  <>
                    {args.warn && (
                      <AutoLogoutDialog
                        onClickContinue={args.onClickContinue}
                        onClickLogout={args.onClickLogout}
                        timeRemaining={warningSeconds}
                      />
                    )}
                  </>
                )}
              </Autologout>
            )}

            <LogoutAuth />

            {!session.groupId ? (
              <GroupSelector
                groups={providers}
                setGroupId={this.setGroupId}
                session={session}
              />
            ) : (
              <GroupWrapper
                groups={providers}
                setGroupId={this.setGroupId}
                {...this.props}
              />
            )}
          </>
        </IntercomWrapper>
      </QueryClientProvider>
    );
  }
}

App.propTypes = {
  checkVersionByAxiosInterceptor: PropTypes.func,
  clearRedirectPath: PropTypes.func,
  currentUser: PropTypes.object,
  enums: PropTypes.object,
  fetchActiveNetworks: PropTypes.func.isRequired,
  fetchCurrentGroup: PropTypes.func.isRequired,
  fetchIpPosition: PropTypes.func.isRequired,
  fetchPosition: PropTypes.func.isRequired,
  fetchUserFromSessionId: PropTypes.func,
  groupId: PropTypes.string,
  location: PropTypes.object,
  logoutSeconds: PropTypes.number,
  logoutUser: PropTypes.func.isRequired,
  refreshSession: PropTypes.func.isRequired,
  refreshTokenPoll: PropTypes.number,
  resetStoreConfigs: PropTypes.func.isRequired,
  session: PropTypes.object,
  sessionExpiredNotification: PropTypes.func.isRequired,
  setCurrentGroup: PropTypes.func,
  setCurrentNetwork: PropTypes.func.isRequired,
  setRedirectPath: PropTypes.func,
  setZendeskReturnToUrl: PropTypes.func.isRequired,
  showPayerInvoices: PropTypes.bool,
  styles: PropTypes.object,
  useAuthPoll: PropTypes.bool,
  useAutoLogout: PropTypes.bool,
  warningSeconds: PropTypes.number,
  zendeskReturnToUrl: PropTypes.string,
  globalState: PropTypes.object.isRequired,
  setCurrentProvider: PropTypes.func.isRequired,
  showPendoFlag: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  logoutLogs: PropTypes.bool,
  showAudioEye: PropTypes.bool,
  impersonationType: PropTypes.string,
  isImpersonation: PropTypes.bool,
};

App.defaultProps = {
  currentUser: {},
  refreshTokenPoll: 600, // 10 minutes
  session: {},
  showPayerInvoices: false,
  logoutLogs: false,
  impersonationType: '',
  isImpersonation: false,
};

App.childContextTypes = {
  enums: PropTypes.object,
  groupId: PropTypes.string,
};

App.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const { flags } = state;
  const {
    autoLogout,
    autoLogoutConfig = {},
    refreshTokenPoll,
    logoutLogs,
  } = flags;
  const warningSeconds = autoLogoutConfig.warning;
  const logoutSeconds = selectLogoutSeconds(state);
  const isImpersonation = Boolean(
    state.session.impersonationType || _.get(state, 'globalState.impersonation'),
  );
  const useAutoLogout = autoLogout && !isImpersonation;
  const useAuthPoll = useAutoLogout;
  const showPendoFlag = showPendo(state);
  const showAudioEye = hbh1430AudioEyeEnabled(state);

  return {
    currentUser: state.user,
    enums: state.session.enums,
    groupId: _.get(state, 'session.groupId'),
    session: state.session,
    showPayerInvoices: hasPayerInvoicesRole(state),
    useAutoLogout,
    useAuthPoll,
    logoutSeconds,
    warningSeconds,
    zendeskReturnToUrl: getZendeskUrl(ownProps),
    globalState: state.globalState,
    showPendoFlag,
    showAudioEye,
    user: state.user,
    refreshTokenPoll,
    logoutLogs,
    impersonationType: state.session.impersonationType,
    isImpersonation,
  };
}

export default connect(mapStateToProps, {
  clearRedirectPath,
  fetchActiveNetworks,
  fetchCurrentGroup,
  fetchIpPosition,
  fetchPosition,
  fetchUserFromSessionId,
  logoutUser,
  refreshSession,
  resetStoreConfigs,
  sessionExpiredNotification,
  setCurrentGroup,
  setCurrentNetwork,
  setRedirectPath,
  setZendeskReturnToUrl,
  setCurrentProvider,
})(App);
