import React from 'react';
import PropTypes from 'prop-types';
import { SelectField } from 'components/Backoffice/form/SelectField';
import { useInvoicePayerOptions } from '../../hooks';

export const PayerFilter = ({
  className,
  onChange,
  value,
}) => {
  const invoicePayerOptions = useInvoicePayerOptions();

  return (
    <SelectField
      className={className}
      label="Payer"
      labelClassName="tracking-normal"
      onChange={({ value: val }) => onChange(val)}
      options={invoicePayerOptions}
      value={value ?? ''}
      placeholder="Choose a Payer"
      clearable
    />
  );
};

PayerFilter.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

PayerFilter.defaultProps = {
  className: undefined,
  value: undefined,
};
