import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { get, isEmpty } from 'lodash';
import { feeSchedulePrograms as fspUtils } from '@unite-us/client-utils';
import { Icon } from '@unite-us/ui';
import { useFind, useFindRecord, usePopulate } from 'src/api/APIHooks';
import { useIsPayer } from 'common/contexts/CurrentProviderContext';
import { CardDetail } from 'src/common/Card';
import formatShortDate from 'src/common/utils/Dates/formatShortDate';
import formatSpend from 'src/common/utils/Money/formatSpend';
import { dollarAmount } from 'src/common/display/Money/DollarAmount';
import {
  PENDING_AND_APPROVED_STATUSES,
} from '../constants';

const AuthorizationDetails = ({
  invoice,
  onDisableSubmitButtonChange,
}) => {
  usePopulate(
    'service_authorization',
    'service_authorization',
    invoice,
    { queryConfig: { placeholderData: undefined } },
  );
  const serviceAuthorization = get(invoice, 'service_authorization', {});
  const serviceAuthorizerName =
    (invoice.service_authorization_reviewer_first_name && invoice.service_authorization_reviewer_last_name) ?
    `${invoice.service_authorization_reviewer_first_name} ${invoice.service_authorization_reviewer_last_name}` :
      'Not found';

  const { data: fspResponse, isLoading: isFspLoading } = useFindRecord(
    'fee_schedule_program',
    invoice.fee_schedule_program_id,
    { queryConfig: { placeholderData: undefined } },
  );
  const feeScheduleProgram = get(fspResponse, 'data.data', {});
  usePopulate(
    'clinical_modifications',
    'clinical_modifications',
    serviceAuthorization,
    { queryConfig: { placeholderData: undefined } },
  );

  const autoAuthorizes = !isEmpty(feeScheduleProgram) && feeScheduleProgram.auto_authorizes;
  const canInvoiceAboveRemainingAuthorizedAmount =
    !isEmpty(feeScheduleProgram) &&
    feeScheduleProgram.can_invoice_above_remaining_authorized_amount;

  const {
    data: invoicedSpendData,
    isFetching: isInvoicedSpendFetching,
    isLoading: isInvoicedSpendLoading,
  } = useFind(
    'invoiced_spend',
    {
      fee_schedule_program: invoice.fee_schedule_program_id,
      person: invoice.client_id,
      service_authorization: serviceAuthorization.id,
      state: PENDING_AND_APPROVED_STATUSES.join(','),
    },
    {
      queryConfig: {
        enabled: !!serviceAuthorization.id,
        refetchOnMount: 'always',
        placeholderData: undefined,
      },
    },
  );
  const metaData = get(
    invoicedSpendData,
    'data.meta',
    {},
  );

  const remainingAuthorizedAmount = metaData.remaining_authorized_amount;

  const isInvoicedSpendLoaded = !isInvoicedSpendFetching && !isInvoicedSpendLoading;
  const exceededInvoiceAmount = isInvoicedSpendLoaded &&
    remainingAuthorizedAmount < 0;

  const isCostBased = fspUtils.isCostPaymentType(invoice.fee_schedule_program_payment_type);
  const remainingAuthorizedAmountInCurrency = dollarAmount(remainingAuthorizedAmount *
    invoice.fee_schedule_program_unit_rate, true);

  // error messages:
  const isPayer = useIsPayer();
  const softCapsErrorText = 'The total amount for this case exceeds the authorized amount. ' +
    `Please verify that the invoice amount is correct prior to ${isPayer ? 'accepting' : 'submitting'}.`;
  const hardCapsErrorText = `This invoice cannot be ${isPayer ? 'accepted' : 'submitted'} ` +
    'because authorized amount has been used.';

  useEffect(() => {
    if (!canInvoiceAboveRemainingAuthorizedAmount) {
      onDisableSubmitButtonChange(exceededInvoiceAmount);
      return () => {
        onDisableSubmitButtonChange(false);
      };
    }
    return () => {};
  }, [canInvoiceAboveRemainingAuthorizedAmount, exceededInvoiceAmount]);

  let payerAuthorizationId;
  if (autoAuthorizes) {
    payerAuthorizationId = 'N/A';
  } else {
    payerAuthorizationId = invoice.service_authorization?.id;
  }

  const {
    service_authorization_approved_starts_at: startsAt,
    service_authorization_approved_ends_at: endsAt,
  } = invoice;
  const serviceAuthorizationDateRange = [startsAt, endsAt].filter(Boolean).map(formatShortDate).join(' - ');

  return (
    <>
      <CardDetail label="Remaining Authorized Amount">
        <div
          className="flex flex-row flex-nowrap items-center py-0"
          data-testid="invoice-remaining-authorized-amount"
        >
          <>{formatSpend(
            remainingAuthorizedAmount,
            invoice.fee_schedule_program_unit,
            isCostBased,
          )}{!isCostBased && ` (${remainingAuthorizedAmountInCurrency})` }
            {exceededInvoiceAmount && (
              <div className="m-0" data-testid="invoice-amount-exceeds-remaining-authorized-amount">
                <Icon
                  className="fill-current text-red pl-1 pt-0 pb-px"
                  icon="IconExclamationCircle"
                  size={16}
                />
              </div>
            )}
          </>
        </div>
      </CardDetail>
      <CardDetail label="Authorized Service Delivery Date(s)">
        {serviceAuthorizationDateRange}
      </CardDetail>
      {
        !isFspLoading && feeScheduleProgram?.cm_code_required_on_authorization && (
          <CardDetail label="ICD-10 Code">
            {serviceAuthorization.clinical_modifications?.map((cm) => cm.code).join(',')}
          </CardDetail>
        )
      }
      <CardDetail label="Authorizer" dataTestId="invoice-authorizer">
        {autoAuthorizes ? 'N/A' : serviceAuthorizerName}
      </CardDetail>
      <CardDetail label="Authorization ID" dataTestId="invoice-authorization-id">
        {autoAuthorizes ? 'N/A' : invoice.service_authorization_short_id}
      </CardDetail>
      <CardDetail label="Payer Authorization ID" dataTestId="invoice-payer-auth-id">
        {payerAuthorizationId}
      </CardDetail>
      {exceededInvoiceAmount && (
        <div className="col-span-3 flex space-x-2 mt-0.5 mb-0">
          <p className="text-red text-13px whitespace-pre-wrap px-7">
            {canInvoiceAboveRemainingAuthorizedAmount ? softCapsErrorText : hardCapsErrorText}
          </p>
        </div>
      )}
    </>
  );
};

AuthorizationDetails.propTypes = {
  invoice: PropTypes.object.isRequired,
  onDisableSubmitButtonChange: PropTypes.func.isRequired,
};

export default AuthorizationDetails;
