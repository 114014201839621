import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import SortableDynamicDataTable from 'src/components/Backoffice/dataTables/SortableDynamicDataTable';
import Pagination from 'src/common/Pagination/Pagination';
import { Icon } from '@unite-us/ui';
import { theme } from 'src/../tailwind.config';
import { DEFAULT_PAGE_SIZE } from 'src/components/Backoffice/backofficeUtils';

const NetworkOrganizationTable = ({
  networks,
  organizations,
  selectedRows,
  setSelectedRows,
  onAdd,
  onRemove,
  ScrollToElementEvent,
}) => {
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [pageNumber, setPageNumber] = useState(1);
  const [{ sortBy, sortDirection }, setSort] = useState({ sortBy: 'Organization Name', sortDirection: 'asc' });
  const data = isEmpty(networks) && isEmpty(organizations) ? [] : [
    ...networks.map((network) => ({
      rowId: `network-${network.id}`,
      data: {
        networkName: network.name,
        organizationName: 'All Organizations',
        id: network.id,
      },
    })),
    ...organizations.map((provider) => ({
      rowId: `provider-${provider.id}`,
      data: {
        networkName: '',
        organizationName: provider.name,
        id: provider.id,
      },
    })),
  ];

  const getPaginatedData = (tableData) => {
    const indexOfLastItem = pageNumber * pageSize;
    const indexOfFirstItem = indexOfLastItem - pageSize;
    return tableData.slice(indexOfFirstItem, indexOfLastItem);
  };

  const getSortedArray = (tableData) => {
    let sortColumn;
    if (sortBy === 'Network Name') sortColumn = 'networkName';
    else if (sortBy === 'Organization Name') sortColumn = 'organizationName';
    else sortColumn = 'id';

    const paginatedData = getPaginatedData(tableData);

    if (sortDirection === 'asc') {
      return paginatedData.sort((a, b) => (a.data[sortColumn] > b.data[sortColumn] ? 1 : -1));
    }
    return paginatedData.sort((a, b) => (a.data[sortColumn] > b.data[sortColumn] ? -1 : 1));
  };

  return (
    <>
      <SortableDynamicDataTable
        title="Requesting Networks/Organizations"
        button={{
          label: 'Add',
          icon: <Icon color={theme.extend.colors['current-blue']} icon="V2Edit" />,
          onClick: onAdd,
          dataTestId: 'add-network-button',
        }}
        secondButton={{
          label: 'Remove',
          icon: <Icon color={theme.extend.colors['current-blue']} icon="IconMinusCircle" />,
          onClick: onRemove,
          disable: isEmpty(selectedRows),
          dataTestId: 'remove-network-button',
        }}
        isMultiselect
        table={{
          headers: ['Network Name', 'Organization Name', 'UUID'],
          data: getSortedArray(data, sortBy, sortDirection),
          emptyTableMessage: 'No requesting network/organization linked to this plan.\n' +
            'Click "Add Network/Organization" to add a network/organization to this plan.',
        }}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        sortBy={sortBy}
        sortDirection={sortDirection}
        setSort={setSort}
        dataTestId={'network-organization-search'}
      />
      <d className="p-6">
        <Pagination
          pageNumber={pageNumber}
          pageSize={pageSize}
          totalItemCount={data.length}
          totalPageCount={Math.ceil(data.length / pageSize)}
          setPageSize={setPageSize}
          setPageNumber={setPageNumber}
          scrollPaginationElement={ScrollToElementEvent}
        />
      </d>
    </>
  );
};

NetworkOrganizationTable.propTypes = {
  networks: PropTypes.array.isRequired,
  organizations: PropTypes.array.isRequired,
  selectedRows: PropTypes.array.isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  ScrollToElementEvent: PropTypes.func.isRequired,
};

export default NetworkOrganizationTable;
