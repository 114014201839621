import datesInRange from 'common/utils/Dates/datesInRange';
import nearestDay from 'common/utils/Dates/nearestDay';
import moment from 'moment';
import { maxBy } from 'lodash';

function filterInsurancesByDateRange(insurances, startDate, endDate, excludeExpiredDates = false) {
  return insurances?.filter((insurance) => {
    const enrolledAt = insurance?.enrolled_at && nearestDay(moment(insurance.enrolled_at));
    const expiredAt = excludeExpiredDates ? null :
      insurance?.expired_at && nearestDay(moment(insurance.expired_at));
    return enrolledAt && datesInRange(enrolledAt, expiredAt, startDate, endDate);
  });
}

function getLatestStartingInsurance(insurances) {
  return maxBy(insurances, (insurance) => insurance?.enrolled_at);
}

function findInsuranceByDateRange({
  insurances,
  startDate,
  endDate,
  ignoreSocialCareExpiredDates,
  serviceAuthorization,
  setInsuranceExpired = () => {},
}) {
  if (!insurances) return null;
  if (!startDate) return null;

  setInsuranceExpired(false);

  // Prioritize service authorization insurance if provided
  const candidateInsurances = serviceAuthorization ?
    [insurances?.find((insurance) => insurance.id === serviceAuthorization.insurance.id)] : insurances;

  // Tier 1: Check both start and end dates
  const validInsurancesForDateRange = filterInsurancesByDateRange(candidateInsurances, startDate, endDate);
  let latestStartingInsurance = getLatestStartingInsurance(validInsurancesForDateRange);

  // Tier 2: Ignore expired dates if specified and no valid insurance found
  if (ignoreSocialCareExpiredDates && !latestStartingInsurance) {
    const validInsurancesIncludingExpired = filterInsurancesByDateRange(candidateInsurances, startDate, endDate, true);
    latestStartingInsurance = getLatestStartingInsurance(validInsurancesIncludingExpired);
    setInsuranceExpired(!!latestStartingInsurance);
  }

  return latestStartingInsurance || null;
}

function requestDateOutOfRange(requestDate, startDate, endDate) {
  if (!requestDate) return false;
  const requestMoment = moment(requestDate);
  return (startDate && requestMoment.isBefore(moment(startDate))) ||
    (endDate && requestMoment.isAfter(moment(endDate)));
}

export { findInsuranceByDateRange, requestDateOutOfRange };
