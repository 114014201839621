import moment from 'moment';

const constructPlanFormPayload = (plan, payerId) => {
  const {
    enrollment_required,
    starts_at,
    ends_at,
    external_id,
    region,
    coverage_period,
    networks,
    plan_type,
    providers,
  } = plan;

  const data = {
    ...plan,
    enrollment_required: enrollment_required === 'true',
    starts_at: moment(starts_at, 'MM/DD/YYYY').toISOString(),
    ends_at: moment(ends_at, 'MM/DD/YYYY').toISOString(),
    external_id: external_id ?? null,
    region: region ?? null,
    payer: { id: payerId },
    coverage_period: coverage_period ? parseInt(coverage_period, 10) : null,
  };

  if (providers?.length) {
    data.providers = providers.map((provider) => ({ id: provider.id }));
  }

  if (plan_type === 'social' && networks?.length) {
    data.networks = networks.map((network) => ({ id: network.id }));
  }

  if (plan_type !== 'social') {
    data.enrollment_required = false;
    data.coverage_period = null;
    delete data.networks;
  }

  return data;
};

export default constructPlanFormPayload;
