import React from 'react';
import PropTypes from 'prop-types';
import { phones as phonesUtils } from '@unite-us/client-utils';
import enumDisplayName from '../utils/enumDisplayName';

const PhoneNumber = ({ phone }) => (
  <div className="flex items-center">
    <div className="flex flex-col">
      {phone.phone_type && (
        <span className="italic">
          {enumDisplayName(phone.phone_type, 'contact_data.phone_types')}
        </span>
      )}
      <span className="max-w-xs">
        {phonesUtils.formatPhoneNumber(phone.phone_number)}
        {phone.extension && ` ext. ${phone.extension}`}
        {phone.is_primary && ' (Primary)'}
      </span>
      <div className="text-sm">
        {phone.acceptable_communication_types && (
          <span className="italic">
            {phone.acceptable_communication_types
              .map((type) => enumDisplayName(type, 'contact_data.communication_types'))
              .join(', ')}
          </span>
        )}
      </div>
    </div>
  </div>
);

PhoneNumber.propTypes = {
  phone: PropTypes.shape({
    phone_number: PropTypes.string.isRequired,
    is_primary: PropTypes.bool,
    acceptable_communication_types: PropTypes.arrayOf(PropTypes.string),
    country_code: PropTypes.string,
    phone_type: PropTypes.string,
    extension: PropTypes.string,
    can_sms: PropTypes.bool,
  }).isRequired,
};

export default PhoneNumber;
