import React from 'react';
import PropTypes from 'prop-types';
import {
  ColumnHeader,
  SortableColumnHeader,
  HeaderRow,
  Table,
  TableBody,
} from 'src/common/tables/V2';
import { intersection, noop } from 'lodash';
import { ENROLLMENT_BULK_ACTION_LIMIT } from 'src/pages/enrollments/constants';
import SelectAllCheckboxColumnHeader from '../../../common/Workqueue/SelectAllCheckboxColumnHeader';
import EnrollmentsTableRow from './EnrollmentsTableRow';
import useFeatureFlag from '../../../common/hooks/useFeatureFlag';

const EnrollmentsTable = ({
  enrollmentRequests,
  openDrawer,
  showEnrollmentAging,
  sortBy,
  sortDirection,
  setSort,
  selectedRows,
  showSelectMultipleEnrollments,
  toggleSelectedRow,
  toggleSelectAllRows,
}) => {
  const pays6096UpdateEWQDesign = useFeatureFlag('pays-6096-update-ewq-design');
  const selectedEnrollmentIds = selectedRows.map((row) => row.id);

  const allRowsOnCurrentPageSelected = intersection(
    selectedEnrollmentIds,
    enrollmentRequests.map((val) => val.id),
  ).length === enrollmentRequests.length;

  return (
    <Table className="bg-white w-full table-fixed">
      <HeaderRow>
        {
          pays6096UpdateEWQDesign && showSelectMultipleEnrollments && (
            <SelectAllCheckboxColumnHeader
              isChecked={allRowsOnCurrentPageSelected}
              onCheckColumn={() => toggleSelectAllRows(enrollmentRequests)}
              ariaLabel="Select all enrollment requests on page"
              disabled={selectedRows.length >= ENROLLMENT_BULK_ACTION_LIMIT && !allRowsOnCurrentPageSelected}
            />
          )
        }
        <ColumnHeader className="w-24 font-medium">Request ID</ColumnHeader>
        <ColumnHeader className="w-24 font-medium">Request Type</ColumnHeader>
        <SortableColumnHeader
          className="w-24 font-medium"
          colName="person.last_name"
          label="client name"
          sortBy={sortBy}
          sortDirection={sortDirection}
          setSort={setSort}
        >
          Client Name
        </SortableColumnHeader>
        <SortableColumnHeader
          className="w-24 font-medium"
          colName="enrollment_status"
          label="status"
          sortBy={sortBy}
          sortDirection={sortDirection}
          setSort={setSort}
        >
          Status
        </SortableColumnHeader>
        <SortableColumnHeader
          className="w-24 font-medium"
          colName="plan.name"
          label="social care coverage plan"
          sortBy={sortBy}
          sortDirection={sortDirection}
          setSort={setSort}
        >
          Social Care Coverage Plan
        </SortableColumnHeader>
        <SortableColumnHeader
          className="w-24 font-medium"
          colName="updated_at"
          label="last updated"
          sortBy={sortBy}
          sortDirection={sortDirection}
          setSort={setSort}
        >
          Last Updated
        </SortableColumnHeader>
        {showEnrollmentAging && (
          <SortableColumnHeader
            className="w-24 font-medium"
            colName="created_at"
            label="time in queue"
            sortBy={sortBy}
            sortDirection={sortDirection}
            setSort={setSort}
          >
            Time In Queue
          </SortableColumnHeader>
        )}
      </HeaderRow>
      <TableBody data-testid="enrollments-table-body">
        {enrollmentRequests.map((request, idx) => (
          <EnrollmentsTableRow
            isChecked={selectedEnrollmentIds.includes(request.id)}
            toggleSelectedRow={() => toggleSelectedRow(request)}
            key={request.id}
            enrollmentRequest={request}
            openDrawer={() => openDrawer(idx)}
            showEnrollmentAging={showEnrollmentAging}
            showSelectMultipleEnrollments={showSelectMultipleEnrollments}
            checkDisabled={selectedRows.length >= ENROLLMENT_BULK_ACTION_LIMIT &&
              !selectedEnrollmentIds.includes(request.id)}
          />
          ))}
      </TableBody>
    </Table>
  );
};

EnrollmentsTable.propTypes = {
  enrollmentRequests: PropTypes.array.isRequired,
  openDrawer: PropTypes.func.isRequired,
  showEnrollmentAging: PropTypes.bool,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  setSort: PropTypes.func.isRequired,
  selectedRows: PropTypes.array,
  showSelectMultipleEnrollments: PropTypes.bool,
  toggleSelectedRow: PropTypes.func,
  toggleSelectAllRows: PropTypes.func,
};

EnrollmentsTable.defaultProps = {
  showEnrollmentAging: false,
  selectedRows: [],
  showSelectMultipleEnrollments: false,
  toggleSelectAllRows: noop,
  toggleSelectedRow: noop,
};

export default EnrollmentsTable;
