import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SearchableField } from 'components/Backoffice/form/SearchableField';
import { useFeeScheduleScreeningOptions } from '../../hooks';

export const ScreeningNameFilter = ({
  onChange,
}) => {
  const [screeningNameSearch, setScreeningNameSearch] = useState();
  const feeScheduleScreeningOptions = useFeeScheduleScreeningOptions(screeningNameSearch);

  return (
    <SearchableField
      label="Screening Name"
      labelClassName="tracking-normal"
      onChange={({ value: val }) => onChange(val)}
      searchQuery={screeningNameSearch}
      options={feeScheduleScreeningOptions}
      onInputChange={setScreeningNameSearch}
      placeholder="Text"
      truncateOptions={false}
      clearable
    />
  );
};

ScreeningNameFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
};
