import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import { TrackerContext, ResourcesHeader } from '@unite-us/client-utils';
import { connect } from 'react-redux';
import { Tabs, Tab } from '@unite-us/ui';
import { useSelectedPrograms } from '@unite-us/app-search';
import { updateGlobalState } from 'actions/Global/globalActions';

import { MY_NETWORKS } from 'common/utils/EventTracker/utils/eventConstants';
import GroupLogo from 'src/components/Group/components/GroupLogo';
import {
  uup459SupersetPhase2,
  cerb1199IsSupersetMyNetwork as cerb1199IsSupersetMyNetworkSelector,
} from '../../../common/utils/FeatureFlags/flags';
import './NetworkSubHeader.scss';

function NetworkSubHeader({
  network,
  router,
  isSupersetEnabled,
  cerb1199IsSupersetMyNetwork,
  isShoppingCartOpen,
  setIsShoppingCartOpen,
  setShoppingCartDrawerRef,
}) {
  if (!isSupersetEnabled && !network) {
    return null;
  }

  useEffect(() => {
    setIsShoppingCartOpen(false);
  }, []);

  const pathname = window.location.pathname.split('/');
  const currentTab = pathname[pathname.length - 1];
  const paramsIncludesCurrentTab = ['browse', 'orgs', 'users'].includes(currentTab);
  const [tabsValue, setTabsValue] = useState(paramsIncludesCurrentTab ? currentTab : 'browse');
  const eventTracker = useContext(TrackerContext);
  const { selectedPrograms } = useSelectedPrograms();

  const handleChange = (value) => {
    eventTracker(MY_NETWORKS[`${value || 'browse'}TabClicked`]);

    if (isSupersetEnabled) {
      router.push(`/browse-resources/${value === 'browse' ? '' : value}`);
    } else {
      router.push(`/network/${network.id}/${value === 'browse' ? '' : value}`);
    }
    setTabsValue(value);
  };

  const onSelectedProgramsClick = () => (event) => {
    setShoppingCartDrawerRef(event.currentTarget);
    setIsShoppingCartOpen(!isShoppingCartOpen);
  };

  const subheaderCustomStyles = cerb1199IsSupersetMyNetwork ? 'network-subheader-cerb1199IsSupersetMyNetwork' : null;

  return (
    <div className={classNames('network-subheader', subheaderCustomStyles)}>
      {/* eslint-disable-next-line max-len */}
      {cerb1199IsSupersetMyNetwork && (
        <ResourcesHeader
          showAddedResourcesBtn={tabsValue === 'browse'}
          selectedProgramsAmount={selectedPrograms.length}
          onSelectedProgramsClick={onSelectedProgramsClick}
        />
      )}
      <div className="network-subheader__tabs">
        <Tabs
          id="network-subheader__profile network-profile-tabs"
          className="network-profile-tabs"
          value={tabsValue}
          onChange={handleChange}
        >
          <Tab
            id="browse-map-tab"
            className="network-subheader__tab"
            value="browse"
            label={cerb1199IsSupersetMyNetwork ? 'Browse Resources' : 'My Network'}
          />
          <Tab
            id="org-tab"
            className="network-subheader__tab"
            value="orgs"
            label="Organizations"
          />
          <Tab
            id="users-tab"
            className="network-subheader__tab"
            value="users"
            label="Users"
          />
        </Tabs>
      </div>
      {!isSupersetEnabled && (
        <div className="network-subheader__network-logo">
          <GroupLogo
            className="network-sidebar__group-logo"
            group={network}
            size="xs"
          />
          <h3 className="network-sidebar__name">{network.name}</h3>
        </div>
      )}
    </div>
  );
}

NetworkSubHeader.propTypes = {
  network: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isSupersetEnabled: PropTypes.bool.isRequired,
  isShoppingCartOpen: PropTypes.bool.isRequired,
  setIsShoppingCartOpen: PropTypes.func.isRequired,
  setShoppingCartDrawerRef: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  networkId: PropTypes.string, // for mapStateToProps
  cerb1199IsSupersetMyNetwork: PropTypes.bool.isRequired,
};

NetworkSubHeader.defaultProps = {
  network: null,
  networkId: null,
};

NetworkSubHeader.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

function mapStateToProps(state, { networkId }) {
  return {
    network: state.globalState.activeNetworks.find((n) => n.id === networkId),
    isSupersetEnabled: uup459SupersetPhase2(state),
    cerb1199IsSupersetMyNetwork: cerb1199IsSupersetMyNetworkSelector(state),
    isShoppingCartOpen: state.globalState.isShoppingCartOpen,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setIsShoppingCartOpen: (isOpen) => dispatch(updateGlobalState({ isShoppingCartOpen: isOpen })),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NetworkSubHeader),
);
