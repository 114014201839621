import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { noop } from 'lodash';
import { Icon } from '@unite-us/ui';
import InvoiceRejectionForm from 'src/pages/invoices/components/InvoiceRejectionForm';
import Notifier from 'src/common/helpers/Notifier';
import { TextButton } from 'src/common/TailwindComponents';
import { useArchiveRecord, useUnarchiveRecord } from 'common/hooks';
import { useInvalidateQueries } from 'src/api/APIHooks';
import ToolTip from 'common/ToolTip';
import SpinIcon from 'src/common/spinners/SpinIcon';
import BulkActionsForm from './BulkActionsForm';
import { selectCurrentBulkActions } from '../utils/selectCurrentBulkActions';
import { READ_ONLY_BULK_ACTION_ICONS } from '../constants';

const BulkActions = ({
  allInvoicesLoading,
  bulkActions,
  className,
  deselectAll,
  onClose,
  downloadInvoices,
  selectAll,
  selectedInvoices,
  deselectInvoices,
  invoices,
  currentEmployeeId,
  payerWQInteractiveView,
 }) => {
  const invalidateQueries = useInvalidateQueries();
  const bulkRejectionModalRef = useRef();
  const bulkActionsFormRef = useRef();
  const [bulkActionsFormOpen, setBulkActionsFormOpen] = useState(false);

  const openRejectionModal = () => {
    bulkRejectionModalRef.current.openDialog();
  };

  const closeRejectionModal = () => {
    bulkRejectionModalRef.current.closeDialog();
  };

  const closeBulkActionForm = () => {
    setBulkActionsFormOpen(false);
    bulkActionsFormRef.current.closeDialog();
  };

  const openBulkActionForm = () => {
    setBulkActionsFormOpen(true);
    bulkActionsFormRef.current.openDialog();
  };

  const unarchiveInvoice = useUnarchiveRecord('invoice', {
      onSuccess: () => {
        onClose();
        deselectAll();
        invalidateQueries('invoice');
        Notifier.dispatch('success', 'Invoices unarchived.');
      },
  });

  const bulkUnarchiveInvoices = () => {
    selectedInvoices.forEach((invoiceId) => unarchiveInvoice({ recordId: invoiceId }));
  };

  const archiveInvoice = useArchiveRecord('invoice', {
      onSuccess: () => {
        onClose();
        deselectAll();
        invalidateQueries('invoice');
        Notifier.dispatch('success', 'Invoices archived.');
      },
    });

  const bulkArchiveInvoices = () => {
    selectedInvoices.forEach((invoiceId) => archiveInvoice({ recordId: invoiceId, employeeId: currentEmployeeId }));
  };

  const selectBulkActionModalForms = (currentBulkActionName) => {
    let openBulkActionsForm;
    let closeBulkActionsForm;

    switch (currentBulkActionName) {
      case 'Reject':
        openBulkActionsForm = openRejectionModal;
        closeBulkActionsForm = closeRejectionModal;
        break;
      case 'Download':
        openBulkActionsForm = downloadInvoices;
        closeBulkActionsForm = noop;
        break;
      case 'Archive':
        openBulkActionsForm = bulkArchiveInvoices;
        closeBulkActionsForm = noop;
        break;
      case 'Unarchive':
        openBulkActionsForm = bulkUnarchiveInvoices;
        closeBulkActionsForm = noop;
        break;
      default:
        openBulkActionsForm = openBulkActionForm;
        closeBulkActionsForm = closeBulkActionForm;
    }

    return {
      open: openBulkActionsForm,
      close: closeBulkActionsForm,
      bulkActionName: currentBulkActionName,
    };
  };
  const bulkActionsContent = {
    IconDownloadV2: 'Download',
    IconCheckCircle: 'Accept',
    LogoShield: 'Pay',
    IconArchive: 'Archive',
    IconCrossCircleV2: 'Reject',
    IconUndo: 'Unarchive',
  };

  const IconComponent = (bulkActionIconName, disableCurrentAction, openBulkActionsForm) => {
    const iconName = bulkActionsContent[bulkActionIconName];
    const readOnlyPayerWQView = !payerWQInteractiveView && !READ_ONLY_BULK_ACTION_ICONS.includes(iconName);
    const disableBulkAction = readOnlyPayerWQView ? true : disableCurrentAction;
    return (
      <div>
        <span className="text-lg flex flex-row" data-testid={`bulkaction-${bulkActionIconName}-data-testid`}>
          <button
            aria-label={iconName}
            className={disableCurrentAction ? 'fill-current opacity-25' : 'fill-current'}
            onClick={openBulkActionsForm}
            tabIndex={0}
            type="button"
            disabled={disableBulkAction}
            data-testid="bulk-action-button"
          >
            <Icon
              icon={bulkActionIconName}
              className={disableCurrentAction ? 'fill-current opacity-25' : 'fill-current'}
              size={25}
            />
          </button>
          <p
            className={
              `m-0 pb-0 self-end ${disableCurrentAction ? 'fill-current opacity-25' : 'fill-current'}`
            }
            style={{ fontSize: 20, fontWeight: 600 }}
          >
            <button type="button" onClick={openBulkActionsForm} disabled={disableBulkAction}>
              {bulkActionIconName === 'LogoShield' ? 'Pay' : ''}
            </button>
          </p>
        </span>
      </div>
    );
  };

  const currentBulkActionsSelected = selectCurrentBulkActions(invoices, selectedInvoices);
  const currentBulkActionInvoices = selectedInvoices.map((invoiceId) => {
    const selectInvoice = invoices.find((invoice) => invoice.id === invoiceId);
    return selectInvoice;
  });

  const validBulkActionInvoices = currentBulkActionInvoices.every((value) => value !== undefined);

  const ToolTipContent = (bulkActionIconName) => (
    <p className="bg-medium-gray text-white font-bold mb-2 w-50 p-4 border-solid opacity-30 rounded">
      {bulkActionsContent[bulkActionIconName]}
    </p>
   );

  const BulkActionTooltip = (bulkActionIconName, disableCurrentAction, openModal) => (
    <ToolTip
      buttonClassName="focus:outline-none"
      buttonComponentContent={() => IconComponent(bulkActionIconName, disableCurrentAction, openModal)}
      panelComponentContent={() => ToolTipContent(bulkActionIconName)}
      enableHover
      hoverDelay={0}
      panelClassName={`z-modal ${disableCurrentAction ? 'disabled' : ''}`}
      placement="bottom"
      data-testid={`${bulkActionIconName}-data-testid`}
      offsetOptions={{ offset: [0, 5] }}
      flipOptions={{ fallbackPlacements: [] }}
    />
    );

  return (
    <div className={cx(className, 'flex items-center space-x-4')}>
      <div className="flex divide-x divide-solid divide-dark-border-blue">
        <span className="pr-2">
          {allInvoicesLoading ? (
            <SpinIcon />
          ) : (
            <div className="text-text-blue font-medium-font">
              {selectedInvoices.length > 0 ? selectedInvoices.length : 'None'}
              &nbsp;selected
            </div>
          )}
        </span>
        <TextButton onClick={selectAll} className="pr-2 pl-2">All</TextButton>
        <TextButton onClick={deselectAll} className="pl-2">None</TextButton>
      </div>
      <div className="flex space-x-4">
        {
          bulkActions.map((bulkActionIconName, index) => {
            const disableCurrentAction = !currentBulkActionsSelected[bulkActionsContent[bulkActionIconName]];
            const currentBulkActionName = bulkActionsContent[bulkActionIconName];
            const bulkActionModals = selectBulkActionModalForms(currentBulkActionName);

            const selectBulkActionForm = (currentBulkActionName === 'Pay' ||
              currentBulkActionName === 'Accept') && selectedInvoices.length >= 1;

            const iconName = bulkActionsContent[bulkActionIconName];
            const readOnlyPayerWQView = !payerWQInteractiveView && !READ_ONLY_BULK_ACTION_ICONS.includes(iconName);

            if (readOnlyPayerWQView) return null;
            return (
              <div className="cursor-pointer" key={bulkActionIconName}>
                {
                  <div>
                    {
                      BulkActionTooltip(bulkActionIconName, disableCurrentAction, bulkActionModals.open)
                    }
                    {
                      currentBulkActionName === 'Reject' && (
                        selectedInvoices.length === 1 ? (
                          <InvoiceRejectionForm
                            invoice={validBulkActionInvoices ? currentBulkActionInvoices[0] : []}
                            isNetworkLead={false}
                            isPayerProvider
                            close={closeRejectionModal}
                            setRef={bulkRejectionModalRef}
                            onSuccess={() => {
                              onClose();
                              deselectAll();
                            }}
                          />
                        ) : (
                          <></>
                        )
                      )
                    }
                    {
                     selectBulkActionForm && (
                     <BulkActionsForm
                       invoices={validBulkActionInvoices ? currentBulkActionInvoices : []}
                       bulkActionName={currentBulkActionName}
                       close={bulkActionModals.close}
                       setRef={bulkActionsFormRef}
                       onSuccess={() => {
                            onClose();
                            deselectAll();
                          }}
                       bulkActionIconName={bulkActionIconName}
                       index={index}
                       deselectInvoices={deselectInvoices}
                       isOpen={bulkActionsFormOpen}
                     />
                      )
                    }
                  </div>
                }
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

BulkActions.propTypes = {
  allInvoicesLoading: PropTypes.bool.isRequired,
  bulkActions: PropTypes.array.isRequired,
  className: PropTypes.string,
  deselectAll: PropTypes.func.isRequired,
  downloadInvoices: PropTypes.func.isRequired,
  selectAll: PropTypes.func.isRequired,
  selectedInvoices: PropTypes.array.isRequired,
  invoices: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  deselectInvoices: PropTypes.func,
  currentEmployeeId: PropTypes.string,
  payerWQInteractiveView: PropTypes.bool,
};

BulkActions.defaultProps = {
  className: '',
  deselectInvoices: () => {},
  currentEmployeeId: '',
  payerWQInteractiveView: false,
};

export default BulkActions;
