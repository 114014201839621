import React from 'react';
import PropTypes from 'prop-types';
import {
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
  Button,
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from '@unite-us/ui';
import { SortableColumnHeader } from 'src/common/tables/V2';
import { isEmpty } from 'lodash';
import Pagination from 'src/common/Pagination/Pagination';
import EmptyTable from 'src/common/tables/EmptyTable/EmptyTable';
import './SortableDynamicDataTable.scss';

const SortableDynamicDataTable = ({
  title,
  selectedRows,
  setSelectedRows,
  withPadding,
  button,
  secondButton,
  table,
  withPagination,
  pagination,
  isMultiselect,
  sortBy,
  sortDirection,
  setSort,
}) => {
  const toggleRowSelection = (rowId) => {
    setSelectedRows((prevSelectedRows) => (
        prevSelectedRows.includes(rowId) ?
        prevSelectedRows.filter((id) => id !== rowId) :
        [...prevSelectedRows, rowId]));
  };

  const selectAllRows = () => {
    if (selectedRows.length === table.data.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(table.data?.map((row) => row.rowId));
    }
  };

  const tableBorder = table.withBorder ?
    'rounded-b-none ui-dynamic-table' : 'border-0 border-t rounded-none ui-dynamic-table';
  const emptyTableBorder = table.withBorder ? 'p-6 border border-t-0 border-solid border-pale-grey' : 'p-6';

  return (
    <div className="dynamic-data-table-container">
      <BaseCard className="p-6 pb-0" noBorder>
        <BaseCardHeader title={title} noBorder className="px-4 font-bold">
          <div className="flex gap-4">
            <Button
              id="add-network-button"
              iconLeft={button.icon}
              className="flex"
              label={button.label}
              onClick={button.onClick}
              disabled={button.disable}
            />
            {secondButton && (
              <Button
                id="remove-network-button"
                iconLeft={secondButton.icon}
                className="flex"
                label={secondButton.label}
                onClick={secondButton.onClick}
                disabled={secondButton.disable}
                data-testid={secondButton.dataTestId}
              />
            )}
          </div>
        </BaseCardHeader>
        <BaseCardBody withPadding={withPadding}>
          <Table className={tableBorder}>
            <TableHeader>
              <TableRow dataTestId="data-row-header">
                {isMultiselect && (
                  <TableHeaderColumn style={{ width: '40px' }}>
                    <input
                      type="checkbox"
                      checked={
                        selectedRows.length === table.data.length && table.data.length > 0
                      }
                      onChange={selectAllRows}
                    />
                  </TableHeaderColumn>
                )}
                {table.headers.map((header) => (
                  <SortableColumnHeader
                    colName={header}
                    className="ui-table-header-column"
                    label={header}
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                    setSort={setSort}
                  >
                    {header}
                  </SortableColumnHeader>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {table.data.map(({ rowId, data }, index) => (
                <TableRow
                  key={`data-row-${rowId}`}
                  dataTestId={`data-row-${index}`}
                >
                  {isMultiselect && (
                    <TableRowColumn style={{ width: '40px' }}>
                      <input
                        type="checkbox"
                        checked={selectedRows.includes(rowId)}
                        onChange={() => toggleRowSelection(rowId)}
                      />
                    </TableRowColumn>
                  )}
                  {Object.keys(data).map((key) => (
                    <TableRowColumn key={key}>{data[key]}</TableRowColumn>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {isEmpty(table.data) && (
            <EmptyTable
              className={emptyTableBorder}
              message={table.emptyTableMessage}
            />
          )}
          {withPagination && (
            <div className="mt-6 mb-6">
              <Pagination
                pageNumber={pagination.pageNumber}
                pageSize={pagination.pageSize}
                totalItemCount={pagination.totalItemCount}
                totalPageCount={pagination.totalPageCount}
                setPageSize={pagination.setPageSize}
                setPageNumber={pagination.setPageNumber}
                scrollPaginationElement={pagination.scrollPaginationElement}
              />
            </div>
          )}
        </BaseCardBody>
      </BaseCard>
    </div>
  );
};

SortableDynamicDataTable.propTypes = {
  title: PropTypes.string.isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  withPadding: PropTypes.bool,
  button: PropTypes.shape({
    label: PropTypes.string.isRequired,
    icon: PropTypes.element,
    onClick: PropTypes.func.isRequired,
    dataTestId: PropTypes.string,
    disable: PropTypes.bool,
  }).isRequired,
  secondButton: PropTypes.shape({
    label: PropTypes.string.isRequired,
    icon: PropTypes.element,
    onClick: PropTypes.func.isRequired,
    dataTestId: PropTypes.string,
    disable: PropTypes.bool,
  }),
  table: PropTypes.shape({
    headers: PropTypes.arrayOf(PropTypes.string).isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
      rowId: PropTypes.string.isRequired,
      data: PropTypes.object.isRequired,
    })).isRequired,
    emptyTableMessage: PropTypes.string,
    withBorder: PropTypes.bool,
  }).isRequired,
  withPagination: PropTypes.bool,
  pagination: PropTypes.shape({
    pageNumber: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    totalItemCount: PropTypes.number.isRequired,
    totalPageCount: PropTypes.number.isRequired,
    setPageSize: PropTypes.func.isRequired,
    setPageNumber: PropTypes.func.isRequired,
    scrollPaginationElement: PropTypes.func.isRequired,
  }),
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  setSort: PropTypes.func.isRequired,
  isMultiselect: PropTypes.bool,
};

SortableDynamicDataTable.defaultProps = {
  withPadding: false,
  withPagination: false,
  isMultiselect: false,
  secondButton: null,
  pagination: {
    pageNumber: 1,
    pageSize: 10,
    totalItemCount: 0,
    totalPageCount: 0,
    setPageSize: () => {},
    setPageNumber: () => {},
    scrollPaginationElement: () => {},
  },
};

export default SortableDynamicDataTable;
