import { isNil } from 'lodash';

export const getAssistanceRequestPathname = (assistanceRequest) => {
  const caseState = assistanceRequest?.case?.state;
  const hasOutcome = !isNil(assistanceRequest?.case?.outcome);

  if (!isNil(caseState) && caseState !== 'assistance_requested') {
    return `/dashboard/assistance-requests/processed/${assistanceRequest.case.id}`;
  }

  if (caseState === 'assistance_requested') {
    return hasOutcome ?
      `/dashboard/assistance-requests/closed/${assistanceRequest.case.id}` :
      `/dashboard/assistance-requests/new/${assistanceRequest.id}`;
  }

  return '/dashboard';
};
