import React from 'react';
import { get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  TableBody,
  TableRow,
  TableRowColumn,
} from '@unite-us/ui';
import { browserHistory } from 'common/utils/browserHistory';
import { getAssistanceRequestPathname } from './getAssistanceRequestPathname';

export default function AssistanceRequests({
  assistanceRequests,
}) {
  return (
    <TableBody>
      {assistanceRequests.map((assistanceRequest) => (
        <TableRow
          key={`table - row - ar - ${assistanceRequest.id}`}
          clickable
          hoverable
          onCellClick={() => browserHistory.push({
           pathname: getAssistanceRequestPathname(assistanceRequest),
           state: { prevLocation: browserHistory.getCurrentLocation().pathname },
          })}
          style={{ borderBottom: '1px solid #E5E5E5' }}
        >
          <TableRowColumn>
            Assistance Request
          </TableRowColumn>
          <TableRowColumn>
            Client
          </TableRowColumn>
          <TableRowColumn>
            {assistanceRequest.provider.name}
          </TableRowColumn>
          <TableRowColumn>
            {moment(get(assistanceRequest, 'created_at') * 1000).format('MMMM DD, YYYY')}
          </TableRowColumn>
          <TableRowColumn>
            Assistance Request
          </TableRowColumn>
        </TableRow>
      ))}
    </TableBody>
  );
}

AssistanceRequests.propTypes = {
  assistanceRequests: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    provider: PropTypes.shape({
      name: PropTypes.string,
    }),
    created_at: PropTypes.number,
  })),
};

AssistanceRequests.defaultProps = {
  assistanceRequests: [],
};
