import React from 'react';
import PropTypes from 'prop-types';
import { SelectField } from 'components/Backoffice/form/SelectField';
import { usePayerProviderOptions } from '../../hooks';

export const PayerProviderFilter = ({
  className,
  labelClassName,
  onChange,
  value,
}) => {
  const payerProviders = usePayerProviderOptions();
  return (
    <SelectField
      className={className}
      label="Provider"
      labelClassName={labelClassName}
      onChange={({ value: val }) => onChange(val)}
      options={payerProviders}
      value={value ?? ''}
      placeholder="Choose a Provider"
      clearable
    />
  );
};

PayerProviderFilter.propTypes = {
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

PayerProviderFilter.defaultProps = {
  className: undefined,
  labelClassName: undefined,
  value: undefined,
};
