import React from 'react';
import PropTypes from 'prop-types';
import { DurationField } from '@unite-us/ui';

export const ServiceDeliveryDatesFilter = ({
  startsAt,
  setStartsAt,
  endsAt,
  setEndsAt,
}) => (
  <DurationField
    className="w-2/3"
    id="payer-filters-drawer-date-filter"
    label="Service Delivery Date(s)"
    labelClassName="leading-snug normal-case text-13px text-text-blue tracking-normal"
    startField={{
      value: startsAt,
      onChange: (value) => setStartsAt(value),
    }}
    endField={{
      value: endsAt,
      onChange: (value) => setEndsAt(value),
    }}
    format="MM/DD/YYYY"
    placeholder="Choose Date Range"
  />
);

ServiceDeliveryDatesFilter.propTypes = {
  startsAt: PropTypes.string.isRequired,
  setStartsAt: PropTypes.func.isRequired,
  endsAt: PropTypes.string.isRequired,
  setEndsAt: PropTypes.func.isRequired,
};
