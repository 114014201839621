import React from 'react';
import PropTypes from 'prop-types';
import { RadioField } from 'components/Backoffice/form';
import { InputField } from '@unite-us/ui';
import EditButton from './components/EditButton';
import { NETWORK_ORG_RADIO_OPTIONS } from './constants';

const NetworkOrganizationOptions = ({
  setViewMode,
  optionSelected,
  setOptionSelected,
  selectedNetwork,
  setSelectedOrganizationIds,
}) => {
  const onChange = (value) => {
    setOptionSelected(value);
    if (value === 'add-network-selected') {
      setSelectedOrganizationIds([]);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-col flex-grow-0 relative">
        <div className="flex">
          <h1 className="pt-1 pb-2 font-bold text-brand-blue">Network</h1>
          <div className="absolute right-0">
            <EditButton
              edit={() => { setViewMode('add-network'); setOptionSelected(''); }}
              id={`edit-network$-${selectedNetwork.id}`}
              aria-label={`edit-network-${selectedNetwork.id}`}
              label="Change Network"
            />
          </div>
        </div>
        <div className="flex-grow p-0 w-full">
          <InputField
            id="search-network-input"
            className="w-full"
            style={{ input: { paddingLeft: '0.5rem' } }}
            value={selectedNetwork?.name}
            label=""
            disabled
            autoComplete="off"
          />
        </div>

        <RadioField
          className="flex flex-wrap pb-2 px-1"
          id="network-org-radio-group"
          options={NETWORK_ORG_RADIO_OPTIONS}
          onChange={onChange}
          value={optionSelected}
          dataTestId="network-org-radio-group"
        />
      </div>
    </div>
  );
};

NetworkOrganizationOptions.propTypes = {
  setViewMode: PropTypes.func.isRequired,
  optionSelected: PropTypes.string.isRequired,
  setOptionSelected: PropTypes.func.isRequired,
  selectedNetwork: PropTypes.object.isRequired,
  setSelectedOrganizationIds: PropTypes.func.isRequired,
};

export default NetworkOrganizationOptions;
