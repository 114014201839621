import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { tracker } from '@unite-us/client-utils';
import { browserHistory } from 'common/utils/browserHistory';
import IndexDetailView from 'src/components/Dashboard/components/IndexDetailView';
import AssistanceRequestsIndex from 'src/components/Dashboard/components/New/AssistanceRequestsIndex';
import { fetchDashboardPendingAssistanceRequests } from 'actions/AssistanceRequest/Group';
import fetchProvidersUserCore from 'src/actions/UserProvider/fetchProvidersUser';
import _ from 'lodash';
import DetailView from 'src/components/Dashboard/components/DetailView';
import AssistanceRequestDetail from 'src/components/AssistanceRequests/components/AssistanceRequestDetail';
import { getDataOfPage, isDataValid } from 'src/components/Dashboard/utils/dataHelpers';
import callOrLog from 'src/common/utils/callOrLog';
import { careCoordinatorsFilter } from 'src/components/Dashboard/utils/filter';
import { noneAssignedOption } from 'src/components/Dashboard/utils/filterDefaults';
import { parentsOnlyServiceTypesFilter } from 'src/components/Dashboard/utils/filter/serviceTypesFilter';
import { DASHBOARD_VIEWS, DASHBOARD_EVENTS } from 'common/utils/EventTracker/utils/eventConstants';
import { getGroupUsersOptions } from 'src/components/Groups/Users/utils';
import labelCustomization from 'src/common/utils/Customization/labels';
import defaultLabels from 'src/constants/labels';
import { crtb1290SortReferralsByOldest, crtb1645ARDateRangeFilter } from 'src/common/utils/FeatureFlags/flags';

export class NewAssistanceRequests extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeUsersInGroup: [],
      page: 1,
      sidx: 'created_at',
      sord: 'desc',
      filters: {
        service_types: _.get(props, 'filters.service_types', []),
        care_coordinator_users: _.get(props, 'filters.care_coordinator_users', []),
        start_date: _.get(props, 'filters.start_date', []),
        end_date: _.get(props, 'filters.end_date', []),
      },
    };

    this.fetch = this.fetch.bind(this);
    this.fetchGroupsUsers = this.fetchGroupsUsers.bind(this);
    this.onCellClick = this.onCellClick.bind(this);
    this.onFiltersChange = this.onFiltersChange.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
    this.onPrevClick = this.onPrevClick.bind(this);
    this.shouldFetch = this.shouldFetch.bind(this);
  }

  componentDidMount() {
    this.fetch();
    this.props.fetchProvidersUserCore({
      providers: this.props.groupId,
    }).then((response) => {
      if (response) {
        const { employees: activeUsersInGroup } = response;
        this.setState({ activeUsersInGroup });
      }
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (_.get(this.props, 'assistanceRequests.currentPage', 1) !==
      _.get(nextProps, 'assistanceRequests.currentPage', 1)) {
      this.setState({ page: nextProps.assistanceRequests.currentPage });
    }

    if (!this.props.refetch && nextProps.refetch) {
      this.fetch();
    }
  }

  onCellClick(row) {
    const pagedData = getDataOfPage(this.props.assistanceRequests, this.state.page, this.props.sortAscending);
    const assistanceRequest = pagedData.data[row] || {};

    callOrLog(() => this.context.eventTracker(DASHBOARD_EVENTS.assistanceRequestsRow, {
      view: DASHBOARD_VIEWS.assistanceRequests,
    }, { assistanceRequest }));
    browserHistory.push(`/dashboard/assistance-requests/new/${assistanceRequest.id}`);
  }

  onFiltersChange(key, activeFilters, callbacks) {
    let newFilters = activeFilters;
    if (_.isEqual(key, 'care_coordinator_users')) {
      newFilters = careCoordinatorsFilter(activeFilters, callbacks);
    }
    this.setState({
      page: 1,
      filters: { ...this.state.filters, ...(key === 'dates' ? activeFilters : { [key]: newFilters }) },
    }, () => {
      callOrLog(() => {
        this.context.eventTracker(
          DASHBOARD_EVENTS.assistanceRequestsFilter,
          tracker.buildDashboardFilterPayload(this.state, this.props),
        );
      });
      this.fetch();
    });
  }

  onNextClick() {
    const page = this.state.page + 1;

    this.shouldFetch(page);
  }

  onPrevClick() {
    const page = this.state.page - 1;

    this.shouldFetch(page);
  }

  shouldFetch(page) {
    if (isDataValid(this.props.assistanceRequests, page)) {
      this.setState({ page });
    } else {
      this.fetch(page);
    }
  }

  fetch(page = 1) {
    const formattedFilters = _.clone(this.state.filters);

    this.props.fetchDashboardPendingAssistanceRequests(
      this.props.groupId,
      {
        target: 'assistanceRequests',
        sidx: this.state.sidx,
        sord: this.state.sord,
        filters: {
          ...formattedFilters,
          statuses: ['pending'],
        },
        page,
      },
    );
  }

  fetchGroupsUsers(search = '') {
    const { groupId } = this.props;
    return this.props.fetchProvidersUserCore({
      providers: groupId,
      options: {
        text: search,
        licensed: false,
      },
    }).then((response) => {
      if (response) {
        const { employees: activeUsersInGroup } = response;
        if (search === '') {
          return _.concat(
            [noneAssignedOption(this.state)],
            getGroupUsersOptions(activeUsersInGroup, this.state),
          );
        }

        return getGroupUsersOptions(activeUsersInGroup, this.state);
      }

      return [];
    });
  }

  render() {
    const {
      assistanceRequests,
      isFetching,
      params,
      serviceTypes,
      labels,
      addARDateRangeFilter,
    } = this.props;
    const { activeUsersInGroup } = this.state;
    const newParams = { ...params, assistance_request_id: params.id };

    let filters = [
      parentsOnlyServiceTypesFilter(serviceTypes, this.state.filters.service_types),
    ];
    filters = _.concat(filters, [
      {
        key: 'care_coordinator_users',
        name: labels.CareCoordinator,
        pluralName: `${labels.CareCoordinator}s`,
        options: _.concat(
          [noneAssignedOption(this.state)],
          getGroupUsersOptions(activeUsersInGroup, this.state),
        ),
      }]);

    const innerContentView = (
      <AssistanceRequestDetail
        params={newParams}
      />
    );

    const prevLocation = browserHistory.getCurrentLocation()?.state?.prevLocation;
    const pathname = _.isNil(prevLocation) ? '/dashboard/assistance-requests/new' : prevLocation;

    const assistanceRequestsDetailView = (
      <DetailView
        type="assistanceRequests"
        params={this.props.params}
        innerContentView={innerContentView}
        hideMessageCenterButton
        onBackClick={() => browserHistory.push(pathname)}
      />
    );
    const pagedData = getDataOfPage(assistanceRequests, this.state.page, this.props.sortAscending);

    return (
      <IndexDetailView
        params={this.props.params}
        DetailView={assistanceRequestsDetailView}
        IndexView={AssistanceRequestsIndex}
        data={pagedData.data}
        paging={pagedData.paging}
        isFetching={isFetching}
        onNextClick={this.onNextClick}
        onPrevClick={this.onPrevClick}
        onCellClick={this.onCellClick}
        initialSort={{ [this.state.sidx]: this.state.sord }}
        onFiltersChange={this.onFiltersChange}
        filters={_.sortBy(filters, 'name')}
        fetchGroupsUsers={this.fetchGroupsUsers}
        addDateRangeFilter={addARDateRangeFilter}
      />
    );
  }
}

NewAssistanceRequests.propTypes = {
  assistanceRequests: PropTypes.shape({
    data: PropTypes.array,
    currentPage: PropTypes.number,
  }).isRequired,
  fetchDashboardPendingAssistanceRequests: PropTypes.func.isRequired,
  fetchProvidersUserCore: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  refetch: PropTypes.bool.isRequired,
  serviceTypes: PropTypes.array.isRequired,
  labels: PropTypes.object,
  sortAscending: PropTypes.bool,
  addARDateRangeFilter: PropTypes.bool,
};

NewAssistanceRequests.defaultProps = {
  labels: defaultLabels,
  sortAscending: false,
  addARDateRangeFilter: false,
};

function mapStateToProps(state) {
  const isCC = _.wget(state, 'session.isCoordinationGroup', false);

  return {
    assistanceRequests: _.get(state, 'dashboard.assistanceRequests', {}),
    filters: _.get(state, 'dashboard.assistanceRequests.filters', {}),
    groupId: _.get(state, 'session.groupId'),
    isFetching: _.get(state, 'dashboard.assistanceRequests.isFetching', false),
    refetch: _.get(state, 'dashboard.refetch'),
    serviceTypes: _.wget(state, isCC ? 'serviceTypes.network' : 'serviceTypes.allPrograms', []),
    token: _.get(state, 'session.token', ''),
    userId: _.get(state, 'user.id'),
    labels: labelCustomization(state),
    sortAscending: crtb1290SortReferralsByOldest(state),
    addARDateRangeFilter: crtb1645ARDateRangeFilter(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ fetchDashboardPendingAssistanceRequests, fetchProvidersUserCore }, dispatch),
    dispatch,
  };
}

NewAssistanceRequests.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewAssistanceRequests);
