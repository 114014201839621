import { coreApi } from 'src/api/config';
import { get } from 'lodash';
import Notifier from 'common/helpers/Notifier';

export default async function fetchAssistanceRequests (
  crtb1443ShowARFacesheet,
  personId,
  providerId,
  displayAssistanceRequests,
) {
  if (!crtb1443ShowARFacesheet || !displayAssistanceRequests) {
    return [];
  }

  try {
    const filters = {
      person: personId,
      originating_provider: providerId,
    };

    const assistanceRequestsQuery = await coreApi.query('assistance_requests', filters);
    const assistanceRequests = get(assistanceRequestsQuery, 'data.data', []);
    await Promise.all([
      assistanceRequests.forEach((ar) => (coreApi.populateRelationship('case', 'case', ar))),
      coreApi.populateRelationship('provider', 'provider', assistanceRequests),
    ]);

    return assistanceRequests;
  } catch (error) {
    Notifier.handleErrors(error);
    return [];
  }
}
