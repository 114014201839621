import React, { useContext, useState, useRef } from 'react';
import { TrackerContext } from '@unite-us/client-utils';
import OrganizationHeader from 'src/components/Organization/components/OrganizationHeader';
import { Link } from 'react-router';
import { browserHistory } from 'common/utils/browserHistory';
import { ORG_TABS } from 'src/components/Organization/constants';
import DivReset from 'src/components/Organization/components/DivLineHeightReset';
import PageHeading from 'src/components/Organization/components/PageHeading';
import {
  InputField, Table,
  TableHeader, TableRow, TableBody,
  TableRowColumn, TableHeaderColumn,
  Pager, Button, BaseCard, BaseCardBody, Dialog,
} from '@unite-us/ui';
import { Icon } from '@unite-us/app-components';
import { Form, Field } from 'react-final-form';
import { theme } from 'src/../tailwind.config';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { capitalize } from 'lodash';
import { Spinner } from 'src/common/spinners';
import useTrackPageView from 'src/components/Organization/utils/useTrackPageView';
import { ORG_SETTINGS, ORGANIZATION_SELF_ONBOARDING } from 'src/common/utils/EventTracker/utils/eventConstants';
import callOrLog from 'src/common/utils/callOrLog';
import { notifySomethingWentWrong } from '../../api/hooks/v1/apiHookOptions';
import { useEmployees, useUsers, useUpdateProvider, useFindProvider } from '../../api/hooks/v1';
import sendUserInvite from '../../../../actions/Invitation/sendUserInvite';
import UserSubheader from '../../components/Users/UserSubheader';

const { Rollbar } = window;
const searchInputStyle = { paddingLeft: '2.5rem' };
// todo: temporary logging for when employee has no user record
const logErrors = async (msg) => {
  if (Rollbar) { Rollbar.error(msg); }
};
const getEmployeeUsers = ({ employeeList, userList }) => employeeList?.map((employee) => {
  const user = userList.find((u) => u.id === employee.user.id);
  // todo: research prod data issue, employee with no user record
  if (!user) {
    logErrors(`Employee: ${employee.id} has no user record`);
  }
  return { ...employee, user };
});

const Users = ({
  sendInvite,
  providerId,
  pageSize,
  providerState,
  location,
}) => {
  useTrackPageView(ORG_SETTINGS.userListViewed);

  const eventTracker = useContext(TrackerContext);

  const [employees, setEmployees] = useState([]);
  const [employeeUsers, setEmployeeUsers] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [query, setQuery] = useState('');
  const [inviteSendingId, setInviteSendingId] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');

  const employeesWithPagingResult = useEmployees(
    {
      providerId,
      pageSize,
      pageNumber,
      query,
      sort: sortOrder === 'asc' ? 'first_name' : '-first_name',
    },
    {
      select: (response) => response.data,
      staleTime: 0,
      onSuccess: (data) => {
        setEmployees(data.data);
      },
    },
  );
  const { updateRecord: updateProvider } = useUpdateProvider(
    {
      onError: () => {
        notifySomethingWentWrong();
      },
    },
    'Organization submitted. Our team will review your submission and send an email with next steps.',
    3600000,
  );

  const dialogRef = useRef();

  const openDialog = () => dialogRef.current.openDialog();
  const closeDialog = () => dialogRef.current.closeDialog();

  const handleSubmitForReview = () => {
    openDialog();
    callOrLog(() => eventTracker(ORGANIZATION_SELF_ONBOARDING.submitForReview));
  };

  const handleClick = () => {
    closeDialog();
    updateProvider(providerId, {
      state: 'draft_submitted',
    });
    callOrLog(() => eventTracker(ORGANIZATION_SELF_ONBOARDING.submit));
    browserHistory.push('/organization/settings');
  };

  const handleCancel = () => {
    closeDialog();
    callOrLog(() => eventTracker(ORGANIZATION_SELF_ONBOARDING.cancel));
  };

  useUsers({
    ids: employees?.map((employee) => employee.user.id).join(','),
  }, {
    enabled: (() => (employees.length > 0))(),
    onSuccess: (data) => {
      setEmployeeUsers(getEmployeeUsers({ employeeList: employees, userList: data }));
    },
    staleTime: 0,
  });

  const isOrgActive = providerState === 'active';
  const { data: provider } = useFindProvider({ providerId });

  const showDraftStepper = provider?.state === 'draft';
  const pageHeadingClasses = showDraftStepper ? 'text-center' : null;

  const draftStepperOrder = (
    <>
      <header>
        <PageHeading text="Users" classes={pageHeadingClasses} />
      </header>
      <UserSubheader isOrgActive={isOrgActive} showDraftStepper={showDraftStepper} />
      <header>
        <div className="flex flex-row relative">
          <div className="mb-2 pr-5 self-center">
            { !showDraftStepper && <PageHeading text={'Users'} /> }
          </div>
          <div className="flex-grow">
            <Icon
              className="pt-2 pl-3 absolute"
              icon="Search"
              size={20}
              color={theme.extend.colors['action-blue']}
            />
            <Form
              onSubmit={(data) => {
                setPageNumber(1);
                setQuery(data?.search);
              }}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Field
                    name="search"
                  >
                    {(params) => (
                      <InputField
                        className="md:w-4/5"
                        hideError
                        style={{ input: searchInputStyle }}
                        placeholder="Search by name or email"
                        label="search" id="search" hideLabel
                        data-test-element="users_search"
                        {...params}
                        onChange={(event) => {
                          if (event.target.value === '' || event.target.value.length > 2) {
                            handleSubmit();
                          }
                        }}
                      />
                    )}
                  </Field>
                </form>
              )}
            />

          </div>
          <div className="ml-10 mt-2 text-right md:flex-grow">
            <Icon className="-mt-1 pr-1" icon="Add" color={theme.extend.colors['action-blue']} />
            <Link
              data-test-element="add_user_link"
              className="ml-0.5 mt-0.5"
              to={'/organization/settings/users/new'}
            >
              Add User
            </Link>
          </div>
        </div>
      </header>
    </>
    );

  return (
    <>
      <OrganizationHeader currentTab={ORG_TABS.users} showOrgStatus location={location} />
      <DivReset className="container max-w-screen-lg pt-8 pb-4">
        { !showDraftStepper && (
          <header>
            <div className="flex flex-row relative">
              <div className="mb-2 pr-5 self-center">
                { !showDraftStepper && <PageHeading text={'Users'} /> }
              </div>
              <div className="flex-grow">
                <Icon
                  className="pt-2 pl-3 absolute"
                  icon="Search"
                  size={20}
                  color={theme.extend.colors['action-blue']}
                />
                <Form
                  onSubmit={(data) => {
                    setPageNumber(1);
                    setQuery(data?.search);
                  }}
                  render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      <Field
                        name="search"
                      >
                        {(params) => (
                          <InputField
                            className="md:w-4/5"
                            hideError
                            style={{ input: searchInputStyle }}
                            placeholder="Search by name or email"
                            label="search" id="search" hideLabel
                            data-test-element="users_search"
                            {...params}
                            onChange={(event) => {
                              if (event.target.value === '' || event.target.value.length > 2) {
                                handleSubmit();
                              }
                            }}
                          />
                        )}
                      </Field>
                    </form>
                  )}
                />

              </div>
              <div className="ml-10 mt-2 text-right md:flex-grow">
                <Icon className="-mt-1 pr-1" icon="Add" color={theme.extend.colors['action-blue']} />
                <Link
                  data-test-element="add_user_link"
                  className="ml-0.5 mt-0.5"
                  to={'/organization/settings/users/new'}
                >
                  Add User
                </Link>
              </div>
            </div>
          </header>
        )}
        { !showDraftStepper && <UserSubheader isOrgActive={isOrgActive} /> }
        {showDraftStepper && draftStepperOrder}
        <Table
          onCellClick={
            (row, column, e) => {
              e.preventDefault();
              browserHistory.push(`/organization/settings/users/${employeeUsers[row].id}`);
            }
          }
          className="my-4"
        >
          <TableHeader>
            <TableRow>
              <TableHeaderColumn>
                <button
                  type="button"
                  className="flex items-center text-action-blue uppercase"
                  aria-label="Sort By Name"
                  onClick={
                    () => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
                  }
                >
                  Name
                  <Icon
                    icon="ChevronArrow"
                    direction={sortOrder === 'asc' ? 'up' : 'down'}
                    className="p-1"
                    color={theme.extend.colors['action-blue']}
                  />
                </button>
              </TableHeaderColumn>
              <TableHeaderColumn>Email</TableHeaderColumn>
              <TableHeaderColumn>Status</TableHeaderColumn>
              <TableHeaderColumn>
                <Pager
                  className="lowercase"
                  paging={{
                    current_page: employeesWithPagingResult?.data?.paging?.current_page,
                    next_page: employeesWithPagingResult?.data?.paging?.next_page,
                    prev_page: employeesWithPagingResult?.data?.paging?.prev_page,
                    per: employeesWithPagingResult?.data?.paging?.per,
                    total_count: employeesWithPagingResult?.data?.paging?.total_count,
                    total_pages: employeesWithPagingResult?.data?.paging?.total_pages,
                  }}
                  onNextClick={() => {
                    setPageNumber(employeesWithPagingResult?.data?.paging?.current_page + 1);
                  }}
                  onPrevClick={() => {
                    setPageNumber(
                      employeesWithPagingResult?.data?.paging?.current_page > 1 ?
                        employeesWithPagingResult?.data?.paging?.current_page - 1 : 1,
                    );
                  }}
                />
              </TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody>
            {employeeUsers?.map((employee) => (
              <TableRow key={employee.id}>
                <TableRowColumn>
                  {employee.full_name}
                </TableRowColumn>
                <TableRowColumn>
                  {employee.email}
                </TableRowColumn>
                <TableRowColumn>
                  {capitalize(employee?.state)}
                </TableRowColumn>
                {isOrgActive && (
                  <TableRowColumn>
                    {!employee?.user?.confirmed_at && employee?.state === 'active' &&
                      inviteSendingId !== employee?.user?.id ? (
                        <button
                          data-test-element={`invite_user_${employee?.user?.id}`}
                          className="text-action-blue background-transparent font-bold
                          px-3 py-1 text-xs outline-none focus:outline-none mr-1
                          mb-1 ease-linear transition-all duration-150" type="button"
                          onClick={(event) => {
                          eventTracker(ORG_SETTINGS.invitedUser);
                          setInviteSendingId(employee?.user?.id);
                          sendInvite({
                            providerId,
                            userId: employee?.user?.id,
                          });
                          setInviteSendingId('');
                          event.stopPropagation();
                        }}
                          onKeyDown={(event) => {
                          if (event.keyCode === 13 || event.keyCode === 32) {
                            eventTracker(ORG_SETTINGS.invitedUser);
                            setInviteSendingId(employee?.user?.id);
                            sendInvite({
                              providerId,
                              userId: employee?.user?.id,
                            });
                            setInviteSendingId('');
                            event.stopPropagation();
                          }
                        }}
                          aria-label={`invite user ${employee?.full_name} via email`}
                        >
                          <Icon
                            className="float-left -mt-0.5 mr-1"
                            icon="Email"
                            size={13}
                            color={theme.extend.colors['action-blue']}
                          />
                          Invite
                        </button>
                    ) :
                      (
                        <>
                          {!employee?.user?.confirmed_at && employee?.state === 'active' ?
                            <Spinner className="float-left h-3" scale={0.25} /> :
                            ''}
                        </>
                      )}

                  </TableRowColumn>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {isOrgActive && (
          <Button
            label="Organization"
            className="mt-10"
            iconLeft={(
              <Icon
                icon="ChevronArrow"
                direction="left"
                color={theme.extend.colors['action-blue']}
              />
            )}
            onClick={() => browserHistory.push('/organization/settings')}
            outline
          />
        )}
        {showDraftStepper && (
          <BaseCard>
            <BaseCardBody withPadding>
              <div
                className="flex flex-col items-center p-3"
                data-testid="org-submit-section"
              >
                <div>
                  Have you finished editing your users?
                </div>
                <div className="strong pt-5 pb-4">
                  Review your details and submit when you&apos;re ready.
                </div>
                <Button
                  label="SUBMIT ORGANIZATION FOR REVIEW"
                  className="primary-button"
                  primary
                  onClick={() => handleSubmitForReview()}
                />
                <Dialog
                  id="submit-organization-dialog"
                  modal
                  onRequestClose={() => closeDialog()}
                  ref={dialogRef}
                  title="Submit Organization"
                  removeCloseIcon
                  showBorderRadius
                >
                  <div>
                    <div className="mb-6">
                      <p className="my-2 mx-2">Are you sure you want to submit your organization for review?</p>
                      <p className="my-3 mx-2">
                        Once you submit your organization,
                        we will review it before it goes live on the Unite Us Platform.
                        You won’t be able to edit your organization while we review it,
                        but you can edit your organization after it goes live on the Unite Us Platform.
                      </p>
                    </div>
                    <div className="flex flex-row-reverse">
                      <Button
                        id="submit_for_review-btn"
                        primary
                        className="ml-2"
                        label="Submit"
                        onClick={() => handleClick()}
                      />
                      <Button
                        id="cancel_review-btn1"
                        label="Cancel"
                        onClick={() => handleCancel()}
                      />
                    </div>
                  </div>
                </Dialog>
              </div>
            </BaseCardBody>
          </BaseCard>
        )}
      </DivReset>

    </>
  );
};

const mapStateToProps = (state) => ({
  providerId: state.user.groups.find((data) => data.group.id === state.session.groupId)?.group?.id,
  providerState: state?.globalState?.currentProvider?.group?.state,
});

const mapDispatchToProps = (dispatch) => ({
  sendInvite: async ({ providerId, userId }) => {
    const inviteResponse = await sendUserInvite({
      provider: providerId,
      user: userId,
    })(dispatch);
    if (!inviteResponse) {
      notifySomethingWentWrong();
    }
  },
});

Users.propTypes = {
  sendInvite: PropTypes.func.isRequired,
  providerId: PropTypes.string.isRequired,
  providerState: PropTypes.string.isRequired,
  pageSize: PropTypes.number,
  location: PropTypes.object.isRequired,
};

Users.defaultProps = {
  pageSize: 50,
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
