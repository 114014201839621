import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import NetworkOrganizationTable from 'components/Backoffice/searchTable/NetworkOrganizationTable';
import AddNetworkModalContent from 'src/pages/backoffice/payers/_id/plans/_plan_id/AddNetworkModalContent';
import DialogV2 from 'common/modal/DialogV2';
import { Spinner } from 'common/spinners';
import { isEmpty } from 'lodash';
import ScrollToElement from 'src/common/utils/Form/ScrollToElement';
import { useFindNetworksById } from 'src/components/Backoffice/api/hooks/v1/networkHooks';
import { useFindProviders } from 'src/components/Backoffice/api/hooks/v1/providerHooks';
import constructPlanFormPayload from 'src/pages/backoffice/payers/_id/utils/constructPlanFormPayload';
import { useUpdatePlan } from 'src/components/Backoffice/api/hooks/v1/planHooks';

const PlanNetworkOrganizations = ({ plan }) => {
  const { updateRecord: updatePlan } = useUpdatePlan();
  const networkIds = plan.networks?.map((network) => network.id).join(',');
  const providerIds = plan.providers?.map((provider) => provider.id).join(',');
  const { data: organizations = [], isLoading: isLoadingOrgs } = useFindProviders(providerIds);
  const { data: networks = [], isLoading: isLoadingNetworks } = useFindNetworksById(networkIds);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState({});
  const [selectedOrganizationIds, setSelectedOrganizationIds] = useState([]);
  const [viewMode, setViewMode] = useState('add-network');
  const [optionSelected, setOptionSelected] = useState('');
  const payerId = plan.payer?.id;
  const AddNetworkModal = useRef(null);

  const openModal = () => {
    setViewMode('add-network');
    setOptionSelected('');
    AddNetworkModal.current.openDialog();
  };

  const closeModal = () => {
    setSelectedNetwork({});
    setSelectedOrganizationIds([]);
    setOptionSelected('');
    setViewMode('add-network');
    AddNetworkModal.current.closeDialog();
  };

  const ScrollToElementEvent = () => {
    ScrollToElement(document.getElementById('TopOfForms'));
  };

  const shouldDisableButton = () => (
    isEmpty(selectedNetwork) ||
    (selectedNetwork && viewMode === 'choose-options' && optionSelected === '') ||
    (selectedOrganizationIds.length === 0 && optionSelected === 'add-orgs-selected')
  );

  const removeMatchingOrganizations = (selectedIds, existingOrganizations) => (
    selectedIds.filter((id) => !existingOrganizations.map((el) => (el.id)).includes(id)).map((id) => ({ id }))
  );

  const canAddNetwork = (selectedIds, existingNetworks) => (
    !isEmpty([selectedIds].filter((obj) => !existingNetworks.map((el) => (el.id)).includes(obj.id)))
  );

  const addNetwork = async () => {
    if (viewMode === 'add-network') {
      setViewMode('choose-options');
      return;
    }

    let updatedPlanWithNetworkOrganizations;
    let canAddOrganizations = false;
    if (optionSelected === 'add-orgs-selected') {
      const newOrgs = removeMatchingOrganizations(selectedOrganizationIds, organizations);
      canAddOrganizations = !!newOrgs.length;
      updatedPlanWithNetworkOrganizations = { ...plan, providers: [...plan.providers, ...newOrgs] };
    } else {
      updatedPlanWithNetworkOrganizations = { ...plan, networks: [...plan.networks, { id: selectedNetwork.id }] };
    }

    if (canAddNetwork(selectedNetwork, networks) || canAddOrganizations) {
      const payload = constructPlanFormPayload(updatedPlanWithNetworkOrganizations, payerId);
      const response = await updatePlan(updatedPlanWithNetworkOrganizations.id, payload);
      if (response) { closeModal(); }
    } else {
      closeModal();
    }
  };

  const removeNetwork = async () => {
    const selectedNetworkIds = selectedRows
      .filter((rowId) => rowId.startsWith('network-'))
      .map((rowId) => rowId.replace('network-', ''));
    const selectedProviderIds = selectedRows
      .filter((rowId) => rowId.startsWith('provider-'))
      .map((rowId) => rowId.replace('provider-', ''));

    const updatedNetworks = plan.networks.filter((network) => !selectedNetworkIds.includes(network.id));
    const updatedProviders = plan.providers.filter((provider) => !selectedProviderIds.includes(provider.id));
    const updatedPlanWithNetworkOrganizations = { ...plan, networks: updatedNetworks, providers: updatedProviders };
    const payload = constructPlanFormPayload(updatedPlanWithNetworkOrganizations, payerId);
    const response = await updatePlan(updatedPlanWithNetworkOrganizations.id, payload);

    if (response) setSelectedRows([]);
  };

  return (
    <>
      {isLoadingOrgs || isLoadingNetworks ? (
        <Spinner />
      ) : (
        <NetworkOrganizationTable
          networks={networks}
          organizations={organizations}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          onAdd={openModal}
          onRemove={removeNetwork}
          ScrollToElementEvent={ScrollToElementEvent}
        />
      )}
      <DialogV2
        cancelHandler={closeModal}
        confirmationHandler={addNetwork}
        confirmLabel="Add"
        id="add-network-modal"
        ref={AddNetworkModal}
        title="Add Network/Organization"
        confirmationBtnDisabled={shouldDisableButton()}
        width="6xl"
        confirmButtonTestId="add-network-modal-confirm-button"
        dialogDescriptionClass="flex flex-col h-screen overflow-hidden bg-white-grey"
        dialogWrapperPadding="p-12"
      >
        <AddNetworkModalContent
          viewMode={viewMode}
          setViewMode={setViewMode}
          optionSelected={optionSelected}
          setOptionSelected={setOptionSelected}
          selectedNetwork={selectedNetwork}
          setSelectedNetwork={setSelectedNetwork}
          selectedOrganizationIds={selectedOrganizationIds}
          setSelectedOrganizationIds={setSelectedOrganizationIds}
        />
      </DialogV2>
    </>
  );
};

PlanNetworkOrganizations.propTypes = {
  plan: PropTypes.object.isRequired,
};

export default PlanNetworkOrganizations;
