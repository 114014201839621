import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import {
  useFind,
  useFindRecord,
  usePopulate,
} from 'api/APIHooks';
import useZcodes from 'src/common/hooks/useZcodes';
import useInvoiceSpend from 'src/components/ServiceAuthorization/useInvoiceSpend';

export const PaymentsTrackServiceWrapper = (props) => {
  const {
    children,
    contactId,
    programId,
    providedService,
    serviceAuthorizationId,
    serviceCase,
  } = props;

  const { data: programData } = useFindRecord(
    'program',
    programId,
    { queryConfig: { placeholderData: undefined, enabled: !!programId } },
  );
  const program = programData?.data?.data;
  usePopulate('fee_schedule_program', 'fee_schedule_program', program, { queryConfig: { placeholderData: undefined } });
  const feeScheduleProgram = program?.fee_schedule_program;
  usePopulate('fee_schedule', 'fee_schedule', feeScheduleProgram, { queryConfig: { placeholderData: undefined } });
  const feeSchedule = feeScheduleProgram?.fee_schedule;

  const { data: insurancesData } = useFind(
    'insurance',
    { person: contactId, state: 'active,pending,inactive' },
    { queryConfig: { placeholderData: undefined } },
  );
  const insurances = insurancesData?.data?.data;
  const { isSuccess: isPlansLoaded } = usePopulate(
    'plan',
    'plan',
    insurances,
    { queryConfig: { placeholderData: undefined } },
  );

  const { data: serviceAuthorizationData } = useFindRecord(
    'service_authorization',
    serviceAuthorizationId,
    { queryConfig: { placeholderData: undefined, enabled: !!serviceAuthorizationId } },
  );
  const serviceAuthorization = serviceAuthorizationData?.data?.data;
  usePopulate('insurance', 'insurance', serviceAuthorization, { queryConfig: { placeholderData: undefined } });
  usePopulate('insurance.plan', 'plan', serviceAuthorization, { queryConfig: { placeholderData: undefined } });
  const serviceAuthorizationPlanId = serviceAuthorization?.insurance?.plan?.id;
  const serviceAuthorizationPayerId = serviceAuthorization?.insurance?.plan?.payer?.id;

  const [inferredPlanId, setInferredPlanId] = useState(null);
  const { data: inferredPlanData } = useFindRecord(
    'plan',
    inferredPlanId,
    { queryConfig: { enabled: !serviceAuthorizationId && !!inferredPlanId, placeholderData: undefined } },
  );
  const inferredPayerId = inferredPlanData?.data?.data?.payer?.id;
  const canonicalPlanId = serviceAuthorizationPlanId || inferredPlanId;
  const casePayerId = serviceAuthorizationPayerId || inferredPayerId;

  const serviceAuthorizationInvoiceInformation = useInvoiceSpend(
    get(serviceCase, 'service_authorization.id'),
  );

  let insuranceExternalMemberId;
  let socialInsurances;
  if (isPlansLoaded) {
    const socialCareCoverages = insurances?.filter((insurance) => insurance.plan.plan_type === 'social');
    const socialCarePayerIds = socialCareCoverages.map((scc) => scc.plan.payer.id);
    const medicalInsurances = insurances.filter((insurance) => insurance.plan.plan_type !== 'social');

    insuranceExternalMemberId = medicalInsurances
      .find((insurance) => socialCarePayerIds.includes(insurance.plan.payer.id))
      ?.external_member_id;

    socialInsurances = socialCareCoverages.filter((scc) => {
      const feeScheduleIds = scc.plan.fee_schedules.map((fs) => fs.id);
      return feeScheduleIds.includes(feeSchedule.id);
    });
  }

  usePopulate(
    'place_of_services',
    'place_of_services',
    feeScheduleProgram,
    { queryConfig: { placeholderData: undefined } },
  );

  const placeOfServiceOptions = feeScheduleProgram?.place_of_services
    ?.map((pos) => ({ label: `${pos.code} - ${pos.description}`, value: pos.id })) ?? [];

  const zcodeConfig = { queryConfig: { enabled: !feeScheduleProgram?.force_zcode_associations } };
  const { data: zcodes } = useZcodes([feeScheduleProgram?.id], casePayerId, zcodeConfig);
  const zcodeOptions = feeScheduleProgram?.force_zcode_associations ? [] :
    zcodes.map((zcode) => ({ label: `${zcode.code} - ${zcode.description}`, value: zcode.id }));

  usePopulate(
    'procedure_code_modifiers',
    'procedure_code_modifiers',
    providedService,
    { queryConfig: { placeholderData: undefined } },
  );

  return React.Children.map(children, (child) => (
    React.cloneElement(child, {
      casePayerId,
      feeScheduleProgram,
      insuranceExternalMemberId,
      insuranceRequired: feeSchedule?.insurance_required,
      placeOfServiceOptions,
      planId: canonicalPlanId,
      providedService,
      socialInsurances,
      serviceAuthorizationInvoiceInformation,
      serviceAuthorization,
      setInferredPlanId,
      zcodeOptions,
    })
  ));
};

PaymentsTrackServiceWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  contactId: PropTypes.string.isRequired,
  fetchFeeSchedules: PropTypes.func.isRequired,
  fetchPaymentsInsurance: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  providedService: PropTypes.object,
  serviceCase: PropTypes.object,
  serviceAuthorizationId: PropTypes.string,
};

PaymentsTrackServiceWrapper.defaultProps = {
  providedService: {},
  serviceCase: {},
  serviceAuthorizationId: '',
};

const mapStateToProps = (state, ownProps) => {
  const programId = get(ownProps.serviceCase, 'program.id');
  const groupId = state.session.groupId;
  const serviceAuthorizationId = get(ownProps.serviceCase, 'service_authorization.id');

  return {
    groupId,
    programId,
    serviceAuthorizationId,
  };
};

export default connect(mapStateToProps)(PaymentsTrackServiceWrapper);
