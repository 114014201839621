import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { browserHistory } from 'common/utils/browserHistory';
import { Tabs, Tab } from '@unite-us/ui';

class UserSettings extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  onChange(value = '') {
    const path = value ? `/user/settings/${value}` : '/user/settings';
    browserHistory.push(path);
  }

  render() {
    const { children, location } = this.props;

    const pathParts = location.pathname.split('/');
    const lastPart = pathParts[pathParts.length - 1];
    const tabValue = lastPart === 'notifications' ? 'notifications' : '';

    return (
      <div className="user-settings">
        <div className="row">
          <div className="col-sm-10 offset-sm-1 pt-5">
            <Tabs
              id="user-settings-tabs"
              className="user-settings-tabs"
              value={tabValue}
              onChange={this.onChange}
            >
              <Tab
                id="user-profile-tab"
                value=""
                label="Account Information"
              />
              <Tab
                id="user-notifications-tab"
                value="notifications"
                label="Notification Settings"
              />
            </Tabs>
            {children}
          </div>
        </div>
      </div>
    );
  }
}

UserSettings.propTypes = {
  children: PropTypes.any.isRequired,
  location: PropTypes.object.isRequired,
};

export default UserSettings;
