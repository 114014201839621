import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SearchableField } from 'components/Backoffice/form/SearchableField';
import { useInvoiceClientNameOptions } from '../../hooks';

export const ClientNameFilter = ({
  className,
  invoicesQuery,
  onChange,
  value,
}) => {
  const [localClientName, setLocalClientName] = useState(value);

  useEffect(() => {
    setLocalClientName(value);
  }, [value]);

  const clientNameOptions = useInvoiceClientNameOptions(localClientName, invoicesQuery);

  return (
    <SearchableField
      className={className}
      label="Client"
      labelClassName="tracking-normal"
      onChange={({ value: val }) => onChange(val)}
      searchQuery={localClientName}
      options={clientNameOptions}
      onInputChange={setLocalClientName}
      placeholder={'Search for a client'}
      truncateOptions={false}
      clearable
    />
  );
};

ClientNameFilter.propTypes = {
  className: PropTypes.string,
  invoicesQuery: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

ClientNameFilter.defaultProps = {
  className: undefined,
  value: undefined,
};
