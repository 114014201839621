import React, { useState, useEffect } from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, startCase, toLower, intersection, flow } from 'lodash';
import FeatureFlagContainer from 'src/common/utils/FeatureFlags/FeatureFlagContainer';
import { hasPayerInvoicesRole } from 'src/common/utils/FeatureFlags/flags';
import { useIsNetworkLead } from 'common/contexts/CurrentProviderContext';
import getTimeDifference from 'common/utils/Dates/getTimeDifference';
import {
  ColumnHeader,
  SortableColumnHeader,
  DataCell,
  DataRow,
  HeaderRow,
  Table,
  TableBody,
} from 'src/common/tables/V2';
import { dollarAmount } from 'src/common/display/Money/DollarAmount';
import InvoiceStatusIndicator from 'src/pages/invoices/utils/InvoiceStatusIndicator';
import formatInvoiceType from 'src/pages/invoices/utils/formatInvoiceType';
import { Checkbox } from 'src/common/TailwindComponents';
import { useFeatureFlag } from 'src/common/hooks';
import InvoiceDifferenceIndicator from './InvoiceDifferenceIndicator';
import formatDate from '../../../common/utils/Dates/formatDate';

const InvoicesTable = ({
  deselectInvoices,
  invoices,
  openDrawer,
  path,
  selectedInvoices,
  selectInvoices,
  setSort,
  showInvoiceAging,
  showInvoiceType,
  showPayerInvoices,
  sortBy,
  sortDirection,
}) => {
  const hasInvoiceAging = useFeatureFlag('pays-1628-invoice-aging');
  const isNetworkLead = useIsNetworkLead();

  const pageInvoices = invoices.map(({ id }) => id);
  const onCheckColumn = () => {
    if (
      selectedInvoices.length !== 0 &&
      intersection(selectedInvoices, pageInvoices).length ===
        pageInvoices.length
    ) {
      deselectInvoices(...pageInvoices);
    } else {
      selectInvoices(...pageInvoices);
    }
  };
  const toggleInvoiceSelected = (invoiceId) => {
    if (selectedInvoices.includes(invoiceId)) {
      deselectInvoices(invoiceId);
    } else {
      selectInvoices(invoiceId);
    }
  };

  const varaibleTableStyles = {
    minWidth: 900,
    minHeight: 500,
  };

  const variableColumnWidth = showPayerInvoices ? 'w-24' : 'w-32';

  // State to hold refs for each DataRow
  const [rowRefs, setRowRefs] = useState([]);

  useEffect(() => {
    // Set up a ref for each invoice
    setRowRefs(invoices.map(() => React.createRef()));
  }, [invoices]);

  return (
    <div className={showPayerInvoices ? 'px-6' : ''} style={showPayerInvoices ? varaibleTableStyles : {}}>
      <Table className="bg-white w-full table-fixed">
        <HeaderRow>
          <ColumnHeader className="w-6">
            <Checkbox
              ariaLabel="Select all invoices on page for export"
              checked={
                intersection(selectedInvoices, pageInvoices).length ===
                pageInvoices.length
              }
              id="invoices-table-header-row-checkbox"
              onChange={onCheckColumn}
            />
          </ColumnHeader>
          <ColumnHeader className="w-24">Invoice ID</ColumnHeader>
          { showInvoiceType && (
            <ColumnHeader className="w-24">Type</ColumnHeader>
          )}
          <SortableColumnHeader
            className="w-24"
            colName="total_amount_invoiced"
            label="amount"
            sortBy={sortBy}
            sortDirection={sortDirection}
            setSort={setSort}
          >
            Amount
          </SortableColumnHeader>
          <SortableColumnHeader
            className="w-32"
            colName="client_name"
            label="client name"
            sortBy={sortBy}
            sortDirection={sortDirection}
            setSort={setSort}
          >
            Client Name
          </SortableColumnHeader>
          <SortableColumnHeader
            className="w-40"
            colName="invoice_status"
            label="status"
            sortBy={sortBy}
            sortDirection={sortDirection}
            setSort={setSort}
          >
            Status
          </SortableColumnHeader>
          <SortableColumnHeader
            className="w-40"
            colName="fee_schedule_program_name"
            label="service provided"
            sortBy={sortBy}
            sortDirection={sortDirection}
            setSort={setSort}
          >
            Service Provided
          </SortableColumnHeader>
          {(isNetworkLead || showPayerInvoices) ? (
            <SortableColumnHeader
              className={variableColumnWidth}
              colName="provider_name"
              label="provider"
              sortBy={sortBy}
              sortDirection={sortDirection}
              setSort={setSort}
            >
              Provider
            </SortableColumnHeader>
          ) : (
            <SortableColumnHeader
              className={variableColumnWidth}
              colName="submitter.first_name"
              label="submitted by"
              sortBy={sortBy}
              sortDirection={sortDirection}
              setSort={setSort}
            >
              Submitted By
            </SortableColumnHeader>
          )}

          <SortableColumnHeader
            className={variableColumnWidth}
            colName="updated_at"
            label="last updated"
            sortBy={sortBy}
            sortDirection={sortDirection}
            setSort={setSort}
          >
            Last Updated
          </SortableColumnHeader>
          { hasInvoiceAging && showInvoiceAging && (
          <SortableColumnHeader
            className="w-32"
            colName={isNetworkLead ? 'submitted_to_network_lead_at' : 'created_at'}
            label="time in queue"
            sortBy={sortBy}
            sortDirection={sortDirection}
            setSort={setSort}
          >
            Time In Queue
          </SortableColumnHeader>
          )}
        </HeaderRow>
        <TableBody>
          {invoices.map((invoice, idx) => {
            // Use the ref corresponding to this index
            const rowRef = rowRefs[idx];
            const submittedAtTimeStamp = isNetworkLead ? invoice.submitted_to_network_lead_at : invoice.created_at;
            const submittedAt = new Date(Date.parse(submittedAtTimeStamp));
            return (
              <DataRow
                ref={rowRef}
                className="cursor-pointer hover:bg-light-fill-blue hover:bg-opacity-50"
                key={invoice.id}
                onClick={openDrawer(idx, rowRef)}
                ariaLabel="Press space to open invoice details"
                dataTestId="workqueue-table-row"
              >
                <DataCell>
                  <Checkbox
                    ariaLabel={`Select invoice ${invoice.short_id} for export`}
                    checked={selectedInvoices.includes(invoice.id)}
                    id={`invoices-table-data-row-${invoice.id}`}
                    onClick={(e) => e.stopPropagation()}
                    onChange={() => toggleInvoiceSelected(invoice.id)}
                  />
                </DataCell>
                <DataCell className="text-action-blue">
                  <Link
                    to={`/invoices/${path}${invoice.id}`}
                    className="underline"
                    onClick={(e) => e.stopPropagation()}
                    aria-label="Invoice ID, opens in a new page"
                  >
                    {invoice.short_id}
                  </Link>
                </DataCell>
                { showInvoiceType && (
                  <DataCell>
                    {formatInvoiceType(invoice.invoice_type)}
                  </DataCell>
                )}
                <DataCell>
                  {dollarAmount(invoice.total_amount_invoiced, true)}
                </DataCell>
                <DataCell>{startCase(toLower(invoice.client_name))}</DataCell>
                <DataCell>
                  <div
                    className="flex flex-row items-center"
                    aria-haspopup="dialog"
                    tabIndex="0"
                  >
                    <InvoiceStatusIndicator
                      isNetworkLead={isNetworkLead}
                      invoiceStatus={invoice.invoice_status}
                      isPayer={showPayerInvoices}
                    />
                    <InvoiceDifferenceIndicator
                      invoiceStatus={invoice.invoice_status}
                      invoiceAmountPaid={invoice.amount_paid}
                      invoiceAmountInvoiced={invoice.total_amount_invoiced}
                      invoicedResolved={invoice.invoice_dispute_resolution_date !== null}
                    />
                  </div>
                </DataCell>
                <DataCell>
                  {invoice.invoice_type === 'screening' ? 'Screening' : invoice.fee_schedule_program_name}
                </DataCell>
                {(isNetworkLead || showPayerInvoices) ? (
                  <DataCell>{invoice.provider_name}</DataCell>
                ) : (
                  <DataCell>
                    {get(invoice, 'submitter.full_name') || 'Auto-generated' }
                  </DataCell>
                )}
                <DataCell>
                  {formatDate(invoice.updated_at)}
                </DataCell>
                {hasInvoiceAging && showInvoiceAging && (
                <DataCell>
                  { getTimeDifference(submittedAt)}
                </DataCell>
                )}
              </DataRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

InvoicesTable.propTypes = {
  deselectInvoices: PropTypes.func.isRequired,
  invoices: PropTypes.array.isRequired,
  openDrawer: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  selectedInvoices: PropTypes.array.isRequired,
  selectInvoices: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  showInvoiceAging: PropTypes.bool,
  showInvoiceType: PropTypes.bool,
  showPayerInvoices: PropTypes.bool,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
};

InvoicesTable.defaultProps = {
  showInvoiceAging: false,
  showInvoiceType: false,
  showPayerInvoices: false,
};

function mapStateToProps(state) {
  return {
    showPayerInvoices: hasPayerInvoicesRole(state),
  };
}

export default flow(
  FeatureFlagContainer,
  connect(mapStateToProps),
)(InvoicesTable);
