import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import moment from 'moment';
import cx from 'classnames';
import { isEmpty, sortBy, last, get } from 'lodash';
import { Icon } from '@unite-us/ui';
import { findInsuranceByDateRange, requestDateOutOfRange } from 'src/common/form/Interactions/components/utils';
import useZcodes from 'src/common/hooks/useZcodes';
import IconButton from 'src/common/display/Profile/components/IconButton';
import {
  useFind,
  useDeleteRecord,
  usePopulate,
  useFindRecord,
} from 'src/api/APIHooks';
import buildProcedureCodeInitialFormValues from 'src/common/form/Interactions/utils/buildProcedureCodeInitialFormValues';
import { useFeatureFlag } from 'common/hooks';
import { defaultMutationConfig } from 'src/components/Backoffice/api/hooks/apiHookOptions';
import DeleteProvidedServiceConfirmationDialog from 'src/components/Cases/components/Detail/DeleteProvidedServiceConfirmationDialog';
import './FeeScheduleProvidedServiceCardData.scss';

const FeeScheduleProvidedServiceCardHeader = ({
  className,
  isBillable,
  isNetworkLead,
  metafields,
  onEdit,
  onSubmit,
  providedService,
  programName,
  showInteractions,
  onDelete,
  ignoreSocialCareExpiredDates,
  disallowContractedServiceForUnconsentedClient,
  isPersonConsented,
  canInvoiceAboveRemainingAuthorizedAmount,
  feeScheduleProgram,
  serviceAuthorization,
  serviceAuthorizationInvoiceInformation,
  groupId,
  personId,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const isEditingDisabled = providedService.state !== 'active';
  const isDeleteDisabled = isEditingDisabled || !!providedService.invoices?.length;

  const metadata = providedService.metadata || [];

  const requiredMetaDataMissing = metafields.some(({ field, validations }) => {
    if (validations && validations.required) {
      const metaDataMatch = metadata.find((md) => md.field === field);

      if (!metaDataMatch) return true;

      const value = metaDataMatch.value || {};

      const metaDataMatchEmpty = field === 'address_field' ? (
        !value.line_1 || !value.city || !value.state || !value.postal_code
      ) : isEmpty(metaDataMatch.value);

      return metaDataMatchEmpty;
    }

    return false;
  });

  const { data: invoiceApiData } = useFind(
    'invoice',
    {
      provided_service: providedService.id,
    },
    {
      queryConfig: {
        placeholderData: undefined,
      },
    },
  );

  const { data: chosenPlan } = useFindRecord(
    'plan',
    providedService?.plan?.id,
    { queryConfig: { enabled: !!providedService?.plan?.id, placeholderData: undefined } },
  );
  const casePayerId = chosenPlan?.data?.data?.payer?.id;

  const { data: insurancesData } = useFind('insurance', {
    plan: providedService?.plan?.id,
    person: personId,
    state: 'active,pending,inactive',
    groupId,
  }, { queryConfig: { placeholderData: undefined, enabled: !!providedService } });

  const insurances = insurancesData?.data?.data;

  const isZcodeAssociationForced = feeScheduleProgram?.force_zcode_associations;
  const { data: zcodes } = useZcodes(
    [feeScheduleProgram?.id],
    casePayerId,
    { queryConfig: { enabled: !isZcodeAssociationForced } },
  );
  const zcodeOptions = isZcodeAssociationForced ? [] :
    zcodes;
  const shouldShowZcodeSelect = !isZcodeAssociationForced && !isEmpty(zcodeOptions);
  const zCodesNonValid = shouldShowZcodeSelect && isEmpty(providedService?.zcodes);

  const invoicesData = get(invoiceApiData, 'data.data', []);
  const invoices = sortBy(invoicesData, 'created_at');

  const insuranceRequired = feeScheduleProgram?.insurance_required;
  const startDate = moment(providedService?.starts_at);
  const endDate = providedService?.ends_at ? moment(providedService?.ends_at) : startDate;
  let validInsurance;
  if (startDate) {
    validInsurance = findInsuranceByDateRange({
      insurances,
      startDate,
      endDate: endDate || startDate,
      ignoreSocialCareExpiredDates,
      serviceAuthorization,
    });
  }

  const requestedAmountNonValid = !canInvoiceAboveRemainingAuthorizedAmount &&
   (serviceAuthorizationInvoiceInformation?.remainingAuthorizedAmount < 0 ||
    providedService?.unit_amount > serviceAuthorizationInvoiceInformation?.remainingAuthorizedAmount);

  const canForbidContractedServiceCreationWhenNoConsent = disallowContractedServiceForUnconsentedClient &&
    !isPersonConsented;

  const serviceAuthorizationStartDate = moment(serviceAuthorization?.approved_starts_at);
  const serviceAuthorizationEndDate =
    serviceAuthorization?.approved_ends_at ?
    moment(serviceAuthorization?.approved_ends_at) : serviceAuthorizationStartDate;

  const requestedDatesNonValid = serviceAuthorization && (
    requestDateOutOfRange(startDate, serviceAuthorizationStartDate, serviceAuthorizationEndDate) ||
    requestDateOutOfRange(endDate, serviceAuthorizationStartDate, serviceAuthorizationEndDate)
  );

  const placeOfServiceOptions = feeScheduleProgram?.place_of_services;
  const shouldShowPlaceOfServiceSelect = !isEmpty(placeOfServiceOptions);
  const placeOfServiceNonValid = shouldShowPlaceOfServiceSelect && !providedService?.place_of_service;

  usePopulate(
    'procedure_code_modifiers',
    'procedure_code_modifiers',
    providedService,
    { queryConfig: { placeholderData: undefined, enabled: !!providedService } },
  );

  const procedureCodes = buildProcedureCodeInitialFormValues({ providedService, feeScheduleProgram });

  const nonValidProcedureCodes = procedureCodes.some(
    (code) => !code.procedure_code || isEmpty(code.procedure_code_modifiers),
  );

  const isSubmitDisabled = requiredMetaDataMissing ||
    !providedService?.unit_amount ||
    !providedService?.starts_at ||
    (insuranceRequired && !validInsurance) ||
    requestedAmountNonValid ||
    zCodesNonValid ||
    placeOfServiceNonValid ||
    canForbidContractedServiceCreationWhenNoConsent ||
    requestedDatesNonValid ||
    nonValidProcedureCodes;

  const showSubmitButton = showInteractions && isBillable && providedService.state === 'active';

  const deleteProvidedServiceConfirmationDialogRef = useRef(null);

  const deleteProvidedService = useDeleteRecord('provided_service', {
    mutationConfig: {
      onSuccess: () => {
        onDelete();
      },
      ...defaultMutationConfig,
    },
  });

  const allowDeletion = useFeatureFlag('pays-2012-allow-deletion-contracted-service-notes');

  const handleSubmit = async () => {
    setSubmitting(true);
    await onSubmit();
    setSubmitting(false);
  };

  return (
    <div className={className}>
      <div className="flex justify-between space-x-4">
        <h3 className="flex-grow text-lg text-text-blue leading-snug">
          {programName} Provided
        </h3>
        {showInteractions && (
          <div className="flex flex-shrink items-start space-x-6 mt-1">
            <IconButton
              className={cx(!showSubmitButton && 'hidden')}
              disabled={isSubmitDisabled || submitting}
              icon="IconSubmit"
              id="submit-provided-service"
              labelText="Submit"
              onClick={() => handleSubmit()}
            />
            {(allowDeletion && isBillable) && (
              <>
                <IconButton
                  disabled={isDeleteDisabled}
                  icon="IconTrash"
                  labelText="Delete"
                  id="delete-provided-service"
                  onClick={() => {
                  deleteProvidedServiceConfirmationDialogRef.current.openDialog();
                }}
                />
                <DeleteProvidedServiceConfirmationDialog
                  ref={deleteProvidedServiceConfirmationDialogRef}
                  onConfirmation={() => deleteProvidedService.deleteRecord(providedService.id, {})}
                />
              </>
            )}
            <IconButton
              disabled={isEditingDisabled}
              icon="IconPencil"
              labelText="Edit"
              id="edit-provided-service"
              onClick={onEdit}
            />
          </div>
        )}
      </div>
      {isBillable && (
        <div className="mt-2 flex space-x-2">
          <span className="text-dark-grey whitespace-no-wrap">
            {moment(providedService.updated_at).utc().format('LL')}
          </span>
          <div className="flex space-x-1 -ml-3 lg:ml-0">
            {providedService.state !== 'active' ? (
              <>
                <Icon
                  className="text-light-green fill-current pt-px"
                  icon="IconCircle"
                  size={24}
                />
                <span className="text-text-blue whitespace-no-wrap">Submitted</span>
              </>
            ) : (
              <>
                <Icon
                  className="text-yellow fill-current pt-px"
                  icon="IconCircle"
                  size={24}
                />
                <span className="text-text-blue whitespace-no-wrap">Pending</span>
              </>
            )}
          </div>
          {
            !isEmpty(invoices) && !isNetworkLead && (
              <Link
                className="whitespace-no-wrap"
                to={`/invoices/${last(invoices).id}`}
              >
                Go to Invoice
              </Link>
            )
          }
        </div>
      )}
    </div>
  );
};

FeeScheduleProvidedServiceCardHeader.propTypes = {
  className: PropTypes.string,
  isBillable: PropTypes.bool.isRequired,
  isNetworkLead: PropTypes.bool,
  metafields: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  programName: PropTypes.string.isRequired,
  providedService: PropTypes.object.isRequired,
  showInteractions: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  ignoreSocialCareExpiredDates: PropTypes.bool.isRequired,
  disallowContractedServiceForUnconsentedClient: PropTypes.bool.isRequired,
  isPersonConsented: PropTypes.bool.isRequired,
  canInvoiceAboveRemainingAuthorizedAmount: PropTypes.bool.isRequired,
  feeScheduleProgram: PropTypes.object.isRequired,
  serviceAuthorization: PropTypes.object.isRequired,
  serviceAuthorizationInvoiceInformation: PropTypes.object.isRequired,
  groupId: PropTypes.string.isRequired,
  personId: PropTypes.string.isRequired,
};

FeeScheduleProvidedServiceCardHeader.defaultProps = {
  className: '',
  isNetworkLead: false,
};

export default FeeScheduleProvidedServiceCardHeader;
