import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@unite-us/app-components';
import { reject, some } from 'lodash';
import UploadAndAttachDocumentsForm from './UploadAndAttachDocumentsForm';
import '../stylesheets/documentUploader.scss';

class UploadAndAttachDocuments extends Component {
  constructor(props) {
    super(props);

    this.onAttach = this.onAttach.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.openDialog = this.openDialog.bind(this);
  }

  onAttach(documents) {
    this.documentDialog.closeModal();
    this.props.onAttachDocuments(documents);
  }

  onCancel() {
    this.documentDialog.closeModal('cancel');
  }

  openDialog() {
    this.documentDialog.openModal();
  }

  render() {
    const {
      anchorEl,
      attachedDocuments,
      contactDocuments,
      dropzoneName,
      warningText,
      showDocumentVisibility,
      hasSocialCareNavigationRole,
    } = this.props;

    const attachableDocuments = reject(contactDocuments, (document) => (
      some(attachedDocuments, { id: document.id })
    ));

    return (
      <div className="upload-and-attach-documents">
        <a
          id="upload-document-link"
          className="upload-and-attach-documents__upload-link"
          role="button"
          onClick={this.openDialog}
        >
          {anchorEl}
        </a>
        <Modal
          id="upload-document-modal"
          title="Upload Documents"
          size="large"
          modal
          showBorderRadius
          ref={(el) => { this.documentDialog = el; }}
        >
          <UploadAndAttachDocumentsForm
            documents={attachableDocuments}
            dropzoneName={dropzoneName}
            onAttach={this.onAttach}
            onCancel={this.onCancel}
            warningText={warningText}
            showDocumentVisibility={showDocumentVisibility}
            hasSocialCareNavigationRole={hasSocialCareNavigationRole}
          />
        </Modal>
      </div>
    );
  }
}

UploadAndAttachDocuments.propTypes = {
  anchorEl: PropTypes.node,
  attachedDocuments: PropTypes.array,
  contactDocuments: PropTypes.array,
  dropzoneName: PropTypes.string.isRequired,
  onAttachDocuments: PropTypes.func.isRequired,
  warningText: PropTypes.string,
  showDocumentVisibility: PropTypes.bool,
  hasSocialCareNavigationRole: PropTypes.bool,
};

UploadAndAttachDocuments.defaultProps = {
  anchorEl: 'Attach or Upload a Document',
  attachedDocuments: [],
  contactDocuments: [],
  showDocumentVisibility: false,
};

export default UploadAndAttachDocuments;
