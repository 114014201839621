export const ASSESSMENTS = {
  clickedByUnauthorizedUser: 'Assessments - Clicked By Unauthorized User',
  clickedFromDetailView: 'Assessments - Clicked via Detail View',
  clickedFromFacesheet: 'Assessments - Clicked via Face Sheet',
  createFromCase: 'Assessments - Updated via Case',
  createFromReferral: 'Assessments - Updated via Referral',
  updatedFromDetailView: 'Assessments - Updated via Detail View',
};

export const ASSISTANCE_REQUEST = {
  clickedOutOfNetworkCase: 'AR - Clicked Create OON Case',
  clickedRefer: 'AR - Clicked Refer',
  closed: 'AR - Closed AR',
  noteAdded: 'when a Note is added to an AR',
};

export const BROWSE = {
  browseFilterApplied: 'Browse - Filter applied on Browse Map',
  addReferralRecipient: 'Browse - Added Referral Recipient',
  removeReferralRecipient: 'Browse - Removed Referral Recipient',
  filtersDrawerOpened: 'Browse - Opened Filters Drawer',
  viewDrawerOrg: 'Browse - Opened Organization Details',
};

export const CARE_COORDINATOR = {
  assignedByDetailView: 'Care Coordinator - Assigned via Detail View',
  assignedByIntake: 'Care Coordinator - Assigned via Intake',
};

export const CONSENT = {
  upload: 'Consent - Client Accepted Consent',
  declined: 'Consent - Client Declined Consent',
};

export const DASHBOARD_EVENTS = {
  allCasesRow: 'Dashboard - Clicked All Cases Row',
  allExternalCasesRow: 'Dashboard - Clicked All External Cases Row',
  allOONCasesRow: 'Dashboard - Clicked All OON Cases Row',
  allNetworkCasesRow: 'Dashboard - Clicked All Network Cases Row',
  assistanceRequestsRow: 'Dashboard - Clicked Assistance Requests Row',
  clientsRow: 'Dashboard - Clicked Clients Row',
  closedAssistanceRequestsRow: 'Dashboard - Clicked Closed Assistance Requests Row',
  closedCasesRow: 'Dashboard - Clicked Closed Cases Row',
  closedExternalCasesRow: 'Dashboard - Clicked Closed External Cases Row',
  closedOONCasesRow: 'Dashboard - Clicked Closed OON Cases Row',
  closedReferralsRow: 'Dashboard - Clicked Closed Referrals Row',
  closedInboundReferralsRow: 'Dashboard - Clicked Closed Inbound Referrals Row',
  draftReferralsRow: 'Dashboard - Clicked Draft Referrals Row',
  intakesRow: 'Dashboard - Clicked Intakes Row',
  newReferralsRow: 'Dashboard - Clicked New Referrals Row',
  oonCasesRow: 'Dashboard - Clicked OON Cases Row',
  openCasesRow: 'Dashboard - Clicked Open Cases Row',
  externalCasesRow: 'Dashboard - Clicked External Cases Row',
  pendingConsentReferralsRow: 'Dashboard - Clicked Pending Consent Referrals Row',
  processedAssistanceRequestsRow: 'Dashboard - Clicked Processed Assistance Requests Row',
  recalledReferralsRow: 'Dashboard - Clicked Recalled Referrals Row',
  rejectedReferralsRow: 'Dashboard - Clicked Rejected Referrals Row',
  reviewReferralsRow: 'Dashboard - Clicked Referrals in Review Row',
  sentReferralsRow: 'Dashboard - Clicked Sent Referrals Row',

  backButton: 'Dashboard - Clicked Details Back Button',
  closeReferral: 'Dashboard - Clicked Close Referral',
  goToFacesheet: 'Dashboard - Clicked Go To Facesheet Button',
  goToIntake: 'Dashboard - Clicked Start Intake',

  // Navigation Details
  navAssistanceRequestClosed: 'Dashboard - Clicked AR Closed Index',
  navAssistanceRequestProcesssed: 'Dashboard - Clicked AR Processed Index',
  navCasedAll: 'Dashboard - Clicked Cases All Index',
  navCasesClosed: 'Dashboard - Clicked Cases Closed Index',
  navCasesOpen: 'Dashboard - Clicked Cases Open Index',
  navClientsAll: 'Dashboard - Clicked Clients All Index',
  navNewAssistanceRequest: 'Dashboard - Clicked New AR Index',
  navNewClientsRequiringAction: 'Dashboard - Clicked New Clients Requiring Action Index',
  navNewPendingConsent: 'Dashboard - Clicked Pending Consent Index',
  navNewReferrals: 'Dashboard - Clicked New Referrals Index',
  navReferralsInReview: 'Dashboard - Clicked Referrals In Review Index',
  navClosedInboundReferrals: 'Dashboard - Clicked on Closed Inbound Referrals Index',
  navScreeningsExternal: 'Dashboard - Clicked Screenings External Index',
  navReferralsClosed: 'Dashboard - Clicked Referrals Closed Index',
  navReferralsDrafts: 'Dashboard - Clicked Referrals Drafts Index',
  navReferralsProviderToProvider: 'Dashboard - Clicked Referrals Provider To Provider Index',
  navReferralsRecalled: 'Dashboard - Clicked Referrals Recalled Index',
  navReferralsRejected: 'Dashboard - Clicked Referrals Rejected Index',
  navReferralsSent: 'Dashboard - Clicked Sent Referrals Pending Consent Index', // is this right
  navReferralsSentAll: 'Dashboard - Clicked Sent Referrals All Index',
  navReferralsSentClosed: 'Dashboard - Clicked Sent Referrals Closed Index',
  navReferralsSentInReview: 'Dashboard - Clicked Sent Referrals In Review Index',
  navReferralsSentNeedsAction: 'Dashboard - Clicked Sent Referrals Needs Action Index',
  navReferralsSentOpen: 'Dashboard - Clicked Sent Referrals Open Index',
  navReferralsSentPendingConsent: 'Dashboard - Clicked Sent Referrals Pending Consent Index', // or is this right
  navScreeningsAll: 'Dashboard - Clicked All Screenings Index',
  navScreeningsNew: 'Dashboard - Clicked New Screenings Index',
  navCasesAllOON: 'Dashboard - Clicked All OON Cases Index',
  navCasesAllNetwork: 'Dashboard - Clicked All Network Cases Index',
  navCasesOON: 'Dashboard - Clicked Cases OON Index',
  navClosedOONCases: 'Dashboard - Clicked Closed OON Cases Index',
  navCasesAllExternal: 'Dashboard - Clicked All External Cases Index',
  navCasesExternal: 'Dashboard - Clicked External Cases Index',
  navClosedExternalCases: 'Dashboard - Clicked Closed External Cases Index',

  allCasesFilter: 'Dashboard - All Cases Select Filters',
  allExternalCasesFilter: 'Dashboard - All External Cases Select Filters',
  allOONCasesFilter: 'Dashboard - All OON Cases Select Filters',
  allNetworkCasesFilter: 'Dashboard - All Network Cases Select Filters',
  assistanceRequestsFilter: 'Dashboard - Assistance Requests Select Filters',
  clientsFilter: 'Dashboard - Clients Select Filters',
  closedAssistanceRequestsFilter: 'Dashboard - Closed Assistance Requests Select Filters',
  closedCasesFilter: 'Dashboard - Closed Cases Select Filters',
  closedExternalCasesFilter: 'Dashboard - Closed External Cases Select Filters',
  closedOONCasesFilter: 'Dashboard - Closed OON Cases Select Filters',
  closedReferralsFilter: 'Dashboard - Closed Referrals Select Filters',
  closedInboundReferralsFilter: 'Dashboard - Closed Referrals Select Filters',
  draftReferralsFilter: 'Dashboard - Draft Referrals Select Filters',
  externalCasesFilter: 'Dashboard - External Cases Select Filters',
  intakesFilter: 'Dashboard - Intakes Select Filters',
  letterFilter: 'Dashboard - Clients Letter Filters',
  newReferralsFilter: 'Dashboard - New Referrals Select Filters',
  oonCasesFilter: 'Dashboard - OON Cases Select Filters',
  openCasesFilter: 'Dashboard - Open Cases Select Filters',
  pendingConsentReferralsFilter: 'Dashboard - Pending Consent Referrals Select Filters',
  processedAssistanceRequestsFilter: 'Dashboard - Processed Assistance Requests Select Filters',
  providerToProviderReferralFilter: 'Dashboard - Provider To Provider Referrals Select Filters',
  recalledReferralsFilter: 'Dashboard - Recalled Referrals Select Filters',
  rejectedReferralsFilter: 'Dashboard - Rejected Referrals Select Filters',
  reviewReferralsFilter: 'Dashboard - Referrals in Review Select Filters',
  screeningsFilter: 'Dashboard - Screenings Select Filters',
};

export const DASHBOARD_VIEWS = {
  allCases: 'Dashboard - All Cases',
  allExternalCases: 'Dashboard - All External Cases',
  allOONCases: 'Dashboard - All OON Cases',
  allNetworkCases: 'Dashboard - All Network Cases',
  assistanceRequestDetailView: 'Dashboard - Assistance Request Detail View',
  assistanceRequests: 'Dashboard - Assistance Requests',
  clients: 'Dashboard - Clients',
  clientsWithIntakes: 'Dashboard - Clients /w Intakes',
  closedAssistanceRequests: 'Dashboard - Closed Assistance Requests',
  closedCases: 'Dashboard - Closed Cases',
  closedExternalCases: 'Dashboard - Closed External Cases',
  closedOONCases: 'Dashboard - Closed OON Cases',
  closedReferrals: 'Dashboard - Closed Referrals',
  closedInboundReferrals: 'Dashboard - Closed Inbound Referrals',
  draftReferrals: 'Dashboard - Draft Referrals',
  externalCases: 'Dashboard - External Cases',
  intakes: 'Dashboard - Intakes',
  newReferrals: 'Dashboard - New Referrals',
  oonCases: 'Dashboard - OON Cases',
  openCases: 'Dashboard - Open Cases',
  pendingConsentReferrals: 'Dashboard - Pending Consent Referrals',
  processedAssistanceRequests: 'Dashboard - Processed Assistance Requests',
  recalledReferrals: 'Dashboard - Recalled Referrals',
  rejectedReferrals: 'Dashboard - Rejected Referrals',
  reviewReferrals: 'Dashboard - Referrals in Review',
  screenings: 'Dashboard - Screenings',
  sentReferrals: 'Dashboard - Sent Referrals',
};

export const FACESHEET = {
  addressUpdated: 'Face Sheet - Address Updated',
  careTeamMemberAdded: 'Face Sheet - Care Team Member Added',
  familyMemberAddClicked: 'Face Sheet - Clicked Add Family Member',
  familyMemberEditClicked: 'Face Sheet - Clicked Edit Family Member',
  familyMemberAdded: 'Face Sheet - Family Member Added',
  familyMemberUpdated: 'Face Sheet - Family Member Updated',
  familyMemberRemoved: 'Face Sheet - Family Member Removed',
  cases: 'Face Sheet - Cases Clicked',
  citizenshipUpdated: 'Face Sheet - Citizenship Updated',
  contactPreferenceUpdated: 'Face Sheet - Contact Preferences Updated',
  dobUpdated: 'Face Sheet - Date of Birth Updated',
  emailUpdated: 'Face Sheet - Email Updated',
  forms: 'Face Sheet - Forms Clicked',
  grossMonthylIncomeUpdated: 'Face Sheet - Gross Monthly Income Updated',
  householdSizeUpdated: 'Face Sheet - Household Size Updated',
  identifiesAsUpdated: 'Face Sheet - Identifies As Updated',
  // TODO(Art): The following key can be deleted once the legacy insurance form is no longer used.
  insuranceUpdated: 'Face Sheet - Insurance Information Updated',
  // Previous pattern (Face Sheet - ...) not being followed per this document
  // https://docs.google.com/document/d/1p_jVOjgHqrDXhCtvQoe3_ZFbXC25-WafmNfzPvDeRyw
  insuranceChanged: 'Insurance Changed - Face Sheet',
  maritalStatusUpdated: 'Face Sheet - Marital Status Updated',
  militaryStatusUpdated: 'Face Sheet - Military Information Updated',
  nameUpdated: 'Face Sheet - Name Updated',
  overview: 'Face Sheet - Clicked Overview',
  phoneUpdated: 'Face Sheet - Phone Number Updated',
  profile: 'Face Sheet - Profile Clicked',
  referralClicked: 'Face Sheet - Clicked Referral',
  referrals: 'Face Sheet - Referrals Clicked',
  screenings: 'Face Sheet - Screenings Clicked',
  sentClientMessage: 'Face Sheet - Sent Client Message',
  serviceCaseClicked: 'Face Sheet - Clicked Case',
  ssnUpdated: 'Face Sheet - SSN Updated',
  uploads: 'Face Sheet - Uploads Clicked',
  resource: 'Face Sheet - Resource Lists Clicked',
};

export const HOME = {
  assignedCCDraftReferrals: 'Home - (Assigned CC) Draft Referrals',
  assignedCCRecalled: 'Home - (Assigned CC) Recalled',
  assignedCCReferralsInReview: 'Home - (Assigned CC) Referrals in Review',

  assignedOrgClosedCases: 'Home - (Assigned Org) Closed Cases',
  assignedOrgDraftReferrals: 'Home - (Assigned Org) Draft Referrals',
  assignedOrgOpenCases: 'Home - (Assigned Org) Open Cases',
  assignedOrgReferralsInReview: 'Home - (Assigned Org) Referrals in Review',

  incomingNewARs: 'Home - (Incoming) New ARs',
  incomingNewReferrals: 'Home - (Incoming) New Referrals',
  incomingPendingConsent: 'Home - (Incoming) Pending Consent',

  referredOutAcceptedReferrals: 'Home - (Referred Out) Accepted Referrals',
  referredOutClosedCases: 'Home - (Referred Out) Closed Cases',
  referredOutNeedsAction: 'Home - (Referred Out) Needs Action',
  referredOutOpenCases: 'Home - (Referred Out) Open Cases',
  referredOutPendingConsent: 'Home - (Referred Out) Pending Consent',
  referredOutReferralsInReview: 'Home - (Referred Out) Referrals in Review',
  referredOutRejectedReferrals: 'Home - (Referred Out) Rejected Referrals',
};

export const INTAKE = {
  createFromAr: 'Intake - Created via AR',
  createFromNavBarExistingContact: 'Intake - Created via Nav Bar for Existing Client',
  createFromNavBarNewContact: 'Intake - Created via Nav Bar for New Client',
  createFromReferral: 'Intake - Clicked Start Intake via Referral',
  edited: 'Assessments - Updated via Face Sheet',
  serviceClicked: 'Intake - Service Selected',
  startFromPlusIcon: 'Intake - Clicked New Intake',
  viewIntakeClicked: 'Intake - Clicked View Intake',
  // Previous pattern (Intake - ...) not being followed per this document
  // https://docs.google.com/document/d/1p_jVOjgHqrDXhCtvQoe3_ZFbXC25-WafmNfzPvDeRyw
  insuranceChanged: 'Insurance Changed - Intake',
};

export const LEFT_NAVIGATION = {
  clientLink: 'Navigation - Clicked Clients',
  dashboardLink: 'Navigation - Clicked Dashboard',
  homeLink: 'Navigation - Clicked Home',
  networkLink: 'Navigation - Clicked My Networks',
  networkMenuItem: 'Navigation - Clicked My Network (to Browse Map)',
  exportsLink: 'Navigation - Clicked Exports',
  insightsLink: 'Navigation - Clicked Insights',
  summaryDashboard: 'Navigation - Clicked Summary Dashboard',
  backOfficeLink: 'Navigation - Clicked Back Office',
  authorizationsLink: 'Navigation - Clicked Authorizations',
  tasksListLink: 'Navigation - Clicked Tasks List',
};

// TODO: (ALEX) Apply download-prefix to new exports
export const LEFT_NAV_REPORTS = {
  downloadContactExport: 'Reports - Downloaded Clients Export',
  downloadGroupExport: 'Reports - Downloaded Org Users Export',
  downloadNetworkExport: 'Reports - Downloaded Network Users Export',
  downloadReferralExport: 'Reports - Downloaded Referrals Export',
  downloadServiceCaseExport: 'Reports - Downloaded Cases Export',
  downloadServiceEpisodeExport: 'Reports - Downloaded Service Episodes Export',
  downloadServiceEpisodeExportsRaw: 'Reports - Downloaded Service Episodes Raw Export',
  exportClicked: 'Reports - Clicked Exports',
  groupClicked: 'Reports - Clicked Org Reports',
  networkClicked: 'Reports - Clicked Network Reports',
};

export const LOGIN = {
  acceptEulaAgreement: 'Login - Accept Eula Agreement',
  accountConfirmation: 'Login - Clicked Confirm Account',
  signedIn: 'Login - Clicked Login',
  signedOut: 'Login - Clicked Log Out',
  session: 'Login - New Session',
};

// TODO: (BITA) We should recategorize these to be more specific to each tab
export const MY_NETWORKS = {
  browseTabClicked: 'My Networks - Clicked Browse Map Tab',
  directorySearched: 'My Networks - Searched Users',
  orgsTabClicked: 'My Networks - Clicked Organizations Tab',
  orgTileClicked: 'My Networks - Clicked Organization',
  providerSearched: 'My Networks - Searched Organizations',
  selectNetwork: 'My Networks - Filtered by Permitted Network',
  selectNetworkScope: 'My Networks - Filtered by Network Scope',
  selectServiceType: 'My Networks - Filtered by Service Type',
  usersTabClicked: 'My Networks - Clicked Users Tab',
  shareClicked: 'My Networks - Shared Org Info',
};

export const NEW_CLIENT = {
  createFromArReferral: 'New Client - Created via AR-Referral Conversion',
  updateFromArReferral: 'New Client - Updated via AR-Referral Conversion',
  createFromArServiceCase: 'New Client - Created via AR-Case Conversion',
  createFromNav: 'New Client - Created via Nav Bar',
  updateFromNav: 'New Client - Updated via Nav Bar',
  createFromReferral: 'New Client - Created via Referral',
  updateFromReferral: 'New Client - Updated via Referral',
  createFromScreening: 'New Client - Created via Screening',
  updateFromScreening: 'New Client - Updated via Screening',
  startFromPlusIcon: 'New Client - Clicked New Client',
  changedInsurance: 'New Client - Insurance Changed',
};

export const ORGANIZATION_SELF_ONBOARDING = {
  submitForReview: 'Org settings - User clicked submit for review (default attributes)',
  submit: 'Org settings - User clicked Submit in submit confirmation modal (default attributes)',
  cancel: 'Org settings - User clicked Cancel in submit confirmation modal',
};

export const REFERRAL = {
  accept: 'Referral - Clicked Accept',
  accepted: 'Referral - Accepted',
  addAnother: 'Referral - Clicked Add Another Referral',
  addAnotherRecipient: 'Referral - Clicked Add Another Recipient',
  autoRecallReferrals: 'Referral - AutoRecall Referrals Checked',
  browseMapClicked: 'Referral - Clicked "Browse Map" link',
  changeReferredToNetwork: 'Referral - Referred To Another Network',
  closeReferral: 'Referral - Clicked Close Referral',
  create: 'Referral - Created',
  createFromAr: 'Referral - Created via AR',
  descriptionUpdated: 'Referral - Description Updated',
  documentUploaded: 'Referral - Document Uploaded',
  heldForReview: 'Referral - Held for Review',
  holdForReview: 'Referral - Clicked Hold for Review',
  noteAdded: 'Referral - Note Added',
  onReferButtonClicked: 'Referral - Clicked Refer via Face Sheet',
  programSelected: 'Referral - Program Selected',
  recall: 'Referral - Recalled',
  referralGroupSelected: 'Referral - Referral Group Selected',
  reject: 'Referral - Clicked Reject',
  rejected: 'Referral - Rejected',
  saveDraft: 'Referral - Saved Draft',
  selectProgram: 'Referral - Select Program Clicked',
  send: 'Referral - Clicked Send',
  sendDraft: 'Referral - Clicked Send on Draft',
  sent: 'Referral - Sent',
  serviceTypeSelected: 'Referral - Service Type Selected',
  serviceTypeUpdated: 'Referral - Service Type Updated',
  startFromPlusIcon: 'Referral - Clicked New Referral',
  shareClicked: 'Referral - Shared Org Info',
};

export const REPORTS = {
  createdExport: 'Reports - Created Export',
  downloadedExport: 'Reports - Downloaded Export',
};

export const SCREENINGS = {
  startFromPlusIcon: 'Screenings - Clicked New Screenings',
};

export const TASKS = {
  startFromPlusIcon: 'Tasks - Clicked New Task',
};

export const SEARCH = {
  changeIndexView: 'SearchBar - Changed view from index',
  clickResult: 'SearchBar - Clicked search result from dropdown',
  clickIndexResult: 'SearchBar - Clicked search result from index',
  closeSearchBar: 'SearchBar - Closed searchbar from top nav',
  ignoredRecords: 'Client Search - Ignored Existing Client Records',
  openSearchBar: 'SearchBar - Opened search from top nav',
  searchIndex: 'SearchBar - Hit enter key on search bar to view all results',
  useRecord: {
    // TODO: to be removed with https://uniteus.atlassian.net/browse/UU3-45303
    client: 'Use Record - Matched Existing during Client Creation',
    intake: 'Use Record - Matched Existing during Intake Creation',
    referral: 'Use Record - Matched Existing during Referral Creation',
    screening: 'Use Record - Matched Existing during Screening Creation',
    // END TODO
    contacts: 'Use Record - Matched Existing during Client Creation',
    intakes: 'Use Record - Matched Existing during Intake Creation',
    referrals: 'Use Record - Matched Existing during Referral Creation',
    screenings: 'Use Record - Matched Existing during Screening Creation',
  },
  useRecordNoId: {
    contacts: 'Use Record - Use Record - Matched Unlinked External Contact during Client Creation',
    // eslint-disable-next-line max-len
    'assistance-requests/contacts': 'Use Record - Use Record - Matched Unlinked External Contact during Client Creation',
    intakes: 'Use Record - Use Record - Matched Unlinked External Contact during Intake Creation',
    referrals: 'Use Record - Use Record - Matched Unlinked External Contact during Referral Creation',
    // eslint-disable-next-line max-len
    'assistance-requests/referrals': 'Use Record - Use Record - Matched Unlinked External Contact during Referral Creation',
    screenings: 'Use Record - Use Record - Matched Unlinked External Contact during Screening Creation',
  },
  viewAllResults: 'SearchBar - Clicked "View All" link for specific model',
};

export const SERVICE_CASE = {
  close: 'Case - Closed Case',
  createFromAr: 'Case - OON Case Created via AR',
  createFromFacesheet: 'Case - Created via Face Sheet',
  createFromReferral: 'Case - OON Case Created via Referral',
  descriptionUpdated: 'Case - Description Updated',
  documentUploaded: 'Case - Document Uploaded',
  noteAdded: 'Case - Note Added',
  primaryWorkerAssigned: 'Case - Primary Worker Assigned',
  reopen: 'Case - Case Reopened',
  createOONCaseFromReferral: 'Case - Clicked Create OON Case in Referral',
  OONGroupSelected: 'Case - OON Group Selected',
  OONGroupSelectedCustom: 'Case - Custom OON Group Added',
  OONGroupDeselected: 'Case - OON Group Removed from Selection',
  OONGroupPrintButtonClicked: 'Case - OON Group(s) Printed',
  OONCaseCreated: 'Case - OON Case Created',
};

export const SERVICES_PROVIDED = {
  addedNewFeeScheduleServiceProvided: 'Services Provided - Added New Fee Schedule Service Provided',
  clickedSaveNote: 'Services Provided - Clicked Save Note',
  editNote: 'Services Provided - Edit Note',
};

export const INVOICE = {
  cboClickedReject: 'Invoice - CBO Clicked Reject',
  cboClickedApprove: 'Invoice - CBO Clicked Approved',
  cboClickedPaid: 'Invoice - CBO Marked As Paid',
  cboClickedFixInvoiceFromDispute: 'Invoice - Clicked Fix and Make New Invoice from Dispute Resolution',
  nlClickedReject: 'Invoice - Network Lead Clicked Reject',
  nlClickedSubmit: 'Invoice - Network Lead Clicked Submit',
  nlClickedDispute: 'Invoice - Network Lead Clicked Dispute',
  nlClickedDisputeResolution: 'Invoice - Network Lead Clicked Dispute Resolution',
  invoiceFilterStatusClicked: 'Invoice - Clicked Filter By Status',
  invoiceFilterLastUpdatedClicked: 'Invoice - Clicked Filter By Last Updated',
  invoiceFilterServiceProvidedClicked: 'Invoice - Clicked Filter By Service Provided',
  invoiceFilterInvoiceShortIdClicked: 'Invoice - Clicked Filter By Short ID',
  invoiceFilterCleared: 'Invoice - Clicked Clear Filter',
  payerClickedApprove: 'Invoice - Payer Clicked Accept',
  payerClickedReject: 'Invoice - Payer Clicked Reject',
  payerClickedPay: 'Invoice - Payer Clicked Pay Invoice',
  invoiceFilterProviderClicked: 'Invoice - Clicked Filter By Payer Provider',
  invoiceFilterClientNameClicked: 'Invoice - Clicked Filter By Client Name',
  invoiceFilterPayerClicked: 'Invoice - Clicked Filter By Payer Name',
  invoiceFilterResolutionReasonClicked: 'Invoice - Clicked Filter By Dispute Resolution Reason',
  invoiceFilterDateDurationClicked: 'Invoice - Clicked Filter By Date Duration',
  invoiceFilterDisputeReasonClicked: 'Invoice - Clicked Filter By Dispute Reason',
  payerClickedBulkActions: {
    pay: 'Invoice - Payer Clicked Bulk Pay Invoices',
    accept: 'Invoice - Payer Clicked Bulk Accept Invoices',
    reject: 'Invoice - Payer Clicked Bulk Reject Invoices',
    archive: 'Invoice - Payer Clicked Bulk Archive Invoices',
    download: 'Invoice - Payer Clicked Bulk Download Invoices',
  },
};

export const ORG_SETTINGS = {
  orgSaved: 'Org settings - Organization saved',
  progSaved: 'Org settings - Program saved',
  locSaved: 'Org settings - Location saved',
  invitedUser: 'Org settings - Invited user',
  savedUser: 'Org settings - Saved User',
  orgViewed: 'Org settings - Organization viewed',
  editOrgViewed: 'Org settings - Edit Organization viewed',
  newProgViewed: 'Org settings - New Program viewed',
  editProgViewed: 'Org settings - Edit Program viewed',
  newLocViewed: 'Org settings - New Location viewed',
  editLocViewed: 'Org settings - Edit Location viewed',
  userListViewed: 'Org settings - User List viewed',
  userViewed: 'Org settings - User viewed',
  newUserViewed: 'Org settings - New User viewed',
  editUserViewed: 'Org settings - Edit User viewed',
  newUserCreated: 'Org settings - New User created by Org Admin',
  createdProgram: 'Org settings - New Program created by Org Admin',
  editedProgram: 'Org settings - New Program edited by Org Admin',

};

const eventConstants = {
  ASSESSMENTS,
  ASSISTANCE_REQUEST,
  BROWSE,
  CARE_COORDINATOR,
  CONSENT,
  DASHBOARD_EVENTS,
  DASHBOARD_VIEWS,
  FACESHEET,
  HOME,
  INTAKE,
  INVOICE,
  LEFT_NAV_REPORTS,
  LEFT_NAVIGATION,
  LOGIN,
  MY_NETWORKS,
  NEW_CLIENT,
  ORGANIZATION_SELF_ONBOARDING,
  REFERRAL,
  REPORTS,
  SCREENINGS,
  SEARCH,
  SERVICE_CASE,
  SERVICES_PROVIDED,
  TASKS,
};

export default eventConstants;
