import {
  useCreateRecordBase,
  useDeleteRecordBase,
  useDeleteRelationshipBase,
  useFindAllBase,
  useFindRecordBase,
  usePopulateBase,
  useQueryBase,
  useSetRelationshipBase,
  useUpdateRecordBase,
  useUpdateRecordMultipartBase,
} from '@unite-us/json-api-resources';
import { isPlainObject } from 'lodash';
import pluralize from 'pluralize';
import { useMemo } from 'react';
import { useMutation, useQuery as useQueryRQ, useQueryClient } from 'react-query';
import { getAdapter } from './config';

/*
// Documentation
// eslint-disable-next-line no-unused-vars
const options = {
  // React-Query mutation options
  // Only for useCreateRecord, useUpdateRecord, useDeleteRecord
  // eg: onError, onSuccess... etc
  // https://react-query.tanstack.com/reference/useMutation
  mutationConfig: {},

  // React-Query query options
  // Only for useQuery, useFindRecord, useFindAll, usePopulate,
  // eg: select, onError, onSuccess ... etc
  // https://react-query.tanstack.com/reference/useQuery
  queryConfig: {},
  api: 'coreApi', // coreApi or apiV1 or apiV4
  jsonApiParams: {
    include: '',
    groupId: '',
    page: {},
    sort: '',
    context: '',
    httpConfig: {},
  },
};

// eslint-disable-next-line no-unused-vars
const overrideOptions = {
  mutationConfig: {},
  jsonApiParams: {},
};

*/

const defaultApi = 'apiV1';

const genQueryFiltersMatchingRecord = ({
  id,
  model,
  including,
  notIncluding,
}) => ({
  predicate: (query) => {
    if (typeof model !== 'string' || model.length < 1) return false;

    const queryModel = query.queryKey[0];
    const isCorrectModel = queryModel.startsWith(pluralize.singular(model));
    if (!isCorrectModel) return false;

    const queryId = query.queryKey[1];
    const isFindRecord = typeof queryId === 'string';
    if (isFindRecord && queryId !== id) return false;

    const queryOptions = query.queryKey[2];
    const queryInclusions = queryOptions?.include?.split(',') ?? [];

    if (including && !queryInclusions.some((rel) => including.includes(rel))) return false;
    if (notIncluding && queryInclusions.some((rel) => notIncluding.includes(rel))) return false;

    return true;
  },
});

const applyUpdatesToRecord = (existingRecord, updates, relationships) => Object.entries(updates).reduce(
  (record, [updatedKey, updatedVal]) => {
    const isRelationshipChange = relationships?.includes(updatedKey);
    if (isRelationshipChange) {
      const updatedId = updatedVal?.id ?? updatedVal;
      if (record[updatedKey]?.id !== updatedId) {
        return { ...record, [updatedKey]: { id: updatedId } };
      }
    } else { // isAttributeChange
      return { ...record, [updatedKey]: updatedVal };
    }
    return record;
  },
  { ...existingRecord },
);

const genUpdaterFunctionForFindAndFindRecordQueries = ({ updates, id }) => (oldData) => {
  if (!oldData?.data?.data) return oldData;
  if (updates.id && updates.id !== id) return oldData;

  const isFindRecordForThisRecord = oldData.data.data.id === id;
  const indexOfExisting = oldData.data.data.findIndex?.((x) => x.id === id) ?? -1;
  const isRecordInQuery = isFindRecordForThisRecord || indexOfExisting >= 0;

  if (!isRecordInQuery) return oldData;

  const responseData = JSON.parse(oldData.request.response).data;
  const relationships = Object.keys((responseData[0] ?? responseData).relationships);

  const existingRecord = oldData.data.data[indexOfExisting] ?? oldData.data.data;
  const recordWithChanges = applyUpdatesToRecord(existingRecord, updates, relationships);

  let dataDataData;
  if (indexOfExisting >= 0) {
    dataDataData = [...oldData.data.data];
    dataDataData[indexOfExisting] = recordWithChanges;
  } else {
    dataDataData = recordWithChanges;
  }

  return { ...oldData, data: { ...oldData.data, data: dataDataData } };
};

export const useFindRecord = (modelName, id, params = {}) => {
  const { api = defaultApi, queryConfig = {}, ...rest } = params;
  return useFindRecordBase(
    modelName,
    id,
    {
      ...rest, api: getAdapter(api), useQuery: useQueryRQ, queryConfig: { enabled: true, ...queryConfig },
    },
  );
};

export const useFind = (modelName, query, params = {}) => {
  const { api = defaultApi, queryConfig = {}, ...rest } = params;
  return useQueryBase(
    modelName,
    query,
    {
      ...rest, api: getAdapter(api), useQuery: useQueryRQ, queryConfig: { enabled: true, ...queryConfig },
    },
  );
};

export const useFindAll = (modelName, params = {}) => {
  const { api = defaultApi, queryConfig = {}, ...rest } = params;
  return useFindAllBase(
    modelName,
    {
      ...rest, api: getAdapter(api), useQuery: useQueryRQ, queryConfig: { enabled: true, ...queryConfig },
    },
  );
};

export const useCreateRecord = (modelName, options = {}) => {
  const callback = useCreateRecordBase({
    useMutation,
    mutationConfig: options.mutationConfig,
  });

  const mutateAsync = callback.mutateAsync;
  callback.mutate = null;
  callback.mutateAsync = null;
  callback.createRecord = (data, overrideOptions = {}) => {
    const api = getAdapter(options.api ?? defaultApi);
    const newData = {
      model: modelName,
      data,
      params: { ...overrideOptions.jsonApiParams, api },
    };

    return mutateAsync(newData, overrideOptions.mutationConfig);
  };

  return callback;
};

export const useUpdateMultipartRecord = (modelName, options = {}) => {
  const callback = useUpdateRecordMultipartBase({ useMutation, mutationConfig: options.mutationConfig });

  const mutateAsync = callback.mutateAsync;
  callback.mutate = null;
  callback.mutateAsync = null;
  callback.updateRecord = (id, data, overrideOptions = {}) => {
    const api = getAdapter(options.api ?? defaultApi);
    const newData = {
      model: modelName,
      id,
      data,
      params: { ...overrideOptions.jsonApiParams, api },
    };
    return mutateAsync(newData, overrideOptions.mutationConfig);
  };
  return callback;
};

export const useUpdateRecord = (modelName, options = {}) => {
  const callback = useUpdateRecordBase({ useMutation, mutationConfig: options.mutationConfig });

  const mutateAsync = callback.mutateAsync;
  callback.mutate = null;
  callback.mutateAsync = null;
  callback.updateRecord = (id, data, overrideOptions = {}) => {
    const api = getAdapter(options.api ?? defaultApi);
    const newData = {
      model: modelName,
      id,
      data,
      params: { ...overrideOptions.jsonApiParams, api },
    };

    return mutateAsync(newData, overrideOptions.mutationConfig);
  };

  return callback;
};

export const useUpdateRecordOptimistically = (modelName, options) => {
  const queryClient = useQueryClient();

  const onMutate = async ({ data: updates, id, model }) => {
    await queryClient.cancelQueries(genQueryFiltersMatchingRecord({ id, model }));

    const queriesIncludingMutatedRelationship = queryClient.getQueriesData(
      genQueryFiltersMatchingRecord({
        id,
        model,
        including: Object.keys(updates),
      }),
    ) ?? [];
    const mutatedIncludedRelationships = queriesIncludingMutatedRelationship.flatMap(
      ([queryKey]) => queryKey[2]?.include?.split(',') ?? [],
    );

    queryClient.setQueriesData(
      genQueryFiltersMatchingRecord({
        id,
        model,
        notIncluding: mutatedIncludedRelationships.length > 0 ? mutatedIncludedRelationships : undefined,
      }),
      genUpdaterFunctionForFindAndFindRecordQueries({ updates, id }),
    );

    return { mutatedIncludedRelationships };
  };

  const onSuccess = (newData, { id, model }, { mutatedIncludedRelationships }) => {
    if (newData?.data?.data?.id === id) {
      if (mutatedIncludedRelationships.length > 0) {
        // The only way to get up-to-date information for a modified, included, record is to refetch from the api
        queryClient.invalidateQueries(
          genQueryFiltersMatchingRecord({
            id,
            model,
            including: mutatedIncludedRelationships,
          }),
        );
      }
      queryClient.setQueriesData(
        genQueryFiltersMatchingRecord({
          id,
          model,
          notIncluding: mutatedIncludedRelationships.length > 0 ? mutatedIncludedRelationships : undefined,
        }),
        genUpdaterFunctionForFindAndFindRecordQueries({ updates: newData.data.data, id }),
      );
    } else {
      queryClient.resetQueries(genQueryFiltersMatchingRecord({ id, model }), { cancelRefetch: true });
    }
  };

  const onError = (err, { id, model }) => {
    queryClient.resetQueries(genQueryFiltersMatchingRecord({ id, model }), { cancelRefetch: true });
  };

  return useUpdateRecord(
    modelName,
    {
      ...options,
      mutationConfig: {
        ...options?.mutationConfig,
        onMutate,
        onSuccess: async (...args) => {
          onSuccess(...args);
          await options?.mutationConfig?.onSuccess?.(...args);
        },
        onError: async (...args) => {
          onError(...args);
          await options?.mutationConfig?.onError?.(...args);
        },
      },
    },
  );
};

export const useDeleteRecord = (modelName, options = {}) => {
  const callback = useDeleteRecordBase({ useMutation, mutationConfig: options.mutationConfig });

  const mutateAsync = callback.mutateAsync;
  callback.mutate = null;
  callback.mutateAsync = null;
  callback.deleteRecord = (id, overrideOptions) => {
    const api = getAdapter(options.api ?? defaultApi);
    const newData = {
      model: modelName,
      id,
      params: { ...overrideOptions.jsonApiParams, api },
    };

    return mutateAsync(newData, overrideOptions.mutationConfig);
  };

  return callback;
};

export const usePopulate = (relationshipName, modelName, data, params = {}) => {
  const { api = defaultApi, queryConfig = {}, ...rest } = params;

  return usePopulateBase(
    relationshipName,
    modelName,
    data,
    {
      ...rest,
      api: getAdapter(api),
      useQuery: useQueryRQ,
      queryConfig: { enabled: true, ...queryConfig },
    },
  );
};

export const usePopulateMemo = (response, queriesDependencies, useIsLoading = false) => {
  const isFetching = useIsLoading ?
    queriesDependencies.some((q) => q.isLoading) : queriesDependencies.some((q) => q.isFetching);
  useMemo(() => {
    if (Array.isArray(response?.data?.data)) {
      response.data.data = [...response.data.data];
    } else if (isPlainObject(response?.data?.data)) {
      response.data.data = { ...response.data.data };
    }
  }, [isFetching]);

  return isFetching;
};

export const useInvalidateQueries = () => {
  const queryClient = useQueryClient();

  return (modelName) => queryClient.invalidateQueries(modelName);
};

export const useSetRelationship = (
  modelName,
  relationshipName,
  options = {},
) => {
  const callback = useSetRelationshipBase({
    useMutation,
    mutationConfig: options.mutationConfig,
  });

  const mutateAsync = callback.mutateAsync;
  callback.mutate = null;
  callback.mutateAsync = null;
  callback.setRelationship = (modelId, relationshipIds, overrideOptions = {}) => {
    const api = getAdapter(options.api ?? defaultApi);
    const newData = {
      modelName,
      id: modelId,
      relationshipModel: relationshipName,
      value: relationshipIds,
      params: { ...overrideOptions.jsonApiParams, api },
    };

    return mutateAsync(newData, overrideOptions.mutationConfig);
  };

  return callback;
};

export const useDeleteRelationship = (
  modelName,
  relationshipName,
  options = {},
) => {
  const callback = useDeleteRelationshipBase({
    useMutation,
    mutationConfig: options.mutationConfig,
  });

  const mutateAsync = callback.mutateAsync;
  callback.mutate = null;
  callback.mutateAsync = null;
  callback.deleteRelationship = (modelId, relationshipIds, overrideOptions = {}) => {
    const api = getAdapter(options.api ?? defaultApi);
    const newData = {
      modelName,
      id: modelId,
      relationshipModel: relationshipName,
      value: relationshipIds,
      params: { ...overrideOptions.jsonApiParams, api },
    };

    return mutateAsync(newData, overrideOptions.mutationConfig);
  };

  return callback;
};
