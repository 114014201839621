import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { isNull } from 'lodash';
import classNames from 'classnames';
import { Combobox } from '@headlessui/react';
import { Icon } from '@unite-us/ui';
import ReduxFormControlWrapper from './ReduxFormControlWrapper';

export const SearchableSelectField = ({
  className,
  clearable,
  hideLabel,
  label,
  labelClassName,
  listBoxUpOptionsClassName,
  onChange,
  options,
  placeholder,
  required,
  value,
  truncateOptions,
  onInputChange,
  inputValue,
  hasError,
  errorMsg,
}) => {
  const selectedOption = options.find((option) => option.value === value) || null;
  const [query, setQuery] = useState('');
  const filteredOptions = useMemo(() => (
    options.filter((option) => (
      query === '' || option.label.toLowerCase().includes(query.toLowerCase())
    ))
  ), [options, query]);
  useEffect(() => {
    if (isNull(selectedOption)) {
      setQuery('');
    }
  }, [selectedOption, setQuery]);

  const onClearClick = (event) => {
    event.stopPropagation();
    onChange('');
  };

  return (
    <Combobox value={selectedOption} onChange={onChange}>
      {({ open }) => (
        <div className={classNames(className, 'antialiased')} aria-live="polite">
          <Combobox.Label className={classNames(
            'block mb-1 leading-snug',
            'font-extrabold font-medium-font normal-case',
            'text-13px',
            !hasError && 'text-blue',
            hasError && 'text-red',
            labelClassName,
            hideLabel && 'hidden',
            required && 'ui-form-field__label--required', // red asterisk after content
          )}
          >
            {label}
          </Combobox.Label>
          <div className="relative">
            <div className={classNames(
              'relative box-border w-full bg-white cursor-default text-left',
              'pl-3 py-2',
              'focus:outline-none border border-solid rounded-md shadow-sm',
              'focus:shadow-input-ring',
              open && 'border-action-blue shadow-input-ring',
              clearable && selectedOption ? 'pr-12' : 'pr-10',
              !hasError &&
              'border-dark-border-blue border-dark-border-blue hover:border-action-blue focus:border-action-blue',
              hasError && 'border-red',
            )}
            >
              <div className="absolute inset-y-0 flex items-center">
                <Icon
                  aria-hidden
                  className="text-gray"
                  icon="IconSearch"
                  size={16}
                />
              </div>
              <Combobox.Input
                className="h-5 w-full pl-6 leading-5 focus:outline-none"
                displayValue={(option) => (option ? option.label : '')}
                onChange={(evt) => {
                  if (onInputChange) { onInputChange(evt.target.value); } else { setQuery(evt.target.value); }
                }}
                value={inputValue}
                placeholder={placeholder}
              />
              {
                clearable && selectedOption && (
                  <span
                    className="absolute opacity-50 inset-y-0 right-4 flex items-center pr-5"
                  >
                    <Icon
                      className="text-gray"
                      icon="IconCross"
                      size={10}
                      onClick={onClearClick}
                      ariaLabel={`Clear ${label}`}
                    />
                  </span>
                )
              }
              <Combobox.Button
                className={classNames(
                  'absolute inset-y-0 right-0 flex items-center pr-4 focus:outline-none',
                  clearable && selectedOption ? 'pl-0' : 'pl-3',
                )}
              >
                <Icon
                  aria-hidden
                  className="text-gray"
                  icon="IconChevronDown"
                  size={12}
                />
              </Combobox.Button>
            </div>
            <Combobox.Options
              className={classNames(
                'absolute z-dropdown box-content pl-0 py-1 z-10 mt-1 w-full max-h-80 overflow-auto',
                'bg-white shadow-lg rounded-md text-base focus:outline-none',
                listBoxUpOptionsClassName,
              )}
            >
              {filteredOptions.map((option) => (
                <Combobox.Option
                  className={({ active }) => classNames(
                    'relative cursor-pointer py-2 pl-3 pr-9 select-none',
                    active && 'bg-action-blue',
                  )}
                  key={option.value}
                  value={option}
                >
                  {({ active, selected }) => (
                    <>
                      <span
                        className={classNames(
                          'block leading-4',
                          truncateOptions && 'truncate',
                          active ? 'text-white' : 'text-text-blue',
                          selected ? 'font-semibold mr-8' : 'font-normal',
                        )}
                      >
                        {option.label}
                      </span>
                      {selected && (
                        <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                          <Icon
                            aria-hidden
                            className={classNames(
                              'h-5 w-5 fill-current',
                              active ? 'text-white' : 'text-action-blue',
                            )}
                            icon="IconCheck"
                          />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
            {
              hasError && (
                <span className="text-red text-xs mt-1 font-medium">
                  {errorMsg}
                </span>
              )
            }
          </div>
        </div>
      )}
    </Combobox>
  );
};

SearchableSelectField.propTypes = {
  className: PropTypes.string,
  clearable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.object,
        PropTypes.array,
        PropTypes.number,
      ]).isRequired,
    }),
  ),
  label: PropTypes.node,
  labelClassName: PropTypes.string,
  hideLabel: PropTypes.bool,
  listBoxUpOptionsClassName: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.object,
    PropTypes.array,
    PropTypes.number,
  ]).isRequired,
  truncateOptions: PropTypes.bool,
  onInputChange: PropTypes.func,
  inputValue: PropTypes.string,
  hasError: PropTypes.bool,
  errorMsg: PropTypes.string,
};

SearchableSelectField.defaultProps = {
  className: '',
  clearable: false,
  options: [],
  label: '',
  labelClassName: undefined,
  listBoxUpOptionsClassName: '',
  placeholder: '',
  required: false,
  truncateOptions: true,
  hideLabel: false,
  onInputChange: null,
  inputValue: undefined,
  hasError: false,
  errorMsg: '',
};

export default ReduxFormControlWrapper(SearchableSelectField);
