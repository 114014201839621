import _ from 'lodash';
import DEFAULT_ENUMS from 'reducers/constants/defaultEnums';

const enumDisplayName = (value, enumsPath) => {
  const path = enumsPath === 'people.gender' ? 'people.genders' : enumsPath;
  const pathEnums = _.wget(DEFAULT_ENUMS, path, []);
  const foundValue = pathEnums.find((element) => element.value === value);
  return foundValue?.display_name || '';
};

export default enumDisplayName;
