import React, { useEffect, useState } from 'react';
import { Link } from 'react-router';
import { usePopulate } from 'src/api/APIHooks';
import { get, noop, startCase } from 'lodash';
import PropTypes from 'prop-types';
import {
  DataCell,
  DataRow,
} from 'src/common/tables/V2';
import getTimeDifference from 'src/common/utils/Dates/getTimeDifference';
import EnrollmentStatusIndicator from 'src/pages/enrollments/components/EnrollmentStatusIndicator';
import { Checkbox } from 'src/common/TailwindComponents';
import useFeatureFlag from '../../../common/hooks/useFeatureFlag';

const EnrollmentsTableRow = ({
  isChecked,
  enrollmentRequest,
  openDrawer,
  showEnrollmentAging,
  showSelectMultipleEnrollments,
  toggleSelectedRow,
  checkDisabled,
}) => {
  const [isFetching, setFetching] = useState(true);
  const lastUpdated = new Date(enrollmentRequest.updated_at);
  usePopulate('plan', 'plan', enrollmentRequest, { queryConfig: { placeholderData: undefined } });
  usePopulate('person', 'person', enrollmentRequest, { queryConfig: { placeholderData: undefined } });
  const requestCreatedAtTimeStamp = enrollmentRequest.created_at;
  const requestCreatedAt = new Date(requestCreatedAtTimeStamp);
  const pays6096UpdateEWQDesign = useFeatureFlag('pays-6096-update-ewq-design');

  useEffect(() => {
    if (get(enrollmentRequest, 'plan.name') !== undefined && get(enrollmentRequest, 'person.id') !== undefined) {
      setFetching(false);
    }
  }, [get(enrollmentRequest, 'plan.name'), get(enrollmentRequest, 'person.id')]);

  return !isFetching && (
    <DataRow
      className="cursor-pointer hover:bg-light-fill-blue hover:bg-opacity-50"
      key={enrollmentRequest.id}
      onClick={openDrawer}
      dataTestId="workqueue-table-row"
    >
      {
        pays6096UpdateEWQDesign && showSelectMultipleEnrollments && (
          <DataCell>
            <Checkbox
              id={`select-enrollment-request-checkbox-${enrollmentRequest.short_id}`}
              ariaLabel={`Select enrollment request ${enrollmentRequest.short_id}`}
              className="select-enrollment-request-checkbox"
              checked={isChecked}
              onClick={(e) => e.stopPropagation()}
              onChange={toggleSelectedRow}
              disabled={checkDisabled}
            />
          </DataCell>
        )
      }
      <DataCell className="text-action-blue">
        <Link to={`/enrollments/${enrollmentRequest.id}`} className="underline">
          {enrollmentRequest.short_id}
        </Link>
      </DataCell>
      <DataCell>{startCase(enrollmentRequest.request_type)}</DataCell>
      <DataCell>{get(enrollmentRequest, 'person.last_name')}, {get(enrollmentRequest, 'person.first_name')}</DataCell>
      <DataCell>
        <EnrollmentStatusIndicator
          status={enrollmentRequest.enrollment_status}
        />
      </DataCell>
      <DataCell>{get(enrollmentRequest, 'plan.name')}</DataCell>
      <DataCell>
        {lastUpdated.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        })}
      </DataCell>
      {showEnrollmentAging && (
        <DataCell>
          {getTimeDifference(requestCreatedAt)}
        </DataCell>
      )}
    </DataRow>
  );
};

EnrollmentsTableRow.propTypes = {
  isChecked: PropTypes.bool,
  enrollmentRequest: PropTypes.object.isRequired,
  showEnrollmentAging: PropTypes.bool,
  openDrawer: PropTypes.func.isRequired,
  toggleSelectedRow: PropTypes.func,
  showSelectMultipleEnrollments: PropTypes.bool,
  checkDisabled: PropTypes.bool.isRequired,
};

EnrollmentsTableRow.defaultProps = {
  isChecked: false,
  showEnrollmentAging: false,
  toggleSelectedRow: noop,
  showSelectMultipleEnrollments: false,
};

export default EnrollmentsTableRow;
