import React from 'react';
import PropTypes from 'prop-types';
import { NoInvoicesFoundCard } from '../components';
import { InvoiceWorkqueue } from './InvoiceWorkqueue';
import { ACCEPTED_CBO_STATUSES } from '../constants';

const AcceptedInvoicesDWQ = ({ children }) => {
  const bulkActions = ['IconDownloadV2', 'IconArchive'];
  return (
    <InvoiceWorkqueue
      bulkActions={bulkActions}
      cboStatuses={ACCEPTED_CBO_STATUSES}
      renderNoInvoicesFound={({ isFiltered, resetFilters }) => (
        <NoInvoicesFoundCard isFiltered={isFiltered} status="accepted" resetFilters={resetFilters} />
      )}
      pageName="Accepted"
      path="accepted/"
    >
      {children}
    </InvoiceWorkqueue>
  );
};

AcceptedInvoicesDWQ.propTypes = {
  children: PropTypes.node,
};

AcceptedInvoicesDWQ.defaultProps = {
  children: undefined,
};
export default AcceptedInvoicesDWQ;
