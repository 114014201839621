import React from 'react';
import PropTypes from 'prop-types';
import { SelectField } from 'components/Backoffice/form/SelectField';
import { hourOnlyISODatetime } from './utils';

export const LastUpdatedFilter = ({
  className,
  onChange,
  value,
}) => {
  const lastUpdatedOptions = [1, 7, 14, 30, 60].map((val) => (
    {
      label: val > 1 ? `Last ${val} days` : 'Today',
      value: hourOnlyISODatetime(val),
    }
  ));

  return (
    <SelectField
      className={className}
      label="Last Updated"
      labelClassName="tracking-normal"
      onChange={({ value: val }) => onChange(val)}
      options={lastUpdatedOptions}
      placeholder="Choose Last Updated"
      value={value ?? ''}
      clearable
    />
  );
};

LastUpdatedFilter.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

LastUpdatedFilter.defaultProps = {
  className: undefined,
  value: undefined,
};
