import React from 'react';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { Button } from '@unite-us/app-components';
import { useIsCBOProvider, useIsNetworkLead, useIsPayer } from 'common/contexts/CurrentProviderContext';
import { useFeatureFlag } from 'common/hooks';
import { PayerProviderFilter } from './PayerProviderFilter';
import { ManagedProviderFilter } from './ManagedProviderFilter';
import { ServiceProvidedFilter } from './ServiceProvidedFilter';
import { InvoiceStatusFilter } from './InvoiceStatusFilter';
import { ShortIdFilter } from './ShortIdFilter';
import { LastUpdatedFilter } from './LastUpdatedFilter';
import { ClientNameFilter } from './ClientNameFilter';
import { PayerFilter } from './PayerFilter';
import { DisputeReasonFilter } from './DisputeReasonFilter';
import { DisputeResolutionReasonFilter } from './DisputeResolutionReasonFilter';
import { ScreeningNameFilter } from './ScreeningNameFilter';
import { InvoiceTypeFilter } from './InvoiceTypeFilter';
import { INVOICE_STATUSES } from '../../constants';
import { ServiceDeliveryDatesFilter } from './ServiceDeliveryDatesFilter';

const AllFiltersDrawer = ({
  availableStatuses,
  close,
  defaultFilterValues,
  filterSetters,
  filterValues,
  invoicesQuery,
  invoiceTypes,
  showArchived,
  showDisputed,
  showInvoiceType,
}) => {
  const isNetworkLead = useIsNetworkLead();
  const isPayer = useIsPayer();
  const isCBO = useIsCBOProvider();

  const showInvoiceDisputeWorkflow = useFeatureFlag('useInvoiceDisputeWorkflow');

  const showArchivedOrDisputed = showDisputed !== false || showArchived !== false;
  const showDisputeReason = showInvoiceDisputeWorkflow && (
    isNetworkLead || isPayer || showArchivedOrDisputed
  );
  const showDisputeResolution = showInvoiceDisputeWorkflow && (
    ((isNetworkLead || isPayer) ? !showDisputed : showArchivedOrDisputed
  ));
  const showSearchByScreeningName = invoiceTypes?.some((type) => type?.value === 'screening');

  const onSubmit = (values) => {
    filterSetters.setClientName(values.clientName);
    filterSetters.setDisputeReason(values.disputeReason);
    filterSetters.setDisputeResolution(values.disputeResolution);
    filterSetters.setFeeScheduleProgram(values.feeScheduleProgram);
    filterSetters.setFeeScheduleScreening(values.feeScheduleScreening);
    filterSetters.setLastUpdated(values.lastUpdated);
    filterSetters.setManagedProvider(values.managedProvider);
    filterSetters.setPayer(values.payer);
    filterSetters.setPayerProvider(values.payerProvider);
    filterSetters.setProvidedServiceEndsAt(values.providedServiceEndsAt);
    filterSetters.setProvidedServiceStartsAt(values.providedServiceStartsAt);
    filterSetters.setShortId(values.shortId);
    filterSetters.setStatus(values.status);
    filterSetters.setType(values.type);

    close();
  };

  return (
    <Form
      initialValues={filterValues}
      onSubmit={onSubmit}
      render={({ form, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className="py-12 pl-12 pr-6 space-y-4 antialiased" data-testid="invoice-workqueue-filter-drawer">
            <div className="text-text-blue">
              <h2 className="font-extrabold">
                Filters
              </h2>
              <p>
                Add filters to refine your search.
              </p>
            </div>
            <Field
              name="status"
              render={({ input }) => (
                <InvoiceStatusFilter
                  availableStatuses={availableStatuses}
                  label="Invoice Status"
                  labelClassName="tracking-normal"
                  showDisputed={showDisputed}
                  {...input}
                />
              )}
            />
            {showInvoiceType && (
              <Field
                name="type"
                render={({ input }) => (
                  <InvoiceTypeFilter invoiceTypes={invoiceTypes} labelClassName="tracking-normal" {...input} />
                )}
              />
            )}
            <Field
              name="shortId"
              render={({ input }) => (
                <ShortIdFilter invoicesQuery={invoicesQuery} {...input} />
              )}
            />
            <Field
              name="lastUpdated"
              render={({ input }) => (
                <LastUpdatedFilter {...input} />
              )}
            />
            {isPayer && (
              <Field
                name="payerProvider"
                render={({ input }) => (
                  <PayerProviderFilter labelClassName="tracking-normal" {...input} />
                )}
              />
            )}
            {isNetworkLead && (
              <Field
                name="managedProvider"
                render={({ input }) => (
                  <ManagedProviderFilter labelClassName="tracking-normal" {...input} />
                )}
              />
            )}
            {isCBO && (
              <Field
                name="feeScheduleProgram"
                render={({ input }) => (
                  <ServiceProvidedFilter labelClassName="tracking-normal" {...input} />
                )}
              />
            )}
            {showSearchByScreeningName && (
              <Field
                name="feeScheduleScreening"
                render={({ input }) => (
                  <ScreeningNameFilter {...input} />
                )}
              />
            )}
            <Field
              name="clientName"
              render={({ input }) => (
                <ClientNameFilter invoicesQuery={invoicesQuery} {...input} />
              )}
            />
            {!isPayer && (
              <Field
                name="payer"
                render={({ input }) => (
                  <PayerFilter {...input} />
                )}
              />
            )}
            {showDisputeReason && (
              <Field
                name="disputeReason"
                render={({ input }) => (
                  <DisputeReasonFilter {...input} />
                )}
              />
            )}
            {showDisputeResolution && (
              <Field
                name="disputeResolution"
                render={({ input }) => (
                  <DisputeResolutionReasonFilter {...input} />
                )}
              />
            )}
            <Field
              name="providedServiceStartsAt"
              render={({ input: startsAtInput }) => (
                <Field
                  name="providedServiceEndsAt"
                  render={({ input: endsAtInput }) => (
                    <ServiceDeliveryDatesFilter
                      startsAt={startsAtInput.value}
                      setStartsAt={startsAtInput.onChange}
                      endsAt={endsAtInput.value}
                      setEndsAt={endsAtInput.onChange}
                    />
                  )}
                />
              )}
            />
            <div className="flex flex-row justify-end space-x-4">
              <Button
                className="py-2 px-4 capitalize"
                label="clear all"
                link
                onClick={() => form.reset(defaultFilterValues)}
              />
              <Button
                className="capitalize"
                label="apply filters"
                type="submit"
                primary
              />
            </div>
          </div>
        </form>
      )}
    />
  );
};

AllFiltersDrawer.propTypes = {
  availableStatuses: PropTypes.arrayOf(PropTypes.oneOf(INVOICE_STATUSES)).isRequired,
  close: PropTypes.func.isRequired,
  defaultFilterValues: PropTypes.shape({
    clientName: PropTypes.string,
    disputeReason: PropTypes.string,
    disputeResolution: PropTypes.string,
    feeScheduleProgram: PropTypes.string,
    feeScheduleScreening: PropTypes.string,
    lastUpdated: PropTypes.string,
    managedProvider: PropTypes.string,
    payer: PropTypes.string,
    payerProvider: PropTypes.string,
    providedServiceEndsAt: PropTypes.string,
    providedServiceStartsAt: PropTypes.string,
    shortId: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  filterSetters: PropTypes.shape({
    setClientName: PropTypes.func.isRequired,
    setDisputeReason: PropTypes.func.isRequired,
    setDisputeResolution: PropTypes.func.isRequired,
    setFeeScheduleProgram: PropTypes.func.isRequired,
    setFeeScheduleScreening: PropTypes.func.isRequired,
    setLastUpdated: PropTypes.func.isRequired,
    setManagedProvider: PropTypes.func.isRequired,
    setPayer: PropTypes.func.isRequired,
    setPayerProvider: PropTypes.func.isRequired,
    setProvidedServiceEndsAt: PropTypes.func.isRequired,
    setProvidedServiceStartsAt: PropTypes.func.isRequired,
    setShortId: PropTypes.func.isRequired,
    setStatus: PropTypes.func.isRequired,
    setType: PropTypes.func.isRequired,
  }).isRequired,
  filterValues: PropTypes.shape({
    clientName: PropTypes.string,
    disputeReason: PropTypes.string,
    disputeResolution: PropTypes.string,
    feeScheduleProgram: PropTypes.string,
    feeScheduleScreening: PropTypes.string,
    lastUpdated: PropTypes.string,
    managedProvider: PropTypes.string,
    payer: PropTypes.string,
    payerProvider: PropTypes.string,
    providedServiceEndsAt: PropTypes.string,
    providedServiceStartsAt: PropTypes.string,
    shortId: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  invoicesQuery: PropTypes.object.isRequired,
  invoiceTypes: PropTypes.array,
  showArchived: PropTypes.oneOf([true, false, null]),
  showDisputed: PropTypes.oneOf([true, false, null]),
  showInvoiceType: PropTypes.bool,
};

AllFiltersDrawer.defaultProps = {
  invoiceTypes: [],
  showArchived: false,
  showDisputed: false,
  showInvoiceType: false,
};

export default AllFiltersDrawer;
