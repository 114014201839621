let ownedRoutes = {};

export function getOwnedRoutes() {
  return Object.freeze({ ...ownedRoutes });
}

export function collectOwnershipFromRoutes(routes) {
  ownedRoutes = {};
  function parseRouteComponent({
    props: {
      path,
      children,
      uuOwnedBy,
    },
  }, absolutePath = '') {
    // Check if nothing to see here.
    if (!path && !children && !uuOwnedBy) {
      return;
    }

    let concatenatedPath = absolutePath;

    if (path && (children || uuOwnedBy)) {
      // e.g: /foo(/bar/:baz), match the top level: /foo, discard the rest.
      const trimmedPath = path.split('(/')[0];
      // Add a backslash to the previous path if needed.
      if (!(/\/$/.test(concatenatedPath))) {
        concatenatedPath += '/';
      }

      if (concatenatedPath !== trimmedPath) {
        concatenatedPath += trimmedPath;
      }

      if (concatenatedPath !== '/') {
        concatenatedPath = concatenatedPath
          // Remove trailing slashes and closing parens.
          .replace(/\)|\/\)|\/$/g, '')
          // Replace route variables (like /:someId) with wildcards.
          .replace(/:(.*)(?=\/)|:(.*)/, '*');
      }

      if (typeof uuOwnedBy === 'string') {
        ownedRoutes[concatenatedPath] = uuOwnedBy;
      }
    }

    // Recurse!
    if (children) {
      if (children?.length) {
        children.forEach((route) => {
          parseRouteComponent(route, concatenatedPath);
        });
      } else {
        parseRouteComponent(children, concatenatedPath);
      }
    }
  }

  parseRouteComponent(routes);
}
