import { useFind } from 'src/api/APIHooks';
import { groupBy, map } from 'lodash';
import { useCurrentProviderId } from 'common/contexts/CurrentProviderContext';

const useFeeScheduleProgramOptions = () => {
  const providerId = useCurrentProviderId();
  const { data } = useFind(
    'fee_schedule_programs',
    {
      'programs.provider': providerId,
      billable: true,
    },
    {
      queryConfig: {
        enabled: !!providerId,
        placeholderData: undefined,
        refetchOnWindowFocus: false,
        staleTime: Infinity,
      },
    },
  );

  const groupByFSPName = groupBy(data?.data?.data ?? [], 'name');
  return map(groupByFSPName, (val, key) => ({
    label: key,
    value: map(val, 'id').join(','),
  }));
};

export default useFeeScheduleProgramOptions;
