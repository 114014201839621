import React from 'react';
import axios from 'axios';
import { get } from 'lodash';
import { updateGlobalState } from 'src/actions/Global/globalActions';
import getIntercomCompanies from 'src/common/utils/Session/utils/getIntercomCompanies';
import {
  FETCH_USER_FOR_SESSION_INITIATED,
  FETCH_USER_FOR_SESSION_SUCCESS,
  FETCH_USER_FOR_SESSION_FAILURE,
} from 'actions';
import { CORE_LEGACY_API_URL } from 'src/config/env/env.config';
import submitLogoutForm from 'src/common/utils/Auth/submitLogoutForm';
import Notifier from '../../../helpers/Notifier';
import ServerDisconnect from '../../SystemNotifications/components/ServerDisconnect';
import StillWorking from '../../SystemNotifications/components/StillWorking';
import ServerReconnect from '../../SystemNotifications/components/ServerReconnect';
import { RETRY_LIMIT } from '../../SystemNotifications/constants/systemNotifications';

function initiate(dispatch) {
  const dispatchObj = {
    type: FETCH_USER_FOR_SESSION_INITIATED,
  };

  dispatch(dispatchObj);
}

function onSuccess({
  response,
  retries,
  dispatch,
}) {
  if (!response || !response.data || !response.data.email) {
    console.error('LogoutLogs: FetchUserFromSessionId no response error. Logging out.');
    submitLogoutForm();
  }

  const intercomCompanies = getIntercomCompanies(response.data.groups);
  dispatch(updateGlobalState({
    intercom: {
      companies: intercomCompanies,
    },
  }));

  const updatedResponse = {
    ...response,
    data: {
      data: response.data,
    },
  };
  const returnObj = JSON.parse(JSON.stringify(updatedResponse));

  const dispatchObj = {
    type: FETCH_USER_FOR_SESSION_SUCCESS,
    payload: returnObj,
  };
  dispatch(dispatchObj);

  // If this was an attempt to reconnect to the server,
  // display a success notification.
  if (retries > 0) {
    const message = <ServerReconnect />;
    Notifier.handleSystemNotification('success', message, true);
  }

  return returnObj;
}

function onError({
  response,
  retries,
  dispatch,
}) {
  if (get(response, 'response.status') === 403) {
    console.error('LogoutLogs: FetchUserFromSessionId 403 error. Logging out.');
    submitLogoutForm();

    return response;
  }

  const dispatchObj = {
    type: FETCH_USER_FOR_SESSION_FAILURE,
    payload: response,
    retries: retries + 1,
  };

  dispatch(dispatchObj);

  const message = retries < RETRY_LIMIT ? <StillWorking /> : <ServerDisconnect />;
  const dismissable = retries >= RETRY_LIMIT;
  Notifier.handleSystemNotification('warning', message, dismissable);

  return response;
}

export default function fetchUserFromSessionId({
  retries,
  shouldInitiateDispatch = true,
}) {
  const request = axios.get('/current_user', { baseURL: CORE_LEGACY_API_URL });

  return (dispatch) => {
    if (shouldInitiateDispatch) {
      initiate(dispatch);
    }

    return request
      .then((success) => onSuccess({
        response: success,
        retries,
        dispatch,
      }))
      .catch((error) => onError({
        response: error,
        retries,
        dispatch,
      }));
  };
}
