import React from 'react';
import PropTypes from 'prop-types';
import { InvoiceWorkqueue } from './InvoiceWorkqueue';
import { DISPUTED_STATUSES } from '../constants';
import { NoInvoicesFoundCard } from '../components';

const DisputedInvoices = ({ children }) => {
  const bulkActions = ['IconDownloadV2'];

  return (
    <InvoiceWorkqueue
      bulkActions={bulkActions}
      cboStatuses={DISPUTED_STATUSES}
      networkLeadStatuses={DISPUTED_STATUSES}
      pageName="Disputed"
      path="disputed/"
      payerStatuses={DISPUTED_STATUSES}
      renderNoInvoicesFound={({ isFiltered, resetFilters }) => (
        <NoInvoicesFoundCard isFiltered={isFiltered} status="disputed" resetFilters={resetFilters} />
      )}
      showDisputed
    >
      {children}
    </InvoiceWorkqueue>
  );
};

DisputedInvoices.propTypes = {
  children: PropTypes.node,
};

DisputedInvoices.defaultProps = {
  children: undefined,
};
export default DisputedInvoices;
